import React from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import { Table, Button, Form, Modal, Input } from 'antd';

const titleName = styled.div`
  color: #00000080;
  font-size: 14;
  font-family: 'GraphikLCGMedium';
`;

const ConversionBody = ({ marathonEvents, setPage, page }) => {
  const navigate = useNavigate();

  const handleRowClick = ({ itemId, item }) => {
    // Navigate to the desired route
    navigate(`/conversionMarathonEventsClients/${itemId}`);
  };

  const columns = [
    {
      title: () => {
        return (
          <div
            style={{
              fontSize: 14,
              fontFamily: 'GraphikLCGMedium',
              color: 'gray',
            }}
          >
            Дата начала
          </div>
        );
      },
      dataIndex: 'startTime',
      width: '20%',
      render: (text, item) => <>{dayjs(text).format('DD.MM.YYYY')} </>,
    },
    {
      title: () => {
        return (
          <div
            style={{
              fontSize: 14,
              fontFamily: 'GraphikLCGMedium',
              color: 'gray',
            }}
          >
            Дата окончания
          </div>
        );
      },
      dataIndex: 'endTime',
      width: '20%',
      render: (text, item) => <>{dayjs(text).format('DD.MM.YYYY')} </>,
    },
    {
      title: () => {
        return (
          <div
            style={{
              fontSize: 14,
              fontFamily: 'GraphikLCGMedium',
              color: 'gray',
            }}
          >
            Количество участников
          </div>
        );
      },
      dataIndex: ['clientsCount'],
      width: '25%',
      render: (text, item) => <>{text}</>,
    },
    {
      title: () => {
        return (
          <div
            style={{
              fontSize: 14,
              fontFamily: 'GraphikLCGMedium',
              color: 'gray',
            }}
          >
            HP приобретен
          </div>
        );
      },
      dataIndex: ['hpBuyCount', 'clientsCount'],
      width: '20%',
      render: (text, item) => (
        <>
          {item.hpBuyCount}/{item.clientsCount}
        </>
      ),
    },
    {
      title: () => {
        return (
          <div
            style={{
              fontSize: 14,
              fontFamily: 'GraphikLCGMedium',
              color: 'gray',
            }}
          >
            Конверсия
          </div>
        );
      },
      dataIndex: ['hpBuyCount', 'clientsCount'],
      width: '20%',
      render: (text, item) => {
        if (item.hpBuyCount === 0) {
          return <>0%</>; // or any other desired value when hpBuyCount is zero
        }
        return <>{Math.round((item.hpBuyCount / item.clientsCount) * 100)}%</>;
      },
    },
  ];

  return (
    <>
      <Table
        dataSource={marathonEvents?.marathonEvents}
        columns={columns}
        rowKey={(item) => item.id}
        pagination={{
          defaultPageSize: 18,
          total: marathonEvents?.total,
          current: page,
          onChange: (page, pageSize) => {
            setPage(page);
          },
          showSizeChanger: false,
        }}
        onRow={(item) => ({
          onClick: () => handleRowClick({ itemId: item?.id, item: item }),
        })}
      />
    </>
  );
};

export default ConversionBody;
