import React from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';
import { FormControl, Select, MenuItem, Button } from '@mui/material';
import { styled as styledMui } from '@mui/system';
import { DatePicker } from 'antd';
import datePickerLocale from 'antd/es/date-picker/locale/ru_RU';
import { toast } from 'react-toastify';

import CancelLogo from '../../images/cancel.svg';
import ArrowLeft from '../../images/ArrowLeft.svg';

const Wrapper = styled.div`
  background-color: #fff;
  position: fixed;
  transition: all 0.3s;
  top: 0;
  right: ${(props) => (props.show ? '0' : '-30%')};
  width: 368px;
  height: 100%;
  z-index: 1;
  overflow-y: scroll;
`;

const Cancel = styled.img`
  cursor: pointer;
  margin-left: auto;
`;

const Header = styled.div`
  display: flex;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  padding: 20px 24px;
  position: sticky;
  top: 0;
`;

const Title = styled.div`
  font-family: 'GraphikLCGMedium';
  font-size: 20px;
  line-height: 24px;
`;

const BodyTitle = styled.div`
  font-family: 'GraphikLCGMedium';
  font-size: 16px;
  line-height: 16px;
  margin-bottom: 24px;
`;

const History = styled.button`
  display: flex;
  font-size: 14px;
  line-height: 16px;
  font-family: 'GraphikLCGMedium';
  border-radius: 8px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 10px 12px 10px 12px;
  margin-top: 24px;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  width: 86%;
  margin: 24px 24px;
`;

const ArrowLeftStyle = styled.img`
  cursor: pointer;
  padding-right: 8px;
`;

const Container = styled.div`
  margin: 8px 24px;
`;

const Label = styled.div`
  color: rgba(0, 0, 0, 0.5);
  font-family: 'GraphikLCGMedium';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  margin-bottom: 8px;
`;

const RefundBlock = styled.div``;

const Fileds = styled.div``;

const Filed = styled.div`
  margin-bottom: 24px;
`;

const FiledHorizontal = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
`;

const StyledFormControl = styledMui(FormControl)(({ theme }) => ({
  width: '100%',
  borderRadius: 8,
  backgroundColor: 'transparent',
}));

const RefundAmountBtn = styled.button`
  width: 100%;
  font-size: 14px;
  line-height: 16px;
  font-family: 'GraphikLCGMedium';
  border-radius: 8px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 10px 12px 10px 0px;
  cursor: pointer;
`;

const StyledChangeSumButton = styled.div`
  font-size: 14px;
  color: #6f2cff;
  background: #fff;
  border: #fff;
  cursor: pointer;
`;

const Input = styled.input`
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  width: 100%;
  padding: 10px;
  font-family: 'GraphikLCGMedium';
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 8px;
  &:focus {
    outline: none;
    border: 1px solid #6f2cff;
  }
`;

const RefundAmount = styled.div`
  display: flex;
  justify-content: end;
  font-family: 'GraphikLCGRegular';
  color: #fffff;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
`;

const OpenButton = styled.button`
  font-family: 'GraphikLCGMedium';
  font-size: 20px;
  line-height: 24px;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  cursor: pointer;
  width: 100%;
  height: 56px;
  background: #6f2cff;
  color: #fff;
  align-self: center;
`;

const HistoryProgram = ({
  refundSubModal,
  salesUsers,
  setSalesPerson,
  userCurrentHP,
  selectUser,
  setRefundSubModal,
  setSelectUser,
  setClientInfoModal,
  refundLetterTime,
  setRefundLetterTime,
  refundAmount,
  setRefundAmount,
  changeRefundAmount,
  setChangeRefundAmount,
  file,
  setFile,
  setComment,
  comment,
  setRefundConfirmationModel,
  refundAmountExplanation,
  payment,
  setMixedPayment,
  mixedPayment,
  cardAmount,
  cashAmount,
  setPayment,
  setCashAmount,
  setCardAmount,
}) => {
  const [selectSalesPerson, setSelectSalesPerson] =
    React.useState('Выберите менеджера');
  const [changeSum, setChangeSum] = React.useState(false);
  const [fileName, setFileName] = React.useState(null);

  const handleChangePayment = (event) => {
    setPayment(event.target.value);
    setCardAmount('0');
    setCashAmount('0');
  };

  const handleCashAmount = (event) => {
    setCashAmount(event.target.value);
    setChangeRefundAmount(parseInt(event.target.value) + parseInt(cardAmount));
  };

  const handleCardAmount = (event) => {
    setCardAmount(event.target.value);
    setChangeRefundAmount(parseInt(event.target.value) + parseInt(cashAmount));
  };

  const renderMixedType = (value) => {
    if (value === 'card') {
      return (
        <Input
          type="text"
          value={cardAmount}
          onChange={handleCardAmount}
          placeholder={'Картой'}
        />
      );
    } else if (value === 'cash') {
      return (
        <Input
          type="text"
          value={cashAmount}
          onChange={handleCashAmount}
          placeholder={'Наличные'}
        />
      );
    } 
  };

  return (
    <Wrapper show={refundSubModal}>
      <Header>
        <Title>{selectUser?.firstName}</Title>
        <Cancel
          src={CancelLogo}
          onClick={() => {
            setSelectUser();
            setClientInfoModal(false);
            setRefundSubModal(false);
            setRefundLetterTime(null);
            setRefundAmount(null);
            setChangeRefundAmount(null);
            setFile(null);
            setSalesPerson(null);
            setSelectSalesPerson('Выберите менеджера');
          }}
        />
      </Header>
      <History
        onClick={() => {
          setRefundSubModal(false);
        }}
      >
        <ArrowLeftStyle src={ArrowLeft} />
        Назад
      </History>
      <Container>
        <BodyTitle>История программ</BodyTitle>
        <Fileds>
          <Filed>
            <Label>Абонемент</Label>
            <StyledFormControl variant="standard">
              <Select
                MenuProps={{
                  disableScrollLock: true,
                }}
                value={userCurrentHP?.id}
                onChange={(event) => {
                  // setMixedSecondType(event.target.value)
                }}
                displayEmpty
                inputProps={{ 'aria-label': 'Without label' }}
                disableUnderline
                style={{
                  fontFamily: 'GraphikLCGMedium',
                  fontSize: 14,
                  borderRadius: 8,
                  border: '1px solid rgba(0, 0, 0, 0.1)',
                  paddingTop: 3,
                  paddingBottom: 3,
                  paddingLeft: 12,
                }}
              >
                <MenuItem
                  value={userCurrentHP?.id}
                  style={{
                    fontFamily: 'GraphikLCGMedium',
                    fontSize: 14,
                    maxWidth: 303,
                  }}
                >
                  {userCurrentHP?.heroPass?.name}(
                  {dayjs(userCurrentHP?.startTime).format('DD.MM.YYYY')}-{' '}
                  {dayjs(userCurrentHP?.endTime).format('DD.MM.YYYY')})
                </MenuItem>
              </Select>
            </StyledFormControl>
          </Filed>
          <FiledHorizontal>
            <Label>Дата покупки</Label>
            <Label style={{ color: 'black', fontFamily: 'GraphikLCGRegular' }}>
              {dayjs(userCurrentHP?.created_at).format('DD.MM.YYYY')}
            </Label>
          </FiledHorizontal>
          <Filed>
            <Label>Дата заявления</Label>
            <DatePicker
              locale={datePickerLocale}
              placeholder="Выберите дату"
              format="DD.MM.YYYY"
              onChange={(date) => {
                setRefundLetterTime(date);
              }}
              style={{
                color: 'black',
                width: '100%',
                borderRadius: 8,
              }}
            />
          </Filed>
          <Filed>
            <Label>Менеджер</Label>
            <StyledFormControl variant="standard">
              <Select
                value={selectSalesPerson}
                onChange={(event) => {
                  setSalesPerson(event.target.value);
                  setSelectSalesPerson(event.target.value);
                }}
                displayEmpty
                inputProps={{ 'aria-label': 'Without label' }}
                disableUnderline
                MenuProps={{
                  disableScrollLock: true,
                }}
                style={{
                  fontFamily: 'GraphikLCGMedium',
                  fontSize: 14,
                  borderRadius: 8,
                  border: '1px solid rgba(0, 0, 0, 0.1)',
                  paddingTop: 3,
                  paddingBottom: 3,
                  paddingLeft: 12,
                }}
              >
                <MenuItem
                  value="Выберите менеджера"
                  style={{
                    fontFamily: 'GraphikLCGMedium',
                    fontSize: 14,
                  }}
                  onClick={() => setSalesPerson(null)}
                >
                  Выберите менеджера
                </MenuItem>
                {salesUsers?.map((item, index) => {
                  return (
                    <MenuItem
                      key={item?.id}
                      value={item?.id}
                      style={{ fontFamily: 'GraphikLCGMedium', fontSize: 14 }}
                      onClick={() => {
                        setSalesPerson(item?.id);
                        setSelectSalesPerson(item?.id);
                      }}
                    >
                     {item?.username || item?.nickname}
                    </MenuItem>
                  );
                })}
              </Select>
            </StyledFormControl>
          </Filed>
          <Filed>
            <Input
              type="text"
              style={{ height: 80 }}
              value={comment}
              onChange={(event) => {
                setComment(event.target.value);
              }}
              placeholder={'Комментарий'}
            />
          </Filed>
          {refundAmount !== null && (
            <>
              <Label>Способ оплаты</Label>
              <StyledFormControl variant="standard">
                <Select
                  MenuProps={{
                    disableScrollLock: true,
                  }}
                  value={payment}
                  onChange={handleChangePayment}
                  displayEmpty
                  inputProps={{ 'aria-label': 'Without label' }}
                  disableUnderline
                  style={{
                    fontFamily: 'GraphikLCGMedium',
                    fontSize: 14,
                    borderRadius: 8,
                    border: '1px solid rgba(0, 0, 0, 0.1)',
                    paddingTop: 3,
                    paddingBottom: 3,
                    paddingLeft: 12,
                    marginBottom: 20
                  }}
                >
                  <MenuItem
                    value=""
                    style={{ fontFamily: 'GraphikLCGMedium', fontSize: 14 }}
                    onClick={() => {
                      setMixedPayment(false);
                    }}
                  >
                    Выберите способ оплаты
                  </MenuItem>
                  <MenuItem
                    style={{ fontFamily: 'GraphikLCGMedium', fontSize: 14 }}
                    value="card"
                    onClick={() => {
                      setMixedPayment(false);
                    }}
                  >
                    Картой
                  </MenuItem>
                  <MenuItem
                    style={{ fontFamily: 'GraphikLCGMedium', fontSize: 14 }}
                    value="cash"
                    onClick={() => {
                      setMixedPayment(false);
                    }}
                  >
                    Наличными
                  </MenuItem>
                  <MenuItem
                    style={{ fontFamily: 'GraphikLCGMedium', fontSize: 14 }}
                    value="mixed"
                    onClick={() => {
                      setMixedPayment(true);
                      setChangeSum(true);
                    }}
                  >
                    Смешанный вид оплаты
                  </MenuItem>
                </Select>
              </StyledFormControl>
              {mixedPayment && (
                <>
                  <div
                    style={{
                      border: '1px solid rgba(0, 0, 0, 0.1)',
                      padding: 10,
                      borderRadius: 8,
                      marginBottom: 8,
                    }}
                  >
                    <StyledFormControl variant="standard">
                      <div
                        style={{
                          fontFamily: 'GraphikLCGMedium',
                          fontSize: 14,
                          marginLeft: 12,
                          marginBottom: 8,
                        }}
                      >
                        Картой
                      </div>
                    </StyledFormControl>
                    {renderMixedType("card")}
                  </div>
                  <div
                    style={{
                      border: '1px solid rgba(0, 0, 0, 0.1)',
                      padding: 10,
                      borderRadius: 8,
                      marginBottom: 8,
                    }}
                  >
                    <StyledFormControl variant="standard">
                      <div
                        style={{
                          fontFamily: 'GraphikLCGMedium',
                          fontSize: 14,
                          marginLeft: 12,
                          marginBottom: 8,
                        }}
                      >
                        Наличными
                      </div>
                    </StyledFormControl>
                    {renderMixedType("cash")}
                  </div>
                </>
              )}
              <FiledHorizontal>
                <Label>Стоимость абонемента</Label>
                <RefundBlock>
                  <RefundAmount>{refundAmountExplanation.fullPrice}₸</RefundAmount>
                </RefundBlock>
              </FiledHorizontal>
              <FiledHorizontal>
                <Label>Штраф</Label>
                <RefundBlock>
                  <RefundAmount>-{refundAmountExplanation.fine}₸</RefundAmount>
                </RefundBlock>
              </FiledHorizontal>
              <FiledHorizontal>
                <Label>Использованные дни</Label>
                <RefundBlock>
                  <RefundAmount>-{refundAmountExplanation.usedAmount}₸</RefundAmount>
                </RefundBlock>
              </FiledHorizontal>
              {!changeSum &&(
                <FiledHorizontal>
                  <Label>Сумма к возврату</Label>
                  <RefundBlock>
                    <RefundAmount>{refundAmount}₸</RefundAmount>
                    <StyledChangeSumButton
                      onClick={() => {
                        setChangeSum(true);
                      }}
                    >
                      Изменить
                    </StyledChangeSumButton>
                  </RefundBlock>
                </FiledHorizontal>
              )}
            </>
          )}

          {changeSum && (
            <Filed>
              <Label>Абонемент</Label>
              <StyledFormControl variant="standard">
                <Input
                  type="number"
                  value={changeRefundAmount}
                  onChange={(event) => {
                    if(!mixedPayment){
                      setChangeRefundAmount(event.target.value);
                    }
                  }}
                  placeholder={`${refundAmount}₸`}
                />
              </StyledFormControl>
            </Filed>
          )}

          {refundAmount !== null && (
            <Filed>
              <label htmlFor="upload-photo">
                <input
                  style={{ display: 'none' }}
                  id="upload-photo"
                  name="upload-photo"
                  type="file"
                  onChange={(event) => {
                    const file = event?.target?.files[0];
                    console.log('file', file);

                    setFileName(event?.target?.files[0]?.name);
                    setFile(event?.target?.files[0]);
                  }}
                />
                <Button
                  variant="contained"
                  component="span"
                  style={{
                    width: '100%',
                    fontSize: 14,
                    fontFamily: 'GraphikLCGMedium',
                    backgroundColor: 'white',
                    boxShadow: 'none',
                    color: '#000000',
                    border: '1px solid rgba(0, 0, 0, 0.1)',
                    borderRadius: 8,
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    alignSelf: 'center',
                    textAlign: 'center',
                  }}
                >
                  {fileName ? fileName.slice(0, 24) : 'Прикрепить заявление'}
                </Button>
              </label>
            </Filed>
          )}
          {userCurrentHP?.id && refundLetterTime && refundAmount === null && (
            <Filed>
              <RefundAmountBtn
                onClick={() => setRefundAmount(refundAmountExplanation.refundAmount)}
              >
                Рассчитать сумму к возврату
              </RefundAmountBtn>
            </Filed>
          )}
          {refundAmount !== null && (
            <Filed>
              <OpenButton
                disabled={file !== null ? false : true}
                style={{ opacity: file !== null ? 1 : 0.5 }}
                onClick={() => {
                  if (parseFloat(changeRefundAmount) < 0){
                    toast.error('Измененная сумма не должна быть меньше 0');
                  }
                  else if (file !== null) {
                    setRefundConfirmationModel(true);
                  }
                }}
              >
                Оформить возврат
              </OpenButton>
            </Filed>
          )}
        </Fileds>
      </Container>
    </Wrapper>
  );
};

export default HistoryProgram;
