import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Table, Button, Divider } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import CreateHero from './CreateHero';
import EditHero from './EditHero';

const Heros = ({
  heros,
  levels,
  deleteHeroClick,
  addHeroClick,
  updateHeroClick
}) => {
  const [display, setDisplay] = useState('default');
  const [editHero, setEditHero] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);

  const columns = [
    {
      title: 'Имя',
      dataIndex: 'name'
    },
    {
      title: 'Описание',
      dataIndex: 'history'
    },
    {
      title: 'Действие',
      key: 'action',
      render: item => (
        <span>
          <Button
            type="link"
            onClick={() => {
              setDisplay('edit');
              setEditHero(item);
            }}
          >
            Редактировать
          </Button>
          <Divider type="vertical" />
        </span>
      )
    }
  ];

  const handleCreate = values => {
    addHeroClick(values);
    setModalVisible(false);
  };

  const handleUpdate = values => {
    updateHeroClick(editHero.id, values);
    setDisplay('default');
    setEditHero(null);
  };

  const handleDelete = () => {
    deleteHeroClick(editHero.id);
    setDisplay('default');
    setEditHero(null);
  };

  return (
    <>
      <Table
        dataSource={heros}
        columns={columns}
        title={() => (
          <div>
            <Button type="primary" onClick={() => setModalVisible(true)}>
              <PlusOutlined /> Новый персонаж
            </Button>
          </div>
        )}
      />
      <CreateHero
        levels={levels}
        title="Добавить новое Персонажа"
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        onCreate={handleCreate}
      />
      {display === 'edit' && editHero && (
        <EditHero
          title="Редактировать Персонажа"
          levels={levels}
          visible={display === 'edit'}
          onCancel={() => setDisplay('default')}
          onUpdate={handleUpdate}
          editHero={editHero}
          onDelete={handleDelete}
        />
      )}
    </>
  );
};

Heros.propTypes = {
  heros: PropTypes.shape({
    name: PropTypes.string,
    attendancePoints: PropTypes.number,
    maxPoints: PropTypes.number,
    perfectWeekPoints: PropTypes.number,
    visitsForPerfectWeek: PropTypes.number
  }).isRequired,
  levels: PropTypes.arrayOf().isRequired,
  deleteHeroClick: PropTypes.func.isRequired,
  addHeroClick: PropTypes.func.isRequired,
  updateHeroClick: PropTypes.func.isRequired
};

export default Heros;
