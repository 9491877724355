import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Table, Button, Divider } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import EditForm from '../shared/EditFormModal';
import CreateForm from '../shared/CreateForm';

const PromptDescriptions = ({
  promptDescriptions,
  deletePromptDescriptionClick,
  updatePromptDescriptionClick,
}) => {
  const [display, setDisplay] = useState('default');
  const [editPromptDescription, setEditPromptDescription] = useState(null);

  const columns = [
    {
      title: 'Агент',
      dataIndex: 'agent_name',
    },
    {
      title: 'Тип prompt',
      dataIndex: 'prompt_type',
    },
    {
      title: 'Максимальный кол-во токенов',
      dataIndex: 'maxTokens',
    },
    {
      title: 'Температура',
      dataIndex: 'temperature',
    },
    {
      title: 'Действие',
      key: 'action',
      render: (text, item) => (
        <span>
          <Button
            type="link"
            onClick={() => {
              setDisplay('edit');
              setEditPromptDescription(item);
            }}
          >
            Редактировать
          </Button>
          <Divider type="vertical" />
        </span>
      ),
    },
  ];

  const editFields = [
    {
      key: 'agent_name',
      label: 'Агент',
      value: editPromptDescription && editPromptDescription.agent_name,
      isRequired: true,
      tooltipText: 'Названия Агента',
    },
    {
      key: 'prompt_type',
      label: 'Тип prompt',
      value: editPromptDescription && editPromptDescription.prompt_type,
      isRequired: true,
      tooltipText: 'Тип prompt',
      options: [
        { value: 'agent', label: 'agent' },
        { value: 'situation', label: 'situation' },
      ],
    },
    {
      key: 'maxTokens',
      label: 'Макс.кол-во токенов',
      value: editPromptDescription && editPromptDescription.maxTokens,
      number: true,
      isRequired: true,
      tooltipText: 'Макс.кол-во токенов(0-4096)',
    },
    {
      key: 'temperature',
      label: 'Температура',
      value: editPromptDescription && editPromptDescription.temperature,
      number: true,
      isRequired: true,
      tooltipText: 'Температура(0-1)',
    },
    {
      key: 'text',
      label: 'Базовый текст для бота',
      textArea: true,
      value: editPromptDescription && editPromptDescription.text,
      tooltipText: 'Базовый текст для бот',
    },
  ];

  const handleUpdate = (values) => {
    updatePromptDescriptionClick(editPromptDescription.id, values);
    setDisplay('default');
    setEditPromptDescription(null);
  };

  const handleDelete = () => {
    deletePromptDescriptionClick(editPromptDescription.id);
    setDisplay('default');
    setEditPromptDescription(null);
  };

  return (
    <>
      <Table dataSource={promptDescriptions} columns={columns} />
      {display === 'edit' && editPromptDescription && (
        <EditForm
          width={1080}
          title="Редактировать оплату"
          visible={display === 'edit'}
          onCancel={() => setDisplay('default')}
          onUpdate={handleUpdate}
          onDelete={handleDelete}
          fields={editFields}
        />
      )}
    </>
  );
};

PromptDescriptions.propTypes = {
  promptDescriptions: PropTypes.shape({
    name: PropTypes.string,
    attendancePoints: PropTypes.number,
    maxPoints: PropTypes.number,
    perfectWeekPoints: PropTypes.number,
    visitsForPerfectWeek: PropTypes.number,
  }).isRequired,
  deletePromptDescriptionClick: PropTypes.func.isRequired,
  addPromptDescriptionClick: PropTypes.func.isRequired,
  updatePromptDescriptionClick: PropTypes.func.isRequired,
};

export default PromptDescriptions;
