import React, { useState } from 'react';
import { Table, Button, Modal, Form, Input } from 'antd';
import styled from 'styled-components';
import { PlusOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

import StyledTooltip from '../shared/StyledTooltip';

const FormItem = Form.Item;

const StyledHeaderContainer = styled.div`
  display: flex;
  justify-content: flex-start;
`;
const Contests = ({ contests, handleAddContest, handleDeleteContest }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [form] = Form.useForm();

  const columns = [
    {
      title: 'Название конкурса',
      dataIndex: 'name',
    },
    {
      title: 'Описание',
      dataIndex: 'description',
    },
    {
      title: 'Действие',
      key: 'action',
      render: (text, item) => (
        <span>
          <Link to={`/contests/${item.id}`}>Редактировать</Link>
        </span>
      ),
    },
  ];

  const handleCreate = (values) => {
    handleAddContest(values);
    setModalVisible(false);
  };

  const getTooltip = (text, fullText) => {
    return <StyledTooltip {...{ text, fullText }} />;
  };

  return (
    <>
      <Table
        dataSource={contests}
        columns={columns}
        rowKey={(item) => item.id}
        title={() => (
          <StyledHeaderContainer>
            <Button type="primary" onClick={() => setModalVisible(true)}>
              <PlusOutlined />
              Новый конкурс
            </Button>
          </StyledHeaderContainer>
        )}
      />
      <Modal
        open={modalVisible}
        title="Новый конкурс"
        okText="Создать"
        cancelText="Закрыть"
        onCancel={() => setModalVisible(false)}
        onOk={() => {
          form
            .validateFields()
            .then((values) => {
              form.resetFields();

              handleCreate(values);
            })
            .catch((info) => {
              // eslint-disable-next-line no-console
              console.log('Validate Failed:', info);
            });
        }}
      >
        <Form form={form} layout="vertical">
          <FormItem
            key="name"
            label={getTooltip('Название конкурса', 'Название конкурса')}
            name="name"
            rules={[
              {
                required: true,
                message: `Пожалуйста, напишите название конкурса`,
              },
            ]}
          >
            <Input placeholder="Название" />
          </FormItem>
          <FormItem
            key="description"
            name="description"
            label={getTooltip('Описание конкурса', 'Описание конкурса')}
            rules={[
              {
                required: true,
                message: `Пожалуйста, выберите классы`,
              },
            ]}
          >
            <Input placeholder="Описание" />
          </FormItem>
        </Form>
      </Modal>
    </>
  );
};

export default Contests;
