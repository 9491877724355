import React from 'react';
import { Layout, Menu, Breadcrumb, Button, Table } from 'antd';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {
  InfoCircleOutlined,
  GatewayOutlined,
  StarOutlined
} from '@ant-design/icons';

import EditEventModal from './EditEventModal';
import CurrentEventForm from './CurrentEventForm';

const { Sider, Content } = Layout;

const StyledBreadcrumb = styled(Breadcrumb)`
  margin: 16px 0;
`;

const StyledHeader = styled.div`
  background: #fff;
  border-bottom: 1px solid #d9d9d9;
  padding: 16px 24px 0;
  display: flex;
  justify-content: space-between;
`;

const StyledMenu = styled(Menu)`
  height: 100%;
  padding-top: 16px;
`;

const StyledLayout = styled(Layout)`
  padding: 0 24px 24px;
  background: #fff;
`;

const StyledContent = styled(Content)`
  padding: 24px;
  margin: 0px;
  min-height: 280px;
`;

const EditEvent = ({
  event,
  clubs,
  trainers,
  programSets,
  stationName,
  users,
  bookings,
  dataSource,
  okAddHandler,
  okEditHandler,
  addBookingModal,
  setAddBookingModal,
  editBookingModal,
  setEditBookingModal,
  deleteButtonHandler,
  eventLevelsObj,
  checkInUserHandler,
  reviews,
  refetchUsers,
  updateEvent,
  addEvent,
  deleteEvent,
  notificationChangeTrainer
}) => {
  const [currentTab, setCurrentTab] = React.useState(1);
  const [stationExercise, setStationExercise] = React.useState(null);

  const handleTabChange = activeKey => {
    setCurrentTab(activeKey);
  };

  const openEditHandler = item => {
    if (item.nickname === 'Свободно') {
      setAddBookingModal(true);
    } else {
      setEditBookingModal(true);
    }
    setStationExercise(item);
  };

  const closeAddBookingModal = () => {
    setAddBookingModal(false);
    setStationExercise(null);
  };

  const closeEditBookingModal = () => {
    setEditBookingModal(false);
    setStationExercise(null);
  };

  const columns = [
    {
      title: 'Станция',
      dataIndex: 'station',
      width: '10%'
    },
    {
      title: 'Название станции',
      dataIndex: 'stationName',
      width: '40%'
    },
    {
      title: 'Пользователь',
      dataIndex: 'nickname',
      width: '25%',
      render: (_, station) => {
        if (station?.isEmpty) {
          return <span>{`${station?.nickname}`}</span>;
        }
        return (
          <span>
            <Link
              to={`/users/${station?.userId}`}
            >{`${station?.nickname} | ${station?.email} | ${station?.userBooking?.level?.name}`}</Link>
          </span>
        );
      }
    },
    {
      title: 'Действие',
      dataIndex: 'booking',
      width: '25%',
      render: (_, item) => {
        return (
          <span>
            <Button type="link" onClick={() => openEditHandler(item)}>
              {item.nickname === 'Свободно' ? 'Добавить' : 'Редактировать'}
            </Button>
          </span>
        );
      }
    },
    {
      title: 'Чекин',
      dataIndex: 'booking',
      width: '25%',
      render: (_, item) => {
        return (
          <span>
            <Button
              type="link"
              onClick={() => checkInUserHandler({ userId: item.userId })}
            >
              {item.nickname === 'Свободно' ? '' : 'Сделать чекин'}
            </Button>
          </span>
        );
      }
    }
  ];

  const reviewColumns = [
    {
      title: 'Оценка',
      dataIndex: 'value',
      width: '10%',
      render: item => {
        return (
          <span>
            {item && (
              <>
                {item}
                <span role="img" aria-label="star">
                  ⭐
                </span>
              </>
            )}
          </span>
        );
      }
    },
    {
      title: 'Комментарий',
      dataIndex: 'comment'
    }
  ];

  return (
    <Layout>
      <StyledBreadcrumb>
        <Breadcrumb.Item>
          <Link to="/events" href="/">
            События
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{event.programSet?.name}</Breadcrumb.Item>
      </StyledBreadcrumb>

      <StyledHeader>
        <h2>
          Редактирование события({bookings.length}/{dataSource.length})
        </h2>
      </StyledHeader>

      <Layout>
        <Sider>
          <StyledMenu mode="inline" defaultSelectedKeys={['1']}>
            <Menu.Item
              icon={<InfoCircleOutlined />}
              key="1"
              onClick={() => handleTabChange(1)}
            >
              Основные
            </Menu.Item>
            <Menu.Item
              icon={<GatewayOutlined />}
              key="2"
              onClick={() => handleTabChange(2)}
            >
              Участники
            </Menu.Item>
            <Menu.Item
              icon={<StarOutlined />}
              key="3"
              onClick={() => handleTabChange(3)}
            >
              Оценки
            </Menu.Item>
          </StyledMenu>
        </Sider>
        <StyledLayout>
          {currentTab === 1 && event && (
            <CurrentEventForm
              event={event}
              clubs={clubs}
              trainers={trainers}
              programSets={programSets}
              updateEvent={updateEvent}
              addEvent={addEvent}
              deleteEvent={deleteEvent}
              notificationChangeTrainer={notificationChangeTrainer}
            />
          )}
          <StyledContent className="site-layout-background">
            {currentTab === 2 && (
              <>
                <Table
                  dataSource={dataSource}
                  rowKey={item => item.id + item.identifier}
                  columns={columns}
                />
                {(addBookingModal || editBookingModal) && (
                  <EditEventModal
                    visible={addBookingModal || editBookingModal}
                    isEdit={editBookingModal}
                    deleteButtonHandler={deleteButtonHandler}
                    setVisible={
                      (addBookingModal && closeAddBookingModal) ||
                      (editBookingModal && closeEditBookingModal)
                    }
                    users={users}
                    stationExercise={stationExercise}
                    okHandler={addBookingModal && okAddHandler}
                    refetchUsers={refetchUsers}
                  />
                )}
              </>
            )}
            {currentTab === 3 && (
              <Table
                dataSource={reviews}
                rowKey={item => item.id}
                columns={reviewColumns}
              />
            )}
          </StyledContent>
        </StyledLayout>
      </Layout>
    </Layout>
  );
};

EditEvent.propTypes = {
  users: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      nickname: PropTypes.string
    })
  ).isRequired,
  event: PropTypes.shape({
    startTime: PropTypes.string,
    endTime: PropTypes.string,
    programSet: PropTypes.shape({
      name: PropTypes.string
    })
  }).isRequired,
  clubs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string
    })
  ).isRequired,
  trainers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      nickname: PropTypes.string
    })
  ).isRequired,
  programSets: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string
    })
  ).isRequired,
  dataSource: PropTypes.arrayOf(PropTypes.object).isRequired,
  addBookingModal: PropTypes.bool.isRequired,
  setAddBookingModal: PropTypes.func.isRequired,
  okAddHandler: PropTypes.func.isRequired,
  editBookingModal: PropTypes.bool.isRequired,
  setEditBookingModal: PropTypes.func.isRequired,
  deleteButtonHandler: PropTypes.func.isRequired,
  initialEditEmail: PropTypes.string,
  stationName: PropTypes.string,
  checkInUserHandler: PropTypes.func.isRequired
};

EditEvent.defaultProps = {
  stationName: '',
  initialEditEmail: ''
};

export default EditEvent;
