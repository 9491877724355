import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Form, Input, Button } from 'antd';

import StyledTooltip from '../shared/StyledTooltip';

const FormItem = Form.Item;

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const StyledInput = styled(Input)`
  width: 320px;
`;

const ProgramSetTime = ({ programSet, form, onFinish }) => {
  const getTooltip = (text, fullText) => {
    return <StyledTooltip {...{ text, fullText }} />;
  };

  return (
    <MainContainer>
      <h2>Время</h2>
      <Form form={form} layout="vertical" hideRequiredMark onFinish={onFinish}>
        <FormItem
          key="transitionTimeRounds"
          label={getTooltip(
            'Время на переход (сек.)',
            'Время которое дается на переход между станциями'
          )}
          name="transitionTimeRounds"
          rules={[
            {
              required: false,
              message: `Пожалуйста, напишите время на переход между раундами(сек.)`
            }
          ]}
          initialValue={programSet.transitionTimeRounds}
        >
          <StyledInput placeholder="Время на переход(сек.) между раундами" />
        </FormItem>
        {programSet?.isStrength && (
          <FormItem
            key="transitionTimeSetsForStrength"
            label={getTooltip(
              'Время на переход (сек.) в каждом подходе для Strength',
              'Напишите через пробел Время на подходе (сек.) в каждом раунде для Strength'
            )}
            name="transitionTimeSetsForStrength"
            rules={[
              {
                required: true,
                message: `Пожалуйста, заполните Время на переход (сек.) в каждом раунде для Strength`
              }
            ]}
            initialValue={programSet?.transitionTimeSetsForStrength?.join(' ')}
          >
            <StyledInput placeholder="Время на переход (сек.)" />
          </FormItem>
        )}
        <FormItem
          key="transitionTimeSets"
          label={getTooltip(
            'Время на отдых (сек.)',
            'Время которое дается на отдых между подходами'
          )}
          name="transitionTimeSets"
          rules={[
            {
              required: false,
              message: `Пожалуйста, напишите время на отдых между подходами`
            }
          ]}
          initialValue={programSet.transitionTimeSets}
        >
          <StyledInput placeholder="Время на отдых между подходами" />
        </FormItem>
        <FormItem
          key="timeGreen"
          label={getTooltip(
            'Время "вверх" (мс.)',
            'Время за которое необходимо выполнить подъем вверх'
          )}
          name="timeGreen"
          rules={[
            {
              required: false,
              message: 'Пожалуйста, напишите время "вверх"'
            }
          ]}
          initialValue={programSet.timeGreen}
        >
          <StyledInput placeholder='Время "вверх"' />
        </FormItem>
        <FormItem
          key="timeRed"
          label={getTooltip(
            'Время "вниз" (мс.)',
            'Время за которое необходимо выполнить спуск вниз'
          )}
          name="timeRed"
          rules={[
            {
              required: false,
              message: 'Пожалуйста, напишите время "вниз"'
            }
          ]}
          initialValue={programSet.timeRed}
        >
          <StyledInput placeholder='Время "вниз"' />
        </FormItem>
        <FormItem
          key="introDuration"
          label={getTooltip(
            'Длительность интро',
            'Длительность интро звука во время тренировки'
          )}
          name="introDuration"
          rules={[
            {
              required: false,
              message: 'Пожалуйста, напишите длительность интро'
            }
          ]}
          initialValue={programSet.introDuration}
        >
          <StyledInput placeholder="Длительность интро" />
        </FormItem>
        {programSet.type === 'endGame' && (
          <>
            <FormItem
              key="firstCycleStationCount"
              label={getTooltip(
                'Кол-во станции в первом цикле',
                'Кол-во станции в первом цикле'
              )}
              name="firstCycleStationCount"
              rules={[
                {
                  required: false,
                  message: `Пожалуйста, напишите кол-во станции в первом цикле`
                }
              ]}
              initialValue={programSet.firstCycleStationCount}
            >
              <StyledInput placeholder="Время на переход(сек.) между раундами для второго цикла" />
            </FormItem>
            <FormItem
              key="secondTransitionTimeRounds"
              label={getTooltip(
                'Время на переход для второго цикла(сек.)',
                'Время которое дается на переход между станциями для второго цикла'
              )}
              name="secondTransitionTimeRounds"
              rules={[
                {
                  required: false,
                  message: `Пожалуйста, напишите время на переход между раундами(сек.) для второго цикла`
                }
              ]}
              initialValue={programSet.secondTransitionTimeRounds}
            >
              <StyledInput placeholder="Время на переход(сек.) между раундами для второго цикла" />
            </FormItem>
            <FormItem
              key="secondTransitionTimeSets"
              label={getTooltip(
                'Время на отдых (сек.) для второго цикла',
                'Время которое дается на отдых между подходами для второго цикла'
              )}
              name="secondTransitionTimeSets"
              rules={[
                {
                  required: false,
                  message: `Пожалуйста, напишите время на отдых между подходами для второго цикла`
                }
              ]}
              initialValue={programSet.secondTransitionTimeSets}
            >
              <StyledInput placeholder="Время на отдых между подходами для второго цикла" />
            </FormItem>
            <FormItem
              key="secondTimeGreen"
              label={getTooltip(
                'Время "вверх" (мс.) для второго цикла',
                'Время за которое необходимо выполнить подъем вверх для второго цикла'
              )}
              name="secondTimeGreen"
              rules={[
                {
                  required: false,
                  message:
                    'Пожалуйста, напишите время "вверх" для второго цикла'
                }
              ]}
              initialValue={programSet.secondTimeGreen}
            >
              <StyledInput placeholder='Время "вверх" для второго цикла' />
            </FormItem>
            <FormItem
              key="secondTimeRed"
              label={getTooltip(
                'Время "вниз" (мс.) для второго цикла',
                'Время за которое необходимо выполнить спуск вниз для второго цикла'
              )}
              name="secondTimeRed"
              rules={[
                {
                  required: false,
                  message: 'Пожалуйста, напишите время "вниз" для второго цикла'
                }
              ]}
              initialValue={programSet.secondTimeRed}
            >
              <StyledInput placeholder='Время "вниз" для второго цикла' />
            </FormItem>
            <FormItem
              key="transitionTimeCycle"
              label={getTooltip(
                'Время на переход (сек.) между циклами',
                'Время которое дается на переход между циклами'
              )}
              name="transitionTimeCycle"
              rules={[
                {
                  required: false,
                  message:
                    'Пожалуйста, напишите время на переход между циклами(сек.)'
                }
              ]}
              initialValue={programSet.transitionTimeCycle}
            >
              <StyledInput placeholder="Время на переход (сек.) между циклами" />
            </FormItem>
          </>
        )}

        <FormItem>
          <ButtonContainer>
            <Button type="primary" htmlType="submit">
              Сохранить изменения
            </Button>
          </ButtonContainer>
        </FormItem>
      </Form>
    </MainContainer>
  );
};

ProgramSetTime.propTypes = {
  programSet: PropTypes.shape({
    transitionTimeRounds: PropTypes.number,
    transitionTimeSets: PropTypes.number,
    timeRep: PropTypes.number,
    timeGreen: PropTypes.number,
    timeRed: PropTypes.number,
    introDuration: PropTypes.number
  }).isRequired,
  form: PropTypes.shape({}).isRequired,
  onFinish: PropTypes.func.isRequired
};

export default ProgramSetTime;
