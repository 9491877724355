import React from 'react';
import { Input, Layout, Menu, Breadcrumb, Table, Button, Divider } from 'antd';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {
  InfoCircleOutlined,
  PlusOutlined,
  PictureOutlined,
} from '@ant-design/icons';
import dayjs from 'dayjs';
import 'dayjs/locale/ru';

import CreateUserPhoto from './CreateUserPhoto';
import EditUserPhoto from './EditUserPhoto';

const { Sider, Content } = Layout;

const StyledBreadcrumb = styled(Breadcrumb)`
  margin: 16px 0;
`;

const StyledHeader = styled.div`
  background: #fff;
  border-bottom: 1px solid #d9d9d9;
  padding: 16px 24px 0;
  display: flex;
  justify-content: space-between;
`;

const StyledMenu = styled(Menu)`
  height: 100%;
  padding-top: 16px;
`;

const StyledLayout = styled(Layout)`
  padding: 0 24px 24px;
  background: #fff;
`;

const StyledContent = styled(Content)`
  padding: 24px;
  margin: 0px;
  min-height: 280px;
`;

const StyledInput = styled(Input)`
  width: 300px;
  margin-bottom: 20px;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 250px;
`;

const UserPhoto = ({
  user,
  userPhotos,
  addUserPhotoClick,
  deleteUserPhotoClick,
  updateUserPhotoClick,
  updateUserClick,
  stages,
  bodyTests,
}) => {
  dayjs.locale('ru');

  const [photoList, setPhotoList] = React.useState(null);
  const [currentTab, setCurrentTab] = React.useState(1);
  const [display, setDisplay] = React.useState('default');
  const [modalVisible, setModalVisible] = React.useState(false);
  const [editUserPhoto, setEditUserPhoto] = React.useState(null);
  const [curStage, setCurStage] = React.useState(null);
  const [occupation, setOccupation] = React.useState(user?.occupation);

  React.useEffect(() => {
    const descriptivePhotos = userPhotos.map((userPhoto) => {
      return {
        id: userPhoto.id,
        stageNumber: userPhoto.stage?.stageNumber,
        contestName: userPhoto.contest?.name,
      };
    });

    setPhotoList(descriptivePhotos);
  }, [userPhotos]);

  const handleTabChange = (activeKey) => {
    setCurrentTab(activeKey);
  };

  const columns = [
    {
      title: 'Названия Конкурса',
      dataIndex: 'contestName',
    },
    {
      title: 'Номер фотки',
      dataIndex: 'stageNumber',
    },
    {
      title: 'Действие',
      key: 'action',
      render: (item) => (
        <span>
          <Button
            type="link"
            onClick={() => {
              setDisplay('edit');
              setEditUserPhoto(
                userPhotos.find((userPhoto) => userPhoto.id === item.id)
              );
            }}
          >
            Редактировать
          </Button>
          <Divider type="vertical" />
        </span>
      ),
    },
  ];

  const handleCreate = (values) => {
    if (curStage) {
      addUserPhotoClick({
        ...values,
        stage: curStage,
      });
      setModalVisible(false);
      return;
    }
    addUserPhotoClick(values);
    setModalVisible(false);
  };

  const handleUpdate = (values) => {
    updateUserPhotoClick(editUserPhoto.id, values);
    setDisplay('default');
    setEditUserPhoto(null);
  };

  const handleDelete = () => {
    deleteUserPhotoClick(editUserPhoto.id);
    setDisplay('default');
    setEditUserPhoto(null);
  };

  return (
    <Layout>
      <StyledBreadcrumb>
        <Breadcrumb.Item>
          <Link to="/userPhotos" href="/">
            Пользователи
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          {' '}
          {user?.nickname ? user?.nickname : 'Этот пользователь'}
        </Breadcrumb.Item>
      </StyledBreadcrumb>

      <StyledHeader>
        <h2>Фотки</h2>
      </StyledHeader>

      <Layout>
        <Sider>
          <StyledMenu mode="inline" defaultSelectedKeys={['1']}>
            <Menu.Item
              icon={<PictureOutlined />}
              key="1"
              onClick={() => handleTabChange(1)}
            >
              Фотки
            </Menu.Item>
            <Menu.Item
              icon={<InfoCircleOutlined />}
              key="2"
              onClick={() => handleTabChange(2)}
            >
              Профессия
            </Menu.Item>
          </StyledMenu>
        </Sider>
        <StyledLayout>
          <StyledContent className="site-layout-background">
            {currentTab === 1 && (
              <>
                <Table
                  dataSource={photoList}
                  columns={columns}
                  title={() => (
                    <div>
                      <Column>
                        {/* <StyledSelect
                          value={curStage}
                          onChange={value => setCurStage(value)}
                          placeholder="Стадия"
                        >
                          {stages.map(stage => (
                            <Select.Option value={stage.id}>
                              {`${stage.stageNumber}|${dayjs(
                                stage.startTime
                              ).format('Do MMMM, YYYY')}-${dayjs(
                                stage.endTime
                              ).format('Do MMMM, YYYY')}`}
                            </Select.Option>
                          ))}
                        </StyledSelect> */}

                        <Button
                          type="primary"
                          onClick={() => setModalVisible(true)}
                        >
                          <PlusOutlined /> Новое Фото
                        </Button>
                      </Column>
                    </div>
                  )}
                />
                <CreateUserPhoto
                  title="Добавить новую Фотку"
                  visible={modalVisible}
                  onCancel={() => setModalVisible(false)}
                  onCreate={handleCreate}
                />
                {display === 'edit' && editUserPhoto && (
                  <EditUserPhoto
                    title="Редактировать Фотку"
                    visible={display === 'edit'}
                    onCancel={() => setDisplay('default')}
                    onUpdate={handleUpdate}
                    editUserPhoto={editUserPhoto}
                    onDelete={handleDelete}
                    bodyTests={bodyTests}
                  />
                )}
              </>
            )}
            {currentTab === 2 && (
              <Column>
                <h3> Профессия: {user?.occupation} </h3>
                <StyledInput
                  value={occupation}
                  placeholder="occupation"
                  onChange={(e) => {
                    setOccupation(e.target.value);
                  }}
                />

                <Button
                  type="primary"
                  onClick={() => updateUserClick(occupation)}
                >
                  Обновить профессию
                </Button>
              </Column>
            )}
          </StyledContent>
        </StyledLayout>
      </Layout>
    </Layout>
  );
};

UserPhoto.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.string.isRequired,
    nickname: PropTypes.string,
  }).isRequired,
  userPhotos: PropTypes.arrayOf(PropTypes.object).isRequired,
  addUserPhotoClick: PropTypes.func.isRequired,
  deleteUserPhotoClick: PropTypes.func.isRequired,
  updateUserPhotoClick: PropTypes.func.isRequired,
  updateUserClick: PropTypes.func.isRequired,
  stages: PropTypes.arrayOf(
    PropTypes.shape({
      stageNumber: PropTypes.number,
    })
  ).isRequired,
  bodyTests: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      testDate: PropTypes.string,
      fatPercent: PropTypes.number,
    })
  ).isRequired,
};

export default UserPhoto;
