import React from 'react';
import styled from 'styled-components';

import { useLoading } from './useLoading';
import Spinner from '../pages/shared/Spinner';

const MainContainer = styled.div`
  z-index: 1000;
  position: absolute;
  width: 100%;
  height: 120vh;
  background: rgba(247, 251, 255, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

function LoadingDialog() {
  const { display } = useLoading();
  React.useEffect(() => {
    if (display) {
      document.body.style.overflow = 'hidden';
      document.body.style.width = 'calc(100% - 20px)';
    } else {
      document.body.style.overflow = '';
      document.body.style.width = '';
    }
  }, [display]);

  return (
    display && (
      <MainContainer>
        <Spinner />
      </MainContainer>
    )
  );
}

export default LoadingDialog;
