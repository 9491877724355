import React from 'react';
import { Layout, Menu, Breadcrumb } from 'antd';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {
  InfoCircleOutlined,
  CheckOutlined,
  CalendarOutlined,
  MessageOutlined,
  InsertRowAboveOutlined
} from '@ant-design/icons';
import MarathonGeneral from './MarathonGeneral';
import MarathonTasks from './MarathonTasks';
import MarathonLectures from './MarathonLectures';
import MarathonEvents from './MarathonEvents';
import RecommendPage from './RecommendPage';
import ActiveMarathons from './ActiveMarathons';

const { Sider, Content } = Layout;

const StyledBreadcrumb = styled(Breadcrumb)`
  margin: 16px 0;
`;

const StyledHeader = styled.div`
  background: #fff;
  border-bottom: 1px solid #d9d9d9;
  padding: 16px 24px 0;
  display: flex;
  justify-content: space-between;
`;

const StyledMenu = styled(Menu)`
  height: 100%;
  padding-top: 16px;
`;

const StyledLayout = styled(Layout)`
  padding: 0 24px 24px;
  background: #fff;
`;

const StyledContent = styled(Content)`
  padding: 24px;
  margin: 0px;
  min-height: 280px;
`;

const Marathon = ({
  marathon,
  marathonEvents,
  marathonEventsCnt,
  page,
  setPage,
  handleUpdateMarathon,
  handleUpdateMarathonFloors,
  handleUpdateMarathonAwards,
  handleDeleteMarathon,
  handleAddTask,
  handleDeleteTask,
  handleUpdateTask,
  handleAddMarathonEvent,
  handleUpdateMarathonEvent,
  handleDeleteMarathonEvent,
  handleAddLecture,
  handleDeleteLecture,
  handleUpdateLecture,
  trainers,
  awards,
  lectures,
  heroPathes,
  setClubs,
  clubs,
  setChoosedClub,
  choosedClub,
  handleUpdatePlanMarathon
}) => {
  const [currentTab, setCurrentTab] = React.useState(1);

  const handleTabChange = activeKey => {
    setCurrentTab(activeKey);
  };
    return (
    <Layout>
      <StyledBreadcrumb>
        <Breadcrumb.Item>
          <Link to="/marathons" href="/">
            Марафоны
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item> {marathon?.name}</Breadcrumb.Item>
      </StyledBreadcrumb>

      <StyledHeader>
        <h2>Марафон</h2>
      </StyledHeader>

      <Layout>
        <Sider>
          <StyledMenu mode="inline" defaultSelectedKeys={['1']}>
            <Menu.Item
              icon={<InfoCircleOutlined />}
              key="1"
              onClick={() => handleTabChange(1)}
            >
              Основные
            </Menu.Item>
            <Menu.Item
              icon={<CheckOutlined />}
              key="2"
              onClick={() => handleTabChange(2)}
            >
              Задания
            </Menu.Item>
            <Menu.Item
              icon={<MessageOutlined />}
              key="3"
              onClick={() => handleTabChange(3)}
            >
              Лекции
            </Menu.Item>
            <Menu.Item
              icon={<CalendarOutlined />}
              key="5"
              onClick={() => handleTabChange(5)}
            >
              Рекомендованный план
            </Menu.Item>
            <Menu.Item
              icon={<CalendarOutlined />}
              key="4"
              onClick={() => handleTabChange(4)}
            >
              Промежутки марафона
            </Menu.Item>
          </StyledMenu>
        </Sider>
        <StyledLayout>
          <StyledContent className="site-layout-background">
            {currentTab === 1 && (
              <MarathonGeneral
                {...{
                  marathon,
                  handleUpdateMarathon,
                  handleUpdateMarathonAwards,
                  handleUpdateMarathonFloors,
                  handleDeleteMarathon,
                  awards,
                  heroPathes
                }}
              />
            )}
            {currentTab === 2 && (
              <MarathonTasks
                {...{
                  handleAddTask,
                  handleDeleteTask,
                  handleUpdateTask,
                  tasks: marathon?.tasks,
                  clubs,
                  setClubs,
                  setChoosedClub,
                  choosedClub,
                }}
              />
            )}
            {currentTab === 3 && (
              <MarathonLectures
                {...{
                  handleAddLecture,
                  handleDeleteLecture,
                  handleUpdateLecture,
                  lectures
                }}
              />
            )}
            {currentTab === 4 && (
              <MarathonEvents
                {...{
                  marathonEvents,
                  marathonEventsCnt,
                  page,
                  setPage,
                  marathonId: marathon?.id,
                  handleAddMarathonEvent,
                  handleUpdateMarathonEvent,
                  handleDeleteMarathonEvent,
                  trainers
                }}
              />
            )}
            {currentTab === 5 && (
              <RecommendPage
                {...{
                  handleAddTask,
                  handleDeleteTask,
                  handleUpdateTask,
                  tasks: marathon?.planRecomended,
                  clubs,
                  setClubs,
                  setChoosedClub,
                  choosedClub,
                  handleUpdatePlanMarathon,
                }}
              />
            )}
          </StyledContent>
        </StyledLayout>
      </Layout>
    </Layout>
  );
};

Marathon.propTypes = {
  marathon: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string,
    tasks: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired
      })
    ).isRequired,
    marathonEvents: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired
      })
    ).isRequired
  }).isRequired,
  handleUpdateMarathon: PropTypes.func.isRequired,
  handleUpdateMarathonAwards: PropTypes.func.isRequired,
  handleDeleteMarathon: PropTypes.func.isRequired,
  handleAddTask: PropTypes.func.isRequired,
  handleDeleteTask: PropTypes.func.isRequired,
  handleUpdateTask: PropTypes.func.isRequired,
  handleAddLecture: PropTypes.func.isRequired,
  handleDeleteLecture: PropTypes.func.isRequired,
  handleUpdateLecture: PropTypes.func.isRequired,
  handleAddMarathonEvent: PropTypes.func.isRequired,
  handleUpdateMarathonEvent: PropTypes.func.isRequired,
  handleDeleteMarathonEvent: PropTypes.func.isRequired,
  trainers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      nickname: PropTypes.string.isRequired
    })
  ).isRequired,
  awards: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired
    })
  ).isRequired,
  lectures: PropTypes.arrayOf(PropTypes.shape()).isRequired
};

export default Marathon;
