import React from 'react';
import PropTypes from 'prop-types';
import { gql, useLazyQuery } from '@apollo/client';

import { Table } from 'antd';
import styled from 'styled-components';
import dayjs from 'dayjs';
import 'dayjs/locale/ru';

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const GET_DATA = gql`
  query getData($userId: ID, $query: PaginationQuery) {
    paymentsByUser(userId: $userId, query: $query) {
      userPayments {
        id
        type
        name
        cost
        heroPass {
          id
          name
          price
        }
        marathonEvent {
          id
          startTime
          endTime
          marathon {
            id
            name
            price
          }
        }
        recurrentPayment {
          id
          name
        }
        payment {
          id
          name
          cost
          countClasses
          ticketType
        }
        created_at
      }

      total
      limit
      page
      pages
    }
  }
`;

const UserPayments = ({ userId }) => {
  const [userPayments, setUserPayments] = React.useState([]);
  const [total, setTotal] = React.useState(0);
  const [page, setPage] = React.useState(1);
  dayjs.locale('ru');

  const [getData, { data, error, loading, refetch }] = useLazyQuery(GET_DATA, {
    fetchPolicy: 'no-cache',
  });

  React.useEffect(() => {
    getData({
      variables: {
        userId: userId,
        query: {
          page: page,
          limit: 10,
        },
      },
    });
  }, []);

  React.useEffect(() => {
    refetch({
      userId: userId,
      query: {
        page: page,
        limit: 10,
      },
    });
  }, [page]);

  React.useEffect(() => {
    if (data && data.paymentsByUser && !loading) {
      setUserPayments(data.paymentsByUser.userPayments);
      setTotal(data.paymentsByUser.total);
    } else if (error) {
      console.log(`error `, error);
    }
  }, [data, loading, error]);

  const userPaymentColumns = [
    {
      title: 'Дата',
      width: '30%',
      render: (text, item) => {
        const paymentDate = dayjs(item.created_at).format('Do MMMM, YYYY');
        return <span>{paymentDate} </span>;
      },
    },
    {
      title: 'Пакет',
      render: (_, item) => {
        let text = item?.name;
        if (!item?.name) {
          if (item?.payment) {
            text = `Покупка ${item?.payment?.countClasses} билетов ${
              item?.payment?.ticketType === 'regular'
                ? 'для клиентов Invictus'
                : 'для всех'
            }`;
          }
          if (item?.heroPass) {
            text = `Покупка HeroPass "${item?.heroPass?.name}"`;
          }
          if (item?.marathonEvent && item?.type !== 'addedFromHeroPass') {
            text = `Покупка Марафона "${
              item?.marathonEvent?.marathon?.name
            }" c ${dayjs(item?.marathonEvent?.startTime).format(
              'Do MMMM'
            )} до ${dayjs(item?.marathonEvent?.endTime).format(
              'Do MMMM, YYYY'
            )}`;
          }
          if (item?.type === 'addedFromHeroPass') {
            text = `Приобретение Марафона через HeroPass "${
              item?.marathonEvent?.marathon?.name
            }" c ${dayjs(item?.marathonEvent?.startTime).format(
              'Do MMMM'
            )} до ${dayjs(item?.marathonEvent?.endTime).format(
              'Do MMMM, YYYY'
            )}`;
          }
        }

        return <span>{text}</span>;
      },
    },
    {
      title: 'Цена',
      render: (text, item) => {
        let cost = item?.cost;
        if (!item?.cost) {
          if (item?.payment) {
            cost = item?.payment?.cost;
          }
          if (item?.heroPass) {
            cost = item?.heroPass?.price;
          }
          if (item?.marathonEvent && item?.type !== 'addedFromHeroPass') {
            cost = item?.marathonEvent?.marathon?.price;
          }
        }

        return (
          <span>
            {item?.type === 'addedFromHeroPass' ? 'Через HeroPass' : cost}
          </span>
        );
      },
    },
  ];

  return (
    <MainContainer>
      <h2> Пополнения </h2>
      <Table
        dataSource={userPayments}
        columns={userPaymentColumns}
        rowKey={(item) => item.id}
        loading={loading}
        pagination={{
          defaultPageSize: 10,
          total: total,
          current: page,
          onChange: (page, pageSize) => {
            setPage(page);
          },
          showSizeChanger: false,
        }}
      />
    </MainContainer>
  );
};

UserPayments.propTypes = {
  userId: PropTypes.string.isRequired,
};

export default UserPayments;
