import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  Layout,
  Menu,
  Breadcrumb,
  Button,
  Form,
  Input,
  Modal,
  Checkbox,
} from 'antd';
import {
  InfoCircleOutlined,
  GatewayOutlined,
  PlusOutlined,
  MinusOutlined,
} from '@ant-design/icons';

import EmailEditor from 'react-email-editor';

import StyledTooltip from '../shared/StyledTooltip';

const FormItem = Form.Item;

const { Sider, Content } = Layout;
const { TextArea } = Input;

const StyledBreadcrumb = styled(Breadcrumb)`
  margin: 16px 0;
`;

const StyledHeader = styled.div`
  background: #fff;
  border-bottom: 1px solid #d9d9d9;
  padding: 0px 24px;
  display: flex;
  justify-content: space-between;
`;

const StyledMenu = styled(Menu)`
  height: 100%;
  padding-top: 16px;
`;
const StyledLayout = styled(Layout)`
  padding: 0 24px 24px;
  background: #fff;
`;

const StyledContent = styled(Content)`
  padding: 24px;
  margin: 0px;
  min-height: 280px;
`;
const HeaderTitle = styled.div`
  font-family: SF Pro Display;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;

  display: flex;
  align-items: center;

  color: #262626;
  margin-bottom: 24px;
`;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 4 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 20 },
  },
};

const getFields = (quesstion) => {
  const options = quesstion.options.map((option) => {
    return option.option;
  });
  const correctAnswers = quesstion.options.filter(
    (option) => option.isCorrect === true
  );
  return {
    options,
    correctAnswers: correctAnswers.map((correctAnswer) => {
      return correctAnswer.option;
    }),
  };
};

const CreateLecture = ({ visible, onCancel, onCreate }) => {
  const [currentTab, setCurrentTab] = useState(1);
  const [form] = Form.useForm();

  const handleTabChange = (activeKey) => {
    setCurrentTab(activeKey);
  };

  const emailEditorRef = React.useRef(null);

  const exportHtml = () => {
    if (emailEditorRef.current) {
      emailEditorRef.current.editor.exportHtml((data) => {
        const { design, html: contentHtml } = data;
        const templateJson = JSON.stringify(design);
        onCreate({ html: contentHtml, json: templateJson });
      });
    }
  };

  const getTooltip = (text, fullText) => {
    return <StyledTooltip {...{ text, fullText }} />;
  };

  return (
    <Modal
      open={visible}
      title="Добавить новую лекцию"
      onCancel={() => onCancel()}
      width={1800}
    >
      <Layout>
        <StyledBreadcrumb>
          <Breadcrumb.Item>Лекция</Breadcrumb.Item>
          <Breadcrumb.Item>Создание Лекции</Breadcrumb.Item>
        </StyledBreadcrumb>

        <StyledHeader>
          <h2>Создание Лекции</h2>
        </StyledHeader>

        <Layout>
          <Sider>
            <StyledMenu mode="inline" defaultSelectedKeys={['1']}>
              <Menu.Item
                icon={<InfoCircleOutlined />}
                key="1"
                onClick={() => handleTabChange(1)}
              >
                Основные
              </Menu.Item>
              <Menu.Item
                icon={<GatewayOutlined />}
                key="2"
                onClick={() => handleTabChange(2)}
              >
                Текст Лекции
              </Menu.Item>
            </StyledMenu>
          </Sider>
          <StyledLayout>
            <StyledContent className="site-layout-background">
              {currentTab === 1 && (
                <>
                  <HeaderTitle>Основные</HeaderTitle>
                  <Form form={form} layout="vertical" hideRequiredMark>
                    <FormItem
                      key="title"
                      label={getTooltip('Заголовок', 'Заголовок Лекции')}
                      name="title"
                    >
                      <Input placeholder="Заголовок" />
                    </FormItem>
                    <FormItem
                      key="weekNumber"
                      label={getTooltip('Номер недели', 'Номер недели')}
                      name="weekNumber"
                    >
                      <Input placeholder="Номер недели" />
                    </FormItem>
                    <Form.List
                      name="questions"
                      rules={[
                        {
                          validator: async (_, names) => {
                            if (!names || names.length < 1) {
                              return Promise.reject(
                                new Error('At least 2 passengers')
                              );
                            }
                          },
                        },
                      ]}
                    >
                      {(questions, { add, remove }, { errors }) => (
                        <>
                          {questions &&
                            questions.map((question, index) => (
                              <Form.Item
                                {...(index === 0
                                  ? formItemLayout
                                  : formItemLayout)}
                                label={index === 0 ? 'Вопросы' : ''}
                                required={false}
                                key={question.key}
                              >
                                <Form.Item
                                  {...question}
                                  name={[question.name, 'text']}
                                  fieldKey={[question.fieldKey, 'text']}
                                  rules={[
                                    {
                                      required: true,
                                      message: 'Missing history name',
                                    },
                                  ]}
                                >
                                  <TextArea row={4} />
                                </Form.Item>
                                {/* <Form.Item
                                  name={[question.name, 'isMultipleAnswers']}
                                  valuePropName="checked"
                                >
                                  <Checkbox>
                                    Несколько правильных ответов
                                  </Checkbox>
                                </Form.Item> */}
                                <Form.List name={[question.name, 'options']}>
                                  {(
                                    options,
                                    { add: addOption, remove: removeOption },
                                    { errors: errorsOption }
                                  ) => (
                                    <>
                                      {options &&
                                        options.map((option, id) => (
                                          <Form.Item
                                            {...(id === 0
                                              ? formItemLayout
                                              : formItemLayout)}
                                            label={id === 0 ? 'варианты' : ''}
                                            required={false}
                                            key={option.key}
                                          >
                                            <Form.Item
                                              {...option}
                                              name={[option.name, 'option']}
                                              fieldKey={[
                                                option.fieldKey,
                                                'option',
                                              ]}
                                              rules={[
                                                {
                                                  required: true,
                                                  message:
                                                    'Missing history name',
                                                },
                                              ]}
                                            >
                                              <Input />
                                            </Form.Item>
                                            <Form.Item
                                              name={[option.name, 'isCorrect']}
                                              valuePropName="checked"
                                            >
                                              <Checkbox>
                                                Правильный ответ
                                              </Checkbox>
                                            </Form.Item>
                                            <Button
                                              danger
                                              onClick={() =>
                                                removeOption(option.name)
                                              }
                                              style={{ width: '60%' }}
                                              icon={<MinusOutlined />}
                                            >
                                              Удалить Вариант
                                            </Button>
                                          </Form.Item>
                                        ))}
                                      <Form.Item>
                                        <Button
                                          type="dashed"
                                          onClick={() => addOption()}
                                          style={{ width: '60%' }}
                                          icon={<PlusOutlined />}
                                        >
                                          Добавить Вариант
                                        </Button>
                                        <Form.ErrorList errors={errorsOption} />
                                      </Form.Item>
                                    </>
                                  )}
                                </Form.List>
                                <Button
                                  danger
                                  onClick={() => remove(question.name)}
                                  style={{ width: '60%' }}
                                  icon={<MinusOutlined />}
                                >
                                  Удалить Вопрос
                                </Button>
                              </Form.Item>
                            ))}

                          <Form.Item>
                            <Button
                              type="dashed"
                              onClick={() => add()}
                              style={{ width: '60%' }}
                              icon={<PlusOutlined />}
                            >
                              Добавить Вопрос
                            </Button>
                            <Form.ErrorList errors={errors} />
                          </Form.Item>
                        </>
                      )}
                    </Form.List>
                    <Button
                      type="primary"
                      onClick={() => {
                        form
                          .validateFields()
                          .then((values) => {
                            form.resetFields();

                            values.questions = values.questions.map(
                              (question) => {
                                const { options, correctAnswers } =
                                  getFields(question);
                                return {
                                  options,
                                  correctAnswers,
                                  text: question.text,
                                  isMultipleAnswers: correctAnswers.length > 1,
                                };
                              }
                            );
                            onCreate({
                              ...values,
                              weekNumber: parseInt(values.weekNumber, 10),
                            });
                          })
                          .catch((info) => {
                            console.log('Validate Failed:', info);
                          });
                      }}
                    >
                      Создать
                    </Button>
                  </Form>
                </>
              )}
              {currentTab === 2 && (
                <>
                  <EmailEditor ref={emailEditorRef} />
                  <Button type="primary" onClick={exportHtml}>
                    Создать
                  </Button>
                </>
              )}
            </StyledContent>
          </StyledLayout>
        </Layout>
      </Layout>
    </Modal>
  );
};
CreateLecture.propTypes = {
  addClick: PropTypes.func.isRequired,
  allUsers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      email: PropTypes.string,
    })
  ).isRequired,
  allLevels: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string,
      degree: PropTypes.number,
    })
  ).isRequired,
};
export default CreateLecture;
