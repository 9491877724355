import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import ExportExcel from '../../../UI/ExportExcel';

const Table = styled.table`
  width: 100%;
  height: max-content;
  border: 1px solid #000000;
  border-collapse: collapse;

  font-family: url('../../fonts/GraphikLCG-Medium');
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
`;

const StyledTd = styled.td`
  border: 1px solid #000000;
`;

const StyledTh = styled.th`
  border: 1px solid #000000;
`;

const ReceptionEvent = ({ users }) => {
  if (users?.length === 0) {
    return <h1> Никто не зареган</h1>;
  }

  const userData = users?.map((user, index) => {
    return {
      "#": index + 1,
      "Никнейм": user?.nickname,
      "Номер телефона": user?.phoneNumber,
      "email": user?.email,
      "Зашел?": user?.hasEntered ? 'Да' : 'Нет',
      "Вышел?": user?.hasExited ? 'Да' : 'Нет',
    };
  });

  return (
    <>
      <ExportExcel data={userData} styles={{position: 'absolute', top: '20%', right: '3%'}}/>
      <Table>
        <tr>
          <StyledTh> # </StyledTh>
          <StyledTh> Никнейм </StyledTh>
          <StyledTh> Номер телефона </StyledTh>
          <StyledTh> email </StyledTh>
          <StyledTh> Зашел? </StyledTh>
          <StyledTh> Вышел? </StyledTh>
        </tr>
        {users.map((user, index) => (
          <tr key={user?.nickname}>
            <StyledTd>{index + 1}</StyledTd>
            <StyledTd>{user?.nickname}</StyledTd>
            <StyledTd>{user?.phoneNumber}</StyledTd>
            <StyledTd>{user?.email}</StyledTd>
            <StyledTd>{user?.hasEntered ? 'Да' : 'Нет'}</StyledTd>
            <StyledTd>{user?.hasExited ? 'Да' : 'Нет'}</StyledTd>
          </tr>
        ))}
      </Table>
    </>
  );
};

ReceptionEvent.propTypes = {
  users: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      nickname: PropTypes.string,
      email: PropTypes.string,
      phoneNumber: PropTypes.string,
      hasEntered: PropTypes.bool,
      hasExited: PropTypes.bool
    })
  ).isRequired
};

export default ReceptionEvent;
