import * as React from "react";
const PhoneIcon = (props) => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g opacity={props.opacity}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.23727 8.76274C6.45727 7.98274 5.86927 7.10941 5.4786 6.22341C5.39593 6.03608 5.4446 5.81674 5.58927 5.67208L6.13527 5.12674C6.5826 4.67941 6.5826 4.04674 6.19193 3.65608L5.40927 2.87341C4.8886 2.35274 4.0446 2.35274 3.52393 2.87341L3.08927 3.30808C2.59527 3.80208 2.38927 4.51474 2.5226 5.22141C2.85193 6.96341 3.86393 8.87074 5.4966 10.5034C7.12927 12.1361 9.0366 13.1481 10.7786 13.4774C11.4853 13.6107 12.1979 13.4047 12.6919 12.9107L13.1259 12.4767C13.6466 11.9561 13.6466 11.1121 13.1259 10.5914L12.3439 9.80941C11.9533 9.41874 11.3199 9.41874 10.9299 9.80941L10.3279 10.4121C10.1833 10.5567 9.96393 10.6054 9.7766 10.5227C8.8906 10.1314 8.01727 9.54274 7.23727 8.76274Z"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);
export default PhoneIcon;
