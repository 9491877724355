import React from 'react';
import { MenuItem } from '@mui/material';
import { FormControl } from '@mui/material';
import { Select } from '@mui/material';
import styled from 'styled-components';
import { styled as styledMui } from '@mui/system';
import { toast } from 'react-toastify';

const SelectUser = styled.input`
  border-radius: 8px;
  border: none;
  padding: 8px;
  font-family: 'GraphikLCGMedium';
  font-size: 14px;
  line-height: 16px;
  &:focus {
    outline: none;
    border: none;
  }
`;
const Dropdown = styled.div`
  display: ${(props) => (props.show ? 'block' : 'none')};
  ${'' /* position: absolute; */}
  width: 92%;
  background: #fff;
  box-shadow: 1px 10px 90px #000;
  transition: all 1s;
  overflow-y: scroll;
  max-height: 350px;
  border-radius: 8px;
`;
const List = styled.div`
  font-family: 'DINProMedium';
  border-top: none;
  font-size: 16px;
  padding: 3%;
  border-top: none;
  line-height: 24px;
  color: #000;
  cursor: pointer;
  &:hover {
    background: rgba(0, 0, 0, 0.1);
  }
`;
const OpenButton = styled.button`
  font-family: 'GraphikLCGMedium';
  font-size: 14px;
  line-height: 16px;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  cursor: pointer;
  width: 100%;
  height: 40px;
  background: #6f2cff;
  color: #fff;
  align-self: center;
  margin-top: 8px;
`;
const CloseButton = styled.button`
  font-family: 'GraphikLCGMedium';
  font-size: 14px;
  line-height: 16px;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  cursor: pointer;
  width: 100%;
  height: 40px;
  background: #ffff;
  align-self: center;
  margin-top: 8px;
  margin-bottom: 32px;
`;

const PaymentContainer = styled.div`
  margin: 8px 24px;
`;

const BodyTitle = styled.div`
  font-family: 'GraphikLCGMedium';
  font-size: 16px;
  line-height: 16px;
  margin-top: 3%;
  margin-bottom: 24px;
`;

const Field = styled.div`
  margin-top: 10px;
  margin-bottom: 8px;
  margin-right: auto;
  font-family: 'GraphikLCGMedium';
  font-size: 14px;
  line-height: 16px;
  color: #00000080;
`;

const StyledSumContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 10px;
`;

const StyledChangeSumButton = styled.button`
  font-size: 14px;
  color: #6f2cff;
  background: #fff;
  border: #fff;
  cursor: pointer;
  margin-bottom: 10px;
  width: 70px;
`;

const CustomPrice = styled.p`
  font-family: 'GraphikLCGMedium';
  font-size: 14px;
  height: 10px;
  text-align: right;
`;

const PriceText = styled.p`
  font-family: 'GraphikLCGMedium';
  font-size: 14px;
  color: #00000080;
`;

const Input = styled.input`
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  width: 100%;
  padding: 10px;
  font-family: 'GraphikLCGMedium';
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 8px;
  &:focus {
    outline: none;
    border: 1px solid #6f2cff;
  }
`;

const StyledFormControl = styledMui(FormControl)(({ theme }) => ({
  width: '100%',
  borderRadius: 8,
  backgroundColor: 'transparent',
  marginBottom: 8,
  border: '1px solid rgba(0, 0, 0, 0.1)',
  padding: 3,
}));

const ClientsReReg = ({
  reRegUsers,
  setSelectedUserReissue,
  selectedUserReissue,
  setSearchReissueClient,
  SetSecondPage,
  secondPage,
  salesUsers,
  setSalesPerson,
  setIsReRegisterHeroPass,
  setPaymentModal,
  payment,
  setMixedPayment,
  mixedPayment,
  cardAmount,
  cashAmount,
  creditAmount,
  setPayment,
  setTotalPrice,
  totalPrice,
  setCashAmount,
  setCardAmount,
  setCreditAmount,
  setCompanyBin,
  companyBin,
  selectUser,
  setSelectedGym,
  selectedGym,
  reRegAmount,
  setReRegSearch,
  clubs,
  reRegType,
  setReRegType,
}) => {
  const [showUsers, setShowUsers] = React.useState(false);
  const [value, setValue] = React.useState('');
  const [valueSalesPerson, setValueSalesPerson] = React.useState('Выберите менеджера');
  const [company, setCompany] = React.useState(false);
  const [selectedCity, setSelectedCity] = React.useState('Выберите город');
  // const [selectedGym, setSelectedGym] = React.useState('Выберите зал')
  const [changeSum, setChangeSum] = React.useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = React.useState(true);
  const [mixedFirstType, setMixedFirstType] = React.useState('');
  const [mixedSecondType, setMixedSecondType] = React.useState('');

  const checkIfAllFieldsAreFilled = () => {
    if (reRegType === 'location') {
      return (
        value !== '' &&
        selectedCity !== 'Выберите город' &&
        selectedGym &&
        payment !== '' &&
        valueSalesPerson !== 'Выберите менеджера'
      );
    } else if (reRegType === 'user') {
      return (
        selectedUserReissue?.nickname &&
        payment !== '' &&
        valueSalesPerson !== 'Выберите менеджера'
      );
    }
    return false;
  };

  React.useEffect(() => {
    setIsButtonDisabled(!checkIfAllFieldsAreFilled());
  }, [value, selectedCity, selectedGym, payment, valueSalesPerson, selectedUserReissue]);

  const onSelect = (user) => {
    setSelectedUserReissue(user);
  };
  const showListUsers = () => {
    setShowUsers(true);
  };

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const handleSalesPerson = (event) => {
    setValueSalesPerson(event.target.value);
  };
  const handleChangePayment = (event) => {
    setPayment(event.target.value);
    setCardAmount('0');
    setCashAmount('0');
    setCreditAmount('0');
  };
  const handleCityChange = (event) => {
    setSelectedCity(event.target.value);
  };

  const handleGymChange = (event) => {
    if(event.target.value.id == selectUser?.club?.id){
      toast.error('Данный пользователь числится в этом клубе');
    }
    else{
      setSelectedGym(event.target.value);
    }
  };

  const handlePriceChange = (event) => {
    setTotalPrice(event.target.value);
  };

  const handleChangeMixedFirst = (event) => {
    setMixedFirstType(event.target.value);
  };

  const handleChangeMixedSecond = (event) => {
    setMixedSecondType(event.target.value);
  };

  const handleCompanyBin = (event) => {
    setCompanyBin(event.target.value);
  };

  const handleCashAmount = (event) => {
    setCashAmount(event.target.value);
    if (
      cardAmount &&
      (mixedFirstType === 'card' || mixedSecondType === 'card')
    ) {
      setTotalPrice(parseInt(cardAmount) + parseInt(event.target.value));
    } else if (
      creditAmount &&
      (mixedFirstType === 'credit' || mixedSecondType === 'credit')
    ) {
      setTotalPrice(parseInt(creditAmount) + parseInt(event.target.value));
    } else {
      setTotalPrice(parseInt(event.target.value));
    }
  };
  const handleCardAmount = (event) => {
    setCardAmount(event.target.value);
    if (
      cashAmount &&
      (mixedFirstType === 'cash' || mixedSecondType === 'cash')
    ) {
      setTotalPrice(parseInt(cashAmount) + parseInt(event.target.value));
    } else if (
      creditAmount &&
      (mixedFirstType === 'credit' || mixedSecondType === 'credit')
    ) {
      setTotalPrice(parseInt(creditAmount) + parseInt(event.target.value));
    } else {
      setTotalPrice(parseInt(event.target.value));
    }
  };
  const handleCreditAmount = (event) => {
    setCreditAmount(event.target.value);
    if (
      cashAmount &&
      (mixedFirstType === 'cash' || mixedSecondType === 'cash')
    ) {
      setTotalPrice(parseInt(cashAmount) + parseInt(event.target.value));
    } else if (
      cardAmount &&
      (mixedFirstType === 'card' || mixedSecondType === 'card')
    ) {
      setTotalPrice(parseInt(cardAmount) + parseInt(event.target.value));
    } else {
      setTotalPrice(parseInt(event.target.value));
    }
  };

  const renderMixedType = (value) => {
    if (value === 'card') {
      return (
        <Input
          type="text"
          value={cardAmount}
          onChange={handleCardAmount}
          placeholder={'Картой'}
        />
      );
    } else if (value === 'cash') {
      return (
        <Input
          type="text"
          value={cashAmount}
          onChange={handleCashAmount}
          placeholder={'Наличные'}
        />
      );
    } else if (value === 'credit') {
      return (
        <Input
          type="text"
          value={creditAmount}
          onChange={handleCreditAmount}
          placeholder={'Кредит'}
        />
      );
    }
  };

  React.useEffect(() => {
    if (reRegType === 'location') {
      setTotalPrice(reRegAmount);
    } else {
      setTotalPrice(30000);
    }
  }, [reRegAmount, reRegType]);

  return (
    <>
      <PaymentContainer>
        <BodyTitle>Переоформление</BodyTitle>
        <Field>Тип переоформления</Field>
        <StyledFormControl variant="standard">
          <Select
            MenuProps={{
              disableScrollLock: true,
            }}
            value={value}
            onChange={handleChange}
            displayEmpty
            disableUnderline
            style={{
              fontFamily: 'GraphikLCGMedium',
              fontSize: 14,
              marginLeft: 12,
            }}
          >
            <MenuItem
              value=""
              style={{ fontFamily: 'GraphikLCGMedium', fontSize: 14 }}
              onClick={() => setReRegType('')}
            >
              Выберите тариф
            </MenuItem>
            <MenuItem
              value="location"
              style={{ fontFamily: 'GraphikLCGMedium', fontSize: 14 }}
              onClick={() => {
                setReRegType('location');
                setTotalPrice(0);
              }}
            >
              На другую локацию
            </MenuItem>
            <MenuItem
              value="user"
              style={{ fontFamily: 'GraphikLCGMedium', fontSize: 14 }}
              onClick={() => {
                setReRegType('user');
                setTotalPrice(30000);
              }}
            >
              На другого клиента
            </MenuItem>
          </Select>
        </StyledFormControl>

        {reRegType === 'location' && (
          <>
            <Field>Выберите город</Field>
            <StyledFormControl variant="standard">
              <Select
                value={selectedCity}
                onChange={handleCityChange}
                displayEmpty
                inputProps={{ 'aria-label': 'Without label' }}
                disableUnderline
                MenuProps={{
                  disableScrollLock: true,
                }}
                style={{
                  fontFamily: 'GraphikLCGMedium',
                  fontSize: 14,
                  marginLeft: 12,
                }}
              >
                <MenuItem
                  value="Выберите город"
                  style={{
                    fontFamily: 'GraphikLCGMedium',
                    fontSize: 14,
                  }}
                  onClick={() => setSelectedCity('Выберите город')}
                >
                Выберите город
                </MenuItem>
                <MenuItem
                  value="Almaty"
                  style={{
                    fontFamily: 'GraphikLCGMedium',
                    fontSize: 14,
                  }}
                  onClick={() => setSelectedCity('Almaty')}
                >
                  Алматы
                </MenuItem>
                <MenuItem
                  value="Astana"
                  style={{
                    fontFamily: 'GraphikLCGMedium',
                    fontSize: 14,
                  }}
                  onClick={() => setSelectedCity('Astana')}
                >
                  Астана
                </MenuItem>
              </Select>
            </StyledFormControl>

            <Field>Выберите зал</Field>
            <StyledFormControl variant="standard">
              <Select
                value={selectedGym || ''}
                onChange={handleGymChange}
                displayEmpty
                inputProps={{ 'aria-label': 'Without label' }}
                disableUnderline
                MenuProps={{
                  disableScrollLock: true,
                }}
                renderValue={(selected) => (selected ? selected.name : 'Выберите зал')}
                style={{
                  fontFamily: 'GraphikLCGMedium',
                  fontSize: 14,
                  marginLeft: 12,
                }}
              >
                <MenuItem
                  value=""
                  style={{
                    fontFamily: 'GraphikLCGMedium',
                    fontSize: 14,
                  }}
                >
                  Выберите зал
                </MenuItem>
                {clubs.map((club) => (
                  <MenuItem
                    key={club.id}
                    value={club}
                    style={{
                      fontFamily: 'GraphikLCGMedium',
                      fontSize: 14,
                    }}
                  >
                    {club.name}
                  </MenuItem>
                ))}
              </Select>
            </StyledFormControl>
          </>
        )}
        {reRegType === 'user' && (
          <>
            <Field>Номер телефона</Field>
            <Dropdown show={showUsers}>
              {reRegUsers.map((user, i) => (
                <List
                  onClick={() => {
                    onSelect(user);
                    setShowUsers(false);
                  }}
                  key={i}
                >
                  {user?.nickname} {user?.phoneNumber}
                </List>
              ))}
            </Dropdown>
            <StyledFormControl variant="standard">
              <SelectUser
                MenuProps={{
                  disableScrollLock: true,
                }}
                placeholder="Введите номер телефона"
                onChange={(e) => {
                  SetSecondPage(secondPage);
                  setSearchReissueClient(e.target.value.toLowerCase());
                }}
                onClick={showListUsers}
                value={selectedUserReissue?.nickname}
              />
            </StyledFormControl>
          </>
        )}

        {reRegType !== '' && (
          <>
            <Field>Способ оплаты</Field>
            <StyledFormControl variant="standard">
              <Select
                MenuProps={{
                  disableScrollLock: true,
                }}
                value={payment}
                onChange={handleChangePayment}
                displayEmpty
                inputProps={{ 'aria-label': 'Without label' }}
                disableUnderline
                style={{
                  fontFamily: 'GraphikLCGMedium',
                  fontSize: 14,
                  marginLeft: 12,
                }}
              >
                <MenuItem
                  value=""
                  style={{ fontFamily: 'GraphikLCGMedium', fontSize: 14 }}
                  onClick={() => {
                    setCompany(false);
                    setMixedPayment(false);
                  }}
                >
                  Выберите способ оплаты
                </MenuItem>
                <MenuItem
                  style={{ fontFamily: 'GraphikLCGMedium', fontSize: 14 }}
                  value="card"
                  onClick={() => {
                    setCompany(false);
                    setMixedPayment(false);
                  }}
                >
                  Картой
                </MenuItem>
                <MenuItem
                  style={{ fontFamily: 'GraphikLCGMedium', fontSize: 14 }}
                  value="cash"
                  onClick={() => {
                    setCompany(false);
                    setMixedPayment(false);
                  }}
                >
                  Наличными
                </MenuItem>
                <MenuItem
                  style={{ fontFamily: 'GraphikLCGMedium', fontSize: 14 }}
                  value="kaspiCredit"
                  onClick={() => {
                    setCompany(false);
                    setMixedPayment(false);
                  }}
                >
                  Каспи кредит
                </MenuItem>
                <MenuItem
                  style={{ fontFamily: 'GraphikLCGMedium', fontSize: 14 }}
                  value="mixed"
                  onClick={() => {
                    setCompany(false);
                    setMixedPayment(true);
                  }}
                >
                  Смешанный вид оплаты
                </MenuItem>
                {reRegType === 'user' && (
                    <MenuItem
                      style={{ fontFamily: 'GraphikLCGMedium', fontSize: 14 }}
                      value="company"
                      onClick={() => {
                        setCompany(true);
                        setMixedPayment(false);
                      }}
                    >
                      Оплата перечислением
                    </MenuItem>
                )}
              </Select>
            </StyledFormControl>
            {mixedPayment && (
              <>
                <div
                  style={{
                    border: '1px solid rgba(0, 0, 0, 0.1)',
                    padding: 10,
                    borderRadius: 8,
                    marginBottom: 8,
                  }}
                >
                  <StyledFormControl variant="standard">
                    <Select
                      MenuProps={{
                        disableScrollLock: true,
                      }}
                      value={mixedFirstType}
                      onChange={handleChangeMixedFirst}
                      displayEmpty
                      inputProps={{ 'aria-label': 'Without label' }}
                      disableUnderline
                      style={{
                        fontFamily: 'GraphikLCGMedium',
                        fontSize: 14,
                        marginLeft: 12,
                      }}
                    >
                      <MenuItem
                        value=""
                        style={{
                          fontFamily: 'GraphikLCGMedium',
                          fontSize: 14,
                        }}
                      >
                        Выберите первый способ оплаты
                      </MenuItem>
                      <MenuItem
                        style={{
                          fontFamily: 'GraphikLCGMedium',
                          fontSize: 14,
                        }}
                        value="card"
                        disabled={mixedSecondType === 'card'}
                      >
                        Картой
                      </MenuItem>
                      <MenuItem
                        style={{
                          fontFamily: 'GraphikLCGMedium',
                          fontSize: 14,
                        }}
                        value="cash"
                        disabled={mixedSecondType === 'cash'}
                      >
                        Наличными
                      </MenuItem>
                      <MenuItem
                        style={{
                          fontFamily: 'GraphikLCGMedium',
                          fontSize: 14,
                        }}
                        value="credit"
                        disabled={mixedSecondType === 'credit'}
                      >
                        Кредит
                      </MenuItem>
                    </Select>
                  </StyledFormControl>
                  {renderMixedType(mixedFirstType)}
                </div>
                <div
                  style={{
                    border: '1px solid rgba(0, 0, 0, 0.1)',
                    padding: 10,
                    borderRadius: 8,
                    marginBottom: 8,
                  }}
                >
                  <StyledFormControl variant="standard">
                    <Select
                      MenuProps={{
                        disableScrollLock: true,
                      }}
                      value={mixedSecondType}
                      onChange={handleChangeMixedSecond}
                      displayEmpty
                      inputProps={{ 'aria-label': 'Without label' }}
                      disableUnderline
                      style={{
                        fontFamily: 'GraphikLCGMedium',
                        fontSize: 14,
                        marginLeft: 12,
                      }}
                    >
                      <MenuItem
                        value=""
                        style={{
                          fontFamily: 'GraphikLCGMedium',
                          fontSize: 14,
                        }}
                      >
                        Выберите второй способ оплаты
                      </MenuItem>
                      <MenuItem
                        style={{
                          fontFamily: 'GraphikLCGMedium',
                          fontSize: 14,
                        }}
                        value="card"
                        disabled={mixedFirstType === 'card'}
                      >
                        Картой
                      </MenuItem>
                      <MenuItem
                        style={{
                          fontFamily: 'GraphikLCGMedium',
                          fontSize: 14,
                        }}
                        value="cash"
                        disabled={mixedFirstType === 'cash'}
                      >
                        Наличными
                      </MenuItem>
                      <MenuItem
                        style={{
                          fontFamily: 'GraphikLCGMedium',
                          fontSize: 14,
                        }}
                        value="credit"
                        disabled={mixedFirstType === 'credit'}
                      >
                        Кредит
                      </MenuItem>
                    </Select>
                  </StyledFormControl>
                  {renderMixedType(mixedSecondType)}
                </div>
              </>
            )}
            {company && (
              <Input
                type="text"
                value={companyBin}
                onChange={handleCompanyBin}
                placeholder={'БИН компании'}
              />
            )}
            <Field>Менеджер</Field>
            <StyledFormControl variant="standard">
              <Select
                value={valueSalesPerson}
                onChange={handleSalesPerson}
                displayEmpty
                inputProps={{ 'aria-label': 'Without label' }}
                disableUnderline
                MenuProps={{
                  disableScrollLock: true,
                }}
                style={{
                  fontFamily: 'GraphikLCGMedium',
                  fontSize: 14,
                  marginLeft: 12,
                }}
              >
                <MenuItem
                  value="Выберите менеджера"
                  style={{
                    fontFamily: 'GraphikLCGMedium',
                    fontSize: 14,
                  }}
                  onClick={() => setSalesPerson()}
                >
                  Выберите менеджера
                </MenuItem>
                {salesUsers?.map((item, index) => {
                  return (
                    <MenuItem
                      key={item?.id}
                      value={index}
                      style={{ fontFamily: 'GraphikLCGMedium', fontSize: 14 }}
                      onClick={() => setSalesPerson(item?.id)}
                    >
                      {item?.username || item?.nickname}
                    </MenuItem>
                  );
                })}
              </Select>
            </StyledFormControl>

            {!changeSum && (
              <StyledSumContainer>
                <div
                  style={{
                    justifyContent: 'space-between',
                    display: 'flex',
                    width: '100%',
                  }}
                >
                  <PriceText>Итоговая сумма к оплате</PriceText>
                  <div style={{ flexDirection: 'column', display: 'flex' }}>
                    <CustomPrice>
                      {' '}
                      {totalPrice}₸{' '}
                    </CustomPrice>
                    <StyledChangeSumButton
                      onClick={() => {
                        setChangeSum(true);
                      }}
                    >
                      Изменить
                    </StyledChangeSumButton>
                  </div>
                </div>
              </StyledSumContainer>
            )}
            {changeSum && (
              <Input
                type="text"
                value={totalPrice}
                onChange={handlePriceChange}
                placeholder={reRegType==='location' ? `${reRegAmount}₸` : `30000₸`}
                style={{ marginTop: 10 }}
              />
            )}
          </>
        )}


        <OpenButton
          disabled={isButtonDisabled ? true : false}
          style={{ opacity: isButtonDisabled ? 0.5 : 1 }}
          onClick={() => {
            if(totalPrice < 100 && reRegType==='user'){
              toast.error('Сумма не должна быть меньше 100₸');
            }
            else{
              setIsReRegisterHeroPass(true);
              setPaymentModal(true);
            }
          }}
        >
          Подтвердить
        </OpenButton>
        <CloseButton
          onClick={() => {
            setReRegSearch(false);
          }}
        >
          Отмена
        </CloseButton>
      </PaymentContainer>
    </>
  );
};

export default ClientsReReg;
