import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import { gql, useMutation, useQuery } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import Contest from "./Contest";
import Loading from "../shared/Loading";
import { useLoading } from "../../context/useLoading";
import withMainLayout from "../../hocs/withMainLayout";

const GET_CONTEST = gql`
  query getContest($id: ID!) {
    contest(id: $id) {
      id
      stages {
        id
        startTime
        endTime
        votingStartTime
        votingEndTime
        stageNumber
      }
      name
      description
    }
  }
`;

// const GET_CONTESTS = gql`
//   query contests {
//     contests {
//       id
//       stages {
//         id
//         startTime
//         endTime
//         stageNumber
//       }
//       name
//       description
//     }
//   }
// `

const UPDATE_CONTEST = gql`
  mutation updateContest($id: ID!, $input: ContestInput) {
    updateContest(id: $id, input: $input) {
      id
      stages {
        id
        startTime
        endTime
        votingStartTime
        votingEndTime
        stageNumber
      }
      name
      description
    }
  }
`;

const DELETE_CONTEST = gql`
  mutation deleteContest($id: ID!) {
    deleteContest(id: $id)
  }
`;

const ADD_STAGE = gql`
  mutation addStage($input: StageInput) {
    addStage(input: $input) {
      id
      startTime
      endTime
      votingStartTime
      votingEndTime
      stageNumber
    }
  }
`;

const UPDATE_STAGE = gql`
  mutation updateStage($id: ID!, $input: StageInput) {
    updateStage(id: $id, input: $input) {
      id
      startTime
      endTime
      votingStartTime
      votingEndTime
      stageNumber
    }
  }
`;

const DELETE_STAGE = gql`
  mutation deleteStage($id: ID!) {
    deleteStage(id: $id)
  }
`;

const ContestContainer = () => {
  const { id: contestId } = useParams();
  const navigate = useNavigate();
  const [contestData, setContestData] = useState(null);
  const { data, loading, error } = useQuery(GET_CONTEST, {
    variables: { id: contestId }
  });

  const { showLoading, hideLoading } = useLoading();

  const [deleteContest] = useMutation(DELETE_CONTEST, {
    update(cache, { data: { deleteContest: id } }) {
      // const { contests } = cache.readQuery({ query: GET_CONTESTS })
      // cache.writeQuery({
      //   query: GET_CONTESTS,
      //   data: {
      //     contests: contests.filter(contest => contest.id !== id)
      //   }
      // })
      hideLoading();
      toast.success("Программа успешно удалена");
    }
  });

  const [updateContest] = useMutation(UPDATE_CONTEST, {
    onCompleted: () => {
      toast.success("Конкурс успешно обновлен");
      hideLoading();
    }
  });

  const [addStage] = useMutation(ADD_STAGE, {
    update(cache, { data: { addStage: stage } }) {
      let { contest } = cache.readQuery({
        query: GET_CONTEST,
        variables: { id: contestId }
      });
      contest = {
        id: contest.id,
        stages: contest.stages ? contest.stages.concat([stage]) : [stage]
      };

      const stages =
        contest && contest.stages
          ? contest.stages
              .filter((elem) => elem !== null)
              .map((oneStage) => oneStage.id)
          : [];

      updateContest({
        variables: { id: contestId, input: { stages } }
      });
      cache.writeQuery({
        query: GET_CONTEST,
        variables: { id: contestId },
        data: { contest }
      });
      toast.success("Стадия успешно добавлена к конкурсу");
      hideLoading();
    }
  });

  const [updateStage] = useMutation(UPDATE_STAGE, {
    update(cache, { data: { updateStage: stage } }) {
      let { contest } = cache.readQuery({
        query: GET_CONTEST,
        variables: { id: contestId }
      });
      const index = contest?.stages?.find(
        (oneStage) => oneStage.id === stage.id
      );
      const newStages = contest.stages.slice();
      if (index !== null && index !== -1) {
        newStages.splice(index, 1, stage);
      }

      contest = {
        id: contest.id,
        stages: newStages
      };

      cache.writeQuery({
        query: GET_CONTEST,
        variables: { id: contestId },
        data: { contest }
      });
      toast.success("Программа успешно обновлена");
      hideLoading();
    }
  });

  const [deleteStage] = useMutation(DELETE_STAGE, {
    update(cache, { data: { deleteStage: id } }) {
      let { contest } = cache.readQuery({
        query: GET_CONTEST,
        variables: { id: contestId }
      });
      contest = {
        id: contest.id,
        stages: contest.stages.filter((stageData) => stageData.id !== id)
      };

      const stages =
        contest && contest.stages
          ? contest.stages.map((stageData) => stageData.id)
          : [];

      updateContest({
        variables: { id: contestId, input: { stages } }
      });
      cache.writeQuery({
        query: GET_CONTEST,
        variables: { id: contestId },
        data: { contest }
      });
      hideLoading();
      toast.success("Стадия успешно удалена с конкурса");
    }
  });

  const handleAddStage = ({ values }) => {
    showLoading();
    addStage({
      variables: {
        input: values
      }
    });
  };

  const handleUpdateStage = ({ id, values }) => {
    showLoading();
    updateStage({
      variables: {
        id,
        input: values
      }
    });
  };

  const handleDeleteStage = (stageId) => {
    showLoading();
    deleteStage({ variables: { id: stageId } });
  };

  const handleDeleteContest = () => {
    deleteContest({ variables: { id: contestId } });
    navigate("/contests");
  };

  const handleUpdateContest = (values) => {
    showLoading();
    updateContest({
      variables: {
        id: contestId,
        input: values
      }
    });
  };

  useEffect(() => {
    if (data && data.contest) {
      setContestData(data.contest);
    }
  }, [data, loading, error]);

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <h1> error occured {error.graphQLErrors[0].message} </h1>;
  }

  return (
    <>
      {contestData && (
        <Contest
          contest={contestData}
          {...{
            handleUpdateContest,
            handleDeleteContest,
            handleAddStage,
            handleDeleteStage,
            handleUpdateStage
          }}
        />
      )}
    </>
  );
};

ContestContainer.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired
    })
  }).isRequired
};

export default withMainLayout(ContestContainer);
