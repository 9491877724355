import React from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';
import { toast } from 'react-toastify';

import Marathons from './Marathons';
import withMainLayout from '../../hocs/withMainLayout';
import Loading from '../shared/Loading';
import { useLoading } from '../../context/useLoading';

const GET_MARATHONS = gql`
  query getMarathons {
    marathons {
      id
      name
      imageURL
      description
      duration
      price
      countClasses
      assessmentClasses
      xPosition
      yPosition
      floorCount
      district
      rating
    }
  }
`;

const ADD_MARATHON = gql`
  mutation addMarathon($input: MarathonInput) {
    addMarathon(input: $input) {
      id
      name
      imageURL
      description
      duration
      price
      countClasses
      assessmentClasses
      xPosition
      yPosition
      floorCount
      district
      rating
    }
  }
`;

const MarathonsContainer = () => {
  const [dataMarathons, setDataMarathons] = React.useState(null);
  const { showLoading, hideLoading } = useLoading();
  const { data, loading, error } = useQuery(GET_MARATHONS);

  React.useEffect(() => {
    if (data && !loading && !error) {
      setDataMarathons(data.marathons);
    }
  }, [data, loading, error]);

  const [addMarathon] = useMutation(ADD_MARATHON, {
    update(cache, { data: { addMarathon: marathon } }) {
      const { marathons } = cache.readQuery({ query: GET_MARATHONS });
      cache.writeQuery({
        query: GET_MARATHONS,
        data: { marathons: marathons.concat([marathon]) }
      });
      hideLoading();
      toast.success('Марафон успешно создано');
    }
  });

  const handleAddMarathon = values => {
    showLoading();
    const newValues = {
      ...values,
      xPosition: parseFloat(values.xPosition),
      yPosition: parseFloat(values.yPosition),
      rating: parseInt(values.rating, 10),
      price: parseInt(values.price, 10),
      countClasses: parseInt(values.countClasses, 10),
      assessmentClasses: parseInt(values.assessmentClasses, 10),
      educationClasses: parseInt(values.educationClasses, 10),
      duration: parseInt(values.duration, 10)
    };
    addMarathon({
      variables: {
        input: newValues
      }
    });
  };

  if (loading && !dataMarathons) {
    return <Loading />;
  }

  return (
    <Marathons
      marathons={dataMarathons}
      addMarathonsClick={handleAddMarathon}
    />
  );
};

export default withMainLayout(MarathonsContainer);
