import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Table, Button, Divider } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { gql, useMutation, useQuery, useLazyQuery } from '@apollo/client';
import { useParams } from "react-router-dom";
import { toast } from 'react-toastify';

import withMainLayout from '../../hocs/withMainLayout';
import EditForm from '../shared/EditFormModal';
import CreateForm from '../shared/CreateForm';


const GET_QUESTIONS_BY_CATEGORY = gql`
  query QuestionsByCategory($id: ID!) {
      questionsByCategory(id: $id) {
          id
          question
          answer
          images
          typeQuestion
          created_at
          updated_at
      }
 }
`;

const CREATE_QUESTION = gql `
  mutation createFaqQuestion($input: FaqQuestionInput) {
    createFaqQuestion(input: $input) {
      id
      question
      answer
    }
 }
`;
const UPDATE_QUESTION = gql `
  mutation UpdateFaqQuestion($id: ID! $input: UpdateFaqQuestionInput) {
      updateFaqQuestion (id: $id input: $input ) {
          id
      }
 }
`;
const DELETE_QUESTION = gql `
  mutation DeleteFaqQuestion($id: ID!) {
      deleteFaqQuestion(id: $id)
 }
`;


const FaqQuestions = () => {
	const { itemId } = useParams();
  const [questions, setQuestions] = useState(null);
  const [display, setDisplay] = useState('default');
  const [editValue, setEditValue] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [image, setImage] = useState();
  const [secondImage, setSecondImage] = useState();

	const [getQuestions , { data, loading, error, refetch }] = useLazyQuery(GET_QUESTIONS_BY_CATEGORY);

	useEffect(()=> {
		getQuestions({
			variables: {
				id: itemId
			}
	});
	}, [getQuestions, itemId]);

  
  const [addQuestionInCategory] = useMutation(CREATE_QUESTION,{
    fetchPolicy: "no-cache",
    onCompleted() {
      toast.success("Вопрос успешно добавлен");
      refetch();
    }
  });
  const [updateQuestion] = useMutation(UPDATE_QUESTION, {
    fetchPolicy: "no-cache",
    onCompleted() {
      toast.success("Вопрос успешно изменён");
      refetch();
    }
  });
  const [deleteQuestion] = useMutation(DELETE_QUESTION,{
    fetchPolicy: "no-cache",
    onCompleted() {
      toast.success("Вопрос успешно удалён");
      refetch();
    }
  });

	useEffect(() => {
		if (data && !loading && !error) {
			setQuestions(data.questionsByCategory);
		}
	}, [data, loading, error]);

  const handleAddQuestion = (values, itemId) => {
    addQuestionInCategory({ variables: { input: { ...values, category: itemId, images: [image, secondImage] }}});
  };


  const handleUpdateQuestion = (id, value) => {
    updateQuestion({ variables: {id: id, input:{...value, images: [image, secondImage] }} });
  };

  const handleDeleteQuestion = (id) => {
    deleteQuestion({ variables:{ id: id } });
  };

  const columns = [
    {
      title: 'Вопрос',
      dataIndex: 'question'
    },
		{
			title: 'Ответ',
			dataIndex: 'answer'
		},
		{
      title: 'Действие',
      key: 'action',
      render: (text, item) => (
        <span>
          <Button
            type="link"
            onClick={() => {
              setDisplay('edit');
              setEditValue(item);
            }}
          >
            Редактировать
          </Button>
          <Divider type="vertical" />
        </span>
      )
    }
  ];

  const fields = [
    {
      key: 'question',
      label: 'Вопрос',
      tooltipText: 'Вопрос'
    },
		{
			key: 'answer',
			label: 'Ответ',
			tooltipText: 'Ответ'
		},
		{
      label: 'Картинки',
      isNotRequired: true,
      customImages: true
		},
    {
      key: 'typeQuestion',
			label: 'Является ли вопрос популярным?',
      tooltipText: 'Популярных вопросов не может быть больше 4',
      options: [{ label:'Да', value: 'popular' }, { label: 'Нет', value: 'notPopular' }]
    }
  ];

  const editFields = [
    {
      key: 'question',
      label: 'Вопрос',
      value: editValue && editValue.question,
      tooltipText: 'Вопрос для FAQ'
    },
		{
			key: 'answer',
			label: 'Ответ',
			tooltipText: 'Ответ на вопрос',
      value: editValue && editValue.answer
		},
		{
			label: 'Картинки',
			tooltipText: 'Скрины',
      value: editValue && editValue.images,
      isNotRequired: true,
      customImages: true
		},    
    {
      key: 'typeQuestion',
			label: 'Является ли вопрос популярным?',
      tooltipText: 'Популярных вопросов не может быть больше 4',
      options: [{ label:'Да', value: 'popular' }, { label: 'Нет', value: 'notPopular' }]
    }

  ];


  const handleCreate = values => {
    handleAddQuestion(values, itemId);
    setModalVisible(false);
  };

  const handleUpdate = values => {
    handleUpdateQuestion(editValue.id, values);
    setDisplay('default');
    setEditValue(null);
  };

  const handleDelete = () => {
    handleDeleteQuestion(editValue.id);
    setDisplay('default');
    setEditValue(null);
  };

  return (
    <>
      <Table
        dataSource={questions}
        columns={columns}
        title={() => (
          <div>
            <Button type="primary" onClick={() => setModalVisible(true)}>
              <PlusOutlined /> Новый вопрос
            </Button>
          </div>
        )}
      />
      <CreateForm
        title="Добавить новый вопрос"
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        onCreate={handleCreate}
        fields={fields}
        setImages={setImage}
        images={image}
        setSecondImage={setSecondImage}
        secondImage={secondImage}
      />
      {display === 'edit' && editValue && (
        <EditForm
          title="Редактировать вопрос"
          visible={display === 'edit'}
          onCancel={() => setDisplay('default')}
          onUpdate={handleUpdate}
          onDelete={handleDelete}
          fields={editFields}
          setImages={setImage}
          images={image}
          setSecondImage={setSecondImage}
          secondImage={secondImage}
        />
      )}
    </>
  );
};

FaqQuestions.propTypes = {
  categories: PropTypes.shape({
    name: PropTypes.string,
		iconUrl: PropTypes.string
  }).isRequired,
  handleDeleteCategory: PropTypes.func.isRequired,
  handleAddCategory: PropTypes.func.isRequired,
  handleUpdateCategory: PropTypes.func.isRequired
};

export default withMainLayout(FaqQuestions);
