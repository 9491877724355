import React from 'react';
import PropTypes from 'prop-types';
import { Button, Form, Modal, Input } from 'antd';

const FormItem = Form.Item;

const { TextArea } = Input;

const ModifyModel = ({
  modalVisible,
  editPromptTrainer,
  handleModifyTrainerDescription,
  setModalVisible,
  setEditPromptTrainer,
}) => {
  const [form] = Form.useForm();

  return (
    <Modal
      open={modalVisible}
      title={editPromptTrainer?.trainer?.nickname}
      okText="Создать"
      cancelText="Закрыть"
      onCancel={() => {
        form.resetFields();
        setModalVisible(false);
        setEditPromptTrainer(null);
      }}
      footer={[
        <Button
          key="submit"
          onClick={() => {
            form.resetFields();
            setEditPromptTrainer(null);
            setModalVisible(false);
          }}
        >
          Отмена
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={() => {
            form
              .validateFields()
              .then((values) => {
                form.resetFields();
                handleModifyTrainerDescription(
                  editPromptTrainer?.trainer?.id,
                  values?.description
                );
                setEditPromptTrainer(null);
                setModalVisible(false);
              })
              .catch((info) => {
                console.log('Validate Failed:', info);
              });
          }}
        >
          Сохранить
        </Button>,
      ]}
    >
      <Form form={form} layout="vertical">
        <FormItem
          key="description"
          label="Описание Тренера"
          name="description"
          initialValue={editPromptTrainer?.description}
        >
          <TextArea row={4} />
        </FormItem>
      </Form>
    </Modal>
  );
};

ModifyModel.propTypes = {
  modalVisible: PropTypes.bool,
  editPromptTrainer: PropTypes.shape({
    id: PropTypes.string,
    description: PropTypes.string,
    trainer: PropTypes.shape({
      id: PropTypes.string,
      nickname: PropTypes.string,
    }),
  }),
  handleModifyTrainerDescription: PropTypes.func,
  setModalVisible: PropTypes.func,
  setEditPromptTrainer: PropTypes.func,
};

export default ModifyModel;
