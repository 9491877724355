import React from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';

import CancelLogo from '../../images/cancel.svg';
import NewSubscriptionPayment from './NewSubscriptionsPayment';
import ArrowLeft from '../../images/ArrowLeft.svg';

const Wrapper = styled.div`
  background-color: #fff;
  position: fixed;
  transition: all 0.3s;
  top: 0;
  right: ${(props) => (props.show ? '0' : '-30%')};
  width: 368px;
  height: 100%;
  z-index: 1;
  overflow-y: auto;
`;

const Cancel = styled.img`
  cursor: pointer;
  margin-left: auto;
`;

const Header = styled.div`
  display: flex;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  padding: 20px 24px;
  position: sticky;
  top: 0;
`;

const Title = styled.div`
  font-family: 'GraphikLCGMedium';
  font-size: 20px;
  line-height: 24px;
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
`;
const BodyTitle = styled.div`
  font-family: 'GraphikLCGMedium';
  font-size: 16px;
  line-height: 16px;
  margin-top: 3%;
`;

const Field = styled.div`
  margin-right: auto;
  font-family: 'GraphikLCGMedium';
  font-size: 14px;
  line-height: 16px;
  color: #00000080;
`;

const Value = styled.div`
  font-family: 'GraphikLCGRegular';
  font-size: 14px;
  line-height: 16px;
`;

const Content = styled.div`
  display: flex;
  margin-top: 7%;
`;

const Section = styled.div`
  padding: 24px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
`;

const OpenButton = styled.button`
  font-family: 'GraphikLCGMedium';
  font-size: 20px;
  line-height: 24px;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  cursor: pointer;
  height: 56px;
  background: #6f2cff;
  color: #fff;
  align-self: center;
  margin-bottom: 16px;
  width: 100%;
`;

const History = styled.button`
  display: flex;
  font-size: 14px;
  line-height: 16px;
  font-family: 'GraphikLCGMedium';
  border-radius: 8px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 10px 12px 10px 12px;
  margin-top: 24px;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  width: 86%;
  margin: 24px 24px 0px;
`;

const ArrowLeftStyle = styled.img`
  cursor: pointer;
  padding-right: 8px;
`;

const NewSubscription = ({
  selectUser,
  setSelectUser,
  activeMarathon,
  userCurrentHP,
  dataUserCurrentHP,
  setNewSubcriptionModal,
  newSubscriptionModal,
  setClientInfoModal,
  typeAbonement,
  setTypeAbonement,
  setNewAbonement,
  newAbonement,
  marathon,
  heroPasses,
  payments,
  setDate,
  setTotalPrice,
  payment,
  setPayment,
  setMarathonEvent,
  setPaymentModal,
  setOldUser,
  setName,
  userPayments,
  salesUsers,
  setSalesPerson,
  salesPerson,
  comment,
  setComment,
  setMixedPayment,
  mixedPayment,
  cashAmount,
  setCashAmount,
  cardAmount,
  setCardAmount,
  creditAmount,
  setCreditAmount,
  totalPrice,
  setCompanyBin,
  companyBin,
  marathonSummerCamp,
  marathonEvent,
  date

}) => {
  const findPayments = payments.find((el) => el.id === newAbonement?.id);
  const [disabled, setDisabled] = React.useState(true);

  React.useEffect(() => {
    if (typeAbonement === 'marathon') {
      if (marathonEvent && payment && salesPerson) {
        setDisabled(false);
      } else if (!marathonEvent && !payment && !salesPerson) {
        setDisabled(true);
      } else {
        setDisabled(true);
      }
    } else if (typeAbonement === 'heropass') {
      if (date && payment && salesPerson) {
        setDisabled(false);
      } else if (!date && !payment && !salesPerson) {
        setDisabled(true);
      } else {
        setDisabled(true);
      }
    } else if (typeAbonement === 'payments') {
      if (newAbonement && payment && salesPerson) {
        setDisabled(false);
      } else if (!date && !payment && !salesPerson) {
        setDisabled(true);
      } else {
        setDisabled(true);
      }
    } else {
      setDisabled(true);
    }
  }, [date, payment, salesPerson, marathonEvent, typeAbonement, newAbonement]);

  return (
    <Wrapper show={newSubscriptionModal}>
      <Header>
        <Title>Клиент</Title>
        <Cancel
          src={CancelLogo}
          onClick={() => {
            setClientInfoModal(false);
            setNewSubcriptionModal(false);
            setMixedPayment(false);
          }}
        />
      </Header>
      <History
        onClick={() => {
          setNewSubcriptionModal(false);
        }}
      >
        <ArrowLeftStyle src={ArrowLeft} />
        Назад
      </History>
      <Body>
        <Section>
          <BodyTitle>Данные о пользователе</BodyTitle>
          <Content>
            <Field>Никнейм</Field>
            <Value>{selectUser?.nickname}</Value>
          </Content>
          <Content>
            <Field>Номер</Field>
            <Value>{selectUser?.phoneNumber}</Value>
          </Content>
          <Content>
            <Field>Email</Field>
            <Value>{selectUser?.username}</Value>
          </Content>
        </Section>
        <Section>
          <BodyTitle>Программа</BodyTitle>
          {selectUser?.hero && (
            <Content>
              <Field>Персонаж</Field>
              <Value>{selectUser?.hero?.name}</Value>
            </Content>
          )}
          {selectUser?.level && (
            <Content>
              <Field>Уровень</Field>
              <Value>{selectUser?.level.name}</Value>
            </Content>
          )}
          {activeMarathon && (
            <Content>
              <Field>Программа</Field>
              <Value>{activeMarathon?.marathon?.name}</Value>
            </Content>
          )}
          {activeMarathon && (
            <Content>
              <Field>Дата окончания</Field>
              {activeMarathon?.marathon && (
                <Value>
                  {dayjs(activeMarathon?.endTime).format('DD.MM.YYYY')}
                </Value>
              )}
            </Content>
          )}
        </Section>
        {dataUserCurrentHP?.currentUserHeroPass && (
          <Section>
            <BodyTitle>Абонемент</BodyTitle>
            <Content>
              <Field>Тариф</Field>
              <Value>{userCurrentHP?.heroPass?.name}</Value>
            </Content>
            <Content>
              <Field>Дата активации</Field>
              <Value>
                {dayjs(userCurrentHP?.heroPass?.startTime).format('DD.MM.YYYY')}
              </Value>
            </Content>
            <Content>
              <Field>Дата истечения</Field>
              <Value>
                {dayjs(userCurrentHP?.endTime).format('DD.MM.YYYY')}
              </Value>
            </Content>
          </Section>
        )}
        <NewSubscriptionPayment
          typeAbonement={typeAbonement}
          setTypeAbonement={setTypeAbonement}
          newSubscriptionModal={newSubscriptionModal}
          setNewSubcriptionModal={setNewSubcriptionModal}
          selectUser={selectUser}
          setSelectUser={setSelectUser}
          activeMarathon={activeMarathon}
          userCurrentHP={userCurrentHP}
          dataUserCurrentHP={dataUserCurrentHP}
          setClientInfoModal={setClientInfoModal}
          setNewAbonement={setNewAbonement}
          newAbonement={newAbonement}
          marathon={marathon}
          heroPasses={heroPasses}
          payments={payments}
          setDate={setDate}
          setTotalPrice={setTotalPrice}
          payment={payment}
          setPayment={setPayment}
          setMarathonEvent={setMarathonEvent}
          findPayments={findPayments}
          userPayments={userPayments}
          salesUsers={salesUsers}
          setSalesPerson={setSalesPerson}
          salesPerson={salesPerson}
          comment={comment}
          setComment={setComment}
          setMixedPayment={setMixedPayment}
          mixedPayment={mixedPayment}
          cashAmount={cashAmount}
          setCashAmount={setCashAmount}
          cardAmount={cardAmount}
          setCardAmount={setCardAmount}
          creditAmount={creditAmount}
          setCreditAmount={setCreditAmount}
          totalPrice={totalPrice}
          setCompanyBin={setCompanyBin}
          companyBin={companyBin}
          marathonSummerCamp={marathonSummerCamp}
          marathonEvent={marathonEvent}
        />
        <div style={{ paddingLeft: 24, paddingRight: 24 }}>
          <OpenButton
            disabled={disabled}
            style={{ opacity: !disabled ? 1 : 0.5 }}
            onClick={() => {
              {
                setPaymentModal(true);
                setOldUser(true);
                setName(selectUser?.nickname);
              }
            }}
          >
            Провести оплату
          </OpenButton>
        </div>
      </Body>
    </Wrapper>
  );
};

export default NewSubscription;
