import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

const Messages = ({ messages }) => {
  const columns = [
    {
      title: 'Заголовок',
      dataIndex: 'title'
    },
    {
      title: 'Тип',
      dataIndex: 'type'
    },
    {
      title: 'Действие',
      key: 'action',
      render: item => (
        <span>
          <Link to={`/messages/${item.id}`}>Редактировать</Link>
        </span>
      )
    }
  ];

  return (
    <>
      <Table
        dataSource={messages}
        columns={columns}
        title={() => (
          <span>
            <Link to="/createMessage/">
              <PlusOutlined /> Новое Сообщение
            </Link>
          </span>
        )}
      />
    </>
  );
};

Messages.propTypes = {
  messages: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      attendancePoints: PropTypes.number,
      maxPoints: PropTypes.number,
      perfectWeekPoints: PropTypes.number,
      visitsForPerfectWeek: PropTypes.number
    })
  ).isRequired
};

export default Messages;
