import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { Tooltip, Row, DatePicker, Table, Col, Descriptions, Spin, Typography, Switch } from 'antd';
import { gql, useQuery, useLazyQuery, useMutation } from '@apollo/client';
import dayjs from 'dayjs';
import 'dayjs/locale/ru';

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const GET_USER_DISCIPLINE = gql`
  query GetUserDiscipline($userId: ID!) {
    getUserDiscipline(userId: $userId) {
      id
      currentDisciplineWeek
      currentPerfectDisciplineWeek
      maxDisciplineWeek
      maxPerfectDisciplineWeek
      frozen
      isViewed
    }
  }
`;

const GET_DISCIPLINE_MONTH = gql`
  query GetDisciplineMonth($userId: ID!, $timestamp: String!) {
    getDisciplineMonth(userId: $userId, timestamp: $timestamp) {
      id
      timestamp
      created_at
      updated_at
      status
      disciplineMonth {
        id
        currentWeek
        created_at
        updated_at
        status
      }
    }
  }
`;

const EDIT_DISCIPLINE_FROZEN = gql`
  mutation updateDiscipline($input: DisciplineInput!) {
    updateDiscipline(input: $input) {
      user {
        id
      }
      frozen
    }
  }
`;

const UserDiscipline = ({ userId }) => {
  const [timeStamp, SetTimeStampn] = useState('');

  const [getDisciplineMonth, { data: monthDiscipline, loading: monthLoading }] = useLazyQuery(GET_DISCIPLINE_MONTH);
  const { data: mainDiscipline, loading: mainLoading, refetch } = useQuery(GET_USER_DISCIPLINE, {
    variables: { userId },
  });

  const [updateDiscipline, {loading: frozenLoading}] = useMutation(
    EDIT_DISCIPLINE_FROZEN,
    {
      onCompleted() {
        refetch();
      },
    }
  );

  useEffect(() => {
    if(timeStamp) {
      getDisciplineMonth({
        variables: {
          userId: userId,
          timestamp: timeStamp,
        },
      });
    }
  }, [timeStamp]);

  const fields = [
    { value: 'currentDisciplineWeek', label: 'Текущие недели' },
    { value: 'currentPerfectDisciplineWeek', label: 'Текущие идеальные недели' },
    { value: 'frozen', label: 'Заморожен' },
    { value: 'isViewed', label: 'Просматривается' },
    { value: 'maxDisciplineWeek', label: 'Макс недель' },
    { value: 'maxPerfectDisciplineWeek', label: 'Макс идеальных недель' }
  ];

  const statusMonth = {
    'frozen': 'Заморожен',
    'perfectDisciplineWeek': 'Идеальные недели дисциплины',
    'disciplineWeek': 'Обычные недели дисциплины',
    'null': 'Нет'
  };

  const renderField = (field, index) => {
    const backgroundColor = index % 2 === 0 ? '#fafafa' : '#ebebeb';
    const labelStyle = { width: '200px', backgroundColor };

    const discipline = mainDiscipline?.getUserDiscipline;
    
    if(discipline) {
      console.log(discipline[field?.value]);
      
      if (field?.value === 'frozen') {
        return (
          <Descriptions.Item label={field?.label} labelStyle={labelStyle}>
            <Switch 
              checkedChildren="Да" 
              unCheckedChildren="Нет" 
              defaultValue={discipline[field?.value]}
              loading={frozenLoading}
              onChange={(val) => {
                updateDiscipline({
                  variables: {
                    input: {
                      user: userId,
                      frozen: val,
                    }
                  }
                });
              }}  
            />
          </Descriptions.Item>
        );
      }
      
      if (typeof discipline[field?.value] === 'boolean') {
        return (
          <Descriptions.Item label={field?.label} labelStyle={labelStyle}>
            {discipline[field?.value] === false ? 'Нет' : 'Да'}
          </Descriptions.Item>
        );
      }
      
      return (
        <Descriptions.Item label={field?.label} labelStyle={labelStyle}>
          {discipline[field?.value]}
        </Descriptions.Item>
      );
    } else {
      return null;
    }
  };

  const onChange = (_date, dateString) => {
    SetTimeStampn(dateString);
  };

  const columnsWeek = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: '15%',
      render: (text) => (
        <Tooltip title={text}>
          <span style={{
            display: 'inline-block',
            maxWidth: '90px',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            verticalAlign: 'middle',
            cursor: 'pointer'
          }}>
            {text}
          </span>
        </Tooltip>
      ),
    },
    {
      title: 'Статус',
      dataIndex: 'status',
      key: 'status',
      width: '20%',
      render: (text) => {
        const status = statusMonth[text];
        return <span>{text}</span>;
      },
    },
    {
      title: 'Неделя по счету',
      dataIndex: 'currentWeek',
      key: 'currentWeek',
      width: '15%',
      align: 'center'
    },
    {
      title: 'Дата создания',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (_text, item) => {
        const dateReceived = dayjs(item.created_at).format(
          'Do MMMM, YYYY, HH:mm'
        );
        return <span>{dateReceived}</span>;
      },
    },
    {
      title: 'Дата обновления',
      dataIndex: 'updated_at',
      key: 'updated_at',
      render: (_text, item) => {
        const dateReceived = dayjs(item.created_at).format(
          'Do MMMM, YYYY, HH:mm'
        );
        return <span>{dateReceived}</span>;
      },
    },
  ];

  const columnsMonth = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: '15%',
      render: (text) => (
        <Tooltip title={text}>
          <span style={{
            display: 'inline-block',
            maxWidth: '110px',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            verticalAlign: 'middle',
            cursor: 'pointer'
          }}>
            {text}
          </span>
        </Tooltip>
      ),
    },
    {
      title: 'Статус',
      dataIndex: 'status',
      key: 'status',
      render: (text) => {
        const status = statusMonth[text];
        return <span>{text}</span>;
      },
    },
    {
      title: 'Дата создания',
      dataIndex: 'created_at',
      key: 'created_at',
      width: '25%',
      render: (_text, item) => {
        const dateReceived = dayjs(item.created_at).format(
          'Do MMMM, YYYY, HH:mm'
        );
        return <span>{dateReceived}</span>;
      },
    },
    {
      title: 'Дата обновления',
      dataIndex: 'updated_at',
      key: 'updated_at',
      width: '25%',
      render: (_text, item) => {
        const dateReceived = dayjs(item.created_at).format(
          'Do MMMM, YYYY, HH:mm'
        );
        return <span>{dateReceived}</span>;
      },
    },
  ];
  
  return (
    <Spin spinning={mainLoading || monthLoading}>
      <MainContainer>
        <Row justify={'space-between'}>
          <Col span={7}>
            <Descriptions
              title={
                <Col>
                  <Typography.Title style={{fontSize: '18px'}}>
                    Информация о дисциплине
                  </Typography.Title>
                  <Typography>
                    ID: {mainDiscipline?.getUserDiscipline?.id}
                  </Typography>
                </Col>
              }
              bordered
              column={1}
              size="small"
            >
              {fields.map((field, index) => renderField(field, index))}
            </Descriptions>
          </Col>

          <Col span={16}>
            <Row align={"middle"} justify={"space-between"}>
              <DatePicker onChange={onChange} picker={"month"} />
            </Row>

            <Row style={{marginTop: '15px'}}>
              <Table 
                columns={columnsMonth}
                dataSource={monthDiscipline?.getDisciplineMonth}
                style={{width: '100%'}}
                expandable={{
                  expandedRowRender: (record) => (
                    <Row>
                      <Table
                        className='expandedTable'
                        style={{width: '100%'}}
                        columns={columnsWeek}
                        dataSource={record?.disciplineMonth}
                      />
                    </Row>
                  )
                }}
              />
            </Row>
          </Col>
        </Row>
      </MainContainer>
    </Spin>
  );
};

export default UserDiscipline;