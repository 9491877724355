import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Table, Button, Modal, Form, Input } from 'antd';
import { Link } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';

import StyledTooltip from '../shared/StyledTooltip';
const FormItem = Form.Item;

const Clubs = ({ clubs, addClubClick }) => {
  const [form] = Form.useForm();
  const [modalVisible, setModalVisible] = useState(false);

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
    },
    {
      title: 'Cтрана',
      dataIndex: 'country',
    },
    {
      title: 'Город',
      dataIndex: 'city',
    },
    {
      title: 'Действие',
      key: 'action',
      render: (text, item) => (
        <span>
          <Link to={`/clubs/${item.id}`}>Редактировать</Link>
        </span>
      ),
    },
  ];

  const fields = [
    {
      key: 'name',
      label: 'Название',
      tooltipText: 'Название клуба',
    },
    {
      key: 'country',
      label: 'Страна',
      tooltipText: 'Страна в которой находится клуб',
    },
    {
      key: 'city',
      label: 'Город',
      tooltipText: 'Город в котором находится клуб',
    },
  ];

  const handleCreate = (values) => {
    addClubClick(values);
    setModalVisible(false);
  };

  const getTooltip = (text, fullText) => {
    return <StyledTooltip {...{ text, fullText }} />;
  };

  return (
    <>
      <Table
        dataSource={clubs}
        columns={columns}
        title={() => (
          <div>
            <Button type="primary" onClick={() => setModalVisible(true)}>
              <PlusOutlined /> Новый клуб
            </Button>
          </div>
        )}
      />
      <Modal
        open={modalVisible}
        title="Добавить новый клуб"
        okText="Создать"
        cancelText="Закрыть"
        onCancel={() => {
          form.resetFields();
          setModalVisible(false);
        }}
        onOk={() => {
          form
            .validateFields()
            .then((values) => {
              form.resetFields();
              const { locksCount } = values;
              handleCreate({
                ...values,
                locksCount: {
                  male: parseInt(locksCount?.male),
                  female: parseInt(locksCount?.female),
                },
              });
            })
            .catch((info) => {
              console.log('Validate Failed:', info);
            });
        }}
      >
        <Form form={form} layout="vertical" hideRequiredMark>
          <FormItem
            key="name"
            label={getTooltip('Название', 'Название клуба')}
            name="name"
          >
            <Input placeholder="Название" />
          </FormItem>
          <FormItem
            key="country"
            label={getTooltip('Страна', 'Страна в которой находится клуб')}
            name="country"
          >
            <Input placeholder="Страна" />
          </FormItem>
          <FormItem
            key="city"
            label={getTooltip('Город', 'Город в которой находится клуб')}
            name="city"
          >
            <Input placeholder="Город" />
          </FormItem>
          <FormItem
            key="tunnel"
            rules={[
              {
                required: true,
                message: 'Укажите туннель',
              },
            ]}
            label={getTooltip('Туннель', 'Туннель для шкафчика')}
            name="tunnel"
          >
            <Input placeholder="Туннель" />
          </FormItem>
          <FormItem label={getTooltip('Кол-во шкафчиков', 'Кол-во шкафчиков')}>
            <Input.Group compact>
              <Form.Item
                name={['locksCount', 'male']}
                noStyle
                rules={[
                  {
                    required: true,
                    message: 'Укажите кол-во мужских шкафчиков',
                  },
                ]}
              >
                <Input
                  type="number"
                  style={{
                    width: '50%',
                  }}
                  placeholder="Мужской"
                />
              </Form.Item>
              <Form.Item
                name={['locksCount', 'female']}
                noStyle
                rules={[
                  {
                    required: true,
                    message: 'Укажите кол-во женских шкафчиков',
                  },
                ]}
              >
                <Input
                  type="number"
                  style={{
                    width: '50%',
                  }}
                  placeholder="Женский"
                />
              </Form.Item>
            </Input.Group>
          </FormItem>
        </Form>
      </Modal>
    </>
  );
};

Clubs.propTypes = {
  clubs: PropTypes.shape({
    name: PropTypes.string,
    city: PropTypes.string,
    gym: PropTypes.string,
  }).isRequired,
  addClubClick: PropTypes.func.isRequired,
};

export default Clubs;
