import React from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';
import 'dayjs/locale/ru';
import { FormControl } from '@mui/material';
import { Select } from '@mui/material';
import { MenuItem } from '@mui/material';
import { useMutation } from '@apollo/client';
import { Popconfirm } from 'antd';
import { toast } from 'react-toastify';
import { gql } from 'graphql-tag';

import CancelLogo from '../../images/cancel.svg';
import ClientsReReg from './ClientsReReg';
import CheckInstallment from '../../images/CheckInstallment.svg';
import TransferModal from './TransferModal';
import ChangingHPDate from './ChangingHPDate';

const Wrapper = styled.div`
  background-color: #fff;
  position: fixed;
  transition: all 0.3s;
  top: 0;
  right: ${(props) => (props.show ? '0' : '-30%')};
  width: 368px;
  height: 100%;
  z-index: 1;
  overflow-y: scroll;
`;
const Cancel = styled.img`
  cursor: pointer;
  margin-left: auto;
`;
const Header = styled.div`
  display: flex;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  padding: 20px 24px;
  position: sticky;
  top: 0;
  background: #ffffff;
`;
const Title = styled.div`
  font-family: 'GraphikLCGMedium';
  font-size: 20px;
  line-height: 24px;
`;
const Body = styled.div`
  display: flex;
  flex-direction: column;
`;
const BodyTitle = styled.div`
  font-family: 'GraphikLCGMedium';
  font-size: 16px;
  line-height: 16px;
  margin-top: 3%;
`;
const Field = styled.div`
  margin-right: auto;
  font-family: 'GraphikLCGMedium';
  font-size: 14px;
  line-height: 16px;
  color: #00000080;
`;
const Value = styled.div`
  font-family: 'GraphikLCGRegular';
  font-size: 14px;
  line-height: 16px;
`;
const Content = styled.div`
  display: flex;
  margin-top: 7%;
`;
const Section = styled.div`
  padding: 24px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
`;
const NewSub = styled.button`
  font-size: 14px;
  line-height: 16px;
  font-family: 'GraphikLCGMedium';
  border-radius: 8px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 10px 12px 10px 12px;
  margin-top: 24px;
  cursor: pointer;
  margin: 24px 24px 8px 24px;
`;
const RefundSub = styled.button`
  font-size: 14px;
  line-height: 16px;
  font-family: 'GraphikLCGMedium';
  border-radius: 8px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 10px 12px 10px 0px;
  cursor: pointer;
  margin: 0px 24px;
  margin-top: 8px;
`;
const RefundSubDisable = styled.button`
  font-size: 14px;
  line-height: 16px;
  font-family: 'GraphikLCGMedium';
  color: rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 10px 12px 10px 12px;
  margin: 24px 24px 8px 24px;
`;
const MainTitle = styled.div`
  font-size: 14px;
  line-height: 16px;
  font-family: 'GraphikLCGMedium';
  color: rgba(0, 0, 0, 0.25);
  background: #ffffff;
`;
const SubTitle = styled.div`
  font-size: 11px;
  font-family: 'GraphikLCGMedium';
  color: rgba(0, 0, 0, 0.25);
  background: #ffffff;
`;
const History = styled.button`
  font-size: 14px;
  line-height: 16px;
  font-family: 'GraphikLCGMedium';
  border-radius: 8px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 10px 12px 10px 12px;
  margin-top: 14px;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  width: 100%;
`;
const HistoryAbsent = styled.button`
  font-size: 14px;
  line-height: 16px;
  font-family: 'GraphikLCGMedium';
  color: rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 10px 12px 10px 12px;
  margin-top: 24px;
  width: 100%;
`;
const ReReg = styled.button`
  font-size: 14px;
  line-height: 16px;
  font-family: 'GraphikLCGMedium';
  border-radius: 8px;
  background: #ffffff; 
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 10px 12px 10px 0px;
  cursor: pointer;
  margin: 0px 24px;
`;
const FinishButton = styled.button`
  font-size: 14px;
  line-height: 16px;
  font-family: 'GraphikLCGMedium';
  border-radius: 8px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 10px 12px 10px 12px;
  margin-top: 10px;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  width: 100%;
`;
const HistoryPayments = styled.button`
  font-size: 14px;
  line-height: 16px;
  font-family: 'GraphikLCGMedium';
  border-radius: 8px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 10px 12px 10px 0px;
  cursor: pointer;
  margin: 8px 24px;
`;
const PaymentsAbsent = styled.button`
  font-size: 14px;
  line-height: 16px;
  font-family: 'GraphikLCGMedium';
  color: rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 10px 12px 10px 12px;
  margin: 8px 24px;
`;
const ChangeAbonement = styled.button`
  font-size: 14px;
  line-height: 16px;
  font-family: 'GraphikLCGMedium';
  border-radius: 8px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 10px 12px 10px 0px;
  cursor: pointer;
  margin: 0px 24px;
`;
const ChangeAbonementAbsent = styled.button`
  font-size: 14px;
  line-height: 16px;
  font-family: 'GraphikLCGMedium';
  color: rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 10px 12px 10px 0px;
  margin: 0px 24px;
`;
const Calendar = styled.div`
  flex-wrap: wrap;
  padding-top: 16px;
  padding-bottom: 30px;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Month = styled.div`
  height: 78px;
  width: 77px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  margin-bottom: 6px;
  margin-right: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;
const NameMonths = styled.div`
  color: black;
  font-family: 'GraphikLCGMedium';
  font-size: 14px;
  line-height: 20px;
  text-transform: capitalize;
  paddingtop: 4px;
`;
const CheckView = styled.img`
  width: 24px;
  height: 24px;
  border-radius: 24px;
  background-color: #05ac52;
  cursor: pointer;
`;
const Circle = styled.div`
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 15px;
`;
const DateCalendar = styled.div`
  font-size: 14px;
  line-height: 16px;
  color: black;
  padding: 5px;
`;
const Sum = styled.div`
  font-size: 14px;
  line-height: 20px;
  color: black;
  padding-bottom: 4px;
`;

const FINISH_MARATHON_EVENT = gql`
  mutation finishMarathonEvent($userId: ID, $marathonEventId: ID) {
    finishMarathonEvent(userId: $userId, marathonEventId: $marathonEventId) {
      status
      message
    }
  }
`;

const ClientInfo = ({
  selectUser,
  setSelectUser,
  activeMarathon,
  userCurrentHP,
  hideLoading,
  refetch,
  dataUserCurrentHP,
  setNewSubcriptionModal,
  clientInfoModal,
  setClientInfoModal,
  setReRegSearch,
  reRegSearch,
  reRegUsers,
  setSelectedUserReissue,
  selectedUserReissue,
  setSearchReissueClient,
  SetSecondPage,
  secondPage,
  salesUsers,
  setSalesPerson,
  salesPerson,
  setIsReRegisterHeroPass,
  setPaymentModal,
  payment,
  setMixedPayment,
  mixedPayment,
  cardAmount,
  cashAmount,
  creditAmount,
  setPayment,
  setTotalPrice,
  totalPrice,
  setCashAmount,
  setCardAmount,
  setCreditAmount,
  setCompanyBin,
  companyBin,
  activeMarathonTickets,
  setHistoryPrograms,
  marathons,
  setHistoryPayments,
  userPayments,
  loadingUserCurrentHP,
  refetchUserCurrentHP,
  recurrentUsers,
  selectedRecurrentUser,
  setSelectedRecurrentUser,
  handleTransferNotUsedMarathon,
  setMarathonEvent,
  handleGetMarathonEvents,
  marathonEventsToTransfer,
  refundSubModal,
  setRefundSubModal,
  dataUserPayments,
  setSelectedGym,
  selectedGym,
  reRegAmount,
  setReRegAmount,
  clubs,
  reRegType,
  setReRegType,
  getReg,
}) => {
  const [valueDate, setValueDate] = React.useState('');
  const [transferShow, setTransferShow] = React.useState(false);
  const [transferModal, setTransferModal] = React.useState(false);
  const [marathonToTransfer, setMarathonToTransfer] = React.useState();
  const [currentUserPayment, setCurrentUserPayment] = React.useState(null);
  const [changeHRModal, setChangeHRModal] = React.useState(false);

  const handleChangeDate = (event) => {
    setValueDate(event.target.value);
  };

  React.useEffect(() => {
    if (userPayments && userCurrentHP?.id) {
      const currentUP = userPayments?.filter(
        (userPayment) => userPayment?.userHeroPass?.id === userCurrentHP?.id
      );
      setCurrentUserPayment(currentUP.length > 0 ? currentUP[0] : null);
    }
  }, [userPayments, userCurrentHP]);

  React.useEffect(() => {
    console.log('currentUserPayment', currentUserPayment);
  }, [currentUserPayment]);

  const Allmonths = selectedRecurrentUser?.monthPaymentsProgress;

  let updatedAllmonths = Allmonths ? [...Allmonths] : [];

  if (updatedAllmonths.length < 12 && updatedAllmonths.length !== 0) {
    const endDate = new Date(Allmonths[0].paymentDate);
    const month = new Date(endDate);
    const secondMonth = new Date(endDate);

    month.setMonth(month.getMonth() + 11);
    secondMonth.setMonth(secondMonth.getMonth() + 12);

    const payment = {
      amount: 0,
      isPaid: true,
      paymentDate: month.toISOString(),
    };
    const payment2 = {
      amount: 0,
      isPaid: true,
      paymentDate: secondMonth.toISOString(),
    };
    updatedAllmonths.push(payment, payment2);
  }

  const wrapperRef = React.useRef(null);

  const scrollToBottom = () => {
    setTimeout(() => {
      if (wrapperRef.current) {
        wrapperRef.current.scrollTo({
          top: wrapperRef.current.scrollHeight,
          behavior: 'smooth',
        });
      }
    }, 50);
  };

  const [finishMarathonEvent] = useMutation(FINISH_MARATHON_EVENT, {
    onError(err) {
      toast.error(`Error ${err.message}`);
    },
    onCompleted() {
      toast.success(`Марафон успешно завершен`);
      refetch();
    },
  });


  return (
    <Wrapper show={clientInfoModal} ref={wrapperRef}>
      <Header>
        <Title>{selectUser?.firstName}</Title>
        <Cancel
          src={CancelLogo}
          onClick={() => {
            setSelectUser(null);
            setClientInfoModal(false);
            setReRegSearch(false);
            setSelectedRecurrentUser(null);
          }}
        />
      </Header>
      <Body>
        <Section>
          <BodyTitle>Данные о пользователе</BodyTitle>
          <Content>
            <Field>Никнейм</Field>
            <Value>{selectUser?.nickname}</Value>
          </Content>
          <Content>
            <Field>Номер</Field>
            <Value>{selectUser?.phoneNumber}</Value>
          </Content>
          <Content>
            <Field>Email</Field>
            <Value>{selectUser?.username}</Value>
          </Content>
        </Section>
        <Section>
          <BodyTitle>Персонаж</BodyTitle>
          <Content>
            <Field>Персонаж</Field>
            <Value>{selectUser?.hero?.name}</Value>
          </Content>
          <Content>
            <Field>Уровень</Field>
            <Value>{selectUser?.level?.name}</Value>
          </Content>
        </Section>
        <Section>
          <BodyTitle>Программа</BodyTitle>
          <Content>
            <Field>Программа</Field>
            <Value>
              {activeMarathon ? activeMarathon?.marathon?.name : 'не участвует'}
            </Value>
          </Content>
          {activeMarathon && (
            <Content>
              <Field>Период</Field>
              <Value>
                {dayjs(activeMarathon?.startTime).format('DD.MM.YYYY')} -{' '}
                {dayjs(activeMarathon?.endTime).format('DD.MM.YYYY')}
              </Value>
            </Content>
          )}
          {activeMarathon && (
            <Content>
              <Field>Баланс</Field>
              <Value>{activeMarathonTickets?.ticketsLeft}</Value>
            </Content>
          )}
          {activeMarathon && (
            <Content>
              <Field>Визитов</Field>
              <Value>{activeMarathonTickets?.marathonVisits}</Value>
            </Content>
          )}
          {activeMarathon && (
            <Content>
              <Field>Посещения Ассессмент</Field>
              <Value>
                {activeMarathonTickets?.assessmentVisit ? 'Да' : 'Нет'}
              </Value>
            </Content>
          )}
          {activeMarathon && (
            <History
              onClick={() => {
                setTransferShow((prevState) => !prevState);
                handleGetMarathonEvents({ value: activeMarathon.marathon.id });
              }}
            >
              Перенести марафон
            </History>
          )}
          {activeMarathon?.marathon.id === '665481a8dc0bb800341e7af6' && (
            <Popconfirm
            title="Завершить марафон?"
            cancelText="Отмена"
            onConfirm={() => {
              finishMarathonEvent({
                variables: {
                  userId: selectUser?.id,
                  marathonEventId: activeMarathon?.id,
                },
              });
            }}
          >
            <FinishButton>Завершить</FinishButton>
          </Popconfirm>
        )}
          {transferShow && (
            <FormControl style={{ width: 300 }}>
              <Select
                MenuProps={{
                  disableScrollLock: true,
                }}
                value={valueDate}
                onChange={handleChangeDate}
                displayEmpty
                disableUnderline
                style={{
                  fontFamily: 'GraphikLCGMedium',
                  marginTop: 20,
                  fontSize: 14,
                  height: 40,
                }}
              >
                <MenuItem
                  value=""
                  style={{ fontFamily: 'GraphikLCGMedium', fontSize: 14 }}
                >
                  Выберите дату
                </MenuItem>
                {marathonEventsToTransfer?.map((item, index) => {
                  if (dayjs().weekday(-7) <= dayjs(item?.startTime)) {
                    return (
                      <MenuItem
                        value={index}
                        key={index}
                        style={{
                          fontFamily: 'GraphikLCGMedium',
                          fontSize: 14,
                        }}
                        onClick={() => {
                          setTransferModal(true);
                          setMarathonToTransfer(item);
                        }}
                      >
                        {dayjs(item?.startTime).format('DD.MM.YYYY')}
                      </MenuItem>
                    );
                  }
                })}
              </Select>
            </FormControl>
          )}
          {marathons?.length > 1 ? (
            <History
              onClick={() => {
                setHistoryPrograms(true);
              }}
            >
              История программ
            </History>
          ) : (
            <HistoryAbsent>История программ отсутствует</HistoryAbsent>
          )}
        </Section>
        {dataUserCurrentHP?.currentUserHeroPass && (
          <Section>
            <BodyTitle>Абонемент</BodyTitle>
            <Content>
              <Field>Тариф</Field>
              <Value>{userCurrentHP?.heroPass?.name}</Value>
            </Content>
            <Content>
              <Field>Дата активации</Field>
              <Value>
                {dayjs(userCurrentHP?.startTime).format('DD.MM.YYYY')}
              </Value>
            </Content>
            <Content>
              <Field>Дата истечения</Field>
              <Value>
                {dayjs(userCurrentHP?.endTime).format('DD.MM.YYYY')}
              </Value>
            </Content>
            {userCurrentHP?.toUser === selectUser?.id && (
              <Content>
                <Field>Абонемент переоформлен с другого пользователя</Field>
                <Value>
                  {dayjs(userCurrentHP?.updated_at).format('DD.MM.YYYY')}
                </Value>
              </Content>
            )}
            {userCurrentHP?.status === 'reRegistered' && (
              <Content>
                <Field>Абонемент переоформлен на другого пользователя</Field>
                <Value>
                  {dayjs(userCurrentHP?.updated_at).format('DD.MM.YYYY')}
                </Value>
              </Content>
            )}
          </Section>
        )}
        <NewSub
          onClick={() => {
            setNewSubcriptionModal(true);
            setReRegSearch(false);
          }}
        >
          Новый абонемент
        </NewSub>
        {userCurrentHP?.toUser !== selectUser?.id && (
          <ReReg
            onClick={() => {
              setReRegSearch((prevState) => !prevState);
              scrollToBottom();
            }}
          >
            Переоформление
          </ReReg>
        )}
        {userCurrentHP?.status === 'regular' && currentUserPayment?.checkId && (
          <RefundSub
            onClick={() => {
              setRefundSubModal(true);
            }}
          >
            Оформить возврат
          </RefundSub>
        )}
        {userPayments?.length > 1 ? (
          <HistoryPayments
            onClick={() => {
              setHistoryPayments(true);
            }}
          >
            История покупок
          </HistoryPayments>
        ) : (
          <PaymentsAbsent>История отсутствует</PaymentsAbsent>
        )}
        {!activeMarathon && dataUserCurrentHP?.currentUserHeroPass ? (
          <ChangeAbonement
            onClick={() => {
              setChangeHRModal(true);
            }}
          >
            Поменять дату абонемента
          </ChangeAbonement>
        ) : (
          <ChangeAbonementAbsent>
            Поменять дату абонемента
          </ChangeAbonementAbsent>
        )}
        <ChangingHPDate
          changeHRModal={changeHRModal}
          setChangeHRModal={setChangeHRModal}
          selectUser={selectUser}
          userCurrentHP={userCurrentHP}
          marathons={marathons}
          refetchUserCurrentHP={refetchUserCurrentHP}
        />
        {userCurrentHP?.status === 'regular' &&
          currentUserPayment &&
          !currentUserPayment?.checkId && (
            <RefundSubDisable>
              <MainTitle>
                Оформить возврат{currentUserPayment?.chackId}
              </MainTitle>
              <SubTitle>Нет чека</SubTitle>
            </RefundSubDisable>
          )}
        <Calendar>
          {updatedAllmonths.map((month, index) => (
            <Month key={month.paymentDate}>
              <NameMonths>{dayjs(month.paymentDate).format('MMMM')}</NameMonths>
              {month.isPaid ? (
                <CheckView src={CheckInstallment} />
              ) : (
                <Circle>
                  <DateCalendar>
                    {dayjs(month.paymentDate).format('DD')}
                  </DateCalendar>
                </Circle>
              )}
              <Sum>{month.amount}₸</Sum>
            </Month>
          ))}
        </Calendar>
        {reRegSearch && (
          <ClientsReReg
            reRegUsers={reRegUsers}
            selectedUserReissue={selectedUserReissue}
            setSelectedUserReissue={setSelectedUserReissue}
            setSearchReissueClient={setSearchReissueClient}
            SetSecondPage={SetSecondPage}
            secondPage={secondPage}
            salesUsers={salesUsers}
            setSalesPerson={setSalesPerson}
            setIsReRegisterHeroPass={setIsReRegisterHeroPass}
            setPaymentModal={setPaymentModal}
            payment={payment}
            setMixedPayment={setMixedPayment}
            mixedPayment={mixedPayment}
            cardAmount={cardAmount}
            cashAmount={cashAmount}
            creditAmount={creditAmount}
            setPayment={setPayment}
            setTotalPrice={setTotalPrice}
            totalPrice={totalPrice}
            setCashAmount={setCashAmount}
            setCardAmount={setCardAmount}
            setCreditAmount={setCreditAmount}
            setCompanyBin={setCompanyBin}
            companyBin={companyBin}
            selectUser={selectUser}
            setSelectedGym={setSelectedGym}
            selectedGym={selectedGym}
            reRegAmount={reRegAmount}
            setReRegSearch={setReRegSearch}
            clubs={clubs}
            reRegType={reRegType}
            setReRegType={setReRegType}
          />
        )}
        {transferModal && (
          <TransferModal
            setVisible={setTransferModal}
            visible={transferModal}
            selectUser={selectUser}
            activeMarathon={activeMarathon}
            handleTransferNotUsedMarathon={handleTransferNotUsedMarathon}
            marathonToTransfer={marathonToTransfer}
          />
        )}
      </Body>
    </Wrapper>
  );
};

export default ClientInfo;
