import React, { useState, useEffect } from 'react';
import { gql, useMutation, useQuery, useLazyQuery } from '@apollo/client';
import { toast } from 'react-toastify';

import PromptTrainers from './PromptTriners';
import Loading from '../shared/Loading';
import { useLoading } from '../../context/useLoading';
import withMainLayout from '../../hocs/withMainLayout';

const GET_TRAINERS = gql`
  query trainers($role: String!) {
    usersByRole(role: $role) {
      id
      nickname
    }
  }
`;

const GET_TRAINER_DESCRIPTIONS = gql`
  query trainerDescriptions {
    trainerDescriptions {
      id
      trainer {
        id
        nickname
      }
      description
    }
  }
`;

const MODIFY_TRAINER_DESCRIPTIONS = gql`
  mutation modifyTrainerDescription($trainerId: ID, $description: String) {
    modifyTrainerDescription(trainerId: $trainerId, description: $description) {
      status
      message
    }
  }
`;

const PromptTrainersContainer = () => {
  const [dataTrainers, setDataTrainers] = useState([]);
  const [promptTrainers, setPromptTrainers] = useState([]);
  const { showLoading, hideLoading } = useLoading();

  const { data: trainersData, loading: trainersLoading } = useQuery(
    GET_TRAINERS,
    {
      variables: {
        role: 'trainer',
      },
    }
  );
  const [getPromptTrainers, { data, loading, error, refetch }] = useLazyQuery(
    GET_TRAINER_DESCRIPTIONS
  );

  const [modifyTrainerDescription] = useMutation(MODIFY_TRAINER_DESCRIPTIONS, {
    onCompleted() {
      refetch();
      hideLoading();
      toast.success('Данные о тренера успешно отредактирован');
    },
  });

  useEffect(() => {
    if (data && !loading && !error && dataTrainers?.length > 0) {
      console.log('data', data);
      const promptTtrainersModify = dataTrainers.map((dataTrainer) => {
        const findPromptDesc = data?.trainerDescriptions.find(
          (trainerDesc) => trainerDesc?.trainer?.id === dataTrainer?.id
        );
        return {
          description: findPromptDesc?.description || null,
          trainer: {
            id: dataTrainer?.id,
            nickname: dataTrainer?.nickname,
          },
        };
      });
      console.log('promptTtrainersModify', promptTtrainersModify);
      setPromptTrainers(promptTtrainersModify);
    }
  }, [data, loading, error, dataTrainers]);

  useEffect(() => {
    if (trainersData && !trainersLoading) {
      console.log('trainersData.usersByRole', trainersData.usersByRole);
      setDataTrainers(trainersData.usersByRole);
      getPromptTrainers();
    }
  }, [trainersData, trainersLoading]);

  const handleModifyTrainerDescription = (trainerId, description) => {
    showLoading();
    modifyTrainerDescription({
      variables: { trainerId: trainerId, description: description },
    });
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <PromptTrainers
      promptTrainers={promptTrainers}
      handleModifyTrainerDescription={handleModifyTrainerDescription}
    />
  );
};

export default withMainLayout(PromptTrainersContainer);
