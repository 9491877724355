import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { Table, Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import CreateLecture from './CreateLecture';
import EditLecture  from './EditLecture';

const MarathonLectures = ({
  lectures,
  handleAddLecture,
  handleDeleteLecture,
  handleUpdateLecture
}) => {
  const [editLecture, setEditLecture] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [editModalVisible, setEditModalVisible] = useState(false);

  const columns = [
    {
      title: 'Название',
      dataIndex: 'title'
    },
    {
      title: 'Неделя',
      dataIndex: 'weekNumber'
    },
    {
      title: 'Действие',
      key: 'action',
      render: (text, item) => (
        <span>
          <Button
            type="link"
            onClick={() => {
              setEditModalVisible(true);
              setEditLecture(item);
            }}
          >
            Редактировать
          </Button>
        </span>
      )
    }
  ];

  return (
    <>
      <Table
        dataSource={lectures}
        columns={columns}
        rowKey={item => item?.id}
        title={() => (
          <div>
            <Button
              type="primary"
              onClick={() => {
                setModalVisible(true);
              }}
            >
              <PlusOutlined /> Новая лекция
            </Button>
          </div>
        )}
      />
      <CreateLecture
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        onCreate={handleAddLecture}
      />
      {editLecture && editModalVisible && (
        <EditLecture
          visible={editModalVisible}
          onCancel={() => setEditModalVisible(false)}
          onUpdate={handleUpdateLecture}
          lecture={editLecture}
        />
      )}
    </>
  );
};

MarathonLectures.propTypes = {
  lectures: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired
    })
  ).isRequired,
  handleAddLecture: PropTypes.func.isRequired,
  handleDeleteLecture: PropTypes.func.isRequired,
  handleUpdateLecture: PropTypes.func.isRequired
};

export default MarathonLectures;
