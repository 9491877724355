import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { gql, useMutation, useQuery, useLazyQuery } from "@apollo/client";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

import CreateMessage from "./CreateMessage";
import { useLoading } from "../../context/useLoading";
import withMainLayout from "../../hocs/withMainLayout";
import Loading from "../shared/Loading";

const GET_DATA = gql`
  query getUsers {
    levels {
      id
      name
      degree
    }
  }
`;

const GET_USERS = gql`
  query find($query: UsersQuery, $pagination: PaginationQuery) {
    findUsers(queryUsers: $query, pagination: $pagination) {
      users {
        id
        nickname
        email
        phoneNumber
      }
      total
      limit
      page
      pages
    }
  }
`;

const ADD_MESSAGE = gql`
  mutation addMessage($input: MessageInput) {
    addMessage(input: $input) {
      id
      title
      type
      html
      json
    }
  }
`;

const CreateMessageContainer = () => {
  const [users, setUsers] = useState([]);
  const [levels, setLevels] = useState([]);
  const navigate = useNavigate();
  const { showLoading, hideLoading } = useLoading();

  const { data, loading, error } = useQuery(GET_DATA);

  const [addMessage] = useMutation(ADD_MESSAGE, {
    onCompleted() {
      hideLoading();
      toast.success("Сообщение успешно создано");
      navigate("/messages");
    }
  });

  const [
    getUsers,
    {
      loading: loadingUsers,
      error: errorUsers,
      data: dataUsers,
      refetch: refetchUsers
    }
  ] = useLazyQuery(GET_USERS);

  const handleAddMessage = (values) => {
    showLoading();
    addMessage({
      variables: {
        input: values
      }
    });
  };

  useEffect(() => {
    getUsers({
      pagination: {
        page: 1,
        limit: 20
      }
    });
  }, []);

  useEffect(() => {
    if (data && data.levels) {
      setLevels(data.levels);
    } else if (error) {
      console.log("error", error);
    }
  }, [data, loading, error]);

  useEffect(() => {
    if (dataUsers && dataUsers.findUsers.users) {
      setUsers(dataUsers.findUsers.users);
    }
  }, [dataUsers, loadingUsers, errorUsers]);

  if (loading && !data) {
    return <Loading />;
  }

  return (
    <CreateMessage
      addClick={handleAddMessage}
      allUsers={users}
      allLevels={levels}
      loadingUsers={loadingUsers}
      refetchUsers={refetchUsers}
    />
  );
};

CreateMessageContainer.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired
    })
  }).isRequired
};

export default withMainLayout(CreateMessageContainer);
