import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Input, Typography, Select, Radio, Spin } from 'antd';
import styled from 'styled-components';

const { TextArea } = Input;
const { Text } = Typography;

const InputItemContainer = styled.div`
    margin-bottom: 20px;
`;

const CreateNotificationModal = ({
                                   isVisible,
                                   setIsVisible,
                                   heading,
                                   message,
                                   type,
                                   setHeading,
                                   setMessage,
                                   setType,
                                   handleSendNotification,
                                   clubs,
                                   selectedClub,
                                   setSelectedClub,
                                   recipientType,
                                   setRecipientType,
                                   selectedUsers,
                                   setSelectedUsers,
                                   users,
                                   handleUserSearch,
                                   usersLoading,
                                 }) => {
  return (
    <Modal
      open={isVisible}
      onCancel={() => setIsVisible(false)}
      title="Новое уведомление"
      onOk={handleSendNotification}
    >
      <InputItemContainer>
        <Text>Заголовок</Text>
        <Input value={heading} onChange={(e) => setHeading(e.target.value)} />
      </InputItemContainer>
      <InputItemContainer>
        <Text>Описание</Text>
        <TextArea value={message} onChange={(e) => setMessage(e.target.value)} />
      </InputItemContainer>
      <InputItemContainer>
        <Text>Тип сообщения</Text>
        <Select
          placeholder="Выберите тип сообщения"
          value={type}
          onChange={(value) => setType(value)}
          style={{ width: '100%' }}
        >
          <Select.Option key="push" value="push">
            push
          </Select.Option>
          <Select.Option key="sms" value="sms" disabled>
            sms
          </Select.Option>
          <Select.Option key="email" value="email" disabled>
            email
          </Select.Option>
        </Select>
      </InputItemContainer>
      <InputItemContainer>
        <Text>Получатель</Text>
        <Radio.Group
          onChange={(e) => setRecipientType(e.target.value)}
          value={recipientType}
        >
          <Radio value="club">Клуб</Radio>
          <Radio value="users">Пользователи</Radio>
        </Radio.Group>
      </InputItemContainer>
      {recipientType === 'club' && (
        <InputItemContainer>
          <Text>Клуб</Text>
          <Select
            placeholder="Выберите клуб"
            value={selectedClub}
            onChange={(value) => setSelectedClub(value)}
            style={{ width: '100%' }}
          >
            {clubs.map((club) => (
              <Select.Option key={club.id} value={club.id}>
                {club.name}
              </Select.Option>
            ))}
          </Select>
        </InputItemContainer>
      )}
      {recipientType === 'users' && (
        <InputItemContainer>
          <Text>Пользователи</Text>
          <Select
            mode="multiple"
            placeholder="Выберите пользователей"
            value={selectedUsers}
            onChange={(value) => setSelectedUsers(value)}
            style={{ width: '100%' }}
            onSearch={handleUserSearch}
            filterOption={false}
            notFoundContent={usersLoading ? <Spin size="small" /> : null}
          >
            {users.map((user) => (
              <Select.Option key={user.id} value={user.id}>
                {user.email} | {user.phoneNumber}
              </Select.Option>
            ))}
          </Select>
        </InputItemContainer>
      )}
    </Modal>
  );
};

CreateNotificationModal.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  setIsVisible: PropTypes.func.isRequired,
  heading: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  setHeading: PropTypes.func.isRequired,
  setMessage: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  setType: PropTypes.func.isRequired,
  handleSendNotification: PropTypes.func.isRequired,
  clubs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  selectedClub: PropTypes.string,
  setSelectedClub: PropTypes.func.isRequired,
  recipientType: PropTypes.string.isRequired,
  setRecipientType: PropTypes.func.isRequired,
  selectedUsers: PropTypes.arrayOf(PropTypes.string).isRequired,
  setSelectedUsers: PropTypes.func.isRequired,
  users: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      email: PropTypes.string,
      phoneNumber: PropTypes.string,
    })
  ).isRequired,
  handleUserSearch: PropTypes.func.isRequired,
  usersLoading: PropTypes.bool.isRequired,
};

export default CreateNotificationModal;
