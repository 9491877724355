import React, { useState, useEffect } from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import { toast } from 'react-toastify';

import Awards from './Awards';
import Loading from '../shared/Loading';
import { useLoading } from '../../context/useLoading';
import withMainLayout from '../../hocs/withMainLayout';

const AWARDS = gql`
  query awards {
    awards {
      id
      name
      description
      type
      imageURL
      requiredAmount
    }
  }
`;

const DELETE_AWARD = gql`
  mutation deleteAward($id: ID!) {
    deleteAward(id: $id)
  }
`;

const ADD_AWARD = gql`
  mutation addAward($input: AwardInput) {
    addAward(input: $input) {
      id
      name
      description
      type
      imageURL
      requiredAmount
    }
  }
`;

const UPDATE_AWARD = gql`
  mutation updateAward($id: ID!, $input: AwardInput) {
    updateAward(id: $id, input: $input) {
      id
      name
      description
      type
      imageURL
      requiredAmount
    }
  }
`;

const AwardsContainer = () => {
  const [dataAwards, setDataAwards] = useState(null);
  const { showLoading, hideLoading } = useLoading();

  const [updateAward] = useMutation(UPDATE_AWARD);

  const { data, loading, error } = useQuery(AWARDS);

  const [deleteAward] = useMutation(DELETE_AWARD, {
    update(cache, { data: { deleteAward: id } }) {
      const { awards } = cache.readQuery({ query: AWARDS });
      cache.writeQuery({
        query: AWARDS,
        data: {
          awards: awards.filter(award => award.id !== id)
        }
      });
      hideLoading();
      toast.success('Награда успешно удалена');
    }
  });

  const [addAward] = useMutation(ADD_AWARD, {
    update(cache, { data: { addAward: award } }) {
      const { awards } = cache.readQuery({ query: AWARDS });
      cache.writeQuery({
        query: AWARDS,
        data: { awards: awards.concat([award]) }
      });
      hideLoading();
      toast.success('Награда успешно создана');
    }
  });

  useEffect(() => {
    if (data && !loading && !error) {
      setDataAwards(data.awards);
    }
  }, [data, loading, error]);

  const handleDeleteAward = awardId => {
    showLoading();
    deleteAward({ variables: { id: awardId } });
  };

  const handleAddAward = values => {
    showLoading();
    const { name, description, type, imageURL, requiredAmount } = values;
    const dataAward = {
      name,
      description,
      type,
      imageURL,
      requiredAmount: parseInt(requiredAmount, 10)
    };
    addAward({
      variables: {
        input: dataAward
      }
    });
  };

  const handleUpdateAward = (id, values) => {
    const { name, description, type, imageURL, requiredAmount } = values;
    const dataAward = {
      name,
      description,
      type,
      imageURL,
      requiredAmount: parseInt(requiredAmount, 10)
    };
    updateAward({ variables: { id, input: dataAward } });
  };

  if (loading && !dataAwards) {
    return <Loading />;
  }

  return (
    <Awards
      awards={dataAwards}
      deleteAwardClick={handleDeleteAward}
      addAwardClick={handleAddAward}
      updateAwardClick={handleUpdateAward}
    />
  );
};

export default withMainLayout(AwardsContainer);
