import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Card, Col, Row } from 'antd';
import ModifyModel from './ModifyModel';

const PromptTrainers = ({ promptTrainers, handleModifyTrainerDescription }) => {
  const [editPromptTrainer, setEditPromptTrainer] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);

  return (
    <>
      <Row gutter={16} style={{ overflow: 'scroll', height: '90%' }}>
        {promptTrainers?.map((promptTrainer, index) => (
          <Col
            span={8}
            style={{ marginBottom: 8 }}
            key={index}
            onClick={() => {
              console.log('asd');
              setEditPromptTrainer(promptTrainer);
              setModalVisible(true);
            }}
          >
            <Card
              hoverable
              title={promptTrainer?.trainer?.nickname}
              bordered={false}
            >
              {promptTrainer?.description
                ? promptTrainer?.description
                : 'Нет Данных'}
            </Card>
          </Col>
        ))}
      </Row>
      {modalVisible && (
        <ModifyModel
          modalVisible={modalVisible}
          setModalVisible={setModalVisible}
          setEditPromptTrainer={setEditPromptTrainer}
          editPromptTrainer={editPromptTrainer}
          handleModifyTrainerDescription={handleModifyTrainerDescription}
        />
      )}
    </>
  );
};

PromptTrainers.propTypes = {
  promptTrainers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      description: PropTypes.string,
      trainer: PropTypes.shape({
        id: PropTypes.string,
        nickname: PropTypes.string,
      }),
    })
  ),
  handleModifyTrainerDescription: PropTypes.func,
};

export default PromptTrainers;
