import React from 'react';
import PropTypes from 'prop-types';
import { Table, Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import styled from 'styled-components';
import CreateNotificationModal from './CreateNotification.modal';

const StyledHeaderContainer = styled.div`
    display: flex;
    justify-content: space-between;
`;

const Notifications = ({
                         columns,
                         notifications,
                         isVisible,
                         setIsVisible,
                         heading,
                         message,
                         type,
                         setHeading,
                         setMessage,
                         setType,
                         handleSendNotification,
                         pagination,
                         onPageChange,
                         clubs,
                         selectedClub,
                         setSelectedClub,
                         recipientType,
                         setRecipientType,
                         selectedUsers,
                         setSelectedUsers,
                         users,
                         handleUserSearch,
                         usersLoading,
                       }) => {
  return (
    <>
      <Table
        dataSource={notifications}
        columns={columns}
        rowKey={(item) => item.id}
        pagination={{
          current: pagination.page,
          total: pagination.total,
          pageSize: pagination.limit,
          onChange: onPageChange,
        }}
        title={() => (
          <StyledHeaderContainer>
            <Button type="primary" onClick={() => setIsVisible(true)}>
              <PlusOutlined /> Новая нотификация
            </Button>
          </StyledHeaderContainer>
        )}
      />
      {isVisible && (
        <CreateNotificationModal
          {...{
            isVisible,
            setIsVisible,
            heading,
            message,
            type,
            setHeading,
            setMessage,
            setType,
            handleSendNotification,
            clubs,
            selectedClub,
            setSelectedClub,
            recipientType,
            setRecipientType,
            selectedUsers,
            setSelectedUsers,
            users,
            handleUserSearch,
            usersLoading,
          }}
        />
      )}
    </>
  );
};

Notifications.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  notifications: PropTypes.arrayOf(PropTypes.object).isRequired,
  isVisible: PropTypes.bool.isRequired,
  setIsVisible: PropTypes.func.isRequired,
  heading: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  setHeading: PropTypes.func.isRequired,
  setMessage: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  setType: PropTypes.func.isRequired,
  handleSendNotification: PropTypes.func.isRequired,
  pagination: PropTypes.shape({
    page: PropTypes.number.isRequired,
    limit: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired,
  }).isRequired,
  onPageChange: PropTypes.func.isRequired,
  clubs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  selectedClub: PropTypes.string,
  setSelectedClub: PropTypes.func.isRequired,
  recipientType: PropTypes.string.isRequired,
  setRecipientType: PropTypes.func.isRequired,
  selectedUsers: PropTypes.arrayOf(PropTypes.string).isRequired,
  setSelectedUsers: PropTypes.func.isRequired,
  users: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      email: PropTypes.string,
      phoneNumber: PropTypes.string,
    })
  ).isRequired,
  handleUserSearch: PropTypes.func.isRequired,
  usersLoading: PropTypes.bool.isRequired,
};

export default Notifications;