import { Breadcrumb, Button, Form, Input, Layout, Menu, Select } from 'antd';
import { GatewayOutlined, InfoCircleOutlined } from '@ant-design/icons';
import React, { useState } from 'react';

import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import StyledTooltip from '../shared/StyledTooltip';
import showConfirm from '../shared/DeleteConfirm';
import styled from 'styled-components';

const FormItem = Form.Item;

const { Header, Sider, Content } = Layout;

const StyledBreadcrumb = styled(Breadcrumb)`
  margin: 16px 0;
`;

const StyledHeader = styled(Header)`
  background: #fff;
  border-bottom: 1px solid #d9d9d9;
  padding: 0px 24px;
`;

const StyledMenu = styled(Menu)`
  height: 100%;
  padding-top: 16px;
`;
const StyledLayout = styled(Layout)`
  padding: 0 24px 24px;
  background: #fff;
`;

const StyledContent = styled(Content)`
  padding: 24px;
  margin: 0px;
  min-height: 280px;
`;
const HeaderTitle = styled.div`
  font-family: SF Pro Display;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;

  display: flex;
  align-items: center;

  color: #262626;
  margin-bottom: 24px;
`;

const Ticket = ({
  ticket,
  allUsers,
  allLevels,
  updateClick,
  deleteClick,
  refetchUsers
}) => {
  const [type, setType] = useState(ticket.type);
  const [title, setTitle] = useState(ticket.title);
  const [text, setText] = useState(ticket.text);
  const [level, setLevel] = useState(ticket.level);
  const [users, setUsers] = useState(ticket.users.map(user => user.id));
  const [severity, setSeverity] = useState(ticket.severity || 1);
  const [affectedUsers, setAffectedUsers] = useState(ticket.affectedUsers || []);

  const getTooltip = (text, fullText) => {
    return <StyledTooltip {...{ text, fullText }} />;
  };

  const onFinish = values => {
    updateClick(values);
  };

  const handleSearch = value => {
    refetchUsers({
      query: {
        phoneNumber: value
      },
      pagination: {
        page: 1,
        limit: 20
      }
    });
  };

  return (
    <Layout>
      <StyledBreadcrumb>
        <Breadcrumb.Item>
          <Link to="/tickets">Tickets</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{ticket?.title}</Breadcrumb.Item>
      </StyledBreadcrumb>
      <StyledHeader>
        <h2>Edit Ticket</h2>
      </StyledHeader>
      <Layout>
        <StyledLayout>
          <StyledContent className="site-layout-background">
            <HeaderTitle>Main</HeaderTitle>
            <Form layout="vertical" hideRequiredMark onFinish={onFinish}>
              <FormItem
                key="title"
                label={getTooltip('Title', 'Title of the ticket')}
                name="title"
                initialValue={ticket.title}
              >
                <Input
                  placeholder="Title"
                  onChange={value => {
                    setTitle(value.target.value);
                  }}
                />
              </FormItem>
              <FormItem
                key="type"
                label={getTooltip('Type', 'Type of the ticket')}
                name="type"
                initialValue={ticket.type}
              >
                <Select
                  placeholder="Type of the ticket"
                  showSearch
                  onChange={value => setType(value)}
                >
                  <Select.Option key="issue" value="issue">
                    Issue
                  </Select.Option>
                  <Select.Option key="request" value="request">
                    Request
                  </Select.Option>
                </Select>
              </FormItem>
              {type === 'onlyUsers' && (
                <FormItem
                  key="users"
                  label={getTooltip(
                    'Users',
                    'Users who will receive the ticket'
                  )}
                  name="users"
                  initialValue={ticket.users.map(user => user.id)}
                >
                  <Select
                    filterOption={false}
                    mode="multiple"
                    placeholder="Users"
                    showSearch
                    onChange={value => {
                      setUsers(value);
                    }}
                  >
                    {allUsers &&
                      allUsers.map(user => (
                        <Select.Option key={user.id} value={user.id}>
                          {user.email} | {user.phoneNumber}
                        </Select.Option>
                      ))}
                  </Select>
                </FormItem>
              )}
              {type === 'onlyCertainLevel' && (
                <FormItem
                  key="level"
                  label={getTooltip(
                    'Level for which this ticket is intended',
                    'Level for which this ticket is intended'
                  )}
                  name="level"
                  initialValue={ticket.level?.id}
                >
                  <Select
                    placeholder="Level"
                    onChange={value => {
                      setLevel(value);
                    }}
                  >
                    {allLevels &&
                      allLevels.map(level => (
                        <Select.Option key={level.id} value={level.id}>
                          {level.name} | degree: {level.degree}
                        </Select.Option>
                      ))}
                  </Select>
                </FormItem>
              )}
              <FormItem
                key="severity"
                label={getTooltip('Severity', 'Severity of the ticket (1-5)')}
                name="severity"
                initialValue={severity}
              >
                <Select
                  placeholder="Severity"
                  onChange={value => setSeverity(value)}
                >
                  <Select.Option key="1" value="1">
                    1 - Low
                  </Select.Option>
                  <Select.Option key="2" value="2">
                    2 - Medium
                  </Select.Option>
                  <Select.Option key="3" value="3">
                    3 - High
                  </Select.Option>
                  <Select.Option key="4" value="4">
                    4 - Critical
                  </Select.Option>
                  <Select.Option key="5" value="5">
                    5 - Blocker
                  </Select.Option>
                </Select>
              </FormItem>
              <FormItem
                key="affectedUsers"
                label={getTooltip('Affected Users', 'Users affected by this ticket')}
                name="affectedUsers"
                initialValue={affectedUsers}
              >
                <Select
                  filterOption={false}
                  mode="multiple"
                  placeholder="Affected Users"
                  showSearch
                  onChange={value => {
                    setAffectedUsers(value);
                  }}
                >
                  {allUsers &&
                    allUsers.map(user => (
                      <Select.Option key={user.id} value={user.id}>
                        {user.email} | {user.phoneNumber}
                      </Select.Option>
                    ))}
                </Select>
              </FormItem>
              <FormItem
                key="text"
                label={getTooltip('Text', 'Text of the ticket')}
                name="text"
                initialValue={ticket.text}
              >
                <Input.TextArea
                  rows={10}
                  placeholder="Ticket Text"
                  value={text}
                  onChange={e => setText(e.target.value)}
                />
              </FormItem>
              <Form.Item style={{ textAlign: 'right' }}>
                <Button
                  danger
                  style={{ float: 'left' }}
                  onClick={() => {
                    showConfirm(() => {
                      deleteClick();
                    });
                  }}
                >
                  Delete
                </Button>
                <Button type="primary" htmlType="submit">
                  Save Changes
                </Button>
              </Form.Item>
            </Form>
          </StyledContent>
        </StyledLayout>
      </Layout>
    </Layout>
  );
};

Ticket.propTypes = {
  ticket: PropTypes.shape({
    title: PropTypes.string,
    type: PropTypes.string,
    text: PropTypes.string,
    users: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        email: PropTypes.string
      })
    ),
    level: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      degree: PropTypes.number
    }),
    severity: PropTypes.number,
    affectedUsers: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        email: PropTypes.string
      })
    )
  }).isRequired,
  allUsers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      email: PropTypes.string
    })
  ).isRequired,
  allLevels: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string,
      degree: PropTypes.number
    })
  ).isRequired,
  updateClick: PropTypes.func.isRequired,
  deleteClick: PropTypes.func.isRequired,
  refetchUsers: PropTypes.func.isRequired
};

export default Ticket;