import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, Form, Input, Select, Avatar, DatePicker } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import dayjs from 'dayjs';

import StyledTooltip from '../shared/StyledTooltip';
import showConfirm from '../shared/DeleteConfirm';
import ImageUpload from '../shared/ImageUpload';

const { TextArea } = Input;
const FormItem = Form.Item;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 4 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 20 },
  },
};

const ImageUploadContainer = styled.div`
  display: flex;
  flex-direction: row;
`;
const StyledAvatar = styled(Avatar)`
  margin-left: 5px;
`;
const StyledDatePicker = styled(DatePicker)`
  width: 100%;
`;

const EditNewsModal = ({
  visible,
  onCancel,
  title,
  editNewsModal,
  onUpdate,
  onDelete,
}) => {
  const [imageUrl, setImageUrl] = React.useState(editNewsModal.imageUrl);
  const [form] = Form.useForm();

  const getTooltip = (text, fullText) => {
    return <StyledTooltip {...{ text, fullText }} />;
  };

  return (
    <Modal
      open={visible}
      title={title}
      okText="Создать"
      cancelText="Закрыть"
      onCancel={onCancel}
      footer={[
        <Button
          danger
          style={{ float: 'left' }}
          onClick={() => {
            showConfirm(() => {
              onDelete();
            });
          }}
        >
          Удалить
        </Button>,
        <Button key="submit" onClick={onCancel}>
          Отмена
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={() => {
            form
              .validateFields()
              .then((values) => {
                form.resetFields();
                delete values.imageUrl;
                console.log({ ...values, imageUrl });
                onUpdate(editNewsModal?.id, { ...values, imageUrl });
                onCancel();
              })
              .catch((info) => {
                console.log('Validate Failed:', info);
              });
          }}
        >
          Сохранить
        </Button>,
      ]}
    >
      <Form form={form} layout="vertical">
        <FormItem
          key="title"
          label={getTooltip('Имя Персонажа', 'Имя Персонажа')}
          name="title"
          initialValue={editNewsModal.title}
        >
          <Input placeholder="Имя Персонажа" />
        </FormItem>
        <FormItem
          key="StartTime"
          label="Дата события"
          name="startTime"
          rules={[
            {
              required: true,
              message: `Пожалуйста, выберите дату`,
            },
          ]}
          initialValue={dayjs(editNewsModal?.startTime)}
        >
          <StyledDatePicker placeholder="Выберите дату" />
        </FormItem>
        <FormItem
          key="EndTime"
          label="Дата события"
          name="endTime"
          rules={[
            {
              required: true,
              message: `Пожалуйста, выберите дату`,
            },
          ]}
          initialValue={dayjs(editNewsModal?.endTime)}
        >
          <StyledDatePicker placeholder="Выберите дату" />
        </FormItem>
        <FormItem
          key="imageUrl"
          label={getTooltip(
            'Обложка',
            'Обложка класса для отображения в общем списке'
          )}
          name="imageUrl"
        >
          <ImageUploadContainer>
            <ImageUpload
              onUpdate={(value) => {
                setImageUrl(value);
              }}
            />
            <StyledAvatar size="large" shape="square" src={imageUrl} />
          </ImageUploadContainer>
        </FormItem>
      </Form>
    </Modal>
  );
};

EditNewsModal.propTypes = {
  editHero: PropTypes.shape({}).isRequired,
  title: PropTypes.string.isRequired,
  visible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default EditNewsModal;
