import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Card, Col, Row } from 'antd';
import ModifyModel from './ModifyModel';

const PromptTrainings = ({
  promptTrainings,
  handleModifyTrainingDescription,
}) => {
  const [editPromptTraining, setEditPromptTraining] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);

  return (
    <>
      <Row gutter={16} style={{ overflow: 'scroll', height: '90%' }}>
        {promptTrainings?.map((promptTraining, index) => (
          <Col
            span={8}
            style={{ marginBottom: 8 }}
            key={index}
            onClick={() => {
              console.log('asd');
              setEditPromptTraining(promptTraining);
              setModalVisible(true);
            }}
          >
            <Card
              hoverable
              title={promptTraining?.programSetType?.toUpperCase()}
              bordered={false}
            >
              {promptTraining?.description
                ? promptTraining?.description
                : 'Нет Данных'}
            </Card>
          </Col>
        ))}
      </Row>
      {modalVisible && (
        <ModifyModel
          modalVisible={modalVisible}
          setModalVisible={setModalVisible}
          setEditPromptTraining={setEditPromptTraining}
          editPromptTraining={editPromptTraining}
          handleModifyTrainingDescription={handleModifyTrainingDescription}
        />
      )}
    </>
  );
};

PromptTrainings.propTypes = {
  promptTrainings: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      description: PropTypes.string,
      trainer: PropTypes.shape({
        id: PropTypes.string,
        nickname: PropTypes.string,
      }),
    })
  ),
  handleModifyTrainingDescription: PropTypes.func,
};

export default PromptTrainings;
