import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, Form, Input, Select, Avatar, DatePicker } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import styled from 'styled-components';

import StyledTooltip from '../shared/StyledTooltip';
import showConfirm from '../shared/DeleteConfirm';
import ImageUpload from '../shared/ImageUpload';

const { TextArea } = Input;
const FormItem = Form.Item;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 4 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 20 },
  },
};

const ImageUploadContainer = styled.div`
  display: flex;
  flex-direction: row;
`;
const StyledAvatar = styled(Avatar)`
  margin-left: 5px;
`;
const StyledDatePicker = styled(DatePicker)`
  width: 100%;
`;

const EditProduct = ({
  visible,
  onCancel,
  title,
  editProduct,
  onUpdate,
  onDelete,
}) => {
  const [form] = Form.useForm();

  const getTooltip = (text, fullText) => {
    return <StyledTooltip {...{ text, fullText }} />;
  };

  return (
    <Modal
      open={visible}
      title={title}
      okText="Создать"
      cancelText="Закрыть"
      onCancel={onCancel}
      footer={[
        <Button
          danger
          style={{ float: 'left' }}
          onClick={() => {
            showConfirm(() => {
              onDelete(editProduct?.id);
              onCancel();
            });
          }}
        >
          Удалить
        </Button>,
        <Button key="submit" onClick={onCancel}>
          Отмена
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={() => {
            form
              .validateFields()
              .then((values) => {
                form.resetFields();
                onUpdate(editProduct?.id, { ...values });
                onCancel();
              })
              .catch((info) => {
                console.log('Validate Failed:', info);
              });
          }}
        >
          Сохранить
        </Button>,
      ]}
    >
      <Form form={form} layout="vertical">
        <FormItem
          key="name"
          label={getTooltip('Название товара', 'Название товара')}
          name="name"
          initialValue={editProduct.name}
        >
          <Input placeholder=" Название товара" />
        </FormItem>
      </Form>
    </Modal>
  );
};

EditProduct.propTypes = {
  editHero: PropTypes.shape({}).isRequired,
  title: PropTypes.string.isRequired,
  visible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default EditProduct;
