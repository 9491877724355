import React, { useEffect, useState } from "react";

import CreateTicket from "./CreateTicket";
import Loading from "../shared/Loading";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import { useLoading } from "../../context/useLoading";
import { useNavigate } from "react-router-dom";
import withMainLayout from "../../hocs/withMainLayout";

const CreateTicketContainer = () => {
  const navigate = useNavigate();
  const { showLoading, hideLoading } = useLoading();

  const [users, setUsers] = useState([]);
  const [levels, setLevels] = useState(null);

  useEffect(() => {
    // Simulate fetching users and levels
    const fetchedUsers = [
      { id: "1", email: "user1@example.com" },
      { id: "2", email: "user2@example.com" },
    ];
    const fetchedLevels = [
      { id: "1", name: "Level 1", degree: 1 },
      { id: "2", name: "Level 2", degree: 2 },
    ];
    setUsers(fetchedUsers);
    setLevels(fetchedLevels);
  }, []);

  if (!users || !levels) return <Loading />;

  const handleCreateClick = (values) => {
    showLoading();
    // Simulate creating the ticket
    setTimeout(() => {
      hideLoading();
      toast.success("Ticket successfully created");
      navigate("/tickets");
    }, 1000);
  };

  return (
    <CreateTicket
      allUsers={users}
      allLevels={levels}
      createClick={handleCreateClick}
      refetchUsers={() => {}}
    />
  );
};

CreateTicketContainer.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
};

export default withMainLayout(CreateTicketContainer);