import { DatePicker, Input } from 'antd';
import { useDebounce } from "use-debounce";
import { FormControl } from '@mui/material';
import { MenuItem } from '@mui/material';
import React from 'react';
import SearchLogo from '../../images/search.svg';
import { Select } from '@mui/material';
import styled from 'styled-components';
import { styled as styledMui } from '@mui/system';
import { useAuth } from '../../context/useAuth';
const { RangePicker } = DatePicker;

const Container = styled.div`
  padding: 8px;
  display: flex;
  flex-direction: column;
`;

const TabsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;
`;

const TabsRow = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
`;

const Tab = styled.div`
  font-size: 16px;
  line-height: 18px;
  color: ${(props) => (props.active ? '#000' : 'rgba(0, 0, 0, 0.6)')};
  font-family: 'GraphikLCGMedium';
  cursor: pointer;
  position: relative;
  padding-bottom: 6px;
  transition: color 0.3s;

  &:hover {
    color: #000;
  }

  &:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: ${(props) => (props.active ? '100%' : '0')};
    height: 2px;
    background-color: #000;
    transition: width 0.3s;
  }
`;

const SubTabsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  margin-top: 12px;
`;

const SubTab = styled.div`
  font-size: 14px;
  line-height: 16px;
  color: ${(props) => (props.active ? '#fff' : 'rgba(0, 0, 0, 0.6)')};
  font-family: 'GraphikLCGMedium';
  cursor: pointer;
  position: relative;
  padding: ${(props) => (props.active ? '4px 12px' : 'transparent')};
  border-radius: 16px;
  transition: color 0.3s, background-color 0.3s;

  background-color: ${(props) => (props.active ? '#333' : 'transparent')};

  &:hover {
    color: ${(props) => (props.active ? '#fff' : '#000')};
  }
`;

const FilterContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 16px;
  gap: 16px;
`;

const StyledFormControl = styledMui(FormControl)(({ theme }) => ({
  minWidth: 165,
  border: '1px solid rgba(0, 0, 0, 0.1)',
  borderRadius: 8,
  backgroundColor: 'transparent',
  padding: 3,
  justifyContent: 'center',
}));

const StyledInput = styled(Input)`
  border-radius: 8px;
  background-color: rgba(0, 0, 0, 0.04);
  height: 40px;
  width: 240px;
  background: rgba(0, 0, 0, 0.04);
  background-image: url(${SearchLogo});
  background-repeat: no-repeat;
  background-position: 5%;
  padding: 0 44px;
  border: none;
  color: rgba(0, 0, 0, 0.2);
  &:focus {
      border: none;
      outline: none;
      background: rgba(0, 0, 0, 0.08);
      background-image: url(${SearchLogo});
      background-repeat: no-repeat;
      background-position: left center;
      background-position: 5%;
      color: #000;
  }
`;
const Badge = styled.span`
  padding: 3px 8px;
  margin-left: 8px;
  font-size: 13px;
  color: #333;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  font-weight: bold;
`;

const SubBadge = styled.span`
  padding: 3px 8px;
  margin-left: 8px;
  font-size: 13px;
  color: ${(props) => (props.active ? '#fff' : '#333')};
  background-color: ${(props) => (props.active ? '#333' : 'rgba(0, 0, 0, 0.1)')};
  border-radius: 12px;
  font-weight: bold;
`;

const SupportHeader = ({
  setPage,
                         setSearch,
                         setActiveMainTab,
                         activeMainTab,
                         setActiveSubTab,
                         activeSubTab,
                         requestsCountData,
                         setAssigned,
                         dateRange,
                         setDateRange,
                       }) => {
  const [value, setValue] = React.useState('');
  const [inputValue, setInputValue] = React.useState('');
  const [debouncedValue] = useDebounce(inputValue, 1000);
  const { user } = useAuth();

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  React.useEffect(() => {
    setSearch(debouncedValue);
  }, [debouncedValue]);

  const statusData = [
    {
      value: 'notFinished',
      title: 'Не решено',
      count:
        (requestsCountData?.allRequestQuestionsByType?.inProgress || 0) +
        (requestsCountData?.allRequestQuestionsByType?.notViewed || 0) +
        (requestsCountData?.allRequestQuestionsByType?.moreDataForAdmin || 0),
      subStatuses: [
        {
          value: 'inProgress',
          title: 'В обработке',
          count: requestsCountData?.allRequestQuestionsByType?.inProgress || 0,
        },
        {
          value: 'notViewed',
          title: 'Не прочитано',
          count: requestsCountData?.allRequestQuestionsByType?.notViewed || 0,
        },
        {
          value: 'moreDataForAdmin',
          title: 'Дополняется',
          count:
            requestsCountData?.allRequestQuestionsByType?.moreDataForAdmin || 0,
        },
      ],
    },
    {
      value: 'finished',
      title: 'Решено',
      count: requestsCountData?.allRequestQuestionsByType?.finished || 0,
    },
  ];

  return (
    <Container>
      <TabsContainer>
        <TabsRow>
          {statusData.map((tab) => (
            <div key={tab.value}>
              <Tab
                active={activeMainTab === tab.value}
                onClick={() => {
                  setActiveMainTab(tab.value);
                  setActiveSubTab('');
                }}
              >
                {tab.title}
                <Badge>{tab.count}</Badge>
              </Tab>
            </div>
          ))}
        </TabsRow>
        {
          activeMainTab === 'notFinished' &&
          <TabsRow>
            <SubTabsContainer>
              {statusData[0]?.subStatuses?.map((subTab) => (
                <SubTab
                  key={subTab.value}
                  active={activeSubTab === subTab.value}
                  onClick={() => {
                    setActiveSubTab(subTab.value);
                    setPage(1);
                  }}
                >
                  {subTab.title}
                  <SubBadge active={activeSubTab === subTab.value}>{subTab.count}</SubBadge>
                </SubTab>
              ))}
            </SubTabsContainer>
          </TabsRow>
        }
      </TabsContainer>

      <FilterContainer>
        <StyledFormControl variant="standard">
          <Select
            MenuProps={{
              disableScrollLock: true,
            }}
            value={value}
            onChange={handleChange}
            displayEmpty
            inputProps={{ 'aria-label': 'Without label' }}
            disableUnderline
            style={{
              paddingLeft: 10,
              fontFamily: 'GraphikLCGMedium',
              fontSize: 14,
            }}
          >
            <MenuItem
              style={{ fontFamily: 'GraphikLCGMedium', fontSize: 14 }}
              value=""
              onClick={() => setAssigned('')}
            >
              Все заявки
            </MenuItem>
            <MenuItem
              style={{ fontFamily: 'GraphikLCGMedium', fontSize: 14 }}
              value="my"
              onClick={() => setAssigned(user?.id)}
            >
              Мои заявки
            </MenuItem>
          </Select>
        </StyledFormControl>

        <RangePicker
          value={dateRange}
          onChange={(dates) => {
            setDateRange(dates || []);
          }}
          format="DD.MM.YYYY"
          style={{ marginRight: '16px' }}
        />

        <StyledInput
          placeholder="Поиск"
          onChange={(e) => {
            const inputValue = e.target.value;
            setInputValue(inputValue);
          }}
        />
      </FilterContainer>
    </Container>
  );
};

export default SupportHeader;
