import React, { useContext, useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { Table, Input, Form, Select, DatePicker } from 'antd'
import { element } from 'prop-types'

const EditableContext = React.createContext(null)

const EditableCellContainer = styled.div`
  cursor: pointer;
  padding-right: 24px;
`

const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm()
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  )
}

const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  options,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false)
  const inputRef = useRef(null)
  const form = useContext(EditableContext)
  const [selected, setSelected] = React.useState(null)

  useEffect(() => {
    if (editing) {
      inputRef.current.focus()
    }
  }, [editing])

  const toggleEdit = () => {
    setEditing(!editing)
    form.setFieldsValue({
      exercise: '5fd367a51242d600178f3417'
    })
    form.setFieldsValue({
      [dataIndex]: record[dataIndex]
    })
  }

  const save = async exercise => {
    try {
      const values = await form.validateFields()
      toggleEdit()
      if (exercise?.exercise) {
        handleSave({ ...record, ...exercise })
      } else {
        handleSave({ ...record, ...values })
      }
    } catch (errInfo) {
      console.log('Save failed:', errInfo)
    }
  }

  let childNode = children

  if (dataIndex == 'exercise') {
    return (
      //   <Form.Item
      //     style={{
      //       margin: 0
      //     }}
      //     name={dataIndex}
      //  >
      <Select
        placeholder={
          record?.exercise?.name
            ? record?.exercise?.name
            : 'Выберите упражнение'
        }
        showSearch
        ref={inputRef}
        style={{ width: 350, marginTop: 10 }}
        onChange={(value, label) => {
          setSelected(label)
          save({ exercise: value })
        }}
        options={options}
        optionFilterProp="children"
        filterOption={(input, option) => (option?.label ?? '').includes(input)}
        filterSort={(optionA, optionB) =>
          (optionA?.label ?? '')
            .toLowerCase()
            .localeCompare((optionB?.label ?? '').toLowerCase())
        }
      />
      //  </Form.Item>
    )
  }

  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{
          margin: 0,
          width: 50
        }}
        name={dataIndex}
      >
        <Input ref={inputRef} onPressEnter={save} onBlur={save} />
      </Form.Item>
    ) : (
      <EditableCellContainer onClick={toggleEdit}>
        {children}
      </EditableCellContainer>
    )
  }

  return <td {...restProps}>{childNode}</td>
}

class EditableTable extends React.Component {
  constructor(props) {
    super(props)
    const options = props.exercises.map(exercise => {
      return {
        value: exercise?.id,
        label: exercise?.name
      }
    })

    this.columns = props.station.isCardio
      ? [
          {
            title: 'Интервалы',
            render: (item, __, index) => {
              return index + 1
            },
            width: '10%'
          },
          {
            title: 'Beginner',
            dataIndex: 'beginnerSpeed',
            editable: true
          },
          {
            title: 'Intermediate',
            dataIndex: 'intermediateSpeed',
            editable: true
          },
          {
            title: 'Athlete',
            dataIndex: 'athleteSpeed',
            editable: true
          },
          {
            title: 'Pro',
            dataIndex: 'proSpeed',
            editable: true
          }
        ]
      : [
          {
            title: 'Интервалы',
            render: (item, __, index) => {
              return index + 1
            },
            width: '10%'
          },
          {
            title: 'Упражнение',
            dataIndex: 'exercise',
            options: options,
            editable: true,
            render: (_, item) => {
              return <span> {item?.exercise?.name}</span>
            }
          },
          {
            title: 'Intermediate',
            dataIndex: 'reps',
            editable: true
          }
        ]
    this.state = {
      dataSource: props.dataSource
    }
  }

  handleSave = row => {
    const newData = [...this.state.dataSource]
    const index = newData.findIndex(item => row.id === item.id)
    const item = newData[index]
    row.beginnerSpeed = parseInt(row.beginnerSpeed)
    row.intermediateSpeed = parseInt(row.intermediateSpeed)
    row.athleteSpeed = parseInt(row.athleteSpeed)
    row.proSpeed = parseInt(row.proSpeed)
    row.reps = parseInt(row.reps)
    newData.splice(index, 1, { ...item, ...row })
    this.setState({
      dataSource: newData
    })
    this.props.setEditInterval(newData)
  }

  render() {
    const { dataSource } = this.state
    const components = {
      body: {
        row: EditableRow,
        cell: EditableCell
      }
    }
    const columns = this.columns.map(col => {
      if (!col.editable) {
        return col
      }

      return {
        ...col,
        onCell: record => ({
          record,
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          options: col.options,
          handleSave: this.handleSave
        })
      }
    })
    return (
      <Table
        components={components}
        rowClassName={() => 'editable-row'}
        bordered
        dataSource={dataSource}
        columns={columns}
        rowKey={item => item.id}
        pagination={false}
      />
    )
  }
}

export default EditableTable
