import React from 'react';
import { 
    Button, 
    Row, 
    Modal, 
    DatePicker, 
    Form, 
    Space, 
    InputNumber, 
    Select, 
    Checkbox,
    AutoComplete
} from 'antd';
import dayjs from 'dayjs';
import 'dayjs/locale/ru';

const { RangePicker } = DatePicker;

const InBodyFilter = ({
    openFilter,
    setOpenFilter,
    onFinish,
    onFinishFailed,
    isUserNull,
    setIsUserNull,
    users,
    handleUserInputChange
}) => {
    const handleCancel = () => {
        setIsUserNull(false);
        setOpenFilter(false);
    };

    return (
        <Modal
            title={'Фильтры'}
            open={openFilter}
            onCancel={handleCancel}
            footer={null}
        >
            <Form
                name="basic"
                labelCol={{ span: 4 }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
                initialValues={{
                    isUserNull: isUserNull,
                }}
            >
                <Form.Item label={null} valuePropName="checked" name="isUserNull">
                    <Checkbox
                        checked={isUserNull}
                        onChange={(e) => setIsUserNull(e.target.checked)}
                    >
                        Пользователь закреплен?
                    </Checkbox>
                </Form.Item>

                {
                    isUserNull &&
                    <Form.Item label="Номер" name="phoneNumber">
                        <AutoComplete
                            options={users?.map(user => ({
                                value: user.phoneNumber,
                                label: `${user.phoneNumber} (${user.email})`,
                            }))}
                            style={{ width: '100%' }}
                            placeholder="Номер"
                            onChange={handleUserInputChange}
                        />
                    </Form.Item>
                }

                <Form.Item label="Дата" name="range">
                    <RangePicker 
                        style={{width: '100%'}}
                        disabledDate={(current) => current && current > dayjs().endOf('day')}
                    />
                </Form.Item>

                <Form.Item label="Рост" name="HT">
                    <InputNumber placeholder='Рост' style={{width: '100%'}}/>
                </Form.Item>

                <Form.Item label="Вес" name="WT">
                    <InputNumber placeholder='Вес' style={{width: '100%'}}/>
                </Form.Item>

                <Form.Item label="Возраст" name="AGE">
                    <InputNumber placeholder='Возраст' style={{width: '100%'}}/>
                </Form.Item>

                <Form.Item label="Пол" name="GENDER">
                    <Select placeholder="Пол">
                        <Select.Option value="M">Мужчина</Select.Option>
                        <Select.Option value="F">Женщина</Select.Option>
                    </Select>
                </Form.Item>

                <Form.Item label={null}>
                    <Row justify={'end'}>
                        <Space>
                            <Button onClick={handleCancel}>
                                Отмена
                            </Button>
                            <Button type="primary" htmlType="submit">
                                Поиск
                            </Button>
                        </Space>
                    </Row>
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default InBodyFilter;