import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Form, Input, Button } from 'antd';

import showConfirm from '../shared/DeleteConfirm';
import StyledTooltip from '../shared/StyledTooltip';

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
const StyledInput = styled(Input)`
  width: 320px;
`;

const FormItem = Form.Item;

const ContestGeneral = ({ contest, form, onFinish, handleDeleteContest }) => {
  const getTooltip = (text, fullText) => {
    return <StyledTooltip {...{ text, fullText }} />;
  };

  return (
    <MainContainer>
      <h2> Основные </h2>
      <Form form={form} layout="vertical" hideRequiredMark onFinish={onFinish}>
        <FormItem
          key="name"
          label={getTooltip('Название конкурса', 'Название конкурса')}
          name="name"
          rules={[
            {
              required: true,
              message: `Пожалуйста, напишите название конкурса`
            }
          ]}
          initialValue={contest?.name}
        >
          <StyledInput placeholder="Название конкурса" />
        </FormItem>
        <FormItem
          key="description"
          label={getTooltip('Описание конкурса', 'Описание конкурса')}
          name="description"
          rules={[
            {
              required: true,
              message: `Пожалуйста, напишите описание конкурса`
            }
          ]}
          initialValue={contest?.description}
        >
          <StyledInput placeholder="Описание конкурса" />
        </FormItem>

        <FormItem>
          <ButtonContainer>
            <Button
              danger
              onClick={() => {
                showConfirm(() => {
                  handleDeleteContest();
                });
              }}
            >
              Удалить
            </Button>
            <Button type="primary" htmlType="submit">
              Сохранить изменения
            </Button>
          </ButtonContainer>
        </FormItem>
      </Form>
    </MainContainer>
  );
};

ContestGeneral.propTypes = {
  contest: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
    stages: PropTypes.arrayOf(PropTypes.shape({}))
  }).isRequired,
  form: PropTypes.shape({}).isRequired,
  onFinish: PropTypes.func.isRequired,
  handleDeleteContest: PropTypes.func.isRequired
};

export default ContestGeneral;
