import React, { useState, useEffect } from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import { toast } from 'react-toastify';

import SpecialBookings from './SpecialBookings';
import Loading from '../shared/Loading';
import { useLoading } from '../../context/useLoading';
import withMainLayout from '../../hocs/withMainLayout';

const GET_SPECIAL_BOOKINGS = gql`
  query getSpecialBookings {
    specialBookings {
      id
      name
      description
      type
      startTime
      endTime
    }
  }
`;

const DELETE_SPECIAL_BOOKING = gql`
  mutation deleteSpecialBooking($id: ID!) {
    deleteSpecialBooking(id: $id)
  }
`;

const ADD_SPECIAL_BOOKING = gql`
  mutation addSpecialBooking($input: SpecialBookingInput) {
    addSpecialBooking(input: $input) {
      id
      name
      description
      type
      startTime
      endTime
    }
  }
`;

const UPDATE_SPECIAL_BOOKING = gql`
  mutation updateSpecialBooking($id: ID!, $input: SpecialBookingInput) {
    updateSpecialBooking(id: $id, input: $input) {
      id
      name
      description
      type
      startTime
      endTime
    }
  }
`;

const SpecialBookingContainer = () => {
  const [dataSpecialBookings, setDataSpecialBookings] = useState(null);
  const { showLoading, hideLoading } = useLoading();

  const [updateSpecialBooking] = useMutation(UPDATE_SPECIAL_BOOKING);

  const { data, loading, error } = useQuery(GET_SPECIAL_BOOKINGS);

  const [deleteSpecialBooking] = useMutation(DELETE_SPECIAL_BOOKING, {
    update(cache, { data: { deleteHero: id } }) {
      const { specialBookings } = cache.readQuery({
        query: GET_SPECIAL_BOOKINGS
      });
      cache.writeQuery({
        query: GET_SPECIAL_BOOKINGS,
        data: {
          specialBookings: specialBookings.filter(
            specialBooking => specialBooking.id !== id
          )
        }
      });
      hideLoading();
      toast.success('Акция успешно удалена');
    }
  });

  const [addSpecialBooking] = useMutation(ADD_SPECIAL_BOOKING, {
    update(cache, { data: { addSpecialBooking: specialBooking } }) {
      const { specialBookings } = cache.readQuery({
        query: GET_SPECIAL_BOOKINGS
      });
      cache.writeQuery({
        query: GET_SPECIAL_BOOKINGS,
        data: { specialBookings: specialBookings.concat([specialBooking]) }
      });
      hideLoading();
      toast.success('Акция успешно создана');
    }
  });

  useEffect(() => {
    if (data && !loading && !error) {
      setDataSpecialBookings(data.specialBookings);
    }
  }, [data, loading, error]);

  const handleDeleteSpecialBooking = specialBookingId => {
    showLoading();
    deleteSpecialBooking({ variables: { id: specialBookingId } });
  };

  const handleAddSpecialBooking = values => {
    showLoading();
    addSpecialBooking({
      variables: {
        input: values
      }
    });
  };

  const handleUpdateSpecialBooking = (id, values) => {
    updateSpecialBooking({ variables: { id, input: values } });
  };

  if (loading && !dataSpecialBookings) {
    return <Loading />;
  }

  return (
    <SpecialBookings
      specialBookings={dataSpecialBookings}
      deleteClick={handleDeleteSpecialBooking}
      addClick={handleAddSpecialBooking}
      updateClick={handleUpdateSpecialBooking}
    />
  );
};

export default withMainLayout(SpecialBookingContainer);
