import React from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';
import 'dayjs/locale/ru';

import CancelLogo from '../../images/cancel.svg';
import ArrowLeft from '../../images/ArrowLeft.svg';

const Wrapper = styled.div`
  background-color: #fff;
  position: fixed;
  transition: all 0.3s;
  top: 0;
  right: ${(props) => (props.show ? '0' : '-30%')};
  width: 368px;
  height: 100%;
  z-index: 1;
  overflow-y: scroll;
`;

const Cancel = styled.img`
  cursor: pointer;
  margin-left: auto;
`;

const Header = styled.div`
  display: flex;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  padding: 20px 24px;
  position: sticky;
  top: 0;
`;

const Title = styled.div`
  font-family: 'GraphikLCGMedium';
  font-size: 20px;
  line-height: 24px;
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
`;
const BodyTitle = styled.div`
  font-family: 'GraphikLCGMedium';
  font-size: 16px;
  line-height: 16px;
  padding-left: 24px;
`;

const Field = styled.div`
  margin-right: auto;
  font-family: 'GraphikLCGMedium';
  font-size: 14px;
  line-height: 16px;
  color: #00000080;
`;

const Value = styled.div`
  font-family: 'GraphikLCGRegular';
  font-size: 14px;
  line-height: 16px;
`;

const Content = styled.div`
  display: flex;
  margin-top: 7%;
`;

const Section = styled.div`
  padding: 24px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
`;

const History = styled.button`
  display: flex;
  font-size: 14px;
  line-height: 16px;
  font-family: 'GraphikLCGMedium';
  border-radius: 8px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 10px 12px 10px 12px;
  margin-top: 24px;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  width: 86%;
  margin: 24px 24px;
`;

const ArrowLeftStyle = styled.img`
  cursor: pointer;
  padding-right: 8px;
`;

const ClientPayments = ({
  selectUser,
  userPayments,
  historyPayments,
  setClientInfoModal,
  setReRegSearch,
  setHistoryPayments,
  setSelectUser,
}) => {
  const GetPaymentsMethod = (item) => {
    switch (item.paymentMethod) {
      case 'cash':
        return 'Наличные';

      case 'card':
        return 'Картой';

      case 'kaspiCredit':
        return 'Рассрочка';
    }
  };

  return (
    <Wrapper show={historyPayments}>
      <Header>
        <Title>{selectUser?.firstName}</Title>
        <Cancel
          src={CancelLogo}
          onClick={() => {
            setSelectUser();
            setClientInfoModal(false);
            setReRegSearch(false);
            setHistoryPayments(false);
          }}
        />
      </Header>
      <History
        onClick={() => {
          setHistoryPayments(false);
        }}
      >
        <ArrowLeftStyle src={ArrowLeft} />
        Назад
      </History>
      <BodyTitle>История покупок</BodyTitle>
      <Body>
        {userPayments?.map((item, index) => (
          <Section key={index}>
            <Content>
              <Field>Платеж</Field>
              <Value>
                {item.heroPass?.name || item.marathonEvent?.marathon?.name}
              </Value>
            </Content>
            <Content>
              <Field>Дата покупки</Field>
              <Value>{dayjs(item?.created_at).format('DD.MM.YYYY')}</Value>
            </Content>
            <Content>
              <Field>Сумма</Field>
              <Value>{item?.paidAmount}₸</Value>
            </Content>
            <Content>
              <Field>Способ оплаты</Field>
              <Value>{GetPaymentsMethod(item)}</Value>
            </Content>
            <Content>
              <Field>Менеджер</Field>
              <Value>{item?.salesman?.username}</Value>
            </Content>
          </Section>
        ))}
      </Body>
    </Wrapper>
  );
};

export default ClientPayments;
