import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { PlusOutlined } from '@ant-design/icons';

import { Button, Descriptions, Select, Modal, Input, Divider, Form } from 'antd';
import EditForm from '../shared/EditFormModal';
import { removeTypenameField } from '../../utils/apollo';
import StyledTooltip from '../shared/StyledTooltip';

import showConfirm from '../shared/DeleteConfirm';

const FormItem = Form.Item;
const { TextArea } = Input;

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const AwardsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 320px;
`;

const SingleAwardContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
`;

const StyledDescriptions = styled(Descriptions)`
  width: 400px;
`;

const StyledImg = styled.img`
  width: 50px;
  height: 50px;
`;

const StyledButton = styled(Button)`
  margin-top: 10px;
`;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 4 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 20 },
  },
};

const MarathonGeneral = ({
  marathon,
  handleUpdateMarathon,
  handleUpdateMarathonAwards,
  handleUpdateMarathonFloors,
  handleDeleteMarathon,
  awards,
  heroPathes,
}) => {
  const [form] = Form.useForm();
  const [modalVisible, setModalVisible] = useState(false);
  const [awardModalVisible, setAwardModalVisible] = useState(false);
  const [floorModalVisible, setFloorModalVisible] = useState(false);

  const [awardsWithPercent, setAwardsWithPercent] = useState([]);

  React.useEffect(() => {
    setAwardsWithPercent(
      removeTypenameField(
        marathon?.awardsWithPercent.map((awardWithPercent) => ({
          ...awardWithPercent,
          award: awardWithPercent.award?.id,
        }))
      )
    );
  }, [marathon?.awardsWithPercent]);

  const fields = [
    {
      title: 'Имя',
      dataIndex: 'name',
    },
    {
      key: 'type',
      label: 'Тип марафона',
      options: [
        { value: 'intro', label: 'intro' },
        { value: '21days', label: '21days' },
        { value: 'muscleGain', label: 'muscleGain' },
        { value: 'intensiveWeightLoss', label: 'intensiveWeightLoss' },
        { value: 'chestAndArms', label: 'chestAndArms' },
        { value: 'bootyLikeThat', label: 'bootyLikeThat' },
        { value: 'absAndCardio', label: 'absAndCardio' },
        { value: 'upgrade', label: 'upgrade' },
        { value: '42days', label: '42days' },
        { value: 'recreation', label: 'recreation' },
      ],
      tooltipText: 'Тип акции',
    },
    {
      title: 'Описание',
      dataIndex: 'description',
    },
    {
      title: 'кол-во посещений ',
      dataIndex: 'countClasses',
    },
    {
      title: 'кол-во посещений в Assessment ',
      dataIndex: 'assessmentClasses',
    },
    {
      title: 'кол-во посещений на обучающую тренировку ',
      dataIndex: 'educationClasses',
    },
    {
      title: 'Срок Билетов',
      dataIndex: 'duration',
    },
    {
      title: 'Цена',
      dataIndex: 'price',
    },
    {
      title: 'Позиция по x-осьи',
      dataIndex: 'xPosition',
    },
    {
      title: 'Позиция по y-осьи',
      dataIndex: 'yPosition',
    },
    {
      title: 'Кол-во этожей',
      dataIndex: 'floorCount',
    },
    {
      title: 'Название района',
      dataIndex: 'district',
    },
    {
      title: 'Премиальность района',
      dataIndex: 'rating',
    },
    {
      title: 'Иконка',
      dataIndex: 'imageURL',
      isImage: true,
    },
  ];

  const handleEditClick = () => {
    setModalVisible(true);
  };
  const handleEditAwardsClick = () => {
    setAwardModalVisible(true);
  };
  const handleEditFloorsClick = () => {
    setFloorModalVisible(true);
  };

  const onUpdate = (values) => {
    handleUpdateMarathon(values);
    setModalVisible(false);
  };

  const onAwardChange = (index, fieldName, value) => {
    const newAwardsWithPercent = [...awardsWithPercent];
    const oldValue = awardsWithPercent[index];
    newAwardsWithPercent[index] = {
      ...oldValue,
      [fieldName]: value,
    };
    setAwardsWithPercent(newAwardsWithPercent);
  };

  const deleteAwardRow = (index) => {
    const newAwardsWithPercent = [...awardsWithPercent];
    newAwardsWithPercent.splice(index, 1);
    setAwardsWithPercent(newAwardsWithPercent);
  };

  const addAwardRow = () => {
    const newAwardsWithPercent = [...awardsWithPercent];
    const emptyAwardWithPercent = {
      award: '',
      completionPercent: '0',
    };
    newAwardsWithPercent.push(emptyAwardWithPercent);
    setAwardsWithPercent(newAwardsWithPercent);
  };

  const handleUpdateAwards = () => {
    const parsedAwards = awardsWithPercent.map((awardWithPercent) => ({
      ...awardWithPercent,
      completionPercent: parseInt(awardWithPercent.completionPercent, 10),
    }));

    handleUpdateMarathonAwards({ awardsWithPercent: parsedAwards });
    setAwardModalVisible(false);
  };

  const handleUpdateFloors = (values) => {
    const parsedFloors = values.floors.map((floor) => ({
      ...floor,
      floorNumber: parseInt(floor.floorNumber, 10),
    }));

    handleUpdateMarathonFloors({ floors: parsedFloors });
    setFloorModalVisible(false);
  };

  const getTooltip = (text, fullText) => {
    return <StyledTooltip {...{ text, fullText }} />;
  };

  return (
    <MainContainer>
      <StyledDescriptions
        title="Описание марафона"
        bordered
        column={1}
        size="small"
        labelStyle={{ width: '200px' }}
        extra={
          <>
            <Button onClick={handleEditClick}>Редактировать</Button>
            <Button onClick={handleEditAwardsClick}>
              Редактировать награды
            </Button>
            <Button onClick={handleEditFloorsClick}>Редактировать этажи</Button>
          </>
        }
      >
        {fields.map((field) =>
          field.isImage ? (
            <Descriptions.Item label={field.title} key={field.title}>
              <StyledImg src={marathon[field.dataIndex]} alt="No Icon" />
            </Descriptions.Item>
          ) : (
            <Descriptions.Item label={field.title} key={field.title}>
              {marathon[field.dataIndex]}
            </Descriptions.Item>
          )
        )}
      </StyledDescriptions>
      <Modal
        open={awardModalVisible}
        onCancel={() => setAwardModalVisible(false)}
        onOk={handleUpdateAwards}
      >
        <h1> Награды марафона </h1>
        <Divider />
        <AwardsContainer>
          {awardsWithPercent.map((awardWithPercent, index) => {
            return (
              <SingleAwardContainer key={awardWithPercent?.award?.id}>
                <h3> Процент выполнения задач для награды</h3>
                <Input
                  value={awardWithPercent?.completionPercent}
                  onChange={(e) =>
                    onAwardChange(index, 'completionPercent', e.target.value)
                  }
                />
                <h3> Награда </h3>
                <Select
                  value={awardWithPercent?.award}
                  onChange={(value) => onAwardChange(index, 'award', value)}
                >
                  {awards.map((award) => (
                    <Select.Option value={award?.id} key={award?.id}>
                      {award?.name}
                    </Select.Option>
                  ))}
                </Select>
                <StyledButton
                  type="primary"
                  danger
                  onClick={() => deleteAwardRow(index)}
                >
                  Delete Row
                </StyledButton>
                <Divider />
              </SingleAwardContainer>
            );
          })}
          <StyledButton type="primary" onClick={addAwardRow}>
            Add row
          </StyledButton>
        </AwardsContainer>
      </Modal>
      <Modal
        open={floorModalVisible}
        onCancel={() => setFloorModalVisible(false)}
        onOk={() => {
          form
            .validateFields()
            .then((values) => {
              form.resetFields();
              handleUpdateFloors(values);
            })
            .catch((info) => {
              console.log('Validate Failed:', info);
            });
        }}
      >
        <Form
          form={form}
          layout="vertical"
          initialValues={{
            floors:
              marathon.floors &&
              marathon.floors.map((floor) => {
                return {
                  floorNumber: floor.floorNumber,
                  imageURL: floor.imageURL,
                };
              }),
          }}
        >
          <Form.List
            name="floors"
            rules={[
              {
                validator: async (_, floors) => {
                  if (!floors || floors.length < 1) {
                    return Promise.reject(new Error('Минимум 2 этажа'));
                  }
                },
              },
            ]}
          >
            {(fields, { add, remove }, { errors }) => (
              <>
                {fields.map((field, index) => (
                  <Form.Item
                    {...(index === 0 ? formItemLayout : formItemLayout)}
                    label={index === 0 ? 'Floors' : ''}
                    required={false}
                    key={field.key}
                  >
                    <Form.Item
                      {...field}
                      name={[field.name, 'floorNumber']}
                      fieldKey={[field.fieldKey, 'floorNumber']}
                      rules={[
                        { required: true, message: 'Отсутствует номер этажа' },
                      ]}
                    >
                      <Input placeholder="Номер этажа" />
                    </Form.Item>
                    <Form.Item
                      {...field}
                      name={[field.name, 'imageURL']}
                      fieldKey={[field.fieldKey, 'imageURL']}
                      rules={[
                        { required: true, message: 'Отсутствует картинка' },
                      ]}
                    >
                      <Input placeholder="ImageURL " />
                    </Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => remove(field.name)}
                      style={{ width: '60%' }}
                      icon={<PlusOutlined />}
                    >
                      Удалить поле
                    </Button>
                  </Form.Item>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    style={{ width: '60%' }}
                    icon={<PlusOutlined />}
                  >
                    Добавить поле
                  </Button>
                  <Form.ErrorList errors={errors} />
                </Form.Item>
              </>
            )}
          </Form.List>
        </Form>
      </Modal>
      {modalVisible && (
        <Modal
          open={modalVisible}
          title="Редактировать задание"
          onCancel={() => setModalVisible(false)}
          footer={[
            <Button
              danger
              style={{ float: 'left' }}
              onClick={() => {
                showConfirm(() => {
                  handleDeleteMarathon();
                });
              }}
            >
              Удалить
            </Button>,
            <Button onClick={() => setModalVisible(false)} htmlType="reset">
              Отмена
            </Button>,
            <Button
              key="submit"
              htmlType="submit"
              type="primary"
              onClick={() => {
                form
                  .validateFields()
                  .then((values) => {
                    form.resetFields();
                    onUpdate(values);
                  })
                  .catch((info) => {
                    // eslint-disable-next-line no-console
                    console.log('Validate Failed:', info);
                  });
              }}
            >
              Сохранить
            </Button>,
          ]}
        >
          <Form form={form} layout="vertical">
            <FormItem
              key="name"
              label={getTooltip('Название марафона', 'Название марафона')}
              name="name"
              initialValue={marathon?.name}
            >
              <Input placeholder="Название марафона" />
            </FormItem>
            <FormItem
              key="type"
              label={getTooltip('Тип марафона', 'Тип марафона')}
              name="type"
              initialValue={marathon?.type}
            >
              <Select mode="single" placeholder="Choose type" showSearch>
                {[
                  { value: 'intro', label: 'intro' },
                  { value: '21days', label: '21days' },
                  { value: 'muscleGain', label: 'muscleGain' },
                  {
                    value: 'intensiveWeightLoss',
                    label: 'intensiveWeightLoss',
                  },
                  { value: 'chestAndArms', label: 'chestAndArms' },
                  { value: 'bootyLikeThat', label: 'bootyLikeThat' },
                  { value: 'absAndCardio', label: 'absAndCardio' },
                  { value: 'upgrade', label: 'upgrade' },
                  { value: '42days', label: '42days' },
                  { value: 'recreation', label: 'recreation' },
                ].map((el) => (
                  <Select.Option key={el.label} value={el.label}>
                    {el.label}
                  </Select.Option>
                ))}
              </Select>
            </FormItem>
            <FormItem
              key="description"
              label={getTooltip('Описание марафона', 'Описание марафона')}
              name="description"
              initialValue={marathon?.description}
            >
              <TextArea rows={4} placeholder="Описание" />
            </FormItem>
            <FormItem
              key="duration"
              label={getTooltip('Срок марафона', 'Срок марафона в днях')}
              name="duration"
              initialValue={marathon?.duration}
            >
              <Input placeholder="Срок марафона" type="number" />
            </FormItem>
            <FormItem
              key="price"
              label={getTooltip('Цена за сундук', 'Цена за сундук в гантелях')}
              name="price"
              initialValue={marathon?.price}
            >
              <Input placeholder="Цена за сундук" type="number" />
            </FormItem>
            <FormItem
              key="countClasses"
              label={getTooltip('Кол-во билетов', 'Кол-во билетов')}
              name="countClasses"
              initialValue={marathon?.countClasses}
            >
              <Input placeholder="Кол-во билетов" type="number" />
            </FormItem>
            <FormItem
              key="assessmentClasses"
              label={getTooltip(
                'Кол-во билетов в Assessment',
                'Кол-во билетов в Assessment'
              )}
              name="assessmentClasses"
              initialValue={marathon?.assessmentClasses}
            >
              <Input placeholder="Кол-во билетов в Assessment" type="number" />
            </FormItem>
            <FormItem
              key="educationClasses"
              label={getTooltip(
                'Кол-во билетов в Education',
                'Кол-во билетов в Education'
              )}
              name="educationClasses"
              initialValue={marathon?.educationClasses}
            >
              <Input placeholder="Кол-во билетов в Education" type="number" />
            </FormItem>
            <FormItem
              key="xPosition"
              label={getTooltip('Позиция по x-осьи', 'Позиция по x-осьи')}
              name="xPosition"
              initialValue={marathon?.xPosition}
            >
              <Input placeholder="Позиция по x-осьи" type="number" />
            </FormItem>
            <FormItem
              key="yPosition"
              label={getTooltip('Позиция по y-осьи', 'Позиция по y-осьи')}
              name="yPosition"
              initialValue={marathon?.yPosition}
            >
              <Input placeholder="Позиция по y-осьи" type="number" />
            </FormItem>
            <FormItem
              key="floorCount"
              label={getTooltip('Кол-во этожей', 'Кол-во этожей')}
              name="floorCount"
              initialValue={marathon?.floorCount}
            >
              <Input placeholder="Кол-во этожей" type="number" />
            </FormItem>
            <FormItem
              key="district"
              label={getTooltip('Название района', 'Название района')}
              name="district"
              initialValue={marathon?.district}
            >
              <Input placeholder="Название района" />
            </FormItem>
            <FormItem
              key="rating"
              label={getTooltip('Премиальность района', 'Премиальность района')}
              name="rating"
              initialValue={marathon?.rating}
            >
              <Input placeholder="Премиальность района" type="number" />
            </FormItem>
            <FormItem
              key="imageURL"
              label={getTooltip('Картинка Марафона', 'Картинка Марафона')}
              name="imageURL"
              initialValue={marathon?.imageURL}
            >
              <Input placeholder="Картинка Марафона" />
            </FormItem>
            <FormItem
              key="planImageURL"
              label={getTooltip(
                'Картинка рекомендованного посещения',
                'Картинка рекомендованного посещения'
              )}
              name="planImageURL"
              initialValue={marathon?.planImageURL}
            >
              <Input placeholder="Картинка рекомендованного посещения" />
            </FormItem>
            <FormItem
              key="color"
              label={getTooltip(
                'Цвет иконки',
                'Цвет иконки в хекс(типа #ED2226)'
              )}
              name="color"
              initialValue={marathon?.color}
            >
              <Input placeholder="Цвет иконки" />
            </FormItem>
            <Form.Item label={getTooltip('Уровень', 'Уровень Марафона')}>
              <Input.Group compact>
                <Form.Item
                  name={['heroPathLevel', 'identifier']}
                  initialValue={marathon?.heroPathLevel?.identifier}
                  noStyle
                >
                  <Select style={{ width: '50%' }}>
                    <Select.Option value="">Убрать</Select.Option>
                    <Select.Option value="all">Для Всех</Select.Option>
                    {heroPathes.map((heroPath) => (
                      <Select.Option value={heroPath?.identifier}>
                        {heroPath?.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  name={['heroPathLevel', 'degree']}
                  initialValue={marathon?.heroPathLevel?.degree}
                  noStyle
                >
                  <Input style={{ width: '50%' }} type="number" />
                </Form.Item>
              </Input.Group>
            </Form.Item>
          </Form>
        </Modal>
      )}
    </MainContainer>
  );
};

MarathonGeneral.propTypes = {
  marathon: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    imageURL: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
  }).isRequired,
  handleUpdateMarathon: PropTypes.func.isRequired,
  handleUpdateMarathonAwards: PropTypes.func.isRequired,
  handleDeleteMarathon: PropTypes.func.isRequired,
  awards: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default MarathonGeneral;
