import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { gql, useMutation, useQuery } from '@apollo/client';
import { toast } from 'react-toastify';

import Stations from './Stations';
import Loading from '../shared/Loading';
import { useLoading } from '../../context/useLoading';

const GET_DATA = gql`
  query getData($id: ID!) {
    zone(id: $id) {
      id
      group
      stations {
        id
        name
        number
        icon
        isCardio
        positions {
          width
          height
        }
      }
    }
  }
`;

const GET_STATIONS = gql`
  query getStations($id: ID!) {
    zone(id: $id) {
      id
      stations {
        id
        name
        number
        icon
        isCardio
        positions {
          width
          height
        }
      }
    }
  }
`;

const DELETE_STATION = gql`
  mutation deleteStation($id: ID!) {
    deleteStation(id: $id)
  }
`;

const ADD_STATION = gql`
  mutation addStation($input: StationInput) {
    addStation(input: $input) {
      id
      number
      name
      icon
      isCardio
      positions {
        width
        height
      }
    }
  }
`;
const UPDATE_STATION = gql`
  mutation updateStation($id: ID!, $input: StationInput) {
    updateStation(id: $id, input: $input) {
      id
      number
      name
      icon
      isCardio
      positions {
        width
        height
      }
    }
  }
`;

const UPDATE_ZONE = gql`
  mutation updateZone($id: ID!, $input: ZoneInput) {
    updateZone(id: $id, input: $input) {
      id
      stations {
        id
        number
        name
        icon
        isCardio
        positions {
          width
          height
        }
      }
    }
  }
`;

const StationsContainer = ({ zoneId }) => {
  const [dataStations, setDataStations] = useState(null);
  const [group, setGroup] = useState(1);
  const { showLoading, hideLoading } = useLoading();
  const [updateZone] = useMutation(UPDATE_ZONE);
  const [updateStation] = useMutation(UPDATE_STATION, {
    onCompleted() {
      hideLoading();
      toast.success('Станция успешна отредактирована');
    },
  });

  const { data, loading, error } = useQuery(GET_DATA, {
    variables: { id: zoneId },
  });

  const [addStation] = useMutation(ADD_STATION, {
    update(cache, { data: { addStation: station } }) {
      let { zone } = cache.readQuery({
        query: GET_STATIONS,
        variables: { id: zoneId },
      });
      zone = {
        id: zone.id,
        stations: zone.stations ? zone.stations.concat([station]) : [station],
      };
      const stations =
        zone && zone.stations
          ? zone.stations.map((dataStation) => dataStation.id)
          : [];
      updateZone({
        variables: { id: zoneId, input: { stations } },
      });
      cache.writeQuery({
        query: GET_STATIONS,
        variables: { id: zoneId },
        data: { zone },
      });
      hideLoading();
      toast.success('Станция успешна создана');
    },
  });

  const [deleteStation] = useMutation(DELETE_STATION, {
    update(cache, { data: { deleteStation: id } }) {
      let { zone } = cache.readQuery({
        query: GET_STATIONS,
        variables: { id: zoneId },
      });
      zone = {
        id: zone.id,
        stations: zone.stations.filter((dataStation) => dataStation.id !== id),
      };
      const stations =
        zone && zone.stations
          ? zone.stations.map((dataStation) => dataStation.id)
          : [];

      updateZone({ variables: { id: zone.id, input: { stations } } });
      cache.writeQuery({
        query: GET_STATIONS,
        variables: { id: zoneId },
        data: {
          zone,
        },
      });
      hideLoading();
      toast.success('Станция успешна удалена');
    },
  });

  useEffect(() => {
    if (data && !loading && !error) {
      setDataStations(data.zone.stations);
      setGroup(data.zone.group);
    }
  }, [data, loading, error]);

  const handleAddStation = (values) => {
    showLoading();
    const dataStation = {
      number: parseInt(values.number, 10),
      icon: values.icon,
      name: values.name,
      isCardio: values.isCardio,
      positions: values?.positions.map((position) => {
        return {
          width: parseInt(position?.width, 10),
          height: parseInt(position?.height, 10),
        };
      }),
    };
    addStation({
      variables: {
        input: dataStation,
      },
    });
  };

  const handleDeleteStation = (stationId) => {
    showLoading();
    deleteStation({
      variables: {
        id: stationId,
      },
    });
  };

  const handleUpdateStation = (id, values) => {
    showLoading();
    const dataStation = {
      number: parseInt(values.number, 10),
      icon: values.icon,
      name: values.name,
      isCardio: values.isCardio,
      positions: values.positions.map((position) => {
        return {
          width: parseInt(position?.width, 10),
          height: parseInt(position?.height, 10),
        };
      }),
    };
    updateStation({ variables: { id, input: dataStation } });
  };

  if (loading && !dataStations) {
    return <Loading />;
  }

  return (
    <>
      {dataStations && (
        <Stations
          stations={dataStations}
          group={group}
          addStationClick={handleAddStation}
          deleteStationClick={handleDeleteStation}
          updateStationClick={handleUpdateStation}
        />
      )}
    </>
  );
};

StationsContainer.propTypes = {
  zoneId: PropTypes.string.isRequired,
};

export default StationsContainer;
