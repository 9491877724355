import { gql } from '@apollo/client';

export const USERS = gql`
    query userHeroWeekHistories {
        userHeroWeekHistories {
        day1 {
            day
            buyHP
            user {
                id
                username
                firstName
                lastName
                nickname
                phoneNumber
            }
            userEvent {
                status
                event {
                    id
                    status
                    programSet {
                        name
                    }
                }
                status
            }
            trainings {
                status
                event {
                    id
                    startTime
                    programSet {
                        name
                    }
                }
            }
            writeSales {
                status
                created
                sales {
                    id
                    username
                }
            }
            callSales {
                status
                created
                sales {
                    id
                    username
                }
            }
            meetSales {
                status
                sales {
                    id
                    username
                }
                created
            }
        }
        day2 {
            day
            buyHP
            user {
                id
                username
                firstName
                lastName
                nickname
                phoneNumber
            }
            userEvent {
                status
                event {
                    id
                    status
                    programSet {
                        name
                    }
                }
                status
            }
            trainings {
                status
                event {
                    id
                    startTime
                    programSet {
                        name
                    }
                }
            }
            writeSales {
                status
                created
                sales {
                    id
                    username
                }
            }
            callSales {
                status
                created
                sales {
                    id
                    username
                }
            }
            meetSales {
                status
                sales {
                    id
                    username
                }
                created
            }
        }
        day3 {
            day
            buyHP
            user {
                id
                username
                firstName
                lastName
                nickname
                phoneNumber
            }
            userEvent {
                status
                event {
                    id
                    status
                    programSet {
                        name
                    }
                }
                status
            }
            trainings {
                status
                event {
                    id
                    startTime
                    programSet {
                        name
                    }
                }
            }
            writeSales {
                status
                created
                sales {
                    id
                    username
                }
            }
            callSales {
                status
                created
                sales {
                    id
                    username
                }
            }
            meetSales {
                status
                sales {
                    id
                    username
                }
                created
            }
        }
        day4 {
            day
            buyHP
            user {
                id
                username
                firstName
                lastName
                nickname
                phoneNumber
            }
            userEvent {
                status
                event {
                    id
                    status
                    programSet {
                        name
                    }
                }
                status
            }
            trainings {
                status
                event {
                    id
                    startTime
                    programSet {
                        name
                    }
                }
            }
            writeSales {
                status
                created
                sales {
                    id
                    username
                }
            }
            callSales {
                status
                created
                sales {
                    id
                    username
                }
            }
            meetSales {
                status
                sales {
                    id
                    username
                }
                created
            }
        }
        day5 {
            day
            buyHP
            user {
                id
                username
                firstName
                lastName
                nickname
                phoneNumber
            }
            userEvent {
                status
                event {
                    id
                    status
                    programSet {
                        name
                    }
                }
                status
            }
            trainings {
                status
                event {
                    id
                    startTime
                    programSet {
                        name
                    }
                }
            }
            writeSales {
                status
                created
                sales {
                    id
                    username
                }
            }
            callSales {
                status
                created
                sales {
                    id
                    username
                }
            }
            meetSales {
                status
                sales {
                    id
                    username
                }
                created
            }
        }
        day6 {
            day
            buyHP
            user {
                id
                username
                firstName
                lastName
                nickname
                phoneNumber
            }
            userEvent {
                status
                event {
                    id
                    status
                    programSet {
                        name
                    }
                }
                status
            }
            trainings {
                status
                event {
                    id
                    startTime
                    programSet {
                        name
                    }
                }
            }
            writeSales {
                status
                created
                sales {
                    id
                    username
                }
            }
            callSales {
                status
                created
                sales {
                    id
                    username
                }
            }
            meetSales {
                status
                sales {
                    id
                    username
                }
                created
            }
        }
        day7{
            day
            buyHP
            user {
                id
                username
                firstName
                lastName
                nickname
                phoneNumber
            }
            userEvent {
                status
                event {
                    id
                    status
                    programSet {
                        name
                    }
                }
                status
            }
            trainings {
                status
                event {
                    id
                    startTime
                    programSet {
                        name
                    }
                }
            }
            writeSales {
                status
                created
                sales {
                    id
                    username
                }
            }
            callSales {
                status
                created
                sales {
                    id
                    username
                }
            }
            meetSales {
                status
                sales {
                    id
                    username
                }
                created
            }
        }
        day8 {
            day
            buyHP
            user {
                id
                username
                firstName
                lastName
                nickname
                phoneNumber
            }
            userEvent {
                status
                event {
                    id
                    status
                    programSet {
                        name
                    }
                }
                status
            }
            trainings {
                status
                event {
                    id
                    startTime
                    programSet {
                        name
                    }
                }
            }
            writeSales {
                status
                created
                sales {
                    id
                    username
                }
            }
            callSales {
                status
                created
                sales {
                    id
                    username
                }
            }
            meetSales {
                status
                sales {
                    id
                    username
                }
                created
            }
        }
        day9 {
            day
            buyHP
            user {
                id
                username
                firstName
                lastName
                nickname
                phoneNumber
            }
            userEvent {
                status
                event {
                    id
                    status
                    programSet {
                        name
                    }
                }
                status
            }
            trainings {
                status
                event {
                    id
                    startTime
                    programSet {
                        name
                    }
                }
            }
            writeSales {
                status
                created
                sales {
                    id
                    username
                }
            }
            callSales {
                status
                created
                sales {
                    id
                    username
                }
            }
            meetSales {
                status
                sales {
                    id
                    username
                }
                created
            }
        }
        day10 {
            day
            buyHP
            user {
                id
                username
                firstName
                lastName
                nickname
                phoneNumber
            }
            userEvent {
                status
                event {
                    id
                    status
                    programSet {
                        name
                    }
                }
                status
            }
            trainings {
                status
                event {
                    id
                    startTime
                    programSet {
                        name
                    }
                }
            }
            writeSales {
                status
                created
                sales {
                    id
                    username
                }
            }
            callSales {
                status
                created
                sales {
                    id
                    username
                }
            }
            meetSales {
                status
                sales {
                    id
                    username
                }
                created
            }
        }
        tomorrow {
            day
            buyHP
            user {
                id
                username
                firstName
                lastName
                nickname
                phoneNumber
            }
            userEvent {
                status
                event {
                    id
                    status
                    programSet {
                        name
                    }
                }
                status
            }
            trainings {
                status
                event {
                    id
                    startTime
                    programSet {
                        name
                    }
                }
            }
            writeSales {
                status
                created
                sales {
                    id
                    username
                }
            }
            callSales {
                status
                created
                sales {
                    id
                    username
                }
            }
            meetSales {
                status
                sales {
                    id
                    username
                }
                created
            }
        }
        today {
            day
            buyHP
            user {
                id
                username
                firstName
                lastName
                nickname
                phoneNumber
            }
            userEvent {
                status
                event {
                    id
                    status
                    programSet {
                        name
                    }
                }
                status
            }
            trainings {
                status
                event {
                    id
                    startTime
                    programSet {
                        name
                    }
                }
            }
            writeSales {
                status
                created
                sales {
                    id
                    username
                }
            }
            callSales {
                status
                created
                sales {
                    id
                    username
                }
            }
            meetSales {
                status
                sales {
                    id
                    username
                }
                created
            }
        }
        wasToday {
            day
            buyHP
            user {
                id
                username
                firstName
                lastName
                nickname
                phoneNumber
            }
            userEvent {
                status
                event {
                    id
                    status
                    programSet {
                        name
                    }
                }
                status
            }
            trainings {
                status
                event {
                    id
                    startTime
                    programSet {
                        name
                    }
                }
            }
            writeSales {
                status
                created
                sales {
                    id
                    username
                }
            }
            callSales {
                status
                created
                sales {
                    id
                    username
                }
            }
            meetSales {
                status
                sales {
                    id
                    username
                }
                created
            }
        }
        redZone {
            day
            buyHP
            user {
                id
                username
                firstName
                lastName
                nickname
                phoneNumber
            }
            userEvent {
                status
                event {
                    id
                    status
                    programSet {
                        name
                    }
                }
                status
            }
            trainings {
                status
                event {
                    id
                    startTime
                    programSet {
                        name
                    }
                }
            }
            writeSales {
                status
                created
                sales {
                    id
                    username
                }
            }
            callSales {
                status
                created
                sales {
                    id
                    username
                }
            }
            meetSales {
                status
                sales {
                    id
                    username
                }
                created
            }
        }
        notBuy {
            day
            buyHP
            user {
                id
                username
                firstName
                lastName
                nickname
                phoneNumber
            }
            trainings {
                status
                event {
                    id
                    startTime
                    programSet {
                        name
                    }
                }
            }
            userEvent {
                status
                event {
                    id
                    status
                    programSet {
                        name
                    }
                }
            }
        }
    }
    }  

`
export const CHANGE_STATUS = gql`
  mutation salesStatus($userId: ID, $input: StatusInput){
    salesStatus(userId: $userId, input: $input){
      updated_at
    }
  }
`;

export const HEROS_WEEK_USER_INFO = gql`
  query userHistoryHeroWeek($userId: ID) {
    userHistoryHeroWeek(userId: $userId) {
      commentSales
      day
      goal
      experienceInGym
      user {
        id
        firstName
        nickname
        email
        phoneNumber
      }
      events {
        type
        startTime
        programSetName
      }
    }
  }
`;


export const ADD_COMMENT = gql`
  mutation CommentSalesHeroWeek($userId: ID, $input: SalesCommentInput) {
    commentSalesHeroWeek(userId: $userId, input: $input) {
      id
    }
  }
`;
