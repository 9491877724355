import React from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import { Form, Input, Button } from "antd";
import styled from "styled-components";
import { toast } from "react-toastify";
import CheckIcon from "../../images/Check.svg";

const SubmitButton = styled(Button)`
  width: 100%;
`;

const ResetPasswordContainer = (props) => {
  const { token } = useParams();
  const [success, setSuccess] = React.useState(false);
  const handleSubmit = async (values) => {
    const { password, repeatPassword } = values;
    if (password !== repeatPassword) {
      toast.error("Пароль не идентичен");
    }
    if (password === repeatPassword) {
      await fetch("/resetPassword", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/x-www-form-urlencoded",
          authorization: `Bearer ${token}`
        },
        body: `${encodeURIComponent("password")}=${encodeURIComponent(
          password
        )}&${encodeURIComponent("token")}=${encodeURIComponent(token)}`
      })
        .then((response) => response.json())
        .then((json) => {
          if (json.status === "error") {
            toast.error(json.message);
          }
          if (json.status === "ok") {
            setSuccess(true);
          }
        });
    }
  };

  return (
    <div
      style={{
        marginTop: 20,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center"
      }}
    >
      {success ? (
        <>
          <img src={CheckIcon} style={{ marginBottom: 24 }} />
          <div
            style={{
              fontFamily: "SF Pro Display",
              fontStyle: "normal",
              fontWeight: 600,
              fontSize: 24,
              textAlign: "center"
            }}
          >
            Ваш пароль успешно изменен
          </div>
        </>
      ) : (
        <>
          <h1
            style={{
              fontSize: 24,
              fontStyle: "normal",
              fontWeight: 600,
              textAlign: "left"
            }}
          >
            Сброс пароля
          </h1>
          <Form
            style={{ minWidth: 312 }}
            name="basic"
            initialValues={{
              remember: true
            }}
            onFinish={handleSubmit}
          >
            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: "Пожалуйста, введите свой пароль!"
                }
              ]}
            >
              <Input.Password placeholder="Новый пароль" />
            </Form.Item>

            <Form.Item
              name="repeatPassword"
              rules={[
                {
                  required: true,
                  message: "Пожалуйста, введите свой пароль!"
                }
              ]}
            >
              <Input.Password placeholder="Повторите пароль" />
            </Form.Item>
            <Form.Item>
              <SubmitButton type="primary" htmlType="submit" size="large">
                Сбросить
              </SubmitButton>
            </Form.Item>
          </Form>
        </>
      )}
    </div>
  );
};

ResetPasswordContainer.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired
    })
  }).isRequired
};

export default ResetPasswordContainer;
