import * as React from "react";
const BookedIcon = (props) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx={12} cy={12} r={12} fill="#B9EE77" />
    <path
      d="M9.20366 6.99H15.4057V8.474H11.0657V11.456H14.4817V12.898H11.0657V17H9.20366V6.99Z"
      fill="black"
      fillOpacity={0.5}
    />
  </svg>
);
export default BookedIcon;