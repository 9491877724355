import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { gql, useLazyQuery, useQuery, useMutation } from '@apollo/client';
import { Link } from 'react-router-dom';
import { Button, Table, DatePicker, Dropdown, Row, Checkbox, Collapse, Divider } from 'antd';
import styled from 'styled-components';
import dayjs from 'dayjs';
import 'dayjs/locale/ru';
import { toast } from 'react-toastify';

import { useLoading } from '../../context/useLoading';

import AddTicketsModal from './AddTicketsModal';
import { useAuth } from '../../context/useAuth';
import AddPackageModal from './AddPackageModal';
import { FilterFilled } from '@ant-design/icons';

const { RangePicker } = DatePicker;

const ColorfulText = styled.div`
  color: ${(p) => p.color};
`;

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const TableHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const StyledButton = styled(Button)`
  margin-left: 10px;
`;
const StyledButtonPackage = styled(Button)`
  margin-right: 10px;
`;
const TableColTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const DateDropdownBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 70px;
`;
const DropdownFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: end;
  width: 100%;
`;

const GET_DATA = gql`
  query getData($userId: ID!, $query: PaginationQuery, $ticketTypes: [String], $types: [String], $rangeDates: [String]) {
    userTransactionsWithPaginate(userId: $userId, query: $query, ticketTypes: $ticketTypes, types: $types, rangeDates: $rangeDates) {
      transactions {
        id
        changeValue
        type
        ticketType
        created_at
        expiryDate
        comment
        booking {
          id
          event {
            id
            startTime
            programSet {
              id
              name
            }
          }
        }
        addedBy {
          id
          username
        }
      }

      total
      limit
      page
      pages
    }
  }
`;

const GET_PAYMENTS = gql`
  query payments {
    payments {
      id
      name
      cost
      isActive
      ticketType
      countClasses
    }
  }
`;

const ADD_TICKETS_BY_SALES = gql`
  mutation addTicketsBySales($input: TransactionInput) {
    addTicketsBySales(input: $input) {
      id
      tickets
      outsiderTickets
    }
  }
`;
const ADD_TICKETS = gql`
  mutation addtickets($input: TransactionInput) {
    addTicketsByAdmin(input: $input) {
      id
      tickets
      outsiderTickets
    }
  }
`;

const UserTransactions = ({ user }) => {
  const { showLoading, hideLoading } = useLoading();
  const [userTransactions, setUserTransactions] = React.useState([]);
  const [total, setTotal] = React.useState(0);
  const [page, setPage] = React.useState(1);
  dayjs.locale('ru');

  const [payments, setPayments] = React.useState([]);
  const { user: currentUser } = useAuth();

  const [modalVisible, setModalVisible] = useState(false);
  const [isAddModoal, setIsAddModoal] = useState(false);
  const [packageModalVisible, setPackageModalVisible] = useState(false);
  const [dropdownDatesOpen, setDropdownDatesOpen] = useState(false);
  const [dropdownTicketsOpen, setDropdownTicketsOpen] = useState(false);
  const [dropdownTypeOpen, setDropdownTypeOpen] = useState(false);
  const [filterOptions, setFilterOptions] = useState({
    ticketTypes: [],
    types: [],
    rangeDates: []
  });

  const [getData, { data, error, loading, refetch }] = useLazyQuery(GET_DATA, {
    fetchPolicy: 'no-cache',
  });

  const {
    data: paymentsData,
    error: paymentsError,
    loading: paymentsLoading,
  } = useQuery(GET_PAYMENTS);

  const [addTicketsBySales, { error: addTicketsBySalesError }] = useMutation(
    ADD_TICKETS_BY_SALES,
    {
      onCompleted: () => {
        hideLoading();
        if (page === 1) {
          refetch({
            userId: user.id,
            query: {
              page: 1,
              limit: 10,
            },
          });
        }
        toast.success('Пакет успешно добавлен');
      },
    }
  );

  const [addTickets, { error: addTicketsError }] = useMutation(ADD_TICKETS, {
    onCompleted: () => {
      hideLoading();
      if (page === 1) {
        refetch({
          userId: user.id,
          query: {
            page: 1,
            limit: 10,
          },
          ...filterOptions
        });
      }
      toast.success('Билеты успешно добавлены');
    },
    onError: (error) => {
      hideLoading();
      toast.error(error?.graphQLErrors?.[0]?.message);
    },
  });

  React.useEffect(() => {
    if (paymentsData && !paymentsLoading && paymentsData.payments) {
      setPayments(paymentsData.payments);
    }
  }, [paymentsData, paymentsLoading, paymentsError]);

  React.useEffect(() => {
    getData({
      variables: {
        userId: user.id,
        query: {
          page: page,
          limit: 10,
        },
        ...filterOptions
      },
    });
  }, []);

  React.useEffect(() => {
    refetch({
      userId: user.id,
      query: {
        page: page,
        limit: 10,
      },
      ...filterOptions
    });
  }, [page]);

  React.useEffect(() => {
    if (data && data.userTransactionsWithPaginate && !loading) {
      setUserTransactions(data.userTransactionsWithPaginate.transactions);
      setTotal(data.userTransactionsWithPaginate.total);
    } else if (error) {
      console.log(`error `, error);
    }
  }, [data, loading, error]);

  const openAddTicketsModal = () => {
    if (
      currentUser?.role === 'salesperson' ||
      currentUser?.role === 'salesadmin'
    ) {
      setPackageModalVisible(true);
    } else {
      setModalVisible(true);
      setIsAddModoal(true);
    }
  };

  const openRemoveTicketsModal = () => {
    setModalVisible(true);
    setIsAddModoal(false);
  };

  const handleRangeChange = (_, dateStrings) => {
    setFilterOptions(prevState => ({...prevState, rangeDates: dateStrings.every(item => item === '') ? [] : dateStrings}));
  };

  React.useEffect(() => {
    console.log(filterOptions);
  }, [filterOptions]);

  const onCancel = () => {
    if (
      currentUser?.role === 'salesperson' ||
      currentUser?.role === 'salesadmin'
    ) {
      setPackageModalVisible(false);
    } else {
      setModalVisible(false);
    }
  };

  const renderChangeValue = (changeValue) => {
    if (changeValue > 0) {
      return <ColorfulText color="#00c000"> +{changeValue}</ColorfulText>;
    } else if (changeValue < 0) {
      return <ColorfulText color="#FF4D4F"> {changeValue}</ColorfulText>;
    }
    return <ColorfulText color="#000000"> {changeValue}</ColorfulText>;
  };

  const renderDescription = (transaction) => {
    if (transaction.type === 'payment') {
      return (
        <span>{`Покупка пакета на ${transaction.changeValue} билетов`} </span>
      );
    }
    if (
      transaction.type === 'adminAdd' ||
      transaction.type === 'addExpiryTickets'
    ) {
      return (
        <span>
          {`${
            transaction.changeValue >= 0 ? 'Добавление' : 'Штраф'
          } админом. Коммент: ${transaction.comment}`}{' '}
        </span>
      );
    }
    if (transaction.type === 'penaltyForMissing') {
      return <span>{`${transaction.changeValue} Штраф за пропуск `} </span>;
    }
    if (transaction.type === 'attentionForMissing') {
      return (
        <span>{`${transaction.changeValue} Предупреждение за пропуск `} </span>
      );
    }
    if (
      transaction.type === 'booking' ||
      transaction.type === 'expiryBooking'
    ) {
      const eventDate = dayjs(transaction?.booking?.event?.startTime).format(
        'Do MMMM, YYYY, HH:mm'
      );
      return (
        <span>
          <Link to={`/events/${transaction?.booking?.event?.id}`}>
            {`Запись на "${transaction?.booking?.event?.programSet?.name}" ${eventDate}`}
          </Link>
        </span>
      );
    }
    if (transaction.type === 'epicHero') {
      return <span> Покупка эпик героя </span>;
    }

    if (
      transaction.type === 'bookingCancel' ||
      transaction.type === 'expiryBookingCancel'
    ) {
      const eventDate = dayjs(transaction?.booking?.event?.startTime).format(
        'Do MMMM, YYYY, HH:mm'
      );
      return (
        <span>
          <Link to={`/events/${transaction?.booking?.event?.id}`}>
            {`Отмена записи на "${transaction?.booking?.event?.programSet?.name}" ${eventDate}`}
          </Link>
        </span>
      );
    }
    if (transaction.type === 'demoVisitsExpired') {
      return <span> Демо билеты сгорели </span>;
    }
    if (transaction.type === 'penalty') {
      return <span> Штраф за поздний выход </span>;
    }
    if (transaction.type === 'ticketsExpired') {
      return <span> Билеты просрочились </span>;
    }
    if (transaction.type === 'bonus') {
      return (
        <span>
          {`${transaction?.changeValue >= 0 ? 'Добавление бонуса' : 'Штраф'} `}
        </span>
      );
    }
    if (transaction.type === 'marathonExpired') {
      return <span> Марафон просрочился </span>; 
    }

    if(transaction.type === 'marathon'){
      return <span>Добавлен на марафон</span>;
    }
    return <span> {transaction.changeValue} </span>;
  };

  const renderSource = (transaction) => {
    if (
      transaction.type === 'adminAdd' ||
      transaction.type === 'addExpiryTickets'
    ) {
      return <span> Админ: {transaction?.addedBy?.username} </span>;
    }
    return <span> Пользователь</span>;
  };

  const renderType = (item) => {
    if (item?.ticketType === 'marathon') {
      return <span>Программные билеты</span>;
    }
    if (item?.ticketType === 'assessment') {
      return <span>Билеты для ассессмент</span>;
    }
    if (item?.ticketType === 'education') {
      return <span>Билеты для обучающей</span>;
    }
    if (item?.ticketType === 'golden') {
      return <span>Билеты для EndGame</span>;
    }
    if (item?.ticketType === 'guest') {
      return <span>Гостевой билет</span>;
    }
    return <span>Обычный билет</span>;
  };


  const DateItems = [
    {
      key: '1',
      label: (
        <DateDropdownBody>
          <RangePicker 
            onChange={handleRangeChange}
            disabledDate={(current) => current && current > dayjs().endOf('day')}
            value={
              filterOptions.rangeDates.length === 2
                ? [dayjs(filterOptions.rangeDates[0]), dayjs(filterOptions.rangeDates[1])]
                : null
            }  
          />
          <DropdownFooter>
            <Button 
              style={{height: '25px', width: '45px'}} 
              disabled={filterOptions?.rangeDates?.length === 0} 
              onClick={() => setFilterOptions(prevState => ({...prevState, rangeDates: []}))}
            >
              Reset
            </Button>
            <Button 
              type='primary' 
              style={{height: '25px', width: '25px'}} 
              onClick={onOk}
            >
              OK
            </Button>
          </DropdownFooter>
        </DateDropdownBody>
      ),
    },
  ];

  const ticketsOptions = [
    {label: 'Программные билеты', value: 'marathon'}, 
    {label: 'Билеты для ассессмент', value: 'assessment'}, 
    {label: 'Билеты для обучающей', value: 'education'}, 
    {label: 'Билеты для EndGame', value: 'golden'}, 
    {label: 'Гостевой билет', value: 'guest'}, 
    {label: 'Обычный билет', value: 'outsider'}
  ];

  const typeOptions = [
    {label: 'Покупка пакета', value: 'payment'}, 
    {label: 'Добавление админом', value: 'adminAdd'}, 
    {label: 'Штраф админом', value: 'addExpiryTickets'}, 
    {label: 'Штраф за пропуск', value: 'penaltyForMissing'}, 
    {label: 'Предупреждение за пропуск', value: 'attentionForMissing'}, 
    {label: 'Записи', value: 'booking'}, 
    {label: 'Просроченные записи', value: 'expiryBooking'}, 
    {label: 'Покупка эпик героя', value: 'epicHero'}, 
    {label: 'Отмена записи', value: 'bookingCancel'}, 
    {label: 'Отмена записи (просроченные)', value: 'expiryBookingCancel'}, 
    {label: 'Демо билеты сгорели', value: 'demoVisitsExpired'}, 
    {label: 'Штраф за поздний выход', value: 'penalty'}, 
    {label: 'Билеты просрочились', value: 'ticketsExpired'}, 
    {label: 'Добавление бонуса', value: 'bonus'}, 
    {label: 'Марафон просрочился', value: 'marathonExpired'}, 
    {label: 'Добавлен на марафон', value: 'marathon'}
  ];

  const handleCheckboxChange = (checkedValues, type) => {
    setFilterOptions(prevState => ({
      ...prevState,
      [type === 'tickets' ? 'ticketTypes' : 'types']: checkedValues
    }));
  };

  const getItems = (options, filters, onReset, onOk, type) => (
    [
      {
        key: '1',
        label: (
          <Row>
            <Checkbox.Group 
              options={options} 
              style={{flexDirection: 'column', marginBottom: '20px'}}
              value={filters}
              onChange={(checkedValues) => handleCheckboxChange(checkedValues, type)}
            />
            <DropdownFooter>
              <Button 
                style={{height: '25px', width: '45px'}} 
                disabled={filters?.length === 0} 
                onClick={() => onReset(type)}
              >
                Reset
              </Button>
              <Button 
                type='primary' 
                style={{height: '25px', width: '25px'}} 
                onClick={onOk}
              >
                OK
              </Button>
            </DropdownFooter>
          </Row>  
        ),
      },
    ]
  );

  const onShowDropdown = (type) => {
    setDropdownTicketsOpen(type === 'tickets');
    setDropdownTypeOpen(type === 'type');
    setDropdownDatesOpen(type === 'dates');
  };  

  const onReset = (type) => {
    setFilterOptions(prevState => ({
      ...prevState,
      [type === 'tickets' ? 'ticketTypes' : 'types']: []
    }));
  };  

  function onOk() {
    setDropdownTicketsOpen(false);
    setDropdownTypeOpen(false);
    setDropdownDatesOpen(false);

    getData({
      variables: {
        userId: user.id,
        query: {
          page: page,
          limit: 10,
        },
        ...filterOptions
      },
    });
  }; 

  const userTransactionColumns = [
    {
      title: 'Билеты',
      width: '10%',
      dataIndex: 'changeValue',
      render: (text, item) => renderChangeValue(item?.changeValue),
    },
    {
      title: <TableColTitle>
                <span>Тип билета</span>
                <Dropdown 
                  open={dropdownTicketsOpen} 
                  menu={{ items: getItems(ticketsOptions, filterOptions?.ticketTypes, onReset, onOk, 'tickets') }}
                  placement="bottom"
                  trigger={[]}
                >
                  <FilterFilled 
                    style={{cursor: 'pointer', color: filterOptions?.ticketTypes?.length > 0 ? '#1890ff' : ''}} 
                    onClick={() => onShowDropdown('tickets')} 
                  />
                </Dropdown>
              </TableColTitle>,
      width: '20%',
      render: (text, item) => renderType(item),
    },
    {
      title: <TableColTitle>
                <span>Причина</span>
                <Dropdown 
                  open={dropdownTypeOpen} 
                  menu={{ items: getItems(typeOptions, filterOptions?.types, onReset, onOk, 'types') }}
                  placement="bottom"
                  trigger={[]}                
                >
                  <FilterFilled 
                    style={{cursor: 'pointer', color: filterOptions?.types?.length > 0 ? '#1890ff' : ''}} 
                    onClick={() => onShowDropdown('type')} 
                  />
                </Dropdown>
              </TableColTitle>,
      width: '20%',
      render: (text, item) => renderDescription(item),
    },
    {
      title: 'Источник',
      width: '15%',
      render: (text, item) => renderSource(item),
    },
    {
      title: <TableColTitle>
              <span>Дата</span>
              <Dropdown 
                open={dropdownDatesOpen} 
                menu={{ items: DateItems }} 
                trigger={[]}
              >
                <FilterFilled 
                  style={{cursor: 'pointer', color: filterOptions?.rangeDates?.length > 0 ? '#1890ff' : ''}} 
                  onClick={() => onShowDropdown('dates')} 
                />
              </Dropdown>
            </TableColTitle>,
      width: '15%',
      render: (text, item) => {
        const dateReceived = dayjs(item.created_at).format(
          'Do MMMM, YYYY, HH:mm'
        );
        return <span>{dateReceived} </span>;
      },
    },
    {
      title: 'Дата истечения',
      width: '20%',
      render: (text, item) => {
        const dateReceived = !item?.expiryDate ? '-' : dayjs(item?.expiryDate).format(
          'Do MMMM, YYYY, HH:mm'
        );
        return <span>{dateReceived}</span>;
      },
    },
  ];

  const handleAddTicketsBySales = ({ values }) => {
    showLoading();
    addTicketsBySales({
      variables: {
        input: {
          user: user.id,
          changeValue: parseInt(values?.changeValue, 10),
          comment: values?.comment,
          payment: values?.payment,
        },
      },
    });
  };

  const handleAddTickets = ({ values, isExpiry }) => {
    showLoading();
    if (isExpiry) {
      addTickets({
        variables: {
          input: {
            user: user.id,
            changeValue: parseInt(values?.changeValue, 10),
            comment: values?.comment,
            ticketType: values?.ticketType,
            expiryDate: values?.expiryDate || null,
          },
        },
      });
    } else {
      addTickets({
        variables: {
          input: {
            user: user.id,
            changeValue: parseInt(values?.changeValue, 10),
            ticketType: values?.ticketType,
            comment: values?.comment,
          },
        },
      });
    }
  };

  const totalBalance = user?.tickets + user?.outsiderTickets + user?.marathonTickets + user?.assessmentTickets

  const headerItems = [
    {
      label: 'Общий баланс',
      value: totalBalance
    },
    {
      label: 'Обычные',
      value: user?.tickets + user?.outsiderTickets
    },
    {
      label: 'Программные',
      value: user?.marathonTickets
    },
    {
      label: 'Ассессмент',
      value: user?.assessmentTickets
    },
    {
      label: 'Обучающая',
      value: user?.educationTickets
    },
    {
      label: 'Гостевые',
      value: user?.guestTickets
    }
  ];

  return (
    <MainContainer>
      {/* <h2> История баланса</h2> */}
      <Table
        dataSource={userTransactions}
        columns={userTransactionColumns}
        rowKey={(item) => item.id}
        loading={loading}
        pagination={{
          defaultPageSize: 10,
          total: total,
          current: page,
          onChange: (page, pageSize) => {
            setPage(page);
          },
          showSizeChanger: false,
        }}
        title={() => (
          <TableHeader>
            {
              headerItems?.map((item, index) => (
                <div key={index} style={{display: 'flex', alignItems: 'center'}}>
                  <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                    <span style={{fontSize: '18px', marginRight: '5px'}}>{item?.label}:</span>
                    <span style={{fontSize: '18px'}}>{item?.value}</span>
                  </div>
                  <Divider type="vertical"/>
                </div>
              ))
            }
            <div style={{display: 'flex', position: 'absolute', top: '-7%', right: '3%'}}>
              {currentUser?.role === 'admin' && (
                <StyledButtonPackage
                  type="primary"
                  onClick={() => setPackageModalVisible(true)}
                >
                  Добавить пакет
                </StyledButtonPackage>
              )}
              <Button type="primary" onClick={openAddTicketsModal}>
                {currentUser?.role === 'salesperson' ||
                currentUser?.role === 'salesadmin'
                  ? 'Добавить пакет'
                  : 'Пополнить'}
              </Button>
              {currentUser?.role === 'admin' && (
                <StyledButton onClick={openRemoveTicketsModal}>
                  Убрать
                </StyledButton>
              )}
            </div>
          </TableHeader>
        )}
      />
      <AddTicketsModal
        handleAddTickets={handleAddTickets}
        isAddModal={isAddModoal}
        modalVisible={modalVisible}
        onCancel={onCancel}
        currentTickets={
          user?.tickets +
          user?.outsiderTickets +
          user?.marathonTickets +
          user?.assessmentTickets
        }
      />
      {packageModalVisible && (
        <AddPackageModal
          modalVisible={packageModalVisible}
          payments={payments}
          onCancel={onCancel}
          onCancel={setPackageModalVisible}
          userIsInvictusClient={user?.isInvictusClient}
          currentTickets={
            user?.tickets +
            user?.outsiderTickets +
            user?.marathonTickets +
            user?.assessmentTickets
          }
          handleAddTicketsBySales={handleAddTicketsBySales}
        />
      )}
    </MainContainer>
  );
};

UserTransactions.propTypes = {
  userTransactions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      changeValue: PropTypes.number,
      created_at: PropTypes.string,
      type: PropTypes.string,
    })
  ).isRequired,
  user: PropTypes.shape({
    id: PropTypes.string.isRequired,
    tickets: PropTypes.number.isRequired,
    outsiderTickets: PropTypes.string.isRequired,
  }).isRequired,
  handleAddTickets: PropTypes.func.isRequired,
  payments: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      cost: PropTypes.number.isRequired,
      isActive: PropTypes.bool.isRequired,
      ticketType: PropTypes.string.isRequired,
      countClasses: PropTypes.number.isRequired,
    })
  ).isRequired,
  handleAddTicketsBySales: PropTypes.func.isRequired,
};

export default UserTransactions;
