import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Table, Button, Divider } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import 'dayjs/locale/ru';

import EditForm from '../shared/EditFormModal';
import CreateForm from '../shared/CreateForm';

const PromptNewss = ({
  promptNews,
  deletePromptNewsClick,
  updatePromptNewsClick,
  addPromptNewsClick,
}) => {
  const [display, setDisplay] = useState('default');
  const [editPromptNews, setEditPromptNews] = useState(null);

  const columns = [
    {
      title: 'Кол-во реакции',
      dataIndex: 'reaction_count',
    },
    {
      title: 'Новость',
      dataIndex: 'news_text',
    },
    {
      title: 'Дата',
      render: (_, item) => (
        <span>{dayjs(item?.news_date).format('Do MMMM HH:mm, YYYY')}</span>
      ),
    },
    {
      title: 'Действие',
      key: 'action',
      render: (text, item) => (
        <span>
          <Button
            type="link"
            onClick={() => {
              setDisplay('edit');
              setEditPromptNews(item);
            }}
          >
            Редактировать
          </Button>
          <Divider type="vertical" />
        </span>
      ),
    },
  ];

  const editFields = [
    {
      key: 'reaction_count',
      label: 'Кол-во реакции',
      value: editPromptNews && editPromptNews.reaction_count,
      isRequired: true,
      tooltipText: 'Количество реакции на новость',
    },
    {
      key: 'news_text',
      label: 'Новость',
      textArea: true,
      value: editPromptNews && editPromptNews.news_text,
      tooltipText: 'Новость',
    },
  ];

  const fields = [
    {
      key: 'reaction_count',
      label: 'Кол-во реакции',
      tooltipText: 'Количество реакции на новость',
    },
    {
      key: 'news_text',
      label: 'Новость',
      tooltipText: 'Новость',
      textArea: true,
    },
  ];

  const handleUpdate = (values) => {
    updatePromptNewsClick(editPromptNews.id, values);
    setDisplay('default');
    setEditPromptNews(null);
  };

  const handleDelete = () => {
    deletePromptNewsClick(editPromptNews.id);
    setDisplay('default');
    setEditPromptNews(null);
  };

  const handleCreate = (values) => {
    addPromptNewsClick(values);
    setDisplay('default');
    setEditPromptNews(null);
  };

  return (
    <>
      <Table
        dataSource={promptNews}
        columns={columns}
        title={() => (
          <div>
            <Button type="primary" onClick={() => setDisplay('add')}>
              <PlusOutlined /> Новую новость
            </Button>
          </div>
        )}
      />
      {display === 'add' && (
        <CreateForm
          title="Добавить новую новость"
          visible={display === 'add'}
          onCancel={() => setDisplay('default')}
          onCreate={handleCreate}
          fields={fields}
        />
      )}
      {display === 'edit' && editPromptNews && (
        <EditForm
          width={1080}
          title="Редактировать оплату"
          visible={display === 'edit'}
          onCancel={() => setDisplay('default')}
          onUpdate={handleUpdate}
          onDelete={handleDelete}
          fields={editFields}
        />
      )}
    </>
  );
};

PromptNewss.propTypes = {
  promptNews: PropTypes.shape({
    name: PropTypes.string,
    attendancePoints: PropTypes.number,
    maxPoints: PropTypes.number,
    perfectWeekPoints: PropTypes.number,
    visitsForPerfectWeek: PropTypes.number,
  }).isRequired,
  deletePromptNewsClick: PropTypes.func.isRequired,
  addPromptNewsClick: PropTypes.func.isRequired,
  updatePromptNewsClick: PropTypes.func.isRequired,
};

export default PromptNewss;
