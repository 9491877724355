import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
`;

const TabsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 12px;
`;

const Tab = styled.div`
  font-size: 14px;
  line-height: 16px;
  color: ${(props) => (props.active ? 'black' : 'rgba(0, 0, 0, 0.50)')};
  font-family: 'GraphikLCGMedium';
  cursor: pointer;
  position: relative;
  padding-bottom: 4px;

  &:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: ${(props) => (props.active ? '100%' : '0')};
    height: 2px;
    background-color: black;
    transition: width 0.3s;
  }
`;

const Badge = styled.span`
  padding: 2px 8px;
  margin-left: 8px;
  font-size: 14px;
  color: black;
`;

const SalesSupportHeader = ({ user, setSearch, setFilter, requestsCountData }) => {
  const [activeTab, setActiveTab] = React.useState('');
  const [showCreateModal, setShowCreateModal] = React.useState(false);

  const counts = requestsCountData?.allRequestQuestionsByType;

  const total =
    counts?.inProgress +
    counts?.moreDataForAdmin +
    counts?.finished +
    counts?.notViewed;

  const statusData = [
    { value: '', title: 'Все заявки', count: total || 0 },
    {
      value: 'inProgress',
      title: 'В обработке',
      count: counts?.inProgress || 0
    },
    {
      value: 'moreDataForAdmin',
      title: 'Дополняется',
      count: counts?.moreDataForAdmin || 0
    },
    { value: 'finished', title: 'Решено', count: counts?.finished || 0 }
  ];

  return (
    <Container>
      <TabsContainer>
        {statusData.map((tab) => (
          <Tab
            key={tab.value}
            active={activeTab === tab.value}
            onClick={() => {
              setActiveTab(tab.value);
              setFilter(tab.value);
            }}
          >
            {tab.title}
            <Badge>{tab.count}</Badge>
          </Tab>
        ))}
      </TabsContainer>
    </Container>
  );
};

export default SalesSupportHeader;
