import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Table, Button, Divider } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import EditForm from '../shared/EditFormModal';
import CreateForm from '../shared/CreateForm';

const Payments = ({
  payments,
  deletePaymentClick,
  addPaymentClick,
  updatePaymentClick
}) => {
  const [display, setDisplay] = useState('default');
  const [editPayment, setEditPayment] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);

  const columns = [
    {
      title: 'Цена',
      dataIndex: 'cost'
    },
    {
      title: 'Название',
      dataIndex: 'name'
    },
    {
      title: 'Скидки',
      dataIndex: 'discount'
    },
    {
      title: 'Количество Посещений',
      dataIndex: 'countClasses'
    },
    {
      title: 'Тип билета',
      dataIndex: 'ticketType'
    },
    {
      title: 'Статус',
      render: (_, item) => {
        if (item.isActive) {
          return 'Активен';
        }
        return 'Неактивен';
      }
    },
    {
      title: 'Демо Билеты?',
      render: (_, item) => {
        if (item.isDemo) {
          return 'Да';
        }
        return 'Нет';
      }
    },
    {
      title: 'Действие',
      key: 'action',
      render: (text, item) => (
        <span>
          <Button
            type="link"
            onClick={() => {
              setDisplay('edit');
              setEditPayment(item);
            }}
          >
            Редактировать
          </Button>
          <Divider type="vertical" />
        </span>
      )
    }
  ];

  const fields = [
    {
      key: 'cost',
      label: 'Стоимость',
      number: true,
      tooltipText: 'Стоимость пакета без скидки'
    },
    {
      key: 'name',
      label: 'Название',
      tooltipText: 'Название пакета'
    },
    {
      key: 'discount',
      label: 'Скидка (%)',
      isNotRequired: true,
      number: true,
      tooltipText: 'Оставьте поле пустым если скидка отсутствует'
    },
    {
      key: 'ticketType',
      label: 'Тип билета',
      options: [
        { value: 'regular', label: 'regular' },
        { value: 'outsider', label: 'outsider' }
      ],
      tooltipText: 'Тип билета: для клиентов инвиктуса и для остальных разный'
    },
    {
      key: 'countClasses',
      label: 'Количество посещений',
      number: true,
      tooltipText: 'Кол-во посещений в пакете'
    },
    {
      key: 'isActive',
      label: 'Статус пакета ',
      checkbox: true,
      isNotRequired: true,
      tooltipText: 'Статус пакета(активен/неактивен)'
    },
    {
      key: 'isDemo',
      label: 'Демо пакет?',
      checkbox: true,
      isNotRequired: true,
      tooltipText: 'Демо пакет?'
    }
  ];

  const editFields = [
    {
      key: 'cost',
      label: 'Стоимость',
      value: editPayment && editPayment.cost,
      number: true,
      isRequired: true,
      tooltipText: 'Стоимость пакета без скидки'
    },
    {
      key: 'name',
      label: 'Название',
      value: editPayment && editPayment.name,
      tooltipText: 'Название пакета'
    },
    {
      key: 'discount',
      label: 'Скидка',
      value: editPayment && editPayment.discount,
      number: true,
      isNotRequired: true,
      tooltipText: 'Оставьте поле пустым если скидка отсутствует'
    },
    {
      key: 'ticketType',
      label: 'Тип билета',
      value: editPayment && editPayment.ticketType,

      options: [
        { value: 'regular', label: 'regular' },
        { value: 'outsider', label: 'outsider' }
      ],
      tooltipText: 'Тип билета: для клиентов инвиктуса и для остальных разный'
    },
    {
      key: 'countClasses',
      label: 'Количество Посещений',
      value: editPayment && editPayment.countClasses,
      number: true,
      isRequired: true,
      tooltipText: 'Кол-во посещений в пакете'
    },
    {
      key: 'isActive',
      label: 'Статус пакета ',
      value: editPayment && editPayment.isActive,
      isNotRequired: true,
      checkbox: true,
      tooltipText: 'Статус пакета(активен/неактивен)'
    },
    {
      key: 'isDemo',
      label: 'Демо пакет?',
      value: editPayment && editPayment.isDemo,
      isNotRequired: true,
      checkbox: true,
      tooltipText: 'Демо пакет?'
    }
  ];

  const handleCreate = values => {
    addPaymentClick(values);
    setModalVisible(false);
  };

  const handleUpdate = values => {
    updatePaymentClick(editPayment.id, values);
    setDisplay('default');
    setEditPayment(null);
  };

  const handleDelete = () => {
    deletePaymentClick(editPayment.id);
    setDisplay('default');
    setEditPayment(null);
  };

  return (
    <>
      <Table
        dataSource={payments}
        columns={columns}
        title={() => (
          <div>
            <Button type="primary" onClick={() => setModalVisible(true)}>
              <PlusOutlined /> Новый пакет
            </Button>
          </div>
        )}
      />
      <CreateForm
        title="Добавить новую оплату"
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        onCreate={handleCreate}
        fields={fields}
      />
      {display === 'edit' && editPayment && (
        <EditForm
          title="Редактировать оплату"
          visible={display === 'edit'}
          onCancel={() => setDisplay('default')}
          onUpdate={handleUpdate}
          onDelete={handleDelete}
          fields={editFields}
        />
      )}
    </>
  );
};

Payments.propTypes = {
  payments: PropTypes.shape({
    name: PropTypes.string,
    attendancePoints: PropTypes.number,
    maxPoints: PropTypes.number,
    perfectWeekPoints: PropTypes.number,
    visitsForPerfectWeek: PropTypes.number
  }).isRequired,
  deletePaymentClick: PropTypes.func.isRequired,
  addPaymentClick: PropTypes.func.isRequired,
  updatePaymentClick: PropTypes.func.isRequired
};

export default Payments;
