import React, { useState } from 'react';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { Select, MenuItem, FormControl, CircularProgress, Autocomplete, TextField } from '@mui/material';
import { styled as styledMui } from '@mui/system';
import styled from 'styled-components';
import {
  CREATE_USER_REQUEST_QUESTION,
  GET_REQUEST_CATEGORIES,
  GET_REQUEST_SUBCATEGORIES,
  GET_USERS
} from './SalesSupport.queries';
import CancelLogo from '../../images/cancel.svg';
import LinearProgress from '@mui/material/LinearProgress';
import { toast } from 'react-toastify';


const Wrapper = styled.div`
    background-color: #fff;
    position: fixed;
    transition: all 0.3s;
    top: 0;
    right: ${(props) => (props.show ? '0' : '-30%')};
    width: 368px;
    height: 100%;
    z-index: 1;
    overflow-y: auto;
`;

const Header = styled.div`
    display: flex;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    padding: 20px 24px;
    position: sticky;
    top: 0;
    background: #ffffff;
`;

const Title = styled.div`
    font-family: 'GraphikLCGMedium';
    font-size: 20px;
    line-height: 24px;
`;

const Cancel = styled.img`
    cursor: pointer;
    margin-left: auto;
`;

const Body = styled.div`
    display: flex;
    flex-direction: column;
    padding: 24px;
`;

const Field = styled.div`
    margin-right: auto;
    font-family: 'GraphikLCGMedium';
    font-size: 14px;
    line-height: 16px;
    color: #000000;
    margin-bottom: 16px;
    opacity: 50%;
`;

const StyledFormControl = styledMui(FormControl)(({ theme }) => ({
  width: '100%',
  borderRadius: 8,
  backgroundColor: 'transparent',
  border: '1px solid rgba(0, 0, 0, 0.1)',
  padding: 3,
  marginBottom: 8,
}));

const StyledAutocomplete = styledMui(Autocomplete)(({ theme }) => ({
  width: '100%',
  backgroundColor: 'transparent',
  padding: '1px',
  marginBottom: 0,
}));

const OpenButton = styled.button`
    font-family: 'GraphikLCGMedium';
    font-size: 20px;
    line-height: 24px;
    border-radius: 8px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    cursor: pointer;
    width: 320px;
    height: 56px;
    background: #6f2cff;
    color: #fff;
    align-self: center;
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Input = styled.input`
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    width: 100%;
    padding: 10px;
    font-family: 'GraphikLCGMedium';
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 8px;
    &:focus {
        outline: none;
        border: 1px solid #6f2cff;
    }
`;

const CreateRequestModal = ({
  visible,
  onClose,
  userId,
  refetch,
  uploadRequestPhoto,
  uploadedMediaLoading
}) => {
  const [subcategory, setSubcategory] = useState(null);
  const [categoryName, setCategoryName] = React.useState(null);
  const [queries, setQueries] = useState([{ desc: '', media: [] }]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [files, setFiles] = useState([]);
  const [subCategories, setSubCategories] = React.useState([]);
  const [selectedCategory, setSelectedCategory] = React.useState();
  const [categories, setCategories] = React.useState([]);
  const [errors, setErrors] = useState({
    user: false,
    category: false,
    subcategory: false,
    description: false,
  });  

  const [createUserRequestQuestion, { loading }] = useMutation(CREATE_USER_REQUEST_QUESTION, {
    onCompleted: (data) => {
      toast.success('Заявка создана!');
      clearFields();
      refetch();
      onClose();
    },
    onError: (error) => {
      toast.error(error.message);
    },
  });

  const [searchTerm, setSearchTerm] = useState('');
  const [users, setUsers] = React.useState([]);

  const {
    data: categoriesData,
    loading: categoriesLoading,
    error: categoriesError,
  } = useQuery(GET_REQUEST_CATEGORIES);


  const [
    getUsers,
    {
      loading: loadingUsers,
      error: errorUsers,
      data: dataUsers,
      refetch: refetchUsers
    }
  ] = useLazyQuery(GET_USERS);

  const [
    getRequestSubCategories,
    {
      data: subCategoriesData,
      loading: subCategoriesLoading,
      error: subCategoriesError
    }
  ] = useLazyQuery(GET_REQUEST_SUBCATEGORIES, {
    fetchPolicy: 'cache-first'
  });

  React.useEffect(() => {
    if (categoriesData && !categoriesLoading && !categoriesError) {
      if (categories?.length === 0)
        setCategories(categoriesData.requestCategories);
    }
  }, [categoriesData]);

  React.useEffect(() => {
    if (dataUsers && dataUsers.findUsers && !loadingUsers) {
      setUsers(dataUsers.findUsers.users);
    } else if (errorUsers) {
      console.log(`error `, errorUsers);
    }
  }, [dataUsers, loadingUsers, errorUsers]);

  React.useEffect(() => {
    refetchUsers({
      query: {
        phoneNumber: searchTerm
      },
      pagination: {
        page: 1,
        limit: 20
      }
    });
  }, [searchTerm]);

  React.useEffect(() => {
    if (subCategoriesData?.findSubcategories?.length > 0 && !subCategoriesLoading && !subCategoriesError) {
      setSubCategories(subCategoriesData.findSubcategories);
    }
  }, [subCategoriesData]);

  React.useEffect(() => {
    if (selectedCategory) {
      getRequestSubCategories({
        variables: {
          categoryId: selectedCategory
        }
      });
    }
  },  [selectedCategory]);

  const handleUserInputChange = (event, value) => {
    const cleanedValue = value.replace(/\D/g, '');
    setSearchTerm(cleanedValue);
  };

  const handleUserChange = (event, value) => {
    setSelectedUser(value);
  };

  const handleSubmit = async () => {
    const {data} = await uploadRequestPhoto({ variables: { files: files } });
    const uploadedMedia = data?.uploadRequestPhoto?.fileUrls;

    const newErrors = {
      user: !selectedUser,
      category: !selectedCategory,
      description: !queries[0].desc.trim(),
    };
  
    setErrors(newErrors);
  
    if (Object.values(newErrors).some((error) => error)) {
      toast.error('Пожалуйста, заполните все обязательные поля.');
      return;
    }

    createUserRequestQuestion({
      variables: {
        input: {
          user: userId,
          affectedUser: selectedUser.id,
          requestCategory: selectedCategory,
          requestSubcategory: subcategory,
          queries: [{...queries[0], media: uploadedMedia}],
        },
      },
    });
  };

  const clearFields = () => {
    setSubcategory(null);
    setCategoryName(null);
    setQueries([{ desc: '', media: [] }]);
    setSelectedUser(null);
    setFiles([]);
    setSelectedCategory(null);
    setSearchTerm('');
  };

  const handleFileChange = (e) => {
    const newFiles = [...e.target.files];
    setFiles((prevFiles) => [...prevFiles, ...newFiles]);
  };
  
  const onChangeCategory = (event) => {
  setCategoryName(event.target.value);
  setSelectedCategory(event.target.value);
  setSubcategory(null);
};

  return (
    <Wrapper show={visible}>
      <Header>
        <Title>Запрос</Title>
        <Cancel src={CancelLogo} onClick={onClose} />
      </Header>
      <Body>
        <Field>Пользователь</Field>
        <StyledAutocomplete
          getOptionLabel={(option) =>
            `${option.phoneNumber} (${option.email})`
          }
          options={users}
          loading={loadingUsers}
          value={selectedUser}
          onChange={handleUserChange}
          onInputChange={handleUserInputChange}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="Введите номер телефона"
              variant="outlined"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {loadingUsers ? <CircularProgress size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
          style = {{
            marginBottom: '15px'
          }}
        />
        {errorUsers && (
          <p style={{ color: 'red' }}>Ошибка: {errorUsers.message}</p>
        )}
        <Field>Категория</Field>
        <StyledFormControl variant="standard">
          <Select
            value={categoryName}
            displayEmpty
            disableUnderline
            onChange={onChangeCategory}
            style={{
              fontFamily: 'GraphikLCGMedium',
              fontSize: 14,
              marginLeft: 12,
            }}
          >
            <MenuItem value="" disabled>
              Выберите категорию
            </MenuItem>
            {categories.map((cat) => (
              <MenuItem
                key={cat?._id}
                value={cat?._id}
                style={{ fontFamily: 'GraphikLCGMedium', fontSize: 14 }}
              >
                {cat.name}
              </MenuItem>
            ))}
          </Select>
        </StyledFormControl>
        {subCategoriesLoading ? <LinearProgress/> : subCategories?.length > 0 ? (
          <>
        <Field>Подкатегория</Field>
        <StyledFormControl variant="standard">
          <Select
            value={subcategory}
            onChange={(e) => setSubcategory(e.target.value)}
            displayEmpty
            disableUnderline
            style={{ fontFamily: 'GraphikLCGMedium', fontSize: 14 }}
          >
            {subCategories?.map((sub) => (
                <MenuItem key={sub._id} value={sub._id} style={{ fontFamily: 'GraphikLCGMedium', fontSize: 14 }}>
                  {sub.name}
                </MenuItem>
              ))}
          </Select>
        </StyledFormControl>
          </>
          ):null}
        <Field>Опишите проблему</Field>
        <Input
          type="text"
          style={{ height: 50 }}
          value={queries[0].desc}
          onChange={(e) => setQueries([{ ...queries[0], desc: e.target.value, created_at: new Date() }])}
          placeholder="Введите описание"
        />

        <div className="file-upload-container">
          <label className="file-upload-label">
            Приложить файл
            <input
              type="file"
              multiple
              onChange={handleFileChange}
              className="file-input"
            />
          </label>

          <div className="file-list">
            {files.map((file, index) => (
              <div key={index} className="file-item">
                <span className="file-name">{file.name}</span>
                <button
                  className="delete-button"
                  onClick={() =>
                    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index))
                  }
                >
                  Удалить
                </button>
              </div>
            ))}
          </div>
        </div>
        <OpenButton onClick={handleSubmit} disabled={loading || uploadedMediaLoading}>
          {(loading || uploadedMediaLoading) ? (
            <>
              <CircularProgress size={20} style={{ marginRight: 10, color: 'white' }} />
              Подтверждение...
            </>
          ) : (
            'Подтвердить'
          )}
        </OpenButton>
        <OpenButton
          onClick={onClose}
          style={{ backgroundColor: '#FFFFFF', color: '#000000' }}
        >
          Отмена
        </OpenButton>
      </Body>
    </Wrapper>
  );
};

export default CreateRequestModal;
