import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { gql, useMutation, useQuery } from '@apollo/client';
import { toast } from 'react-toastify';

import Zones from './Zones';
import Loading from '../shared/Loading';
import { useLoading } from '../../context/useLoading';

const GET_DATA = gql`
  query getData($id: ID!) {
    club(id: $id) {
      id
      zones {
        id
        name
        group
      }
    }
  }
`;

const GET_ZONES = gql`
  query getZones($id: ID!) {
    club(id: $id) {
      id
      zones {
        id
        name
        group
      }
    }
  }
`;

const ADD_ZONE = gql`
  mutation addZone($input: ZoneInput) {
    addZone(input: $input) {
      id
      name
      group
    }
  }
`;

const UPDATE_CLUB = gql`
  mutation updateClub($id: ID!, $input: ClubInput) {
    updateClub(id: $id, input: $input) {
      id
      zones {
        id
        name
        group
      }
    }
  }
`;

const ZoneContainer = ({ clubId }) => {
  const [dataZones, setDataZones] = useState(null);
  const { showLoading, hideLoading } = useLoading();
  const [updateClub] = useMutation(UPDATE_CLUB);

  const { data, loading, error } = useQuery(GET_DATA, {
    variables: { id: clubId }
  });

  const [addZone] = useMutation(ADD_ZONE, {
    update(cache, { data: { addZone: zone } }) {
      let { club } = cache.readQuery({
        query: GET_ZONES,
        variables: { id: clubId }
      });
      club = {
        id: club.id,
        zones: club.zones ? club.zones.concat([zone]) : [zone]
      };
      const zones =
        club && club.zones ? club.zones.map(dataZone => dataZone.id) : [];
      updateClub({
        variables: { id: clubId, input: { zones } }
      });
      cache.writeQuery({
        query: GET_ZONES,
        variables: { id: clubId },
        data: { club }
      });
      hideLoading();
      toast.success('Зона успешно создано');
    }
  });

  useEffect(() => {
    if (data && !loading && !error) {
      setDataZones(data.club.zones);
    }
  }, [data, loading, error]);

  const handleAddZone = values => {
    showLoading();
    const dataZone = {
      name: values.name,
      group: parseInt(values.group, 10)
    };
    addZone({
      variables: {
        input: dataZone
      }
    });
  };

  if (loading && !dataZones) {
    return <Loading />;
  }

  return (
    <>
      {dataZones && (
        <Zones zones={dataZones} clubId={clubId} addZoneClick={handleAddZone} />
      )}
    </>
  );
};

ZoneContainer.propTypes = {
  clubId: PropTypes.string.isRequired
};

export default ZoneContainer;
