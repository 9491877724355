import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Modal, Form, Input, DatePicker, Select } from 'antd';
import StyledTooltip from '../shared/StyledTooltip';
import PROGRAM_SET_TYPES from '../../utils/programSetTypes';

const FormItem = Form.Item;

const StyledDatePicker = styled(DatePicker)`
  width: 100%;
`;

const MILLISECONDS_IN_ONE_WEEK = 1000 * 3600 * 24 * 7;

const CreateWeeklyTask = ({ visible, onCancel, title, onCreate }) => {
  const [type, setType] = React.useState(null);
  const [form] = Form.useForm();

  const getTooltip = (text, fullText) => {
    return <StyledTooltip {...{ text, fullText }} />;
  };

  const getWeekNumber = (currentDate = new Date().getTime()) => {
    const firstMonday = Date.parse('01 Jan 2018 00:00:00');

    const weekNumber = Math.floor(
      (currentDate - firstMonday) / MILLISECONDS_IN_ONE_WEEK
    );

    return weekNumber;
  };

  return (
    <Modal
      open={visible}
      title={title}
      okText="Создать"
      cancelText="Закрыть"
      onCancel={onCancel}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            form.resetFields();
            onCreate({
              ...values,
              weekNumber: getWeekNumber(Date.parse(values.startDate)),
            });
          })
          .catch((info) => {
            // eslint-disable-next-line no-console
            console.log('Validate Failed:', info);
          });
      }}
    >
      <Form form={form} layout="vertical">
        <FormItem
          key="name"
          label={getTooltip('Название Задания', 'Название Задания')}
          name="name"
        >
          <Input placeholder="Название Задания" />
        </FormItem>
        <FormItem
          key="changeValue"
          label={getTooltip('Баллы', 'Баллы за выполнение недельного задания')}
          name="changeValue"
        >
          <Input type="number" placeholder="Баллы" />
        </FormItem>
        <FormItem
          key="startDate"
          label={getTooltip(
            'Неделя выполнение задания',
            'Неделя выполнение задания'
          )}
          name="startDate"
          rules={[
            {
              required: true,
              message: `Пожалуйста, выберите неделю`,
            },
          ]}
        >
          <StyledDatePicker picker="week" placeholder="Выберите неделю" />
        </FormItem>
        <FormItem
          key="type"
          label={getTooltip('Тип Задания', 'Тип Задания')}
          name="type"
        >
          <Select
            placeholder="Тип Задания"
            mode="single"
            onChange={(value) => setType(value)}
          >
            {['visits', 'visitsToClass', 'allInOneEvent', 'bonusForClass'].map(
              (typeData) => (
                <Select.Option key={typeData} value={typeData}>
                  {typeData}
                </Select.Option>
              )
            )}
          </Select>
        </FormItem>
        {type === 'visits' && (
          <FormItem
            key="visitsCount"
            label={getTooltip('Количество Посещении', 'Количество Посещении')}
            name="visitsCount"
          >
            <Input type="number" placeholder="Количество Посещении" />
          </FormItem>
        )}
        {(type === 'visitsToClass' ||
          type === 'allInOneEvent' ||
          type === 'bonusForClass') && (
          <FormItem
            key="typeOfClass"
            label={getTooltip('Класс', 'К ласс который должен будет посетить')}
            name="typeOfClass"
          >
            <Select mode="single" placeholder="Выберите класс" showSearch>
              {PROGRAM_SET_TYPES.map((typeOfClass) => (
                <Select.Option key={typeOfClass} value={typeOfClass}>
                  {typeOfClass}
                </Select.Option>
              ))}
            </Select>
          </FormItem>
        )}
        {type === 'visitsToClass' && (
          <FormItem
            key="userVisits"
            label={getTooltip(
              'Количество Посещении',
              'Количество посещении для каждого пользователя'
            )}
            name="userVisits"
          >
            <Input type="number" placeholder="Количество Посещении" />
          </FormItem>
        )}
      </Form>
    </Modal>
  );
};

CreateWeeklyTask.propTypes = {
  title: PropTypes.string.isRequired,
  visible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired,
};

export default CreateWeeklyTask;
