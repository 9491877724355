import React, { useState, useEffect } from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import { toast } from 'react-toastify';

import Heros from './Heros';
import Loading from '../shared/Loading';
import { useLoading } from '../../context/useLoading';
import withMainLayout from '../../hocs/withMainLayout';

const GET_HEROS = gql`
  query getHero {
    allHeros {
      id
      type
      countSales
      tickets
      backgroundURL
      identifier
      name
      history
      sex
      avatarURL
      bwAvatarURL
      imageURL
      heroLevels {
        level {
          id
          name
        }
        history
        avatarURL
        imageURL
      }
    }
    levels {
      id
      name
    }
  }
`;

const DELETE_HERO = gql`
  mutation deleteHero($id: ID!) {
    deleteHero(id: $id)
  }
`;

const ADD_HERO = gql`
  mutation addHero($input: HeroInput) {
    addHero(input: $input) {
      id
      type
      countSales
      tickets
      backgroundURL
      identifier
      name
      history
      sex
      avatarURL
      bwAvatarURL
      imageURL
      heroLevels {
        level {
          id
          name
        }
        history
        avatarURL
        imageURL
      }
    }
  }
`;

const UPDATE_HERO = gql`
  mutation updateHero($id: ID!, $input: HeroInput) {
    updateHero(id: $id, input: $input) {
      id
      type
      countSales
      tickets
      backgroundURL
      identifier
      name
      history
      sex
      avatarURL
      bwAvatarURL
      imageURL
      heroLevels {
        level {
          id
          name
        }
        history
        avatarURL
        imageURL
      }
    }
  }
`;

const HeroContainer = () => {
  const [levels, setLevels] = useState(null);
  const [dataHeros, setDataHeros] = useState(null);
  const { showLoading, hideLoading } = useLoading();

  const [updateHero] = useMutation(UPDATE_HERO);

  const { data, loading, error } = useQuery(GET_HEROS);

  const [deleteHero] = useMutation(DELETE_HERO, {
    update(cache, { data: { deleteHero: id } }) {
      const { allHeros } = cache.readQuery({ query: GET_HEROS });
      cache.writeQuery({
        query: GET_HEROS,
        data: {
          allHeros: allHeros.filter(hero => hero.id !== id)
        }
      });
      hideLoading();
      toast.success('Персонаж успешно удален');
    }
  });

  const [addHero] = useMutation(ADD_HERO, {
    update(cache, { data: { addHero: hero } }) {
      const { allHeros } = cache.readQuery({ query: GET_HEROS });
      cache.writeQuery({
        query: GET_HEROS,
        data: { allHeros: allHeros.concat([hero]) }
      });
      hideLoading();
      toast.success('Персонаж успешно создан');
    }
  });

  useEffect(() => {
    if (data && !loading && !error) {
      setDataHeros(data.allHeros);
      setLevels(data.levels);
    }
  }, [data, loading, error]);

  const handleDeleteHero = heroId => {
    showLoading();
    deleteHero({ variables: { id: heroId } });
  };

  const handleAddHero = values => {
    showLoading();
    values.countSales = parseInt(values.countSales, 10);
    values.tickets = parseInt(values.tickets, 10);
    addHero({
      variables: {
        input: values
      }
    });
  };

  const handleUpdateHero = (id, values) => {
    values.countSales = parseInt(values.countSales, 10);
    values.tickets = parseInt(values.tickets, 10);
    updateHero({ variables: { id, input: values } });
  };

  if (loading && !dataHeros) {
    return <Loading />;
  }

  return (
    <Heros
      heros={dataHeros}
      levels={levels}
      deleteHeroClick={handleDeleteHero}
      addHeroClick={handleAddHero}
      updateHeroClick={handleUpdateHero}
    />
  );
};

export default withMainLayout(HeroContainer);
