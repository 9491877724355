import React from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import { toast } from 'react-toastify';

import CoefficientCompress from './CoefficientCompress';
import { useLoading } from '../../context/useLoading';
import Loading from '../shared/Loading';
import withMainLayout from '../../hocs/withMainLayout';

const GET_COEFFICIENTS = gql`
  query coefficientsCompress {
    coefficientsCompress {
      id
      type
      coefficient
    }
  }
`;

const DELETE_COEFFICIENT = gql`
  mutation deleteCoefficientCompress($id: ID!) {
    deleteCoefficientCompress(id: $id)
  }
`;

const UPDATE_COEFFICIENT = gql`
  mutation updateCoefficientCompress(
    $id: ID!
    $input: CoefficientCompressInput
  ) {
    updateCoefficientCompress(id: $id, input: $input) {
      id
      type
      coefficient
    }
  }
`;

const ADD_COEFFICIENT = gql`
  mutation addCoefficientCompress($input: CoefficientCompressInput) {
    addCoefficientCompress(input: $input) {
      id
      type
    }
  }
`;

const CoefficientsCompress = () => {
  const [coefficients, setCoefficients] = React.useState(null);
  const { showLoading, hideLoading } = useLoading();

  const { data, loading, error } = useQuery(GET_COEFFICIENTS);

  const [updateCoefficient] = useMutation(UPDATE_COEFFICIENT);
  const [deleteCoefficient] = useMutation(DELETE_COEFFICIENT, {
    update(cache, { data: { deleteCoefficient: id } }) {
      hideLoading();
      toast.success('Коэффициент сжатия успешно удалено');
    }
  });

  const [addCoefficient] = useMutation(ADD_COEFFICIENT, {
    update(cache, { data: { addCoefficient: coefficient } }) {
      const { coefficients } = cache.readQuery({
        query: GET_COEFFICIENTS
      });
      hideLoading();
      toast.success('Автоматическая нотификация успешно создана');
    }
  });

  React.useEffect(() => {
    if (data && !loading && !error) {
      setCoefficients(data.coefficientsCompress);
    }
  }, [data, loading, error]);

  const handleDeleteCoefficient = coefficientId => {
    showLoading();
    deleteCoefficient({ variables: { id: coefficientId } });
  };

  const handleUpdateCoefficient = (id, values) => {
    updateCoefficient({
      variables: {
        id,
        input: {
          input: {
            type: values.type,
            coefficient: values.coefficient
          }
        }
      }
    });
  };

  const handleAddCoefficient = values => {
    showLoading();
    addCoefficient({
      variables: {
        input: {
          type: values.type,
          coefficient: Number(values.coefficient)
        }
      }
    });
  };

  if (loading || !coefficients) {
    return <Loading />;
  }

  return (
    <CoefficientCompress
      coefficients={coefficients}
      deleteClick={handleDeleteCoefficient}
      addClick={handleAddCoefficient}
      updateClick={handleUpdateCoefficient}
    />
  );
};

export default withMainLayout(CoefficientsCompress);
