import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Form, Layout, Menu, Breadcrumb } from 'antd';

import { InfoCircleOutlined, OrderedListOutlined } from '@ant-design/icons';

import ContestGeneral from './ContestGeneral';
import ContestStages from './ContestStages';
// import ContestStages from './ContestStages'

const { Header, Sider, Content } = Layout;

const StyledBreadcrumb = styled(Breadcrumb)`
  margin: 16px 0;
`;

const StyledHeader = styled(Header)`
  background: #fff;
  border-bottom: 1px solid #d9d9d9;
  padding: 0px 24px;
`;

const StyledMenu = styled(Menu)`
  height: 100%;
  padding-top: 16px;
`;
const StyledLayout = styled(Layout)`
  padding: 0 24px 24px;
  background: #fff;
`;

const StyledContent = styled(Content)`
  padding: 24px;
  margin: 0px;
  min-height: 280px;
`;

const Contest = ({
  contest,
  handleUpdateContest,
  handleDeleteContest,
  handleAddStage,
  handleDeleteStage,
  handleUpdateStage
}) => {
  const [form] = Form.useForm();
  const [currentTab, setCurrentTab] = useState(1);

  const handleTabChange = activeKey => {
    setCurrentTab(activeKey);
  };

  const onFinish = values => {
    handleUpdateContest(values);
  };

  return (
    <Layout>
      <StyledBreadcrumb>
        <Breadcrumb.Item>
          <Link to="/contests">Конкурсы</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{contest?.name}</Breadcrumb.Item>
      </StyledBreadcrumb>
      <StyledHeader>
        <h2>Редактирование конкурса</h2>
      </StyledHeader>
      <Layout>
        <Sider>
          <StyledMenu mode="inline" defaultSelectedKeys={['1']}>
            <Menu.Item
              icon={<InfoCircleOutlined />}
              key="1"
              onClick={() => handleTabChange(1)}
            >
              Основные
            </Menu.Item>
            <Menu.Item
              icon={<OrderedListOutlined />}
              key="2"
              onClick={() => handleTabChange(2)}
            >
              Стадии конкурса
            </Menu.Item>
          </StyledMenu>
        </Sider>
        <StyledLayout>
          <StyledContent className="site-layout-background">
            {currentTab === 1 && (
              <ContestGeneral
                {...{
                  contest,
                  onFinish,
                  form,
                  handleDeleteContest
                }}
              />
            )}
            {currentTab === 2 && (
              <ContestStages
                {...{
                  contest,
                  handleAddStage,
                  handleDeleteStage,
                  handleUpdateStage
                }}
              />
            )}
          </StyledContent>
        </StyledLayout>
      </Layout>
    </Layout>
  );
};

Contest.propTypes = {
  contest: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string,
    description: PropTypes.string,
    stages: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        startTime: PropTypes.string,
        endTime: PropTypes.string,
        votingStartTime: PropTypes.string,
        votingEndTime: PropTypes.string
      })
    )
  }).isRequired,
  handleUpdateContest: PropTypes.func.isRequired,
  handleDeleteContest: PropTypes.func.isRequired,
  handleAddStage: PropTypes.func.isRequired,
  handleDeleteStage: PropTypes.func.isRequired,
  handleUpdateStage: PropTypes.func.isRequired
};

export default Contest;
