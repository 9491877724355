import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';
import 'dayjs/locale/ru';

import Chat from '../../images/svg/ChatIcon';
import EyeIcon from '../../images/svg/EyeIcon';
import PhoneIcon from '../../images/svg/PhoneIcon';
import DotIcon from '../../images/svg/DotIcon';

const Wrapper = styled.div`
  display: flex;
  margin: 1%;
`;
const Column = styled.div`
  flex-direction: column;
  padding-right: 14px;
`;
const DayCont = styled.div`
  display: flex;
  align-items: center;
  height: 48px;
  width: 180px;
  border-bottom: 1px solid #0000000D;
`;
const DayText = styled.div`
  font-size: 14px;
  font-family: 'GraphikLCGMedium';
  height: 12px;
`;
const Counts = styled.div`
  font-size: 14px;
  color: #00000080;
  margin-left: 4px;
  opacity: 0.5;
`;
const Card = styled.div`
  background-color: #F8F7F8; 
  border-radius: 4px;
  border: 1px solid #0000000D;
  margin: 8px 0px 8px 0px;
  display: flex;
  justify-content: space-between;
`;

const ColumnBody = styled.div`
  flex-direction: column;
  padding-left: 8px;
  cursor: pointer;
`;
const Nickname = styled.div`
  font-size: 10px;
  color: #00000080;
  opacity: 0.5;
`;
const PhoneNumber = styled.div`
  color: '#00000080';
  opacity: 0.5;
  font-size: 12px;
  font-family: 'GraphikLCGMedium'
`;
const FullName = styled.div`
  font-size: 12px;
  font-family: 'GraphikLCGMedium';
  margin: 4px 0px 8px 0px;
`;
const CircleCont = styled.div`
  display: flex;
  justify-content: space-between;
  width: 80px;
  margin: 8px 0px 8px 0px;
`;
const Circle = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 64px;
  background-color: #D9D9D9;
  align-content: center;
`;
const BookAtribute = styled.div`
  text-align: center;
  font-size: 14px;
  color: #00000080;
  font-family: 'GraphikLCGMedium';
  text-transform: capitalize;
`;
const InClubCont = styled.div`
  justify-content: space-between;
  display: flex;
  align-items: center;
  width: 102%
`;
const Club = styled.div`
  background-color: #6F2CFF;
  border-radius: 32px;
  align-content: center;
  height: 24px;
`;
const ClubText = styled.div`
  color: #fff;
  font-size: 10px;
  padding: 6px 8px 6px 8px;
  font-family: 'GraphikLCGMedium';
  line-height: 12px;
`;
const Event = styled.div`
  border: 1px solid #00000026;
  align-content: left;
  border-radius: 4px;
  margin: 8px 8px 8px 0px;
  width: 120%;
`;
const EventName = styled.div`
  color: black;
  font-size: 10px;
  font-family: 'GraphikLCGMedium';
  padding: 4px 0px 0px 8px;
  display: flex;
`;
const EventDate = styled.div`
  color: black;
  font-size: 8px;
  padding: 0px 0px 4px 8px;
`;
const RightColumn = styled.div`
  flex-direction: column;
  padding: 6px 4px 8px 4px;
`;
const ButtonStatus = styled.div`
  background-color: #0000001A;
  border-radius: 8px;
  align-items: center;
  padding: 4px 4px 0px 4px;
  cursor: pointer;
`;
const Row = styled.div`
  display: flex;
  align-items: center;
`;
const NickNameCont = styled.div`
  display: flex;
`;
const Identifier = styled.div`
  margin-left: auto;
  font-size: 10px;
  font-family: 'GraphikLCGMedium';
  margin-right: 10px;
`;
const HPIcon = styled.div`
  background-color: #4EBA3C;
  color: #fff;
  border-radius: 32px;
  padding: 4px;
  font-size: 8px;
  font-family: 'GraphikLCGMedium';
  align-content: center;
  justify-content: center;
  text-align: right;
  margin-left: auto;
  margin-top: 5px;
`;
dayjs.locale('ru');

const CustomCard = ({ 
  data, 
  handleChangeStatus, 
  user, 
  setShowInfo, 
  handleGetUserInfo, 
  setNewChanges,
  newChanges, 
  setSelectedUser,
  setSelectedUserZone,
  setShowInput,
  setComment,
  onRefresh,
  setIsModalOpen,
  setType
 }) => {
  // const [scrollPosition, setScrollPosition] = useState(0);

  // useEffect(() => {
  //   const handleScroll = () => {
  //     const currentScrollPosition = window.scrollY || window.pageYOffset;
  //     setScrollPosition(currentScrollPosition);

  //     if (currentScrollPosition > 1700) {
  //       onRefresh();
  //     }
  //   };

  //   window.addEventListener('scroll', handleScroll);

  //   handleScroll();
  //   return () => {
  //     window.removeEventListener('scroll', handleScroll);
  //   };
  // }, [onRefresh]);
  // console.log('scrollPosition',scrollPosition)
  
  const RenderName = (name) => {
  switch(name) {
    case 'day7': 
      return 'День 7';
    case 'day8':
      return  'День 8';
    case 'day9':
      return 'День 9';
    case 'day10':
      return 'День 10';
     case 'day6':
      return 'День 6';
      case 'day5':
        return 'День 5';
      case 'day4':
        return 'День 4';
      case 'day3':
        return 'День 3';
      case 'day2':
        return 'День 2';
      case 'day1':
          return 'День 1';
      case 'notBuy':
          return 'Не купили';
      case 'today':
          return 'Сегодня';
      case 'tomorrow':
          return 'Завтра';
      case 'wasToday':
          return 'Был сегодня';      
    default: 
      return 'В зоне риска';  
  } 
};
const today = dayjs().startOf('day');
const tomorrow = dayjs().startOf('day').add(1, 'day');
const now = dayjs();

const RenderColor = ({ user, day }) => {
  const findEvent = user.trainings.find(obj => dayjs(obj.event.startTime).isSame(now, 'day'));
  const eventDate = findEvent ? dayjs(findEvent.event.startTime) : null;
   if (user.buyHP) {
    return '#4EBA3C';
  }
  else if (day === 'redZone') {
    return '#FF5B5B';
  } else if (eventDate && eventDate.isAfter(now)) {
    return '#FFC83C';
  }
  return 'transparent';
};
return (
    <Wrapper>
      {Object.entries(data).reverse().map(([day, users], index) => (
        <Column key={index}>
          <DayCont>
            <DayText style={{ color: day === 'redZone' ? '#FF5B5B' : day === 'inClub' ? '#6F2CFF' : '#000000', }}>{RenderName(day)}</DayText>
            <Counts>{users?.length}</Counts>
          </DayCont>
          {Array.isArray(users) && users.map((obj, idx) => {
            const todayTrainings  = obj.trainings.find(evn => dayjs(evn.event.startTime).isSame(today, 'day'));
            const tomorrowTrainings = obj.trainings.find(evn => dayjs(evn.event.startTime).isSame(tomorrow, 'day'));
            const upcomingTraining = obj.trainings.find(training => {
              const startTime = dayjs(training.event.startTime);
              return startTime.isSame(today, 'day') && startTime.isAfter(now);
          });
          return (
            <Card key={idx} style={{ 
              borderTopWidth: 4, 
              borderTopColor: RenderColor({day, user: obj}),
              borderTopLeftRadius: 4, 
              borderTopRightRadius: 4,
              backgroundColor:  obj.buyHP ? '#4EBA3C1A' : day === 'redZone' ? '#FF5B5B26' : '#F8F7F8'
             }}
             >
              <ColumnBody onClick={() => {
                  handleGetUserInfo(obj.user._id);
                  setShowInfo(true);
                  setSelectedUser(obj);
                  setSelectedUserZone(day);
                  setShowInput(false);
                  setComment(null);
                }} >
                <Row>
                  {day === 'redZone' || day === 'today' || day === 'tomorrow' || day === 'wasToday' ? (
                    <>
                      <NickNameCont>
                        <Nickname>{obj.day}</Nickname>
                        <Nickname style={{marginLeft: 2, marginRight: 5}}>день</Nickname> 
                      </NickNameCont>
                      <DotIcon />
                  </> ) : null}
                  <Nickname style={{marginLeft: 5}}>{obj?.user?.nickname}</Nickname>
                   {obj.buyHP ? (<HPIcon>HP</HPIcon>) : null}
                </Row>
                <FullName>{`${obj?.user?.firstName || ""} ${obj?.user?.lastName || ""}`.trim() || "Не авторизован"}</FullName>
                <PhoneNumber>{obj?.user?.phoneNumber}</PhoneNumber>
                <InClubCont>
                  <CircleCont>
                    {obj.userEvent.slice(0, 3).map((event, i) => (
                      <Circle key={i} style={{ backgroundColor: event?.status === 'checkIn' ? '#B9EE77' : event.status === 'missed' ? '#FFD0D0' : '#D9D9D9' }}>
                        <BookAtribute>{event.event.programSet?.name.charAt(0)}</BookAtribute>
                      </Circle>
                    ))}
                    {obj?.userEvent?.length > 0 ? (
                      Array.from({ length: 3 - obj?.userEvent?.length }).map((_, i) => (
                        <Circle key={i} />
                      ))
                    ) : (
                      Array.from({ length: 3 }).map((_, i) => (
                        <Circle key={i} />
                      ))
                    )}
                  </CircleCont>
                  {upcomingTraining ? (
                    <Club style={{ backgroundColor: upcomingTraining.status === 'inClub' ? '#6F2CFF' : upcomingTraining.status === 'before' ? '#FFC83C' : 'transparent' }} >
                      <ClubText style={{color: upcomingTraining.status === 'inClub' ? '#fff' : 'black' }}>{upcomingTraining.status.status === 'inClub' && day ? 'В зале' : 'Сегодня'}</ClubText>
                    </Club>
                  ) : null}
                </InClubCont>
                {todayTrainings ? (
                  <Event>
                     <EventName>
                      {todayTrainings.event.programSet?.name}
                      <Identifier>{todayTrainings?.identifier}</Identifier>
                     </EventName>
                     <EventDate>
                      {todayTrainings?.event.startTime
                        ? dayjs(todayTrainings.event.startTime).format('DD MMMM HH:mm')
                        : ''}
                    </EventDate>
                    </Event>
                ): null}
                {tomorrowTrainings && day === 'tomorrow'? (
                  <Event>
                     <EventName>
                      {tomorrowTrainings.event.programSet?.name}
                      <Identifier>{tomorrowTrainings?.identifier}</Identifier>
                     </EventName>
                     <EventDate>
                      {tomorrowTrainings?.event.startTime
                        ? dayjs(tomorrowTrainings.event.startTime).format('DD MMMM HH:mm')
                        : ''}
                    </EventDate>
                    </Event>
                ): null}
              </ColumnBody>
              <RightColumn>
                {['writeSales', 'meetSales', 'callSales'].map((type, idx) => (
                  <React.Fragment key={idx}>
                    {obj[type]?.status && (
                      <div style={{
                        position: 'absolute',
                        width: 8,
                        height: 8,
                        borderRadius: 8,
                        backgroundColor: '#fff',
                        textAlign: 'center',
                        color: 'black',
                        fontSize: 6,
                        textTransform: 'capitalize',
                      }}>
                        {obj[type]?.sales?.username?.charAt(0)}
                      </div>
                    )}
                    <ButtonStatus style={{ margin: '4px 0' }} onClick={() => {
                      setIsModalOpen(true);
                      setType(type);
                      setSelectedUser(obj);
                      // setNewChanges(newChanges + 1);
                      // handleChangeStatus({
                      //   userId: obj.user._id, 
                      //   values: {
                      //     [type]: {
                      //       status: !obj[type]?.status,
                      //       sales: obj[type]?.status ? null : user.id, 
                      //     }
                      //   }
                      // });
                    }}>
                      {type === 'writeSales' && <Chat opacity={obj[type]?.status ? 1 : 0.5} />}
                      {type === 'meetSales' && <EyeIcon opacity={obj[type]?.status ? 1 : 0.5} />}
                      {type === 'callSales' && <PhoneIcon opacity={obj[type]?.status ? 1 : 0.5} />}
                    </ButtonStatus>
                  </React.Fragment>
                ))}
              </RightColumn>
            </Card>
      );})}
        </Column>
      ))}
    </Wrapper>
  );
};

export default CustomCard;
