import { Button, Modal } from 'antd';
import React from 'react';
import styled from 'styled-components';
import { useAuth } from '../../context/useAuth';

const Container = styled.div`
  background-color: white;
  border-radius: 8px;
`;

const Title = styled.p`
  color: rgba(0, 0, 0, 1);
  font-family: 'GraphikLCGMedium';
  font-size: 24px;
  line-height: 32px;
  text-align: center;
`;

const Section = styled.div`
  display: flex;
  justify-content: space-between;
`;

const PaySection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const SubText = styled.p`
  color: rgba(0, 0, 0, 0.5);
  font-family: 'GraphikLCGMedium';
  font-size: 14px;
  line-height: 16px;
`;

const Name = styled.p`
  color: rgba(0, 0, 0, 1);
  font-family: 'GraphikLCGRegular';
  font-size: 14px;
  line-height: 16px;
`;

const Price = styled.p`
  color: rgba(0, 0, 0, 1);
  font-family: 'GraphikLCGRegular';
  font-size: 24px;
  line-height: 32px;
`;

const ButtonOk = styled.button`
  width: 100%;
  background-color: rgba(111, 44, 255, 1);
  border-radius: 8px;
  border: none;
  height: 56px;
  color: rgba(255, 255, 255, 1);
  font-family: 'GraphikLCGMedium';
  font-size: 18px;
  line-height: 24px;
  cursor: pointer;
  &:hover {
    transition: all 0.3s;
    border: 1px solid rgba(111, 44, 255, 1);
    background-color: white;
    color: rgba(111, 44, 255, 1);
  }
`;

const ButtonCancel = styled.button`
  margin-top: 8px;
  width: 100%;
  background-color: rgba(255, 255, 255, 1);
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  height: 56px;
  color: rgba(0, 0, 0, 1);
  font-family: 'GraphikLCGMedium';
  font-size: 18px;
  line-height: 24px;
  cursor: pointer;
`;

const Confirmation = ({
  paymentModal,
  setPaymentModal,
  payment,
  totalPrice,
  name,
  selectUser,
  isReRegisterHeroPass,
  handleConfirmation,
}) => {
  const { user } = useAuth();
  const paymentMethodRender = () => {
    if (payment === 'cash') {
      return 'Наличные';
    } else if (payment === 'card') {
      return 'Карта';
    } else if (payment === 'mixed') {
      return 'Смешанный вид оплаты';
    } else if (payment === 'company') {
      return 'Оплата перечислением';
    } else return 'Каспи кредит';
  };

  return (
    <Modal
      open={paymentModal}
      centered
      footer={null}
      width={368}
      onCancel={() => setPaymentModal(false)}
    >
      <Container>
        <Title>Вы подтверждаете проведение оплаты?</Title>
        <Section
          style={{
            marginTop: 32,
            marginBottom: 8,
          }}
        >
          <SubText>Имя:</SubText>
          <Name>
            {isReRegisterHeroPass ? selectUser?.nickname : name}
          </Name>
        </Section>
        <Section style={{ marginBottom: 16 }}>
          <SubText>Администратор:</SubText>
          <Name>{user?.nickname}</Name>
        </Section>
        <PaySection>
          <SubText>Сумма к оплате</SubText>
          <Price>
            {totalPrice}
            {'₸'}
          </Price>
        </PaySection>
        <PaySection>
          <SubText>Способ оплаты</SubText>
          <Price>{paymentMethodRender()}</Price>
        </PaySection>
        <ButtonOk onClick={handleConfirmation}>
          Подтверждаю
        </ButtonOk>
        <ButtonCancel onClick={() => setPaymentModal(false)}>
          Отмена
        </ButtonCancel>
      </Container>
    </Modal>
  );
};

export default Confirmation;
