import React, { useState, useEffect } from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import { toast } from 'react-toastify';

import Clubs from './Clubs';
import Loading from '../shared/Loading';
import { useLoading } from '../../context/useLoading';
import withMainLayout from '../../hocs/withMainLayout';

const GET_CLUBS = gql`
  query getClubs {
    clubs {
      id
      name
      city
      country
      zones {
        id
      }
    }
  }
`;

const ADD_CLUB = gql`
  mutation addClub($input: ClubInput) {
    addClub(input: $input) {
      id
      name
      city
      country
      zones {
        id
      }
    }
  }
`;

const ClubsContainer = () => {
  const [dataClubs, setDataClubs] = useState(null);
  const { showLoading, hideLoading } = useLoading();

  const { data, loading, error } = useQuery(GET_CLUBS);

  const [addClub] = useMutation(ADD_CLUB, {
    update(cache, { data: { addClub: club } }) {
      const { clubs } = cache.readQuery({ query: GET_CLUBS });
      cache.writeQuery({
        query: GET_CLUBS,
        data: { clubs: clubs.concat([club]) }
      });
      hideLoading();
      toast.success('Клуб успешно создан');
    }
  });

  useEffect(() => {
    if (data && !loading && !error) {
      setDataClubs(data.clubs);
    }
  }, [data, loading, error]);

  const handleAddClub = values => {
    showLoading();
    addClub({
      variables: {
        input: values
      }
    });
  };

  if (loading || !dataClubs) {
    return <Loading />;
  }

  return (
    <>{dataClubs && <Clubs clubs={dataClubs} addClubClick={handleAddClub} />}</>
  );
};

export default withMainLayout(ClubsContainer);
