import React from 'react';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { toast } from 'react-toastify';

import GeneratorAIShadow from './GeneratorAIShadow';
import withMainLayout from '../../hocs/withMainLayout';

const GET_USERS = gql`
  query find($query: UsersQuery, $pagination: PaginationQuery) {
    findUsers(queryUsers: $query, pagination: $pagination) {
      users {
        id
        nickname
        email
        phoneNumber
      }
      total
      limit
      page
      pages
    }
  }
`;

const GET_USER_MARATHON_EVENTS = gql`
  query userMarathonEventsByUser($userId: ID) {
    userMarathonEventsByUser(userId: $userId) {
      id
      marathon {
        id
        name
        duration
      }
      marathonEvent {
        id
        startTime
        endTime
      }
    }
  }
`;

const GET_AI_SHADOW_MESSAGES = gql`
  query aiShadowMessagesByUserMarathonEventId($userMarathonEventId: ID) {
    aiShadowMessagesByUserMarathonEventId(
      userMarathonEventId: $userMarathonEventId
    ) {
      day
      text
    }
  }
`;

const GENERATE_SCHEDULE = gql`
  mutation generateSchedule($userId: ID, $userMarathonEventId: ID) {
    generateSchedule(
      userId: $userId
      userMarathonEventId: $userMarathonEventId
    ) {
      message
      status
    }
  }
`;

const GENERATE_SCENARIO = gql`
  mutation generateScenario($userId: ID, $promptDescription: String) {
    generateScenario(userId: $userId, promptDescription: $promptDescription) {
      message
      status
    }
  }
`;

const GENERATE_AI_SHADOW_MESSAGE = gql`
  mutation generateAIShadowMessage(
    $userId: ID
    $userMarathonEventId: ID
    $day: Int
  ) {
    generateAIShadowMessage(
      userId: $userId
      userMarathonEventId: $userMarathonEventId
      day: $day
    ) {
      day
      message
    }
  }
`;

const GeneratorAIShadowContainer = ({}) => {
  const [text, setText] = React.useState(null);
  const [users, setUsers] = React.useState([]);
  const [selectedUserId, setSelectedUserId] = React.useState(null);
  const [selectedUserMEId, setSelectedUserMEId] = React.useState(null);
  const [userMarathonEvents, setUserMarathonEvents] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [messagesData, setMessagesData] = React.useState([]);

  const [
    generateSchedule,
    { data: generateScheduleData, loading: generateScheduleLoading },
  ] = useMutation(GENERATE_SCHEDULE);
  const [generateScenario, { loading: generateScenarioLoading }] =
    useMutation(GENERATE_SCENARIO);

  const [generateAIShadowMessage] = useMutation(GENERATE_AI_SHADOW_MESSAGE);

  const [
    getUsers,
    {
      loading: loadingUsers,
      error: errorUsers,
      data: dataUsers,
      refetch: refetchUsers,
    },
  ] = useLazyQuery(GET_USERS);

  const [
    getMessages,
    { loading: loadingMessages, error: errorMessages, data: dataMessages },
  ] = useLazyQuery(GET_AI_SHADOW_MESSAGES);

  const [
    getUserMarathonEvents,
    {
      loading: loadingUserMarathonEvents,
      error: errorUserMarathonEvents,
      data: dataUserMarathonEvents,
    },
  ] = useLazyQuery(GET_USER_MARATHON_EVENTS);

  React.useEffect(() => {
    getUsers({
      pagination: {
        page: 1,
        limit: 20,
      },
    });
  }, []);

  React.useEffect(() => {
    if (generateScheduleData && !generateScheduleLoading) {
      const findUserMarathon = userMarathonEvents.find(
        (userMarathonEvent) =>
          `${userMarathonEvent?.id}` === `${selectedUserMEId}`
      );

      const fetchMessages = async () => {
        if (findUserMarathon?.marathon?.duration) {
          setLoading(true);
          // Ждем выполнения всех запросов через 60 секунд
          setTimeout(async () => {
            toast.success('Идет генерация собщении от AIShadow', {
              position: 'top-center',
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: false,
              pauseOnHover: true,
              draggable: false,
              theme: 'colored',
            });
            const requests = Array.from({
              length: findUserMarathon.marathon.duration,
            }).map(async (_, index) => {
              try {
                const result = await generateAIShadowMessage({
                  variables: {
                    userId: selectedUserId,
                    userMarathonEventId: selectedUserMEId,
                    day: index + 1,
                  },
                });
                return {
                  day: index + 1,
                  message: result.data.generateAIShadowMessage.message,
                };
              } catch (error) {
                console.error(
                  `Error or timeout fetching message for day ${index + 1}:`,
                  error
                );
                return null;
              }
            });
            await Promise.all(requests);
            setTimeout(() => {
              getMessages({
                variables: {
                  userMarathonEventId: selectedUserMEId,
                },
              });
              setLoading(false);
            }, 5000);

            // const successfulMessages = results.filter(
            //   (data) => data !== null && data?.message !== null
            // );

            // setMessagesData((prevMessagesData) => [
            //   ...prevMessagesData,
            //   ...successfulMessages,
            // ]);
          }, 60000); // Задержка в 60 секунд
        }
      };

      fetchMessages();
    }
  }, [generateScheduleData, generateScheduleLoading]);

  React.useEffect(() => {
    if (
      dataUserMarathonEvents &&
      dataUserMarathonEvents.userMarathonEventsByUser
    ) {
      setUserMarathonEvents(dataUserMarathonEvents.userMarathonEventsByUser);
    } else if (errorUserMarathonEvents) {
      console.log('errorUserMarathonEvents', errorUserMarathonEvents);
    }
  }, [
    loadingUserMarathonEvents,
    errorUserMarathonEvents,
    dataUserMarathonEvents,
  ]);

  React.useEffect(() => {
    if (dataMessages && dataMessages.aiShadowMessagesByUserMarathonEventId) {
      const filterData =
        dataMessages?.aiShadowMessagesByUserMarathonEventId?.filter(
          (message) => !!message?.text
        );
      setMessagesData(filterData);
    } else if (errorMessages) {
      console.log('error', errorMessages);
    }
  }, [dataMessages, loadingMessages, errorMessages]);

  React.useEffect(() => {
    if (dataUsers && dataUsers.findUsers) {
      setUsers(dataUsers.findUsers.users);
    } else if (errorUsers) {
      console.log('error', errorUsers);
    }
  }, [dataUsers, loadingUsers, errorUsers]);

  React.useEffect(() => {
    if (selectedUserId) {
      getUserMarathonEvents({
        variables: { userId: selectedUserId },
      });
    }
  }, [selectedUserId]);

  const handleGenerate = ({
    userId,
    userMarathonEventId,
    promptDescription,
  }) => {
    setMessagesData([]);
    generateSchedule({ variables: { userId, userMarathonEventId } });
    generateScenario({ variables: { userId, promptDescription } });
    toast.success('Идет генерация всех сценариев', {
      position: 'top-center',
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: false,
      pauseOnHover: true,
      draggable: false,
      theme: 'colored',
    });
  };

  return (
    <GeneratorAIShadow
      {...{
        users,
        text,
        setText,
        refetchUsers,
        loadingUsers,
        loading: generateScheduleLoading || generateScenarioLoading || loading,
        setLoading,
        userMarathonEvents,
        setUserMarathonEvents,
        setSelectedUserId,
        setSelectedUserMEId,
        handleGenerate,
        messagesData,
      }}
    />
  );
};

export default withMainLayout(GeneratorAIShadowContainer);
