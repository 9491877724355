import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Button, Descriptions } from 'antd';
import EditForm from '../shared/EditFormModal';

const TREASURE_CHEST_TYPES = [
  {
    value: 'common',
    label: 'common'
  },
  {
    value: 'uncommon',
    label: 'uncommon'
  },
  {
    value: 'legendary',
    label: 'legendary'
  }
];

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledDescriptions = styled(Descriptions)`
  width: 400px;
`;

const StyledImg = styled.img`
  width: 50px;
  height: 50px;
`;

const TreasureChestGeneral = ({
  treasureChest,
  handleUpdateChest,
  handleDeleteChest
}) => {
  const [modalVisible, setModalVisible] = useState(false);

  const fields = [
    {
      label: 'Название сундука',
      fieldName: 'name'
    },
    {
      label: 'Тип сундука',
      fieldName: 'type'
    },
    {
      label: 'Цена',
      fieldName: 'price'
    },
    {
      label: 'Иконка',
      isImage: true,
      fieldName: 'imageURL'
    }
  ];

  const editFields = [
    {
      key: 'name',
      label: 'Название сундука',
      value: treasureChest && treasureChest.name,
      tooltipText: 'Название сундука'
    },
    {
      key: 'price',
      label: 'Цена за сундук',
      tooltipText: 'Цена за сундук в гантелях',
      value: treasureChest && treasureChest.price,
      number: true
    },
    {
      key: 'imageURL',
      label: 'Иконка сундука',
      isNotRequired: true,
      tooltipText: 'Иконка сундука для приложения',
      image: true
    },
    {
      key: 'type',
      label: 'Тип сундука',
      type: 'select',
      options: TREASURE_CHEST_TYPES,
      value: treasureChest && treasureChest.type,
      tooltipText: 'Тип сундука: обычный, необычный, легендарный'
    }
  ];

  const handleEditClick = () => {
    setModalVisible(true);
  };

  const onUpdate = values => {
    handleUpdateChest(values);
    setModalVisible(false);
  };

  return (
    <MainContainer>
      <StyledDescriptions
        title="Описание сундука"
        bordered
        column={1}
        size="small"
        labelStyle={{ width: '200px' }}
        extra={<Button onClick={handleEditClick}>Редактировать</Button>}
      >
        {fields.map(field =>
          field.isImage ? (
            <Descriptions.Item label={field.label} key={field.label}>
              <StyledImg src={treasureChest[field.fieldName]} alt="No Icon" />
            </Descriptions.Item>
          ) : (
            <Descriptions.Item label={field.label} key={field.label}>
              {treasureChest[field.fieldName]}
            </Descriptions.Item>
          )
        )}
      </StyledDescriptions>
      {modalVisible && editFields && (
        <EditForm
          title="Редактировать уровень"
          visible={modalVisible}
          onCancel={() => setModalVisible(false)}
          onUpdate={onUpdate}
          onDelete={handleDeleteChest}
          fields={editFields}
        />
      )}
    </MainContainer>
  );
};

TreasureChestGeneral.propTypes = {
  treasureChest: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    imageURL: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired
  }).isRequired,
  handleUpdateChest: PropTypes.func.isRequired,
  handleDeleteChest: PropTypes.func.isRequired
};

export default TreasureChestGeneral;
