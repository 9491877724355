import React from "react";
import { Route, Navigate } from "react-router-dom";
import { useAuth } from "../context/useAuth";

const ProtectedRoute = ({ roles, children }) => {
  const { checkUserIsLoggedIn, getRole } = useAuth();
  const role = getRole();
  if (!checkUserIsLoggedIn() || !roles.includes(role)) {
    return <Navigate to="/login" replace />;
  }

  return children;
};

export default ProtectedRoute;
