import React from "react";
import PropTypes from "prop-types";
import { gql, useMutation, useQuery } from "@apollo/client";
import { toast } from "react-toastify";

import { useLoading } from "../../context/useLoading";
import UserPhoto from "./UserPhoto";
import WithMainLayout from "../../hocs/withMainLayout";
import Loading from "../shared/Loading";
import { useParams } from "react-router-dom";

const GET_USER_PHOTOS = gql`
  query getUserPhotos($userId: ID!) {
    photosByUserId(userId: $userId) {
      id
      imageURL
      compressedImageURL
      user {
        id
        nickname
      }
      stage {
        id
        stageNumber
      }
      bodyTest {
        id
        testDate
        fatPercent
      }
      contest {
        id
        name
      }
      bodyMeasurement {
        waist
      }
    }
    user(id: $userId) {
      id
      username
      nickname
      email
      occupation
      role
    }
    stages {
      id
      stageNumber
      startTime
      endTime
    }
    bodyTestsByUserId(userId: $userId) {
      id
      testDate
      fatPercent
    }
  }
`;

const ADD_USER_PHOTO = gql`
  mutation addUserPhoto($input: UserPhotoInput) {
    addUserPhoto(input: $input) {
      id
      imageURL
      compressedImageURL
      user {
        id
        nickname
      }
      bodyMeasurement {
        waist
      }
    }
  }
`;

const UPDATE_USER_PHOTO = gql`
  mutation updateUserPhoto($id: ID!, $input: UserPhotoInput) {
    updateUserPhoto(id: $id, input: $input) {
      id
      imageURL
      compressedImageURL
      user {
        id
        nickname
      }
      bodyMeasurement {
        waist
      }
    }
  }
`;

const DELETE_USER_PHOTO = gql`
  mutation deleteUserPhoto($id: ID!) {
    deleteUserPhoto(id: $id)
  }
`;

const EDIT_USER = gql`
  mutation editUser($id: ID!, $input: EditUserInput) {
    editUser(id: $id, input: $input) {
      id
      username
      nickname
      email
      occupation
    }
  }
`;

const UserPhotoContainer = ({ match }) => {
  const { userId } = useParams();
  const { showLoading, hideLoading } = useLoading();
  const [userPhotosData, setUserPhotosData] = React.useState([]);
  const [bodyTests, setBodyTests] = React.useState([]);
  const [user, setUser] = React.useState(null);
  const [stages, setStages] = React.useState([]);
  const { data, loading, error } = useQuery(GET_USER_PHOTOS, {
    variables: {
      userId
    }
  });
  const [updateUserPhoto] = useMutation(UPDATE_USER_PHOTO, {
    onCompleted: () => {
      toast.success("Фото обновлено");
      hideLoading();
    }
  });

  const [updateUser] = useMutation(EDIT_USER, {
    onCompleted: () => {
      toast.success("Информация обновлена");
      hideLoading();
    }
  });

  const [addUserPhoto] = useMutation(ADD_USER_PHOTO, {
    update(cache, { data: { addUserPhoto: addUserPhotoItem } }) {
      const { photosByUserId } = cache.readQuery({
        query: GET_USER_PHOTOS,
        variables: {
          userId
        }
      });
      cache.writeQuery({
        query: GET_USER_PHOTOS,
        variables: {
          userId
        },
        data: { photosByUserId: photosByUserId.concat([addUserPhotoItem]) }
      });
      hideLoading();
      toast.success("Фото добавлено");
    }
  });

  const [deleteUserPhoto] = useMutation(DELETE_USER_PHOTO, {
    update(cache, { data: { deleteUserPhoto: id } }) {
      const { photosByUserId } = cache.readQuery({
        query: GET_USER_PHOTOS,
        variables: {
          userId
        }
      });
      cache.writeQuery({
        query: GET_USER_PHOTOS,
        variables: {
          userId
        },
        data: {
          photosByUserId: photosByUserId.filter((photo) => photo.id !== id)
        }
      });
      hideLoading();
      toast.success("Фото успешно удален");
    }
  });

  React.useEffect(() => {
    if (data && !error && !loading) {
      setUserPhotosData(data.photosByUserId);
      setUser(data.user);
      setStages(data.stages);
      setBodyTests(data.bodyTestsByUserId);
    }
  }, [data, loading, error]);

  const handleAddUserPhoto = (values) => {
    showLoading();
    values.bodyMeasurement = {
      waist: parseInt(values?.bodyMeasurement ? values.bodyMeasurement : 0, 10)
    };
    addUserPhoto({
      variables: {
        input: { ...values, user: userId }
      }
    });
  };

  const handleDeleteUserPhoto = (userPhotoId) => {
    showLoading();
    deleteUserPhoto({ variables: { id: userPhotoId } });
  };

  const handleUpdateUserPhoto = (userPhotoId, values) => {
    showLoading();
    values.bodyMeasurement = {
      waist: parseInt(values?.bodyMeasurement ? values.bodyMeasurement : 0, 10)
    };
    updateUserPhoto({ variables: { id: userPhotoId, input: values } });
  };

  const handleUpdateUser = (occupation) => {
    updateUser({
      variables: {
        id: userId,
        input: {
          occupation
        }
      }
    });
  };

  if (loading || !userPhotosData || !user) {
    return <Loading />;
  }
  return (
    <UserPhoto
      userPhotos={userPhotosData}
      stages={stages}
      user={user}
      addUserPhotoClick={handleAddUserPhoto}
      deleteUserPhotoClick={handleDeleteUserPhoto}
      updateUserPhotoClick={handleUpdateUserPhoto}
      updateUserClick={handleUpdateUser}
      bodyTests={bodyTests}
    />
  );
};

UserPhotoContainer.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired
    })
  }).isRequired
};

export default WithMainLayout(UserPhotoContainer);
