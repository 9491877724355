import React from "react";
import PropTypes from "prop-types";
import { gql, useMutation, useQuery } from "@apollo/client";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import Loading from "../shared/Loading";
import { useLoading } from "../../context/useLoading";
import withMainLayout from "../../hocs/withMainLayout";
import Season from "./Season";

const GET_DATA = gql`
  query getData($id: ID!) {
    season(id: $id) {
      id
      name
      description
      subheading
      bannerURL
      visitsForPerfectWeek
      perfectWeekPoints
      attendancePoints
      startTime
      endTime
      weeklyTasks {
        id
      }
    }
  }
`;
const GET_SEASONS = gql`
  query getSeasons {
    seasons {
      id
      name
      description
      subheading
      bannerURL
      visitsForPerfectWeek
      perfectWeekPoints
      attendancePoints
      startTime
      endTime
      weeklyTasks {
        id
      }
    }
  }
`;

const UPDATE_SEASON = gql`
  mutation updateSeason($id: ID!, $input: SeasonInput) {
    updateSeason(id: $id, input: $input) {
      id
      name
      description
      subheading
      bannerURL
      visitsForPerfectWeek
      perfectWeekPoints
      attendancePoints
      startTime
      endTime
      weeklyTasks {
        id
      }
    }
  }
`;
const DELETE_SEASON = gql`
  mutation deleteSeason($id: ID!) {
    deleteSeason(id: $id)
  }
`;

const SeasonContainer = (props) => {
  const { id: seasonId } = useParams();
  const [seasonData, setSeasonData] = React.useState(null);
  const { showLoading, hideLoading } = useLoading();
  const navigate = useNavigate();

  const { data, loading, error } = useQuery(GET_DATA, {
    variables: { id: seasonId }
  });
  const [updateSeason] = useMutation(UPDATE_SEASON);

  const [deleteSeason] = useMutation(DELETE_SEASON, {
    update(cache, { data: { deleteSeason: id } }) {
      const { seasons } = cache.readQuery({ query: GET_SEASONS });
      cache.writeQuery({
        query: GET_SEASONS,
        data: {
          seasons: seasons.filter((season) => season.id !== id)
        }
      });
      hideLoading();
      toast.success("Сезон успешно удален");
      navigate("/seasons");
    }
  });

  React.useEffect(() => {
    if (data && data.season) {
      setSeasonData(data.season);
    }
  }, [data, loading, error]);

  if (loading)
    return (
      <div>
        <Loading />
      </div>
    );

  const handleUpdateClick = (values) => {
    const dataSeason = {
      visitsForPerfectWeek: parseInt(values.visitsForPerfectWeek, 10),
      perfectWeekPoints: parseInt(values.perfectWeekPoints, 10),
      attendancePoints: parseInt(values.attendancePoints, 10)
    };
    delete values.visitsForPerfectWeek;
    delete values.perfectWeekPoints;
    delete values.attendancePoints;
    updateSeason({
      variables: { id: seasonId, input: { ...values, ...dataSeason } }
    });
  };

  const handleDeleteClick = () => {
    showLoading();
    deleteSeason({ variables: { id: seasonId } });
  };

  return (
    <>
      {seasonData && (
        <Season
          season={seasonData}
          updateClick={handleUpdateClick}
          deleteClick={handleDeleteClick}
        />
      )}
    </>
  );
};

SeasonContainer.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired
    })
  }).isRequired
};

export default withMainLayout(SeasonContainer);
