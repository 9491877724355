import React from 'react';
import { gql, useMutation, useQuery, useLazyQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';

import EditEvent from './EditEvent';
import Loading from '../shared/Loading';
import withMainLayout from '../../hocs/withMainLayout';
import { useLoading } from '../../context/useLoading';

const GET_EVENT = gql`
  query event($id: ID!) {
    event(id: $id) {
      id
      startTime
      endTime
      limitStation
      trainer {
        id
        role
        nickname
      }
      reviews {
        id
        value
        comment
      }
      programSet {
        id
        name
        type
        zone {
          group
        }
        club {
          id
          name
        }
        stationExercises {
          id
          station {
            id
            number
          }
          exercise {
            id
            name
          }
        }
      }
    }

    programSets {
      id
      name
      club {
        id
        name
      }
    }
  }
`;

const GET_CLUBS = gql`
  query clubs {
    clubs {
      id
      name
    }
  }
`;

const GET_TRAINERS = gql`
  query trainers($role: String!) {
    usersByRole(role: $role) {
      id
      role
      nickname
    }
  }
`;

const GET_BOOKINGS = gql`
  query bookingsByEvent($input: BookingFilterInput) {
    bookingsByEvent(input: $input) {
      id
      user {
        id
        nickname
        phoneNumber
        email
        level {
          id
          name
          degree
        }
      }
      identifier
      event {
        id
      }
      group
      exercise {
        name
      }
      station {
        icon
      }
    }
  }
`;

const GET_USERS = gql`
  query find($query: UsersQuery, $pagination: PaginationQuery) {
    findUsers(queryUsers: $query, pagination: $pagination) {
      users {
        id
        nickname
        email
        phoneNumber
        level {
          id
          name
          degree
        }
      }
      total
      limit
      page
      pages
    }
  }
`;

const ADD_BOOKING = gql`
  mutation addBooking($input: BookingInput) {
    addBooking(input: $input) {
      id
      user {
        id
        nickname
      }
      identifier
    }
  }
`;

const UPDATE_BOOKING = gql`
  mutation updateBooking($id: ID!, $input: BookingInput) {
    updateBooking(id: $id, input: $input) {
      id
      station {
        id
        number
        icon
      }
      group
    }
  }
`;

const DELETE_BOOKING = gql`
  mutation deleteBooking($id: ID!) {
    deleteBooking(id: $id)
  }
`;

const CHECK_IN_USER = gql`
  mutation checkIn($input: CheckInInput) {
    checkIn(input: $input) {
      status
      message
    }
  }
`;

const UPDATE_EVENT = gql`
  mutation updateEvent($id: ID!, $input: EventInput) {
    updateEvent(id: $id, input: $input) {
      id
      programSet {
        id
        name
        club {
          id
          name
        }
      }
      status
      startTime
      endTime
      trainer {
        id
        role
        nickname
      }
    }
  }
`;

const ADD_EVENT = gql`
  mutation addEvent($input: EventInput) {
    addEvent(input: $input) {
      id
      programSet {
        id
      }
      startTime
      endTime
      trainer {
        id
        role
        nickname
      }
    }
  }
`;
const NOTIFICATION_CHANGE_TRAINER = gql`
  mutation addNotificationEvent($eventId: ID) {
    addNotificationEvent(eventId: $eventId) {
      message
    }
  }
`;

const DELETE_EVENT = gql`
  mutation deleteBooking($id: ID!) {
    deleteBooking(id: $id)
  }
`;

const EditEventContainer = ({ match }) => {
  // const { programSets } = location.state
  const navigate = useNavigate();

  const [programSets, setProgramSets] = React.useState([]);
  const { id: eventId } = useParams();
  const { showLoading, hideLoading } = useLoading();
  const [users, setUsers] = React.useState([]);
  const [event, setEvent] = React.useState({});
  const [trainers, setTrainers] = React.useState([]);
  const [bookings, setBookings] = React.useState([]);
  const [addBookingModal, setAddBookingModal] = React.useState(false);
  const [editBookingModal, setEditBookingModal] = React.useState(false);
  const [allStations, setAllStations] = React.useState([]);
  const [currentItem, setCurrentItem] = React.useState(null);
  const [objectIdentifier, setObjectIdentifier] = React.useState({});
  const [clubs, setClubs] = React.useState([]);

  const [reviews, setReviews] = React.useState([]);
  // GraphQL quiries and Mutations - start
  const { data, loading, error, refetch } = useQuery(GET_EVENT, {
    variables: {
      id: eventId,
    },
  });

  const [updateEvent] = useMutation(UPDATE_EVENT, {
    onError(err) {
      hideLoading();
      toast.error(`Error ${err.message}`);
    },
    onCompleted() {
      hideLoading();
      refetch();
      toast.success('Изменения сохранены');
    },
  });

  const [deleteEvent] = useMutation(DELETE_EVENT, {
    onError(err) {
      hideLoading();
      toast.error(`Error ${err.message}`);
    },
    onCompleted() {
      hideLoading();
      refetch();
      toast.success('Событие удалено');
      navigate('/events');
    },
  });

  const [addEvent] = useMutation(ADD_EVENT, {
    onError(err) {
      hideLoading();
      toast.error(`Error ${err.message}`);
    },
    onCompleted() {
      hideLoading();
      toast.success('Событие создано на следующую неделю');
      navigate('/events');
    },
  });
  const [notificationChangeTrainer] = useMutation(NOTIFICATION_CHANGE_TRAINER);

  const [
    getUsers,
    {
      loading: loadingUsers,
      error: errorUsers,
      data: dataUsers,
      refetch: refetchUsers,
    },
  ] = useLazyQuery(GET_USERS);

  const { data: clubsData, loading: clubsLoading } = useQuery(GET_CLUBS);

  React.useEffect(() => {
    if (clubsData && !clubsLoading) {
      setClubs(clubsData.clubs);
    }
  }, [clubsData, clubsLoading]);

  React.useEffect(() => {
    getUsers({
      pagination: {
        page: 1,
        limit: 20,
      },
    });
  }, []);

  const {
    data: bookingsData,
    loading: bookingsLoading,
    refetch: refetchBookings,
  } = useQuery(GET_BOOKINGS, {
    variables: {
      input: { event: eventId },
    },
  });

  const { data: trainersData, loading: trainersLoading } = useQuery(
    GET_TRAINERS,
    {
      variables: {
        role: 'trainer',
      },
    }
  );

  React.useEffect(() => {
    if (trainersData && !trainersLoading) {
      setTrainers(trainersData.usersByRole);
    }
  }, [trainersData, trainersLoading]);

  React.useEffect(() => {
    if (bookingsData && !bookingsLoading) {
      setBookings(bookingsData.bookingsByEvent);
      const obj = {};
      if (bookingsData.bookingsByEvent.length > 0) {
        for (let i = 0; i < bookingsData.bookingsByEvent.length; i += 1) {
          const { identifier } = bookingsData.bookingsByEvent[i];
          // const { nickname, id: userId } = bookingsData.bookingsByEvent[i].user
          const nickname = bookingsData.bookingsByEvent[i].user?.nickname;
          const userId = bookingsData.bookingsByEvent[i].user?.id;
          obj[identifier] = {
            ...bookingsData.bookingsByEvent[i].user,
            nickname,
            userId,
            bookingId: bookingsData.bookingsByEvent[i].id,
          };
          setObjectIdentifier(obj);
        }
      } else {
        setObjectIdentifier(obj);
      }
    }
  }, [bookingsData]);

  React.useEffect(() => {
    if (data && !loading && !error) {
      setEvent(data.event);
      setReviews(data.event.reviews);
      setProgramSets(data.programSets);
    } else if (error) {
      console.log('error', error);
    }
  }, [data, loading, error]);

  const [addBooking] = useMutation(ADD_BOOKING, {
    onCompleted() {
      refetchBookings();
      hideLoading();
      toast.success('Запись завершена');
    },
    onError({ message }) {
      toast.error(message);
      hideLoading();
    },
  });

  const [updateBooking] = useMutation(UPDATE_BOOKING, {
    onError(err) {
      hideLoading();
      toast.error(`Error ${err.message}`);
    },
    onCompleted() {
      refetchBookings();
      hideLoading();
      toast.success('Запись завершена');
    },
  });

  const [deleteBooking] = useMutation(DELETE_BOOKING, {
    onError(err) {
      hideLoading();
      toast.error(`Error ${err.message}`);
    },
    onCompleted() {
      refetchBookings();
      hideLoading();
      toast.success('Запись удалена');
    },
  });

  const [checkInUser] = useMutation(CHECK_IN_USER, {
    onCompleted: () => {
      hideLoading();
      toast.success('Check in successful');
    },
  });
  // GraphQL quiries and Mutations - end

  React.useEffect(() => {
    if (dataUsers && dataUsers.findUsers && !loadingUsers) {
      setUsers(dataUsers.findUsers.users);
    }
  }, [dataUsers, loadingUsers, errorUsers]);

  React.useEffect(() => {
    const arr = [];
    for (let j = 0; j < event.programSet?.stationExercises.length; j += 1) {
      for (let i = 0; i < event.programSet?.zone.group; i += 1) {
        arr.push({
          ...event.programSet?.stationExercises[j],
          identifier: `${
            event.programSet?.stationExercises[j]?.station?.number
          }${String.fromCharCode(65 + i)}`,
          zoneNumber: i + 1,
        });
      }
    }
    setAllStations(arr);
  }, [event]);

  const handleAddBooking = ({ user, stationExercise }) => {
    try {
      addBooking({
        variables: {
          input: {
            user: user.id,
            event: eventId,
            station: stationExercise.stationId,
            exercise: stationExercise.exerciseId,
            identifier: stationExercise.identifier,
            group: stationExercise.zoneNumber,
            level: user?.level?.id,
          },
        },
      });
    } catch (error) {
      console.log(error);
      toast.error('Что пошло не так');
      hideLoading();
    }
  };

  const okAddHandler = ({ user, stationExercise }) => {
    const { bookingId } = stationExercise;
    showLoading();
    handleAddBooking({ user, stationExercise });
    setAddBookingModal(false);
  };

  const deleteButtonHandler = ({ stationExercise }) => {
    showLoading();
    const { bookingId } = stationExercise;
    deleteBooking({
      variables: {
        id: bookingId,
      },
    });
    setEditBookingModal(false);
  };

  const checkInUserHandler = ({ userId }) => {
    showLoading();
    checkInUser({
      variables: {
        input: {
          userId,
          eventId,
        },
      },
    });
  };

  let dataSource = allStations.map((item) => {
    return {
      ...item,
      eventId: item.id,
      id: `${item.id}${item.identifier}`,
      station: item.identifier,
      stationName: item.exercise?.name,
      userBooking: objectIdentifier[item.identifier],
      nickname: objectIdentifier[item.identifier]?.nickname || 'Свободно',
      email: objectIdentifier[item.identifier]?.email || '',
      userId: objectIdentifier[item.identifier]?.userId,
      phoneNumber: objectIdentifier[item.identifier]?.phoneNumber,
      isEmpty: !objectIdentifier[item.identifier]?.userId,
      bookingId: objectIdentifier[item.identifier]?.bookingId,
      stationId: item?.station?.id,
      exerciseId: item?.exercise?.id,
    };
  });

  if (loading && loadingUsers && bookingsLoading) {
    return <Loading />;
  }

  return (
    <EditEvent
      event={event}
      clubs={clubs}
      trainers={trainers}
      bookings={bookings}
      stationName={currentItem?.identifier}
      programSets={programSets}
      users={users}
      addBooking={addBooking}
      updateBooking={updateBooking}
      dataSource={dataSource}
      okAddHandler={okAddHandler}
      addBookingModal={addBookingModal}
      setAddBookingModal={setAddBookingModal}
      editBookingModal={editBookingModal}
      setEditBookingModal={setEditBookingModal}
      deleteButtonHandler={deleteButtonHandler}
      checkInUserHandler={checkInUserHandler}
      reviews={reviews}
      refetchUsers={refetchUsers}
      updateEvent={updateEvent}
      addEvent={addEvent}
      deleteEvent={() => {
        toast.error('Обратитесь к Разработчикам');
      }}
      notificationChangeTrainer={notificationChangeTrainer}
    />
  );
};

EditEventContainer.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
  location: PropTypes.shape({
    state: PropTypes.shape({
      programSets: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          name: PropTypes.string,
        })
      ),
    }),
  }).isRequired,
};

export default withMainLayout(EditEventContainer);
