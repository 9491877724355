import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import withHelmet from "../../hocs/withHelmet";
import withLoginLayout from "../../hocs/withLoginLayout";
import { useAuth } from "../../context/useAuth";

const Logout = () => {
  const { logout } = useAuth();
  const navigate = useNavigate();

  React.useEffect(() => {
    logout();
    navigate("/login");
  }, []);

  return (
    <>
      <h1>Logout</h1>
    </>
  );
};

export default withHelmet([{ tag: "title", content: "Logout" }])(
  withLoginLayout(Logout)
);
