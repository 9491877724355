import * as React from "react";
const PhoneIconWithBC = (props) => (
	<svg
	width={24}
	height={24}
	viewBox="0 0 24 24"
	fill="none"
	xmlns="http://www.w3.org/2000/svg"
	{...props}
>
	<rect width={24} height={24} rx={8} fill="black" fillOpacity={0.1} />
	<path
		fillRule="evenodd"
		clipRule="evenodd"
		d="M11.2373 12.7627C10.4573 11.9827 9.86927 11.1094 9.4786 10.2234C9.39593 10.0361 9.4446 9.81674 9.58927 9.67208L10.1353 9.12674C10.5826 8.67941 10.5826 8.04674 10.1919 7.65608L9.40927 6.87341C8.8886 6.35274 8.0446 6.35274 7.52393 6.87341L7.08927 7.30808C6.59527 7.80208 6.38927 8.51474 6.5226 9.22141C6.85193 10.9634 7.86393 12.8707 9.4966 14.5034C11.1293 16.1361 13.0366 17.1481 14.7786 17.4774C15.4853 17.6107 16.1979 17.4047 16.6919 16.9107L17.1259 16.4767C17.6466 15.9561 17.6466 15.1121 17.1259 14.5914L16.3439 13.8094C15.9533 13.4187 15.3199 13.4187 14.9299 13.8094L14.3279 14.4121C14.1833 14.5567 13.9639 14.6054 13.7766 14.5227C12.8906 14.1314 12.0173 13.5427 11.2373 12.7627Z"
		stroke="black"
		strokeLinecap="round"
		strokeLinejoin="round"
	/>
</svg>
);
export default PhoneIconWithBC;
