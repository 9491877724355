import React, { useState, useEffect } from 'react';
import { gql, useQuery } from '@apollo/client';

import Messages from './Messages';
import Loading from '../shared/Loading';
import withMainLayout from '../../hocs/withMainLayout';

const GET_MESSAGES = gql`
  query getMessages {
    messages {
      id
      title
      type
    }
  }
`;

const MessagesContainer = () => {
  const [dataMessages, setDataMessages] = useState(null);

  const { data, loading, error } = useQuery(GET_MESSAGES, {
    fetchPolicy: 'no-cache'
  });

  useEffect(() => {
    if (data && !loading && !error) {
      setDataMessages(data.messages);
    }
  }, [data, loading, error]);

  if (loading && !dataMessages) {
    return <Loading />;
  }

  return <Messages messages={dataMessages} />;
};

export default withMainLayout(MessagesContainer);
