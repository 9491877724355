import React, { useState, useEffect } from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import { toast } from 'react-toastify';

import Products from './Products';
import Loading from '../shared/Loading';
import { useLoading } from '../../context/useLoading';
import withMainLayout from '../../hocs/withMainLayout';

const GET_DATA = gql`
  query getData {
    products {
      id
      name
    }
  }
`;

const GET_PRODUCT = gql`
  query getProducts {
    products {
      id
      name
    }
  }
`;

const DELETE_PRODUCT = gql`
  mutation deleteProduct($id: ID!) {
    deleteProduct(id: $id)
  }
`;

const ADD_PRODUCT = gql`
  mutation addProduct($input: ProductInput) {
    addProduct(input: $input) {
      id
      name
    }
  }
`;
const UPDATE_PRODUCT = gql`
  mutation updateProduct($id: ID!, $input: ProductInput) {
    updateProduct(id: $id, input: $input) {
      id
      name
    }
  }
`;

const ProductsContainer = () => {
  const [dataProducts, setDataProducts] = useState(null);
  const { showLoading, hideLoading } = useLoading();

  const { data, loading, error } = useQuery(GET_DATA);

  const [updateProduct] = useMutation(UPDATE_PRODUCT, {
    onCompleted: () => {
      hideLoading();
    }
  });

  const [deleteProduct] = useMutation(DELETE_PRODUCT, {
    update(cache, { data: { deleteProduct: id } }) {
      const { products } = cache.readQuery({ query: GET_PRODUCT });
      cache.writeQuery({
        query: GET_PRODUCT,
        data: {
          products: products.filter(product => product.id !== id)
        }
      });
      hideLoading();
      toast.success('Новость успешно удален');
    }
  });

  const [addProduct] = useMutation(ADD_PRODUCT, {
    update(cache, { data: { addProduct: product } }) {
      const { products } = cache.readQuery({ query: GET_PRODUCT });
      cache.writeQuery({
        query: GET_PRODUCT,
        data: { products: products.concat([product]) }
      });
      hideLoading();
      toast.success('Новость успешно создан');
    }
  });

  useEffect(() => {
    if (data && !loading && !error) {
      setDataProducts(data.products);
    }
  }, [data, loading, error]);

  const handleDeleteProduct = productId => {
    showLoading();
    console.log('productId', productId);
    deleteProduct({ variables: { id: productId } });
  };

  const handleAddProduct = values => {
    showLoading();
    addProduct({
      variables: {
        input: values
      }
    });
  };

  const handleUpdateProduct = (productId, values) => {
    showLoading();
    updateProduct({ variables: { id: productId, input: values } });
  };

  if (loading && dataProducts) {
    return <Loading />;
  }

  return (
    <>
      {dataProducts && (
        <Products
          dataProducts={dataProducts}
          deleteProductClick={handleDeleteProduct}
          addProductClick={handleAddProduct}
          updateProductClick={handleUpdateProduct}
        />
      )}
    </>
  );
};

export default withMainLayout(ProductsContainer);
