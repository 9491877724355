import React from 'react';
import styled from 'styled-components';

import SalesSupportHeader from './SalesSupportHeader';
import SalesSupportBody from './SalesSupportBody';
import SalesSupportRequestModal from './SalesSupportRequestModal';
import Loading from '../../images/svg/Loading';

const Container = styled.div`
  margin: 2% 2% 0 2%;
  flex: 1;
  background-color: #fff;
`;
const Cont = styled.div`
  display: flex;
  height: 300px;
`;
const SalesSupport = ({
  user,
  requests,
  total,
  setPage,
  page,
  setSearch,
  setFilter,
  setSelectedRequest,
  selectedRequest,
  setShowRequestModal,
  showRequestModal,
  setAnswer,
  onSubmitAdminAnswer,
  loading,
  loadingRequests,
  requestsCountData,
  refetch,
  uploadRequestPhoto,
  uploadedMediaData,
  uploadedMediaLoading
}) => {
  const renderStatus = (status) => {
    switch (status) {
      case 'inProgress':
        return `В обработке`;
      case 'moreDataForAdmin':
        return `Требуется дополнение`;
      case 'finished':
        return 'Решено';
      default:
        return 'Не прочитано';
    }
  };

  return (
    <Container>
      <SalesSupportHeader
        setFilter={setFilter}
        requestsCountData={requestsCountData}
      />
      {loadingRequests ? (
        <Cont>
          <Loading />
        </Cont>
      ) : (
        <SalesSupportBody
          user={user}
          requests={requests}
          total={total}
          setPage={setPage}
          page={page}
          setSearch={setSearch}
          setSelectedRequest={setSelectedRequest}
          setShowRequestModal={setShowRequestModal}
          renderStatus={renderStatus}
          refetch={refetch}
          uploadRequestPhoto={uploadRequestPhoto}
          uploadedMediaData={uploadedMediaData}
          uploadedMediaLoading={uploadedMediaLoading}
        />
      )}
      <SalesSupportRequestModal
        setSelectedRequest={setSelectedRequest}
        selectedRequest={selectedRequest}
        setShowRequestModal={setShowRequestModal}
        showRequestModal={showRequestModal}
        renderStatus={renderStatus}
        setAnswer={setAnswer}
        onSubmitAdminAnswer={onSubmitAdminAnswer}
        loading={loading}
      />
    </Container>
  );
};

export default SalesSupport;
