import { gql } from '@apollo/client';


export const GET_REQUEST_QUESTIONS = gql`
    query allRequestQuestions($adminId: ID, $query: PaginationQuery, $userFind: UserFind, $userId: ID) {
        allRequestQuestions(adminId: $adminId, query: $query, userFind: $userFind, userId: $userId) {
            total
            limit
            page
            pages
            requestQuestions {
                id
                status
                isAnswerViewed
                created_at
                updated_at
                lastAnswerDate
                requestCategory {
                    _id
                    name
                }
                requestSubcategory {
                    _id
                    name
                }
                queries {
                    media
                    desc
                    title
                    created_at
                    adminId {
                        id
                        nickname
                    }
                }
                assigned {
                    id
                    nickname
                    lastName
                    firstName
                }
                user {
                    id
                    username
                    role
                    nickname
                    firstName
                    phoneNumber

                }
            }
        }
    }
`;


export const UPDATE_USER_REQUEST = gql`
    mutation updatedUserRequestQuestion($id: ID!, $input: RequestQuestionInput) {
        updatedUserRequestQuestion(id: $id, input: $input) {
            id
        }
    }
`;


export const GET_REQUEST_QUESTIONS_BY_TYPE = gql`
    query AllRequestQuestionsByType($userId: ID) {
        allRequestQuestionsByType(userId: $userId ) {
            finished
            moreDataForAdmin
            notViewed
            inProgress
        }
    }
`;

export const CREATE_USER_REQUEST_QUESTION = gql`
    mutation createUserRequestQuestion($input: RequestQuestionInput!) {
        createUserRequestQuestion(input: $input) {
            id
            user {
                id
                nickname
            }
            requestCategory {
                _id
                name
            }
            requestSubcategory {
                _id
                name
            }
            status
        }
    }
`;


export const GET_REQUEST_CATEGORIES = gql`
    query GetRequestCategories {
        requestCategories {
            _id
            name
            isDeleted
        }
    }
`;


export const GET_REQUEST_SUBCATEGORIES = gql`
    query GetRequestSubcategories($categoryId: ID!) {
        findSubcategories(categoryId: $categoryId) {
            _id
            name
            requestCategory {
                _id
                name
            }
            isDeleted
        }
    }
`;


export const GET_USERS = gql`
    query find($query: UsersQuery) {
        findUsers(queryUsers: $query) {
            users {
                id
                phoneNumber
                nickname
                email
            }
        }
    }
`;

export const UPLOAD_REQUEST_PHOTO = gql`
    mutation uploadRequestPhoto($files: [Upload!]!) {
        uploadRequestPhoto(files: $files) {
            status
            fileUrls
        }
    }
`;