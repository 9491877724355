import React, { useState, useEffect } from 'react';
import { gql, useLazyQuery } from '@apollo/client';

import Loading from '../../shared/Loading';
import withMainLayout from '../../../hocs/withMainLayout';
import { useAuth } from '../../../context/useAuth';

import TrainerReviews from './TrainerReviews';

const GET_REVIEWS = gql`
  query trainerReviews($trainerId: ID) {
    trainerReviews(trainerId: $trainerId) {
      id
      value
      programSet {
        id
        name
      }
      event {
        startTime
        endTime
      }
      comment
    }
  }
`;

const TrainerReviewsContainer = () => {
  const [reviews, setReviews] = useState([]);

  const { user } = useAuth();

  const [getReviews, { data, loading, error }] = useLazyQuery(GET_REVIEWS);

  useEffect(() => {
    if (user && user.id) {
      getReviews({
        variables: {
          trainerId: user.id
        }
      });
    }
  }, [user]);

  useEffect(() => {
    if (data && data.trainerReviews) {
      setReviews(data.trainerReviews);
    }
  }, [data, loading, error]);

  if (!data || loading) {
    return <Loading />;
  }

  return <TrainerReviews reviews={reviews} />;
};

export default withMainLayout(TrainerReviewsContainer);
