import React, { useState, useEffect } from 'react';
import { gql, useQuery, useLazyQuery } from '@apollo/client';

import AllUserPayments from './AllUserPayments';
import Loading from '../shared/Loading';
import withMainLayout from '../../hocs/withMainLayout';

const GET_DATA = gql`
  query getData($query: PaginationQuery) {
    allUserPaymentsWithPagination(query: $query) {
      userPayments {
        id
        type
        user {
          id
          nickname
        }
        heroPass {
          id
          name
          price
        }
        marathonEvent {
          id
          startTime
          endTime
          marathon {
            id
            name
            price
            countClasses
          }
        }
        payment {
          id
          name
          cost
          countClasses
          ticketType
        }
        created_at
      }

      total
      limit
      page
      pages
    }
  }
`;

const AllUserPaymentsContainer = () => {
  const [userPayments, setUserPayments] = useState([]);
  const [total, setTotal] = React.useState(0);
  const [page, setPage] = React.useState(1);

  const [getData, { data, loading, error, refetch }] = useLazyQuery(GET_DATA);

  React.useEffect(() => {
    getData({
      variables: {
        query: {
          page: page,
          limit: 10
        }
      }
    });
  }, []);

  React.useEffect(() => {
    refetch({
      query: {
        page: page,
        limit: 10
      }
    });
  }, [page]);

  useEffect(() => {
    if (data && data.allUserPaymentsWithPagination && !loading) {
      setUserPayments(data.allUserPaymentsWithPagination.userPayments);
      setTotal(data.allUserPaymentsWithPagination.total);
    }
  }, [data, loading, error]);

  if (loading) {
    return <Loading />;
  }

  // const descriptivePayments = allUserPayments
  //   .filter(userPayment => {
  //     return userPayment?.payment !== null
  //   })
  //   .map(userPayment => ({
  //     ...userPayment,
  //     userNickname: userPayment?.user?.nickname,
  //     countClasses: userPayment?.payment?.countClasses,
  //     cost: userPayment?.payment?.cost,
  //     ticketType: userPayment?.payment?.ticketType
  //   }))

  return (
    <AllUserPayments
      userPayments={userPayments}
      total={total}
      page={page}
      setPage={setPage}
    />
  );
};

export default withMainLayout(AllUserPaymentsContainer);
