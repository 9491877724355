import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Modal, Form, Input, Avatar } from 'antd';

import StyledTooltip from '../shared/StyledTooltip';
import ImageUpload from '../shared/ImageUpload';

const FormItem = Form.Item;

const ImageUploadContainer = styled.div`
  display: flex;
  flex-direction: row;
`;
const StyledAvatar = styled(Avatar)`
  margin-left: 5px;
`;

const CreateUserPhoto = ({ visible, onCancel, title, onCreate }) => {
  const [form] = Form.useForm();
  const [imageURL, setImageURL] = React.useState(null);
  const [compressedImageURL, setCompressedImageURL] = React.useState(null);

  console.log({
    compressedImageURL,
    imageURL,
  });

  const getTooltip = (text, fullText) => {
    return <StyledTooltip {...{ text, fullText }} />;
  };

  return (
    <Modal
      open={visible}
      title={title}
      okText="Создать"
      cancelText="Закрыть"
      onCancel={onCancel}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            form.resetFields();
            delete values.imageURL;
            onCreate({ ...values, imageURL, compressedImageURL });
          })
          .catch((info) => {
            // eslint-disable-next-line no-console
            console.log('Validate Failed:', info);
          });
      }}
    >
      <Form form={form} layout="vertical">
        <FormItem
          key="imageURL"
          label={getTooltip('Фотка', 'Фотка')}
          name="imageURL"
          rules={[
            {
              required: false,
              message: `Пожалуйста, загрузите обложку класса`,
            },
          ]}
        >
          <ImageUploadContainer>
            <ImageUpload
              onUpdate={(value) => {
                setImageURL(value);
              }}
              onUpdateCompressed={(value) => {
                setCompressedImageURL(value);
              }}
              uploadLink="/api/uploadImageWithCompressed"
            />
            <StyledAvatar size="96" shape="square" src={imageURL} />
          </ImageUploadContainer>
        </FormItem>
        <FormItem
          key="bodyMeasurement"
          label={getTooltip('Замеры', 'Замеры пользователя')}
          name="bodyMeasurement"
        >
          <FormItem
            key={['bodyMeasurement', 'waist']}
            label={getTooltip('Талия', 'Талия')}
            name={['bodyMeasurement', 'waist']}
          >
            <Input type="number" placeholder="Талия" />
          </FormItem>
        </FormItem>
      </Form>
    </Modal>
  );
};

CreateUserPhoto.propTypes = {
  title: PropTypes.string.isRequired,
  visible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired,
};

export default CreateUserPhoto;
