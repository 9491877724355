import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Table } from 'antd';
import dayjs from 'dayjs';
import 'dayjs/locale/ru';

dayjs.locale('ru');

const AllDroppedRewards = ({ allDroppedRewards, total, page, setPage }) => {
  const getItemName = (item) => {
    switch (item.type) {
      case 'treasureChest':
        return (
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <Link to={`/treasureChests/${item?.treasureChest?.id}`}>
            {`Сундук: ${item?.treasureChest?.name}`}
          </Link>
        );

      case 'epicHero':
        return `Герой ${item?.epicHero?.name}`;
      default:
        return '';
    }
  };

  const getRewardName = (reward) => {
    if (!reward) {
      return 'no reward';
    }

    switch (reward.type) {
      case 'dumbbell':
        return `Гантели: ${reward.changeValue}`;

      case 'ticket':
        return `Билеты: ${reward.changeValue}`;
      case 'guestTicket':
        return `Гостевые билеты: ${reward.changeValue}`;

      case 'treasureChest':
        return reward.name;

      case 'epicHero':
        return reward.name;

      default:
        return '';
    }
  };
  const columns = [
    {
      title: 'Клиент',
      width: '20%',
      render: (_, item) => (
        <span>
          <Link to={`/users/${item?.user?.id}`}>{item?.user?.nickname}</Link>
        </span>
      ),
    },
    {
      title: 'Предмет',
      width: '20%',
      render: (_, item) => {
        return <span> {getItemName(item)}</span>;
      },
    },
    {
      title: 'Дата получения предмета',
      render: (_, item) => {
        return (
          <span> {dayjs(item?.created_at).format('Do MMMM HH:mm, YYYY')}</span>
        );
      },
    },
    {
      title: 'Выпало',
      render: (_, item) => {
        return <span> {getRewardName(item?.droppedReward)}</span>;
      },
    },
    {
      title: 'Дата использования предмета',
      render: (_, item) => {
        if (item.isUsed) {
          return (
            <span>
              {' '}
              {dayjs(item?.usedDate || item?.updated_at).format(
                'Do MMMM HH:mm, YYYY'
              )}
            </span>
          );
        }
        return '';
      },
    },
  ];

  return (
    <Table
      dataSource={allDroppedRewards}
      columns={columns}
      rowKey={(item) => item.id}
      pagination={{
        defaultPageSize: 10,
        total: total,
        current: page,
        onChange: (page, pageSize) => {
          setPage(page);
        },
        showSizeChanger: false,
      }}
    />
  );
};

AllDroppedRewards.propTypes = {
  allDroppedRewards: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
};

export default AllDroppedRewards;
