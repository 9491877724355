import * as React from "react";
const InClubTodayIcon = (props) => (
  <svg
    width={48}
    height={24}
    viewBox="0 0 48 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width={48} height={24} rx={12} fill="#FFC83C" />
    <path
      d="M7.45 15.1C5.21 15.1 3.96 13.67 3.96 11.47V11.39C3.96 9.24 5.43 7.74 7.43 7.74C9.07 7.74 10.34 8.56 10.5 10.24H9.15C9 9.28 8.48 8.8 7.44 8.8C6.19 8.8 5.36 9.78 5.36 11.38V11.46C5.36 13.07 6.12 14.02 7.45 14.02C8.45 14.02 9.13 13.52 9.28 12.51H10.58C10.37 14.24 9.16 15.1 7.45 15.1ZM13.9321 15.1C12.3821 15.1 11.2821 14.08 11.2821 12.44V12.36C11.2821 10.74 12.3921 9.66 13.8821 9.66C15.1821 9.66 16.3521 10.43 16.3521 12.3V12.65H12.5221C12.5621 13.65 13.0521 14.2 13.9621 14.2C14.6921 14.2 15.0721 13.91 15.1621 13.4H16.3321C16.1621 14.51 15.2821 15.1 13.9321 15.1ZM15.1521 11.84C15.0821 10.93 14.6421 10.54 13.8821 10.54C13.1521 10.54 12.6621 11.03 12.5421 11.84H15.1521ZM18.6429 10.67V15H17.4329V9.77H20.9229V10.67H18.6429ZM24.0795 14.16C24.9895 14.16 25.5195 13.5 25.5195 12.42V12.35C25.5195 11.27 24.9895 10.61 24.0795 10.61C23.1695 10.61 22.6295 11.26 22.6295 12.34V12.42C22.6295 13.5 23.1595 14.16 24.0795 14.16ZM24.0695 15.1C22.5195 15.1 21.3895 14.04 21.3895 12.44V12.36C21.3895 10.74 22.5395 9.66 24.0795 9.66C25.5995 9.66 26.7595 10.73 26.7595 12.33V12.41C26.7595 14.04 25.6095 15.1 24.0695 15.1ZM29.1063 12.78C29.0363 13.37 28.9563 13.78 28.7963 14.1H30.8263V10.66H29.2363C29.2063 11.3 29.1763 12.14 29.1063 12.78ZM28.1463 16.27H27.1163V14.1H27.4463C27.8163 13.97 27.9363 13.46 28.0263 12.7C28.1363 11.81 28.1863 10.46 28.1863 9.77H32.0263V14.1H32.7863V16.27H31.7563L31.7163 15H28.1863L28.1463 16.27ZM37.0434 12.78H34.8734V15H33.6634V9.77H34.8734V11.88H37.0434V9.77H38.2534V15H37.0434V12.78ZM41.6988 10.65C41.0488 10.65 40.7288 10.91 40.7288 11.44V11.47C40.7288 11.99 41.0088 12.25 41.6988 12.25H42.4388V10.65H41.6988ZM40.4788 15H39.1488L40.5788 12.85C39.9688 12.66 39.5088 12.26 39.5088 11.46V11.43C39.5088 10.34 40.2988 9.77 41.5387 9.77H43.6388V15H42.4388V13.08H41.7188L40.4788 15Z"
      fill="black"
    />
  </svg>
);
export default InClubTodayIcon;
