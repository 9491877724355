import React from 'react';
import PropTypes from 'prop-types';
import { gql, useLazyQuery } from '@apollo/client';

import { Table, Tag, Button, Divider } from 'antd';
import styled from 'styled-components';
import dayjs from 'dayjs';
import 'dayjs/locale/ru';

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const GET_DATA = gql`
  query getData($userId: ID, $query: PaginationQuery) {
    userClubEntryWithPaginate(userId: $userId, query: $query) {
      userClubEntries {
        club {
          name
        }
        events {
          programSet {
            name
            type
          }
          startTime
        }
        timeEnter
        timeExit
        created_at
      }
      total
      limit
      page
      pages
    }
  }
`;

const UserClubEntries = ({ userId }) => {
  const [userClubEntries, setUserClubEntries] = React.useState([]);
  const [total, setTotal] = React.useState(0);
  const [page, setPage] = React.useState(1);
  dayjs.locale('ru');

  const [getData, { data, error, loading, refetch }] = useLazyQuery(GET_DATA, {
    fetchPolicy: 'no-cache',
  });

  React.useEffect(() => {
    getData({
      variables: {
        userId: userId,
        query: {
          page: page,
          limit: 10,
        },
      },
    });
  }, []);

  React.useEffect(() => {
    refetch({
      userId: userId,
      query: {
        page: page,
        limit: 10,
      },
    });
  }, [page]);

  React.useEffect(() => {
    if (data && data.userClubEntryWithPaginate && !loading) {
      setUserClubEntries(data.userClubEntryWithPaginate.userClubEntries);
      setTotal(data.userClubEntryWithPaginate.total);
    } else if (error) {
      console.log(`error `, error);
    }
  }, [data, loading, error]);

  const userPaymentColumns = [
    {
      title: 'Клуб',
      key: 'club',
      render: (item) => {
        return <span>{item?.club?.name}</span>;
      },
    },
    {
      title: 'Тренировки',
      key: 'events',
      render: (item) => {
        const allColors = {
          fullBody: 'blue',
          upperBody: 'green',
          legs: 'orange',
          functional: 'lime',
          gluteLab: 'magenta',
          push: 'red',
          pull: 'red',
          armBlast: 'magenta',
          endGame: 'gold',
          assessment: 'cyan',
          education: 'skyblue',
          guest: 'purple',
          strength: 'purple',
          streetWorkout: 'purple',
          bootcamp: 'skyblue',
        };

        return item?.events?.map((event, index) => {
          return (
            <Tag color={allColors[event?.programSet?.type]}>
              {event?.programSet?.name}(
              {dayjs(event?.startTime).format('DD.MM.YYYY HH:mm')})
            </Tag>
          );
        });
      },
    },
    {
      title: 'Зашел-Вышел',
      key: 'duration',
      render: (item) => {
        return (
          <span>
            {' '}
            {dayjs(item?.timeEnter).format('DD.MM.YYYY HH:mm')} -{' '}
            {dayjs(item?.timeExit).format('DD.MM.YYYY HH:mm')}
          </span>
        );
      },
    },
  ];

  return (
    <MainContainer>
      <h2> Время в зале </h2>
      <Table
        dataSource={userClubEntries}
        columns={userPaymentColumns}
        rowKey={(item) => item.id}
        loading={loading}
        pagination={{
          defaultPageSize: 10,
          total: total,
          current: page,
          onChange: (page, pageSize) => {
            setPage(page);
          },
          showSizeChanger: false,
        }}
      />
    </MainContainer>
  );
};

UserClubEntries.propTypes = {
  userId: PropTypes.string.isRequired,
};

export default UserClubEntries;
