import React, { useState, useEffect } from 'react';
import { gql, useQuery } from '@apollo/client';
import PropTypes from 'prop-types';

import ReceptionEvent from './ReceptionEvent';
import Loading from '../../shared/Loading';

const GET_DATA = gql`
  query usersExitStatusByEvent($eventId: ID!) {
    usersExitStatusByEvent(eventId: $eventId) {
      id
      nickname
      email
      phoneNumber
      hasEntered
      hasExited
    }
  }
`;

const ReceptionEventContainer = ({ eventId }) => {
  const [users, setUsers] = useState([]);

  const { data, loading, error } = useQuery(GET_DATA, {
    variables: {
      eventId
    }
  });

  useEffect(() => {
    if (data) {
      setUsers(data.usersExitStatusByEvent);
    }    
  }, [data, loading, error]);

  if (error) {
    return <h1> Произошла ошибка</h1>;
  }

  if (loading) {
    return <Loading />;
  }

  return <> {users && <ReceptionEvent users={users} />} </>;
};

ReceptionEventContainer.propTypes = {
  eventId: PropTypes.string.isRequired
};

export default ReceptionEventContainer;
