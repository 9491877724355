import React from "react";
import styled from 'styled-components';
import { gql, useQuery } from '@apollo/client';
import dayjs from 'dayjs';
import { Table } from 'antd';
import { Link } from 'react-router-dom';

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const StyledTable = styled(Table)`
  margin-top: 24px;
`;


const GET_EVENTS_BY_MARATHON_EVENT_ID = gql`
  query GetEventsByMarathonEventId($marathonEventId: ID!) {
    getEventsByMarathonEventId(marathonEventId: $marathonEventId) {
        id
        endTime
        type
        trainer {
            id
            firstName
            lastName
            nickname
        }
        programSet {
            id
            name
        }
        startTime
    }
  }
`;

const ActiveMarathons = ({marathonEvent}) => {
    const { data, loading } = useQuery(GET_EVENTS_BY_MARATHON_EVENT_ID, {
        variables: { marathonEventId: marathonEvent?.id },
    });
    
    const columns = [
        {
            title: 'Название тренировки',
            render: (_, item) => {
                const programName = item?.programSet?.name;
                return <span>{programName}</span>;
            },
            width: '25%',
        },
        {
            title: 'Тренер',
            width: '25%',
            render: (_, item) => {
                const trainerName = item?.trainer?.nickname;
                return <span> {trainerName}</span>;
            },
        },
        {
            title: 'Начало тренировки',
            render: (_, item) => {
                const eventDate = dayjs(item?.startTime).format('Do MMMM, YYYY');
                return <span>{eventDate}</span>;
            },
            width: '20%',
        },
        {
            title: 'Конец тренировки',
            render: (_, item) => {
                const eventDate = dayjs(item?.endTime).format('Do MMMM, YYYY');
                return <span>{eventDate}</span>;
            },
            width: '30%',
        },
        {
            title: 'Действие',
            key: 'action',
            render: (text, item) => {
                return (
                    <Link to={`/events/${item.id}`}>
                        Посмотреть
                    </Link>
                );
            },
        },
    ];

    return (
        <MainContainer>
            <StyledTable
                loading={loading}
                dataSource={data?.getEventsByMarathonEventId}
                columns={columns}
                rowKey={(item) => item?.id}
            />
        </MainContainer>
    );
};

export default ActiveMarathons;