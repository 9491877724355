import React, { useEffect } from 'react';
import { 
    Modal, 
    Form, 
    AutoComplete,
    Row,
    Space,
    Button,
    Typography
} from 'antd';

const InBodyEdit = ({
    openEdit,
    setOpenEdit,
    item,
    onFinishEdit,
    onFinishEditFailed,
    handleUserEditChange,
    users,
    setSearchTerm
}) => {
    const [form] = Form.useForm();

    const handleCancel = () => {
        setOpenEdit(false);
        setSearchTerm('');
        form.setFieldsValue({
            phoneNumber: ''
        });
    };

    useEffect(() => {
        form.setFieldsValue({
            phoneNumber: item?.user ? `${item?.user?.phoneNumber} (${item?.user?.nickname})` : ''
        });
    }, [item, form]);

    return (
        <Modal
            title={'Изменить пользователя'}
            open={openEdit}
            onCancel={handleCancel}
            footer={null}
        >
            <Row style={{marginBottom: '15px'}}>
                {
                    item?.user ? 
                    <Typography style={{fontSize: '16px'}}>
                        Текущий пользователь: {item?.user?.phoneNumber} {`(${item?.user?.nickname})`}
                    </Typography>
                    :
                    <Typography style={{fontSize: '16px'}}>
                        Пользователь не закреплен
                    </Typography>
                }
            </Row>

            <Form
                form={form}
                name="basic"
                labelCol={{ span: 3 }}
                onFinish={(values) => {
                    const purePhoneNumber = values?.phoneNumber?.split(' ')[0];
                    onFinishEdit(purePhoneNumber, item);
                }}
                onFinishFailed={onFinishEditFailed}
                autoComplete="off"
            >
                <Form.Item label="Номер" name="phoneNumber">
                    <AutoComplete
                        options={users?.map(user => ({
                            value: `${user?.phoneNumber} (${user?.nickname})`,
                            label: `${user?.phoneNumber} (${user?.nickname})`,
                        }))}
                        style={{ width: '100%' }}
                        placeholder="Номер"
                        onChange={handleUserEditChange}
                    />
                </Form.Item>

                <Form.Item label={null}>
                    <Row justify={'end'}>
                        <Space>
                            <Button onClick={handleCancel}>
                                Отмена
                            </Button>
                            <Button type="primary" htmlType="submit">
                                Изменить
                            </Button>
                        </Space>
                    </Row>
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default InBodyEdit;