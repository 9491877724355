import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Table, Button } from 'antd';
import { Link } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';

import CreateForm from '../shared/CreateForm';

const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;
const HeaderTitle = styled.div`
  font-family: SF Pro Display;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;

  display: flex;
  align-items: center;

  color: #262626;
`;

const Zones = ({ zones, clubId, addZoneClick }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const columns = [
    {
      title: 'Название',
      dataIndex: 'name'
    },
    {
      title: 'Действие',
      key: 'action',
      render: (text, item) => (
        <span>
          <Link to={`/clubs/${clubId}/${item.id}`}>Редактировать</Link>
        </span>
      )
    }
  ];

  const fields = [
    {
      key: 'name',
      label: 'Название зоны',
      tooltipText: 'Название зоны'
    },
    {
      key: 'group',
      label: 'Количество групп станций',
      number: true,
      tooltipText: 'Количество групп станций'
    }
  ];

  const handleCreate = values => {
    addZoneClick(values);
    setModalVisible(false);
  };

  return (
    <>
      <Table
        dataSource={zones}
        columns={columns}
        title={() => (
          <Header>
            <HeaderTitle>Станции</HeaderTitle>
            <Button type="primary" onClick={() => setModalVisible(true)}>
              <PlusOutlined /> Новая зона
            </Button>
          </Header>
        )}
      />
      <CreateForm
        title="Добавить новую зону"
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        onCreate={handleCreate}
        fields={fields}
      />
    </>
  );
};

Zones.propTypes = {
  zones: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string
  }).isRequired,
  clubId: PropTypes.string.isRequired,
  addZoneClick: PropTypes.func.isRequired
};

export default Zones;
