import React, { useContext, useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { Table, Input, Form } from 'antd'

const EditableContext = React.createContext(null)

const EditableCellContainer = styled.div`
  cursor: pointer;
  padding-right: 24px;
`

const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm()
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  )
}

const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false)
  const inputRef = useRef(null)
  const form = useContext(EditableContext)
  useEffect(() => {
    if (editing) {
      inputRef.current.focus()
    }
  }, [editing])

  const toggleEdit = () => {
    setEditing(!editing)
    form.setFieldsValue({
      [dataIndex]: record[dataIndex]
    })
  }

  const save = async () => {
    try {
      const values = await form.validateFields()
      toggleEdit()
      handleSave({ ...record, ...values })
    } catch (errInfo) {
      console.log('Save failed:', errInfo)
    }
  }

  let childNode = children

  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{
          margin: 0
        }}
        name={dataIndex}
        rules={[
          {
            required: true,
            message: `${title} is required.`
          }
        ]}
      >
        <Input ref={inputRef} onPressEnter={save} onBlur={save} />
      </Form.Item>
    ) : (
      <EditableCellContainer onClick={toggleEdit}>
        {children}
      </EditableCellContainer>
    )
  }

  return <td {...restProps}>{childNode}</td>
}

class EditableTable extends React.Component {
  constructor(props) {
    super(props)
    this.columns = [
      {
        title: 'Класс',
        dataIndex: 'levelName',
        width: '30%'
      },
      {
        title: 'Подходы',
        dataIndex: 'sets',
        editable: true
      },
      {
        title: 'Повторения',
        dataIndex: 'reps',
        editable: true
      },
      {
        title: 'Мин.вес (кг_',
        dataIndex: 'minWeight',
        editable: true
      },
      {
        title: 'Макс.вес (кг)',
        dataIndex: 'maxWeight',
        editable: true
      }
    ]
    const exerciseDetails = props.dataSource.map(exerciseDetail => {
      return {
        ...exerciseDetail,
        levelName: exerciseDetail?.level?.name,
        levelId: exerciseDetail?.level?.id
      }
    })
    console.log('exerciseDetails', exerciseDetails)
    this.state = {
      dataSource: exerciseDetails
    }
  }

  handleSave = row => {
    const newData = [...this.state.dataSource]

    const index = newData.findIndex(item => row.levelId === item.levelId)

    const item = newData[index]

    newData.splice(index, 1, { ...item, ...row })

    this.setState({
      dataSource: newData
    })
    this.props.setCurrentStation({
      ...this.props.currentStation,
      exerciseDetails: newData
    })
  }

  render() {
    const { dataSource } = this.state
    const components = {
      body: {
        row: EditableRow,
        cell: EditableCell
      }
    }
    const columns = this.columns.map(col => {
      if (!col.editable) {
        return col
      }

      return {
        ...col,
        onCell: record => ({
          record,
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          handleSave: this.handleSave
        })
      }
    })
    return (
      <Table
        components={components}
        rowClassName={() => 'editable-row'}
        bordered
        dataSource={dataSource}
        columns={columns}
        rowKey={item => item.levelId}
        pagination={false}
      />
    )
  }
}

export default EditableTable
