import { Modal } from 'antd';

const confirmModal = Modal.confirm;

const showConfirm = func => {
  confirmModal({
    title: 'Вы уверены, что хотите удалить эту запись?',
    content: 'Эта запись будет удалена',
    okType: 'primary',
    onOk: () => func(),
    // eslint-disable-next-line no-console
    onCancel: () => console.log('Cancel'),
    cancelText: 'Отменить',
    okText: 'Удалить'
  });
};

export default showConfirm;
