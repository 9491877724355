import * as React from "react";
const EyeIconWithBC = (props) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width={24} height={24} rx={8} fill="black" fillOpacity={0.1} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.0785 12.3115C5.97383 12.1175 5.97383 11.8822 6.0785 11.6882C7.33983 9.3555 9.66983 7.3335 11.9998 7.3335C14.3298 7.3335 16.6598 9.3555 17.9212 11.6888C18.0258 11.8828 18.0258 12.1182 17.9212 12.3122C16.6598 14.6448 14.3298 16.6668 11.9998 16.6668C9.66983 16.6668 7.33983 14.6448 6.0785 12.3115Z"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.4142 10.5858C14.1953 11.3668 14.1953 12.6332 13.4142 13.4142C12.6332 14.1953 11.3668 14.1953 10.5858 13.4142C9.80474 12.6332 9.80474 11.3668 10.5858 10.5858C11.3668 9.80474 12.6332 9.80474 13.4142 10.5858"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default EyeIconWithBC;
