import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Modal, Form, Select } from 'antd';
import { DatePicker } from 'antd';
import dayjs from 'dayjs';

import StyledTooltip from '../shared/StyledTooltip';
import datePickerLocale from 'antd/es/date-picker/locale/ru_RU';

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const AddHeroPassModal = ({
  modalVisible,
  onCancel,
  heroPasses,
  handleAddHeroPass,
}) => {
  const [form] = Form.useForm();
  const [heroPassId, setHeroPassId] = React.useState(null);

  const getTooltip = (text, fullText) => {
    return <StyledTooltip {...{ text, fullText }} />;
  };

  return (
    <Modal
      open={modalVisible}
      onCancel={() => {
        form.resetFields();
        onCancel();
      }}
      okButtonProps={{
        disabled: !heroPassId,
        color: !heroPassId ? 'gray' : 'blue',
      }}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            form.resetFields();
            const { heroPass, startTime } = values;
            console.log('value', values);
            onCancel();
            handleAddHeroPass({
              heroPassId: heroPass,
              startTime: startTime,
            });
          })
          .catch((info) => {
            console.log('Validate Failed:', info);
          });
      }}
      cancelText="Отмена"
    >
      <MainContainer>
        <Form form={form} layout="vertical">
          <Form.Item
            key="heroPass"
            label={getTooltip('Тип Hero Pass', 'На какой срок Hero Pass?')}
            name="heroPass"
          >
            <Select
              mode="single"
              onChange={(value) => {
                setHeroPassId(value);
              }}
              placeholder="Выберите Hero`s Pass"
            >
              {heroPasses.map((heroPass) => (
                <Select.Option key={heroPass?.id} value={heroPass?.id}>
                  {heroPass?.name}| Стоимость: {heroPass?.price} тг
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            key="startTime"
            label={getTooltip('Дата', 'Дата начала HeroPass')}
            name="startTime"
            initialValue={dayjs()}
          >
            <DatePicker
              locale={datePickerLocale}
              placeholder="Выберите дату начала"
              format="DD.MM.YYYY"
              style={{
                width: '100%',
              }}
            />
          </Form.Item>
        </Form>
      </MainContainer>
    </Modal>
  );
};

AddHeroPassModal.propTypes = {
  onCancel: PropTypes.func.isRequired,
  modalVisible: PropTypes.bool.isRequired,
  heroPasses: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      price: PropTypes.number.isRequired,
    })
  ).isRequired,
  handleAddTicketsBySales: PropTypes.func.isRequired,
};

export default AddHeroPassModal;
