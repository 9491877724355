import React from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import PropTypes from "prop-types";

import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import ruLocale from "@fullcalendar/core/locales/ru";

function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();
    return <Component {...props} router={{ location, navigate, params }} />;
  }

  return ComponentWithRouterProp;
}

const CalendarContainer = styled.div`
  background: #fff;
`;

const TrainerEvents = ({ events, history }) => {
  // date formatting
  const descriptiveEvents = events?.map((event) => {
    return {
      ...event,
      programName: event?.programSet?.name,
      clubName: event?.programSet?.club?.name
    };
  });

  const myEventsList = descriptiveEvents?.map((event) => ({
    title: event?.programName,
    start: Date?.parse(event?.startTime),
    end: Date?.parse(event?.endTime),
    id: event?.id
  }));

  const handleCalendarClick = (event) => {
    const eventId = event?.event?._def?.publicId;
    history?.push(`/trainerEvents/${eventId}`);
  };

  return (
    <>
      <CalendarContainer>
        <FullCalendar
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
          initialView="dayGridMonth"
          views={["dayGridMonth", "timeGridWeek", "timeGridDay"]}
          viewClassNames="dayGridMonth"
          headerToolbar={{
            start: "prev next title",
            center: "today",
            end: "dayGridMonth timeGridWeek timeGridDay"
          }}
          slotLabelFormat={{ hour: "numeric", minute: "2-digit" }}
          eventTimeFormat={{
            hour: "2-digit",
            minute: "2-digit",
            hour12: false
          }}
          locale={ruLocale}
          eventClick={handleCalendarClick}
          events={myEventsList}
        />
      </CalendarContainer>
    </>
  );
};

TrainerEvents.propTypes = {
  events: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired
};

export default withRouter(TrainerEvents);
