import React, { useState, useEffect } from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import { toast } from 'react-toastify';

import Contests from './Contests';
import Loading from '../shared/Loading';
import { useLoading } from '../../context/useLoading';
import withMainLayout from '../../hocs/withMainLayout';

const GET_CONTESTS = gql`
  query contests {
    contests {
      id
      stages {
        id
        startTime
        endTime
      }
      name
      description
    }
  }
`;

const DELETE_CONTEST = gql`
  mutation deleteContest($id: ID!) {
    deleteContest(id: $id)
  }
`;

const ADD_CONTEST = gql`
  mutation addContest($input: ContestInput) {
    addContest(input: $input) {
      id
      name
      description
    }
  }
`;

const ContestsContainer = () => {
  const [dataContests, setDataContests] = useState([]);

  const { showLoading, hideLoading } = useLoading();

  const { data, loading, error } = useQuery(GET_CONTESTS);

  const [deleteContest] = useMutation(DELETE_CONTEST, {
    update(cache, { data: { deleteContest: id } }) {
      const { contests } = cache.readQuery({ query: GET_CONTESTS });
      cache.writeQuery({
        query: GET_CONTESTS,
        data: {
          contests: contests.filter(contest => contest.id !== id)
        }
      });
      hideLoading();
      toast.success('Конкурс успешно удален');
    }
  });

  const [addContest] = useMutation(ADD_CONTEST, {
    update(cache, { data: { addContest: contest } }) {
      const { contests } = cache.readQuery({ query: GET_CONTESTS });
      cache.writeQuery({
        query: GET_CONTESTS,
        data: { contests: contests.concat([contest]) }
      });
      hideLoading();
      toast.success('Упражнение успешно создано');
    }
  });

  useEffect(() => {
    if (data) {
      setDataContests(data.contests);
    }
  }, [data, loading, error]);

  const handleAddContest = values => {
    showLoading();
    addContest({
      variables: {
        input: values
      }
    });
  };

  const handleDeleteContest = contestId => {
    showLoading();
    deleteContest({ variables: { id: contestId } });
  };

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <h1> error occured {error.graphQLErrors[0].message} </h1>;
  }

  return (
    <>
      {dataContests && (
        <Contests
          {...{ contests: dataContests, handleAddContest, handleDeleteContest }}
        />
      )}
    </>
  );
};

export default withMainLayout(ContestsContainer);
