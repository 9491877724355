import React, { useState, useEffect } from 'react';
import { gql, useLazyQuery } from '@apollo/client';

import AllDroppedRewards from './AllDroppedRewards';
import Loading from '../shared/Loading';
import withMainLayout from '../../hocs/withMainLayout';

const GET_DATA = gql`
  query getData($query: PaginationQuery) {
    allDroppedRewardsWithPagination(query: $query) {
      items {
        id
        type
        isUsed
        created_at
        updated_at
        usedDate
        treasureChest {
          id
          name
        }
        user {
          id
          nickname
        }
        droppedReward {
          id
          type
          name
          changeValue
          ... on RewardTreasureChest {
            treasureChest {
              id
              name
            }
          }
          ... on RewardEpicHero {
            id
            epicHero {
              id
              name
            }
          }
        }
      }

      total
      limit
      page
      pages
    }
  }
`;

const AllDroppedRewardsContainer = () => {
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [items, setItems] = useState([]);

  const [getData, { data, loading, error, refetch }] = useLazyQuery(GET_DATA);

  React.useEffect(() => {
    getData({
      variables: {
        query: {
          page: page,
          limit: 10
        }
      }
    });
  }, []);

  React.useEffect(() => {
    refetch({
      query: {
        page: page,
        limit: 10
      }
    });
  }, [page]);

  useEffect(() => {
    if (data && data.allDroppedRewardsWithPagination && !loading) {
      setItems(data.allDroppedRewardsWithPagination.items);
      setTotal(data.allDroppedRewardsWithPagination.total);
    }
  }, [data, loading, error]);

  if (loading) {
    return <Loading />;
  }

  return (
    <AllDroppedRewards
      allDroppedRewards={items}
      total={total}
      page={page}
      setPage={setPage}
    />
  );
};

export default withMainLayout(AllDroppedRewardsContainer);
