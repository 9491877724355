import React from "react";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import { Button } from "antd";
import { FileExcelOutlined } from "@ant-design/icons";

const ExportExcel = ({data, styles}) => {
    const handleExport = () => {
        const worksheet = XLSX.utils.json_to_sheet(data);

        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

        const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });

        const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
        saveAs(blob, "data.xlsx");
    };

    return (
        <Button 
            icon={<FileExcelOutlined />} 
            onClick={handleExport}
            style={styles}
        >
            Скачать Excel
        </Button>
    );
};

export default ExportExcel;