import React from 'react';
import PropTypes from 'prop-types';
import { gql, useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { toast } from 'react-toastify';

import { Table, Tag, Button, Modal, Form, Input, DatePicker } from 'antd';
import styled from 'styled-components';
import dayjs from 'dayjs';
import 'dayjs/locale/ru';
import datePickerLocale from 'antd/es/date-picker/locale/ru_RU';

import { useLoading } from '../../context/useLoading';
import StyledTooltip from '../shared/StyledTooltip';
import AddHeroPassModal from './AddHeroPassModal';

const { RangePicker } = DatePicker;
const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
const FormItem = Form.Item;

const StyledButtonHeroPass = styled(Button)`
  margin-right: 10px;
`;

const GET_DATA = gql`
  query getData($userId: ID, $query: PaginationQuery) {
    userHeroPassWithPaginate(userId: $userId, query: $query) {
      userHeroPasses {
        id
        heroPass {
          id
          name
        }
        availableCount
        isAvailable
        countDays
        isRefunded
        refundedTime
        startTime
        endTime
        startFreezingDay
        created_at
      }
      total
      limit
      page
      pages
    }
  }
`;
const ADD_HEROPASS = gql`
  mutation addHeroPassToUser(
    $userId: ID
    $heroPassId: ID
    $startTime: DateTime
  ) {
    addHeroPassToUser(
      userId: $userId
      heroPassId: $heroPassId
      startTime: $startTime
    ) {
      status
      message
    }
  }
`;

const GET_HEROPASSES = gql`
  query heroPasses {
    heroPasses {
      id
      name
      price
    }
  }
`;

const UPDATE_USERHEROPASS = gql`
  mutation updateUserHeroPass($id: ID!, $input: UserHeroPassInput) {
    updateUserHeroPass(id: $id, input: $input) {
      id
      heroPass {
        id
        name
      }
      availableCount
      isAvailable
      countDays
      isRefunded
      refundedTime
      startTime
      endTime
      startFreezingDay
      created_at
    }
  }
`;

const UserHeroPasses = ({ userId }) => {
  const [form] = Form.useForm();
  const { showLoading, hideLoading } = useLoading();
  const [modal, setModal] = React.useState(false);
  const [heroPassModal, setHeroPassModal] = React.useState(false);
  const [editUserHeroPass, setEditUserHeroPass] = React.useState(null);
  const [userHeroPasses, setUserHeroPasses] = React.useState([]);
  const [heroPasses, setHeroPasses] = React.useState([]);
  const [total, setTotal] = React.useState(0);
  const [page, setPage] = React.useState(1);
  dayjs.locale('ru');

  const [getData, { data, error, loading, refetch }] = useLazyQuery(GET_DATA, {
    fetchPolicy: 'no-cache',
  });

  const {
    data: heroPassesData,
    error: heroPassesError,
    loading: heroPassesLoading,
  } = useQuery(GET_HEROPASSES);

  const [addHeroPassToUser] = useMutation(ADD_HEROPASS, {
    onError(err) {
      hideLoading();
      toast.error(`Error ${err.message}`);
    },
    onCompleted() {
      toast.success('Hero`s Pass успешно добавлен');
      hideLoading();
      refetch();
    },
  });

  const [updateUserHeroPass] = useMutation(UPDATE_USERHEROPASS, {
    onError(err) {
      hideLoading();
      toast.error(`Error ${err.message}`);
    },
    onCompleted() {
      toast.success('Успешно изменено');
      hideLoading();
      refetch();
    },
  });

  React.useEffect(() => {
    getData({
      variables: {
        userId: userId,
        query: {
          page: page,
          limit: 10,
        },
      },
    });
  }, []);

  React.useEffect(() => {
    refetch({
      userId: userId,
      query: {
        page: page,
        limit: 10,
      },
    });
  }, [page]);

  React.useEffect(() => {
    if (heroPassesData && !heroPassesLoading && heroPassesData.heroPasses) {
      setHeroPasses(heroPassesData.heroPasses);
    }
  }, [heroPassesData, heroPassesLoading, heroPassesError]);

  React.useEffect(() => {
    if (data && data.userHeroPassWithPaginate && !loading) {
      setUserHeroPasses(data.userHeroPassWithPaginate.userHeroPasses);
      setTotal(data.userHeroPassWithPaginate.total);
    } else if (error) {
      console.log(`error `, error);
    }
  }, [data, loading, error]);

  const userPaymentColumns = [
    {
      title: 'Название',
      key: 'name',
      render: (item) => {
        return <span>{item?.heroPass?.name}</span>;
      },
    },
    {
      title: 'Продолжительность',
      key: 'date',
      render: (item) => {
        return (
          <span>
            {dayjs(item?.startTime).format('DD.MM.YYYY')} -{' '}
            {dayjs(item?.endTime).format('DD.MM.YYYY')}
          </span>
        );
      },
    },
    {
      title: 'Кол-во доступных заморозок',
      key: 'availableCount',
      render: (item) => {
        return <span>{item?.availableCount}</span>;
      },
    },
    {
      title: 'Кол-во заморозочных дней',
      key: 'countDays',
      render: (item) => {
        return <span>{item?.countDays}</span>;
      },
    },
    {
      title: 'Статус',
      key: 'status',
      render: (item) => {
        if (
          dayjs(item?.endTime) >= dayjs() &&
          dayjs(item?.startTime) <= dayjs()
        ) {
          if (!item?.isAvailable) {
            return (
              <Tag color={'blue'}>
                ЗАМОРОЖЕН(c {dayjs(item?.startFreezingDay).format('DD.MM.YYYY')}
                )
              </Tag>
            );
          }
          return <Tag color={'green'}>АКТИВЕН</Tag>;
        }
        return <Tag color={'red'}>НЕАКТИВЕН</Tag>;
      },
    },
    {
      title: 'Действие',
      key: 'action',
      render: (item) => (
        <span>
          <Button
            type="link"
            onClick={() => {
              setEditUserHeroPass(item);
              setModal(false);
            }}
          >
            Редактировать
          </Button>
        </span>
      ),
    },
  ];

  const getTooltip = (text, fullText) => {
    return <StyledTooltip {...{ text, fullText }} />;
  };

  return (
    <MainContainer>
      <Table
        dataSource={userHeroPasses}
        columns={userPaymentColumns}
        rowKey={(item) => item.id}
        loading={loading}
        pagination={{
          defaultPageSize: 10,
          total: total,
          current: page,
          onChange: (page, pageSize) => {
            setPage(page);
          },
          showSizeChanger: false,
        }}
        title={() => (
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <h2> HeroPass </h2>
            <StyledButtonHeroPass
              type="primary"
              onClick={() => setHeroPassModal(true)}
            >
              Добавить Hero`s Pass
            </StyledButtonHeroPass>
          </div>
        )}
      />
      <Modal
        open={editUserHeroPass}
        title="Редактировать HeroPass"
        okText="Редактировать"
        cancelText="Закрыть"
        onCancel={() => {
          form.resetFields();
          setEditUserHeroPass(null);
          setModal(null);
        }}
        footer={[
          <Button
            key="submit"
            onClick={() => {
              form.resetFields();
              setEditUserHeroPass(null);
              setModal(null);
            }}
          >
            Отмена
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={() => {
              form
                .validateFields()
                .then((values) => {
                  form.resetFields();
                  const { availableCount, countDays, duration } = values;
                  updateUserHeroPass({
                    variables: {
                      id: editUserHeroPass?.id,
                      input: {
                        availableCount: parseInt(availableCount),
                        countDays: parseInt(countDays),
                        startTime: dayjs(duration[0]),
                        endTime: dayjs(duration[1]),
                      },
                    },
                  });
                  setEditUserHeroPass(null);
                  setModal(null);
                })
                .catch((info) => {
                  console.log('Validate Failed:', info);
                });
            }}
          >
            Сохранить
          </Button>,
        ]}
      >
        <Form form={form} layout="vertical">
          <FormItem
            key="availableCount"
            label={getTooltip(
              'Кол-во доступных заморозок',
              'Кол-во доступных заморозок'
            )}
            name="availableCount"
            rules={[
              {
                required: true,
                message: 'Укажите Кол-во доступных заморозок',
              },
            ]}
            initialValue={editUserHeroPass?.availableCount}
          >
            <Input placeholder="Кол-во доступных заморозок" type="number" />
          </FormItem>
          <FormItem
            key="countDays"
            label={getTooltip(
              'Кол-во заморозочных дней',
              'Кол-во заморозочных дней'
            )}
            name="countDays"
            rules={[
              {
                required: true,
                message: 'Укажите Кол-во заморозочных дней',
              },
            ]}
            initialValue={editUserHeroPass?.countDays}
          >
            <Input placeholder="Кол-во заморозочных дней" type="number" />
          </FormItem>
          <FormItem
            key="Duration"
            label={getTooltip(
              'Продолжительность',
              'Время начала и завершения события'
            )}
            name="duration"
            rules={[
              {
                required: true,
                message: 'Укажите Продолжительность HeroPass',
              },
            ]}
            initialValue={[
              dayjs(editUserHeroPass?.startTime),
              dayjs(editUserHeroPass?.endTime),
            ]}
          >
            <RangePicker showTime locale={datePickerLocale} />
          </FormItem>
        </Form>
      </Modal>

      <AddHeroPassModal
        modalVisible={heroPassModal}
        onCancel={() => {
          setHeroPassModal(false);
        }}
        heroPasses={heroPasses}
        handleAddHeroPass={({ heroPassId, startTime }) => {
          showLoading();
          addHeroPassToUser({
            variables: {
              userId: userId,
              heroPassId: heroPassId,
              startTime: startTime,
            },
          });
        }}
      />
    </MainContainer>
  );
};

UserHeroPasses.propTypes = {
  userId: PropTypes.string.isRequired,
};

export default UserHeroPasses;
