import React from 'react';
import styled from 'styled-components';
import { gql, useQuery, useLazyQuery, useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import dayjs from 'dayjs';

import ConversionClientsBody from './ConversionClientsBody';
import ConversionClientsHeader from './ConversionClientsHeader';
import withMainLayout from '../../hocs/withMainLayout';
import CommentModal from './CommentModal';

import Loading from '../shared/Loading';
import ArrowLeft from '../../images/ArrowLeft.svg';

const GET_MARATHON_EVENTS_CLIENTS = gql`
  query userMarathonEvents($marathonEventId: ID) {
    userMarathonEvents(marathonEventId: $marathonEventId) {
      id
      marathonVisits
      marathonMissed
      marathonEvent {
        startTime
        marathon {
          name
        }
      }
      user {
        id
        nickname
        phoneNumber
        email
      }
      note {
        noteText
        createdAt
      }
      userHeroPass {
        id
        startTime
        endTime
        heroPass {
          name
        }
      }
      created_at
    }
  }
`;
const ADD_NOTE = gql`
  mutation addUserMarathonEventNote(
    $id: ID!
    $input: UserMarathonEventNoteInput
  ) {
    addUserMarathonEventNote(id: $id, input: $input) {
      status
    }
  }
`;

const Wrapper = styled.div`
  margin: 2%;
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  flex: 1;
  background-color: #fff;
`;
const Header = styled.div`
  background: white;
  position: absolute;
  top: 15px;
  font-size: 32px;
  line-height: 40px;
  font-family: 'GraphikLCGMedium';
  display: flex;
`;
const Button = styled.button`
  cursor: pointer;
  background: white;
  border-radius: 8px;
  border: 1px solid #0000001a;
  margin-right: 20px;
`;
const ArrowLeftStyle = styled.img``;

const ConversionMarathonEventsClients = () => {
  const params = useParams();
  const { itemId } = useParams();
  const [page, setPage] = React.useState(1);
  const [clients, setClients] = React.useState(null);
  const [search, setSearch] = React.useState();
  const [filter, setFilter] = React.useState(0);
  const [modalComment, setModalComment] = React.useState(false);
  const [comment, setComment] = React.useState('');
  const [idUserMarathonEvent, setIdUserMarathonEvent] = React.useState();
  const navigate = useNavigate();

  const handleCancel = () => {
    // Navigate to the desired route
    navigate('/conversionContainer');
  };
  const handleReload = () => {
    window.location.reload();
  };

  const [
    getMarahonEventsClients,
    { loading: loadingClients, error: errorClients, data: dataClients },
  ] = useLazyQuery(GET_MARATHON_EVENTS_CLIENTS);

  const [
    addNote,
    { data: dataAddNote, loading: loadingAddNote, error: errorAddNote },
  ] = useMutation(ADD_NOTE, {
    fetchPolicy: 'no-cache',
    onCompleted() {
      toast.success('Заметка успешно добавлена');
      handleReload();
    },
  });

  React.useEffect(() => {
    if (dataClients && dataClients?.userMarathonEvents && !loadingClients) {
      setClients(dataClients?.userMarathonEvents);
    }
  }, [dataClients, loadingClients, errorClients]);

  React.useEffect(() => {
    getMarahonEventsClients({
      variables: {
        marathonEventId: itemId,
      },
    });
  }, [getMarahonEventsClients, itemId]);

  const HandleAddNote = ({ id, values }) => {
    addNote({
      variables: {
        id: id,
        input: {
          note: {
            noteText: values,
          },
        },
      },
    });
  };

  if (loadingClients || clients?.length < 1 || !clients) {
    return <Loading />;
  }
  const filteredUsers = () => {
    if (search) {
      return clients.filter((itm) => {
        return itm?.user?.phoneNumber?.includes(search);
      });
    }

    if (filter === 1) {
      return clients.filter((obj) => {
        return obj?.userHeroPass !== null;
      });
    }

    if (filter === 2) {
      return clients.filter((obj) => {
        return obj?.userHeroPass === null;
      });
    }
    if (filter === 0) {
      return clients;
    }

    return clients;
  };

  return (
    <Wrapper>
      <Header>
        <Button
          onClick={() => {
            handleCancel();
          }}
        >
          <ArrowLeftStyle src={ArrowLeft} />
        </Button>
        {clients[0]?.marathonEvent?.marathon?.name}
        <div style={{ paddingLeft: 5 }}>
          {dayjs(clients[0]?.marathonEvent?.startTime).format('DD.MM.YY')}
        </div>
      </Header>
      <ConversionClientsHeader
        setSearch={setSearch}
        setFilter={setFilter}
        filter={filter}
      />
      <ConversionClientsBody
        clients={clients}
        filteredUsers={filteredUsers}
        setModalComment={setModalComment}
        setIdUserMarathonEvent={setIdUserMarathonEvent}
      />
      {modalComment && (
        <CommentModal
          modalComment={modalComment}
          setModalComment={setModalComment}
          comment={comment}
          setComment={setComment}
          HandleAddNote={HandleAddNote}
          idUserMarathonEvent={idUserMarathonEvent}
        />
      )}
    </Wrapper>
  );
};

export default withMainLayout(ConversionMarathonEventsClients);
