import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";

import { gql, useMutation, useQuery } from "@apollo/client";
import { toast } from "react-toastify";

import Level from "./Level";
import Loading from "../shared/Loading";
import { useLoading } from "../../context/useLoading";
import withMainLayout from "../../hocs/withMainLayout";
import { removeTypenameField } from "../../utils/apollo";

const GET_LEVEL = gql`
  query level($id: ID!) {
    level(id: $id) {
      id
      name
      degree
      maxPoints
      attendancePoints
      perfectWeekPoints
      punishmentPoints
      enterWeightBonusPoints
      visitsForPerfectWeek
      hurdle {
        hurdleType
        male {
          bodyFatComparisonValue
          changeOverValue
          changeBelowValue
          requirementPass
        }
        female {
          bodyFatComparisonValue
          changeOverValue
          changeBelowValue
          requirementPass
        }
      }
      levelGoalPlans {
        id
        goal
        programSetFrequencies {
          proportion
          types
        }
        programSetFrequenciesFemale {
          proportion
          types
        }
      }
    }
  }
`;

// const DELETE_LEVEL = gql`
//   mutation deleteLevel($id: ID!) {
//     deleteLevel(id: $id)
//   }
// `

const UPDATE_LEVEL = gql`
  mutation updateLevel($id: ID!, $input: LevelInput) {
    updateLevel(id: $id, input: $input) {
      id
      name
      degree
      maxPoints
      attendancePoints
      perfectWeekPoints
      punishmentPoints
      enterWeightBonusPoints
      visitsForPerfectWeek
      hurdle {
        hurdleType
        male {
          bodyFatComparisonValue
          changeOverValue
          changeBelowValue
          requirementPass
        }
        female {
          bodyFatComparisonValue
          changeOverValue
          changeBelowValue
          requirementPass
        }
      }
    }
  }
`;

const ADD_LEVEL_GOAL_PLAN = gql`
  mutation addLevelGoalPlan($input: LevelGoalPlanInput) {
    addLevelGoalPlan(input: $input) {
      id
      goal
      programSetFrequencies {
        proportion
        types
      }
    }
  }
`;

const UPDATE_LEVEL_GOAL_PLAN = gql`
  mutation updateLevelGoalPlan($id: ID!, $input: LevelGoalPlanInput) {
    updateLevelGoalPlan(id: $id, input: $input) {
      id
      goal
      programSetFrequencies {
        proportion
        types
      }
    }
  }
`;

const DELETE_LEVEL_GOAL_PLAN = gql`
  mutation deleteLevelGoalPlan($id: ID!) {
    deleteLevelGoalPlan(id: $id)
  }
`;

const LevelContainer = () => {
  const { id: levelId } = useParams();

  const { showLoading, hideLoading } = useLoading();
  const [level, setLevel] = useState(null);

  const { data, loading, error, refetch } = useQuery(GET_LEVEL, {
    variables: {
      id: levelId
    }
  });

  const [updateLevel] = useMutation(UPDATE_LEVEL, {
    onCompleted() {
      toast.success("Уровень успешно обновлен");
      hideLoading();
    }
  });

  const handleUpdateLevel = (values) => {
    showLoading();
    const dataLevel = {
      name: values.name,
      degree: parseInt(values.degree, 10),
      maxPoints: parseInt(values.maxPoints, 10),
      attendancePoints: parseInt(values.attendancePoints, 10),
      perfectWeekPoints: parseInt(values.perfectWeekPoints, 10),
      punishmentPoints: parseInt(values.punishmentPoints, 10),
      enterWeightBonusPoints: parseInt(values.enterWeightBonusPoints, 10),
      visitsForPerfectWeek: parseInt(values.visitsForPerfectWeek, 10)
    };
    updateLevel({ variables: { id: levelId, input: dataLevel } });
  };

  // const [deleteLevel] = useMutation(DELETE_LEVEL, {
  //   onCompleted() {
  //     toast.success('Уровень успешно удален')
  //     history.push('/levels')
  //   }
  // })

  // const handleDeleteLevel = () => {
  //   deleteLevel({ variables: { id: levelId } })
  //   history.push('/programSets')
  // }

  const [addLevelGoalPlan] = useMutation(ADD_LEVEL_GOAL_PLAN, {
    onCompleted() {
      toast.success("Планер добавлен");
      hideLoading();
      refetch();
    }
  });

  const handleAddLevelGoalPlan = (input) => {
    showLoading();
    const cleanInput = removeTypenameField(input);
    addLevelGoalPlan({
      variables: {
        input: { level: levelId, ...cleanInput }
      }
    });
  };

  const [updateLevelGoalPlan] = useMutation(UPDATE_LEVEL_GOAL_PLAN, {
    onCompleted() {
      toast.success("Планер обновлен");
      hideLoading();
      refetch();
    }
  });

  const handleUpdateLevelGoalPlan = (id, input) => {
    showLoading();
    const cleanInput = removeTypenameField(input);

    updateLevelGoalPlan({
      variables: {
        id,
        input: cleanInput
      }
    });
  };

  const [deleteLevelGoalPlan] = useMutation(DELETE_LEVEL_GOAL_PLAN, {
    onCompleted() {
      toast.success("Планер удален");
      refetch();
      hideLoading();
    }
  });

  const handleDeleteLevelGoalPlan = (id) => {
    deleteLevelGoalPlan({
      variables: {
        id
      }
    });
  };

  const handleUpdateHurdle = (values) => {
    showLoading();

    const hurdleInput = {
      hurdle: values.hurdle
    };
    updateLevel({ variables: { id: levelId, input: hurdleInput } });
  };

  useEffect(() => {
    if (data && data.level) {
      setLevel(data.level);
    }
  }, [data, loading, error]);

  if (!level) {
    return <Loading />;
  }

  return (
    <Level
      {...{
        level,
        handleUpdateLevel,
        handleUpdateHurdle,
        handleAddLevelGoalPlan,
        handleUpdateLevelGoalPlan,
        handleDeleteLevelGoalPlan
      }}
    />
  );
};

LevelContainer.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired
    })
  }).isRequired
};

export default withMainLayout(LevelContainer);
