import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Tooltip, Table, Spin, Button, notification } from 'antd';
import { gql, useQuery, useMutation } from '@apollo/client';
import { RedoOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import 'dayjs/locale/ru';

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const GET_USER_IN_BODY_TESTS = gql`
    query userInBodyTestsByUserPagination($userId: ID!, $page: Int!, $limit: Int!) {
        userInBodyTestsByUserPagination(userId: $userId, page: $page, limit: $limit) {
            totalCount
            totalPages
            currentPage
            items {
                id
                testDate
                GENDER
                HT
                AGE
                WT
                TOT_SCORE
                metabolicAge
            }
        }
    }
`;

const DOWNLOAD_LATEST_USER_TEST = gql`
  mutation DownloadLatestUserTest($userId: ID!) {
    downloadLatestUserTest(userId: $userId) {
      status
      message
    }
  }
`;

const UserInBody = ({ userId }) => {
    const [page, setPage] = useState(1);
    const [pageSize] = useState(10);

    const [downloadTest, { loading: downloadTestLoading, data: downloadTestData, error: downloadTestError }] = useMutation(DOWNLOAD_LATEST_USER_TEST);
    
    useEffect(() => {
        if (downloadTestData?.downloadLatestUserTest) {
            const response = downloadTestData.downloadLatestUserTest;
    
            notification.info({
                message: response.status,
                description: response.message,
            });
        }
    
        if (downloadTestError) {
            notification.error({
                message: 'Ошибка',
                description: downloadTestError.message || 'Произошла ошибка при выполнении запроса.',
            });
        }
    }, [downloadTestData, downloadTestError]);

    const { data, loading } = useQuery(GET_USER_IN_BODY_TESTS, {
        variables: { userId, page, limit: pageSize },
        fetchPolicy: 'cache-and-network',
    });

    const columns = [
        {
            title: 'InBody ID',
            dataIndex: 'id',
            key: 'id',
            width: '15%',
            render: (text) => (
              <Tooltip title={text}>
                <span style={{
                  display: 'inline-block',
                  maxWidth: '90px',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  verticalAlign: 'middle',
                  cursor: 'pointer'
                }}>
                  {text}
                </span>
              </Tooltip>
            ),
        },
        {
            title: 'Дата прохождения',
            dataIndex: 'testDate',
            key: 'testDate',
            render: (_text, item) => {
                const dateReceived = dayjs(item?.testDate).format(
                    'Do MMMM, YYYY, HH:mm'
                );
                return <span>{dateReceived}</span>;
            },
        },
        {
            title: 'Пол',
            dataIndex: 'GENDER',
            key: 'GENDER',
            align: 'center'
        },
        {
            title: 'Возраст',
            dataIndex: 'AGE',
            key: 'AGE',
            align: 'center'
        },
        {
            title: 'Вес',
            dataIndex: 'WT',
            key: 'WT',
            align: 'center'
        },
        {
            title: 'Рост',
            dataIndex: 'HT',
            key: 'HT',
            align: 'center'
        },
        {
            title: 'Метаболический возраст',
            dataIndex: 'metabolicAge',
            key: 'metabolicAge',
            align: 'center'
        },
        {
            title: 'Общий результат',
            dataIndex: 'TOT_SCORE',
            key: 'TOT_SCORE',
            align: 'center'
        },
    ];
 
    const { items, totalCount } = data?.userInBodyTestsByUserPagination || {};

    const handleDownload = async () => {
        downloadTest({
            variables: { userId },
        });
    };

    return (
        <Spin spinning={loading}>
            <MainContainer>
                <Button 
                    type={'primary'}
                    icon={<RedoOutlined />}
                    onClick={handleDownload}
                    loading={downloadTestLoading}
                    style={{
                        width: '150px',
                        position: 'absolute',
                        top: '-75px',
                        right: '0'
                    }}
                >
                    Обновить данные
                </Button>
                <Table
                    dataSource={items}
                    columns={columns}
                    rowKey={(item) => item.id}
                    pagination={{
                        defaultPageSize: pageSize,
                        total: totalCount,
                        current: page,
                        onChange: (newPage) => {
                            setPage(newPage);
                        },
                        showSizeChanger: false,
                    }}
                />
            </MainContainer>
        </Spin>
    );
};

export default UserInBody;