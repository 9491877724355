import React from 'react';
import PropTypes from 'prop-types';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { EditOutlined, CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Row, Table, Input, Space, Button } from 'antd';
import styled from 'styled-components';
import dayjs from 'dayjs';
import 'dayjs/locale/ru';

const ColorfulText = styled.div`
  color: ${(p) => p.color};
  min-width: 30px;
`;

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const GET_DATA = gql`
  query getData($userId: ID, $query: PaginationQuery) {
    pointHistoryWithPagination(userId: $userId, query: $query) {
      userPoints {
        id
        changeValue
        type
        cycleCount
        weekNumber
        event {
          id
          programSet {
            id
            name
          }
        }
        created_at
      }

      total
      limit
      page
      pages
    }
  }
`;

const UPDATE_USER_POINT = gql`
  mutation UpdateUserPoint($id: ID!, $input: UserPointInput!) {
    updateUserPoint(id: $id, input: $input) {
      id
      changeValue
      type
      cycleCount
      weekNumber
      event {
        id
        programSet {
          id
          name
        }
      }
      user {
        id
      }
    }
  }
`;

const UserPoints = ({ userId }) => {
  const [userPoints, setUserPoints] = React.useState([]);
  const [total, setTotal] = React.useState(0);
  const [page, setPage] = React.useState(1);
  const [editingRowId, setEditingRowId] = React.useState(null);
  const [inputValue, setInputValue] = React.useState('');
  const [currentVal, setCurrentVal] = React.useState('');
  dayjs.locale('ru');

  const [getData, { data, error, loading, refetch }] = useLazyQuery(GET_DATA, {
    fetchPolicy: 'no-cache',
  });

  const [updateUserPoint, { loading: isUpdating }] = useMutation(UPDATE_USER_POINT);

  React.useEffect(() => {
    getData({
      variables: {
        userId: userId,
        query: {
          page: page,
          limit: 10,
        },
      },
    });
  }, []);

  React.useEffect(() => {
    refetch({
      userId: userId,
      query: {
        page: page,
        limit: 10,
      },
    });
  }, [page]);

  React.useEffect(() => {
    if (data && data.pointHistoryWithPagination && !loading) {
      setUserPoints(data.pointHistoryWithPagination.userPoints);
      setTotal(data.pointHistoryWithPagination.total);
    } else if (error) {
      console.log(`error `, error);
    }
  }, [data, loading, error]);

  const handleEdit = (item) => {
    setEditingRowId(item?.id);
    setInputValue(item?.changeValue);
    setCurrentVal(item?.changeValue);
  };

  const handleSave = async () => {
    if(currentVal !== inputValue) {
      try {
        await updateUserPoint({
          variables: {
            id: editingRowId,
            input: {
              changeValue: parseInt(inputValue, 10),
            },
          },
        });
        setEditingRowId(null);
        refetch();
      } catch (error) {
        console.error('Ошибка при обновлении:', error);
      }
    } else {
      setEditingRowId(null);
    }
  };

  const handleCancel = () => {
    setEditingRowId(null);
    setInputValue('');
  };

  const renderChangeValue = (item) => {
    if (editingRowId === item.id) {
      return (
        <Space.Compact style={{ width: '100%', height: '25px' }}>
          <Input
            type="number"
            size="small"
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') handleSave();
            }}
          />
          <Button
            type="primary"
            style={{ height: '25px' }}
            danger
            icon={<CloseOutlined />}
            onClick={handleCancel}
          />
          <Button
            type="primary"
            style={{ height: '25px' }}
            icon={<CheckOutlined />}
            onClick={handleSave}
          />
        </Space.Compact>
      );
    }

    return (
      <Row>
        <ColorfulText color={item.changeValue > 0 ? '#00c000' : item.changeValue < 0 ? '#FF4D4F' : '#000000'}>
          {item.changeValue > 0 ? `+${item.changeValue}` : item.changeValue}
        </ColorfulText>
        <EditOutlined
          style={{ marginLeft: '5px', cursor: 'pointer' }}
          onClick={() => handleEdit(item)}
        />
      </Row>
    );
  };

  const renderType = (type) => {
    if(type === 'checkIn') {
      return 'Начисление';
    } else if(type === 'penalty') {
      return 'Штраф';
    } else if(type === 'perfectWeek') {
      return 'Идеальная неделя';
    }  else if(type === 'perfectDiscipline') {
      return 'Идеальная дисциплина';
    }
  };

  const renderDescription = (item) => {
    if (item.type === 'perfectWeek') {
      return <span> Бонус за идеальную неделю </span>;
    }
    if (item.type === 'penalty') {
      return <span> Штраф за непосещение</span>;
    }
    return <span> {item?.event?.programSet?.name} </span>;
  };

  const userPointColumns = [
    {
      title: 'Очки',
      width: '15%',
      dataIndex: 'changeValue',
      render: (text, item) => renderChangeValue(item),
    },
    {
      title: 'Тип',
      width: '15%',
      dataIndex: 'type',
      render: (text, item) => <span>{renderType(item?.type)}</span>,
    },
    {
      title: 'Примечание',
      width: '15%',
      render: (text, item) => renderDescription(item),
    },
    {
      title: 'Неделя по счету',
      width: '15%',
      dataIndex: 'weekNumber',
      render: (text, item) => <span>{item?.weekNumber}</span>,
    },
    {
      title: 'Дата',
      width: '20%',
      render: (text, item) => {
        const dateReceived = dayjs(item.created_at).format('Do MMMM, YYYY');
        return <span>{dateReceived} </span>;
      },
    },
  ];

  return (
    <MainContainer>
      <h2> Очки</h2>
      <Table
        dataSource={userPoints}
        columns={userPointColumns}
        rowKey={(item) => item.id}
        loading={loading || isUpdating}
        pagination={{
          defaultPageSize: 10,
          total: total,
          current: page,
          onChange: (page, pageSize) => {
            setPage(page);
          },
          showSizeChanger: false,
        }}
      />
    </MainContainer>
  );
};

UserPoints.propTypes = {
  userPoints: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      changeValue: PropTypes.number,
      created_at: PropTypes.string,
      type: PropTypes.string,
    })
  ).isRequired,
};

export default UserPoints;
