import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Table, Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import EditStation from './EditStation';
import CreateForm from '../shared/CreateForm';

const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;
const HeaderTitle = styled.div`
  font-family: SF Pro Display;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;

  display: flex;
  align-items: center;

  color: #262626;
`;

const Stations = ({
  stations,
  group,
  addStationClick,
  deleteStationClick,
  updateStationClick,
}) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [display, setDisplay] = useState('default');
  const [editStation, setEditStation] = useState(null);

  const columns = [
    {
      title: 'Номер',
      dataIndex: 'number',
    },
    {
      title: 'Название',
      dataIndex: 'name',
    },
    {
      title: 'Иконка',
      dataIndex: 'icon',
    },
    {
      title: 'Кардио тренажер?',
      render: (_, item) => {
        if (item.isCardio) {
          return <span> Да </span>;
        }
        return <span> Нет </span>;
      },
    },
    {
      title: 'Действие',
      key: 'action',
      render: (text, item) => (
        <span>
          <Button
            type="link"
            onClick={() => {
              setDisplay('edit');
              setEditStation(item);
            }}
          >
            Редактировать
          </Button>
        </span>
      ),
    },
  ];

  // const fields = [
  //   {
  //     key: 'number',
  //     label: 'Номер',
  //     number: true,
  //     tooltipText: 'Номер станции',
  //   },
  //   {
  //     key: 'name',
  //     label: 'Название',
  //     isNotRequired: true,
  //     tooltipText: 'Название станции',
  //   },
  //   {
  //     key: 'icon',
  //     label: 'Иконка',
  //     type: 'select',
  //     options: icons,
  //     tooltipText: 'Иконка станции',
  //   },
  //   {
  //     key: 'isCardio',
  //     label: 'Кардио тренажер?',
  //     checkbox: true,
  //     isNotRequired: true,
  //     tooltipText: 'Этот тренажер для кардио?',
  //   },
  // ]

  // const editFields = [
  //   {
  //     key: 'number',
  //     label: 'Номер',
  //     value: editStation && editStation.number,
  //     number: true,
  //     isRequired: true,
  //     tooltipText: 'Номер станции',
  //   },
  //   {
  //     key: 'name',
  //     label: 'Название',
  //     value: editStation && editStation.name,
  //     isNotRequired: true,
  //     tooltipText: 'Название станции',
  //   },
  //   {
  //     key: 'icon',
  //     label: 'Иконка',
  //     value: editStation && editStation.icon,
  //     options: icons,
  //     isRequired: true,
  //     tooltipText: 'Иконка станции',
  //   },
  //   {
  //     key: 'isCardio',
  //     label: 'Кардио тренажер?',
  //     value: editStation && editStation.isCardio,
  //     isNotRequired: true,
  //     checkbox: true,
  //     tooltipText: 'Это кардио тренажер?',
  //   },
  // ]

  const handleCreate = (values) => {
    addStationClick(values);
    setModalVisible(false);
  };

  const handleUpdate = (values) => {
    updateStationClick(editStation.id, values);
    setDisplay('default');
    setEditStation(null);
  };

  const handleDelete = () => {
    deleteStationClick(editStation.id);
    setDisplay('default');
    setEditStation(null);
  };

  return (
    <>
      <Table
        dataSource={stations}
        columns={columns}
        title={() => (
          <Header>
            <HeaderTitle>Станции</HeaderTitle>
            <Button type="primary" onClick={() => setModalVisible(true)}>
              <PlusOutlined /> Новая станция
            </Button>
          </Header>
        )}
      />
      {/* <CreateForm
        title="Добавить новый станцию"
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        onCreate={handleCreate}
        fields={fields}
      /> */}
      <EditStation
        title="Добавить новый станцию"
        group={group}
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        onCreate={handleCreate}
        isNew={true}
      />
      {display === 'edit' && editStation && (
        <EditStation
          editStation={editStation}
          group={group}
          title="Редактировать станцию"
          visible={display === 'edit'}
          onCancel={() => setDisplay('default')}
          isNew={false}
          onUpdate={handleUpdate}
          onDelete={handleDelete}
        />
      )}
    </>
  );
};

Stations.propTypes = {
  stations: PropTypes.shape({
    id: PropTypes.string,
    number: PropTypes.string,
    icon: PropTypes.string,
  }).isRequired,
  addStationClick: PropTypes.func.isRequired,
  deleteStationClick: PropTypes.func.isRequired,
  updateStationClick: PropTypes.func.isRequired,
};

export default Stations;
