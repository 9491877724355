import React from 'react';
import styled from 'styled-components';
import { Table, Button, Form, Modal, Input } from 'antd';

const ClientsBody = ({
  users,
  usersCnt,
  page,
  setPage,
  setSelectUser,
  setClientInfoModal,
  setNewUserModal,
  getUserActiveMarathon,
  getUserMarathons,
  getUserPayments,
  setSelectedRecurrentUser
  }) => {
  const columns = [
    {
      title: () => {
				return <div style={{fontSize:14, fontFamily:'GraphikLCGMedium', color:'gray'}}>Имя</div>;
      },
      dataIndex: 'nickname',
      width: '25%',
      render: (text, item) => (
        <>{text}</>
      )
    },
    {
      title: () => {
				return <div style={{fontSize:14, fontFamily:'GraphikLCGMedium', color:'gray'}}>Номер</div>;
      },
      dataIndex: 'phoneNumber',
      width: '25%',
      render: (text, item) => (
        <>{text}</>
      )
    },
    {
      title: () => {
				return <div style={{fontSize:14, fontFamily:'GraphikLCGMedium', color:'gray'}}>Абонемент</div>;
      },
      dataIndex: ['heroPass', 'heroPass', 'name'],
      width: '25%',
      render: (text, item) => (
        <>{text}</>
      )
    },
    {
      title: () => {
				return <div style={{fontSize:14, fontFamily:'GraphikLCGMedium', color:'gray'}}>Уровень</div>;
      },
      dataIndex: ['level', 'name'],
      width: '25%',
      render: (text, item) => (
        <>{text}</>
      )
    }
  ];

  return ( 
	  <>
      <Table
        dataSource={users}
        columns={columns}
        rowKey={(item) => item.id}
        pagination={{
          defaultPageSize: 10,
          total: usersCnt,
          current: page,
          onChange: (page, pageSize) => {
            setPage(page);
          },
          showSizeChanger: false
	  	 }}
	  	 onRow={(item) => {
         return {
           onClick: itm => {
             setSelectUser(item);
             setClientInfoModal(true);
             setNewUserModal(false);
             setSelectedRecurrentUser(null);
             getUserActiveMarathon({
             variables: {
               userId: item?.id
             }
             });
             getUserMarathons({
             variables: {
               userId: item?.id
             }
             });
             getUserPayments({
             variables: {
               userId: item?.id
             }
           });
         }};
         }
        }
      />
	  </>
	);
};

export default ClientsBody;
