import React, { useState, useEffect } from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import { toast } from 'react-toastify';

import Exercises from './Exercises';
import Loading from '../shared/Loading';
import { useLoading } from '../../context/useLoading';
import withMainLayout from '../../hocs/withMainLayout';

const GET_EXERCISES = gql`
  query getExercises {
    exercises {
      id
      name
      type
      description
      videoURL
      videoIdentifier
    }
  }
`;

const DELETE_EXERCISE = gql`
  mutation deleteExercise($id: ID!) {
    deleteExercise(id: $id)
  }
`;

const ADD_EXERCISE = gql`
  mutation addExercise($input: ExerciseInput) {
    addExercise(input: $input) {
      id
      name
      type
      description
      videoURL
      videoIdentifier
    }
  }
`;

const UPDATE_EXERCISE = gql`
  mutation updateExercise($id: ID!, $input: ExerciseInput) {
    updateExercise(id: $id, input: $input) {
      id
      name
      type
      description
      videoURL
      videoIdentifier
    }
  }
`;

const ExercisesContainer = () => {
  const [dataExercises, setDataExercises] = useState(null);
  const { showLoading, hideLoading } = useLoading();

  const [updateExercise] = useMutation(UPDATE_EXERCISE, {
    onCompleted: () => {
      hideLoading();
    }
  });

  const { data, loading, error } = useQuery(GET_EXERCISES);

  const [deleteExercise] = useMutation(DELETE_EXERCISE, {
    update(cache, { data: { deleteExercise: id } }) {
      const { exercises } = cache.readQuery({ query: GET_EXERCISES });
      cache.writeQuery({
        query: GET_EXERCISES,
        data: {
          exercises: exercises.filter(exercise => exercise.id !== id)
        }
      });
      hideLoading();
      toast.success('Упражнение успешно удален');
    }
  });

  const [addExercise] = useMutation(ADD_EXERCISE, {
    update(cache, { data: { addExercise: exercise } }) {
      const { exercises } = cache.readQuery({ query: GET_EXERCISES });
      cache.writeQuery({
        query: GET_EXERCISES,
        data: { exercises: exercises.concat([exercise]) }
      });
      hideLoading();
      toast.success('Упражнение успешно создан');
    }
  });

  useEffect(() => {
    if (data && !loading && !error) {
      setDataExercises(data.exercises);
    }
  }, [data, loading, error]);

  const handleDeleteExercise = exerciseId => {
    showLoading();
    deleteExercise({ variables: { id: exerciseId } });
  };

  const handleAddExercise = values => {
    showLoading();
    addExercise({
      variables: {
        input: values
      }
    });
  };

  const handleUpdateExercise = (id, values) => {
    showLoading();
    updateExercise({ variables: { id, input: values } });
  };

  if (!dataExercises) {
    return <Loading />;
  }

  return (
    <Exercises
      exercises={dataExercises}
      deleteExerciseClick={handleDeleteExercise}
      addExerciseClick={handleAddExercise}
      updateExerciseClick={handleUpdateExercise}
    />
  );
};

export default withMainLayout(ExercisesContainer);
