import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Modal } from 'antd';
import dayjs from 'dayjs';
import 'dayjs/locale/ru';

const Header = styled.div`
  font-family: 'GraphikLCGMedium';
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: center;
  margin-bottom: 32px;
`;

const Container = styled.div`
  margin-bottom: 24px;
`;

const Name = styled.div`
  font-family: 'GraphikLCGMedium';
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: center;
  color: rgba(0, 0, 0, 0.5);
  margin-bottom: 8px;
`;

const Content = styled.div`
  font-family: 'GraphikLCGMedium';
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: center;
  backgrcoloround: rgba(0, 0, 0, 1);
`;

const WarningContainer = styled.div`
  background: rgba(255, 230, 230, 1);
  border-radius: 8px;
  padding-top: 8px;
  padding-bottom: 8px;
  margin-bottom: 8px;
`;

const WarningTitle = styled.div`
  font-family: 'GraphikLCGSemibold';
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: center;
  margin-bottom: 8px;
`;

const WarningContent = styled.div`
  font-family: 'GraphikLCGMedium';
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: center;
`;

const PublishModal = ({
  visible,
  onCancel,
  title,
  startTime,
  endTime,
  events,
  previewEvents,
  updateMultipleEventsClick,
}) => {
  return (
    <Modal
      open={visible}
      title={title}
      footer={null}
      onCancel={() => {
        onCancel();
      }}
    >
      <Header>Вы подтверждаете публикацию расписания?</Header>
      <Container>
        <Name>Неделя</Name>
        <Content>
          {dayjs(startTime).format('Do MMMM')} -{' '}
          {dayjs(endTime).format('Do MMMM')}
        </Content>
      </Container>
      <Container>
        <Name>Количество занятий</Name>
        <Content>{events?.length}</Content>
      </Container>
      {previewEvents?.length > 0 && (
        <WarningContainer>
          <WarningTitle>Внимание!</WarningTitle>
          <WarningContent>
            У вас есть {previewEvents?.length} неподтвержденных занятий
          </WarningContent>
        </WarningContainer>
      )}

      <div
        style={{
          marginBottom: 8,
        }}
      >
        <button
          style={{
            width: '100%',
            color: 'white',
            borderRadius: 8,
            paddingTop: 16,
            paddingBottom: 16,
            fontFamily: 'GraphikLCGMedium',
            fontSize: 14,

            backgroundColor: 'rgba(111, 44, 255, 1)',
            border: 'none',
            cursor: 'pointer',
          }}
          onClick={() => {
            onCancel();
            updateMultipleEventsClick(
              previewEvents.map((event) => {
                return {
                  id: event.id,
                  event: {
                    status: 'published',
                  },
                };
              })
            );
          }}
        >
          Подтверждаю
        </button>
      </div>
    </Modal>
  );
};

PublishModal.propTypes = {
  previewEvents: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  visible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  events: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default PublishModal;
