import React, { useState, useEffect } from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import { toast } from 'react-toastify';

import Levels from './Levels';
import Loading from '../shared/Loading';
import { useLoading } from '../../context/useLoading';
import withMainLayout from '../../hocs/withMainLayout';

const GET_LEVELS = gql`
  query getLevels {
    levels {
      id
      name
      degree
      maxPoints
      attendancePoints
      perfectWeekPoints
      punishmentPoints
      enterWeightBonusPoints
      visitsForPerfectWeek
      hurdle {
        hurdleType
        male {
          bodyFatComparisonValue
          changeOverValue
          changeBelowValue
          requirementPass
        }
        female {
          bodyFatComparisonValue
          changeOverValue
          changeBelowValue
          requirementPass
        }
      }
    }
  }
`;

const DELETE_LEVEL = gql`
  mutation deleteLevel($id: ID!) {
    deleteLevel(id: $id)
  }
`;

const ADD_LEVEL = gql`
  mutation addLevel($input: LevelInput) {
    addLevel(input: $input) {
      id
      name
      degree
      maxPoints
      attendancePoints
      perfectWeekPoints
      punishmentPoints
      enterWeightBonusPoints
      visitsForPerfectWeek
      hurdle {
        hurdleType
        male {
          bodyFatComparisonValue
          changeOverValue
          changeBelowValue
          requirementPass
        }
        female {
          bodyFatComparisonValue
          changeOverValue
          changeBelowValue
          requirementPass
        }
      }
    }
  }
`;
const UPDATE_LEVEL = gql`
  mutation updateLevel($id: ID!, $input: LevelInput) {
    updateLevel(id: $id, input: $input) {
      id
      name
      degree
      maxPoints
      attendancePoints
      perfectWeekPoints
      punishmentPoints
      enterWeightBonusPoints
      visitsForPerfectWeek
      hurdle {
        hurdleType
        male {
          bodyFatComparisonValue
          changeOverValue
          changeBelowValue
          requirementPass
        }
        female {
          bodyFatComparisonValue
          changeOverValue
          changeBelowValue
          requirementPass
        }
      }
    }
  }
`;

const LevelsContainer = () => {
  const [dataLevels, setDataLevels] = useState(null);
  const { showLoading, hideLoading } = useLoading();

  const [updateLevel] = useMutation(UPDATE_LEVEL);

  const { data, loading, error } = useQuery(GET_LEVELS);

  const [deleteLevel] = useMutation(DELETE_LEVEL, {
    update(cache, { data: { deleteLevel: id } }) {
      const { levels } = cache.readQuery({ query: GET_LEVELS });
      cache.writeQuery({
        query: GET_LEVELS,
        data: {
          levels: levels.filter(level => level.id !== id)
        }
      });
      hideLoading();
      toast.success('Уровень успешно удален');
    }
  });

  const [addLevel] = useMutation(ADD_LEVEL, {
    update(cache, { data: { addLevel: level } }) {
      const { levels } = cache.readQuery({ query: GET_LEVELS });
      cache.writeQuery({
        query: GET_LEVELS,
        data: { levels: levels.concat([level]) }
      });
      hideLoading();
      toast.success('Уровень успешно создан');
    }
  });

  useEffect(() => {
    if (data && !loading && !error) {
      setDataLevels(data.levels);
    }
  }, [data, loading, error]);

  const handleDeleteLevel = levelId => {
    showLoading();
    deleteLevel({ variables: { id: levelId } });
  };

  const handleAddLevel = values => {
    showLoading();
    const dataLevel = {
      name: values.name,
      degree: parseInt(values.degree, 10),
      maxPoints: parseInt(values.maxPoints, 10),
      attendancePoints: parseInt(values.attendancePoints, 10),
      perfectWeekPoints: parseInt(values.perfectWeekPoints, 10),
      punishmentPoints: parseInt(values.punishmentPoints, 10),
      enterWeightBonusPoints: parseInt(values.enterWeightBonusPoints, 10),
      visitsForPerfectWeek: parseInt(values.visitsForPerfectWeek, 10)
    };
    addLevel({
      variables: {
        input: dataLevel
      }
    });
  };

  const handleUpdateLevel = (id, values) => {
    const dataLevel = {
      name: values.name,
      degree: parseInt(values.degree, 10),
      maxPoints: parseInt(values.maxPoints, 10),
      attendancePoints: parseInt(values.attendancePoints, 10),
      perfectWeekPoints: parseInt(values.perfectWeekPoints, 10),
      punishmentPoints: parseInt(values.punishmentPoints, 10),
      enterWeightBonusPoints: parseInt(values.enterWeightBonusPoints, 10),
      visitsForPerfectWeek: parseInt(values.visitsForPerfectWeek, 10)
    };
    updateLevel({ variables: { id, input: dataLevel } });
  };

  const handleUpdateHurdle = (id, values) => {
    const dataLevel = {
      hurdle: values.hurdle
    };
    updateLevel({ variables: { id, input: dataLevel } });
  };

  if (loading && !dataLevels) {
    return <Loading />;
  }

  return (
    <Levels
      levels={dataLevels}
      deleteLevelClick={handleDeleteLevel}
      addLevelClick={handleAddLevel}
      updateLevelClick={handleUpdateLevel}
      updateHurdleClick={handleUpdateHurdle}
    />
  );
};

export default withMainLayout(LevelsContainer);
