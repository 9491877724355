import React from 'react';
import { useMutation, useLazyQuery } from '@apollo/client';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';

import {
  GET_REQUEST_QUESTIONS,
  UPDATE_USER_REQUEST,
  GET_REQUEST_QUESTIONS_BY_TYPE,
  UPLOAD_REQUEST_PHOTO
} from './SalesSupport.queries';

import withMainLayout from '../../hocs/withMainLayout';

import { useAuth } from '../../context/useAuth';

import SalesSupport from './SalesSupport';

const SalesSupportContainer = () => {
  const { user } = useAuth();
  const [requests, setRequests] = React.useState([]);
  const [page, setPage] = React.useState(1);
  const [total, setTotal] = React.useState(1);
  const [search, setSearch] = React.useState('');
  const [filter, setFilter] = React.useState('');
  const [selectedRequest, setSelectedRequest] = React.useState();
  const [showRequestModal, setShowRequestModal] = React.useState(false);
  const [answer, setAnswer] = React.useState('');
  const [updateType, setUpdateType] = React.useState('');

  const [uploadRequestPhoto, { data: uploadedMediaData, loading: uploadedMediaLoading }] = useMutation(UPLOAD_REQUEST_PHOTO, {
      onCompleted: (data) => {
          toast.success({
              message: 'Успешно загруженно'
          });
      },
      onError: (e) => {
        toast.error(`Error ${e.message}`);
      },
  });
  
  const [
    getRequestQuestionsCount,
    {
      data: requestsCountData,
      loading: requestsCountLoading,
      error: requestsCountError
    }
  ] = useLazyQuery(GET_REQUEST_QUESTIONS_BY_TYPE, {
    fetchPolicy: 'no-cache'
  });

  const [
    getRequestQuestions,
    {
      data: requestData,
      loading: loadingRequests,
      error: requestError,
      refetch
    }
  ] = useLazyQuery(GET_REQUEST_QUESTIONS, {
    fetchPolicy: 'no-cache'
  });

  const createAdminAnswer = () => {
    setSelectedRequest({
      ...selectedRequest,
      status: updateType === 'adminAnswer' ? 'moreDataForAdmin' : 'finished',
      isAnswerViewed: false,
      lastAnswerDate: dayjs().toISOString(),
      queries: [
        ...selectedRequest.queries,
        {
          desc: answer,
          created_at: dayjs(),
          adminId: { id: user?.id, nickname: user?.nickname }
        }
      ]
    });
    setAnswer('');
  };

  const [updateRequestQuestion, { loading: loadingUpdateRequest }] =
    useMutation(UPDATE_USER_REQUEST, {
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
      onCompleted() {
        refetch();
        if (updateType === 'addAdmin' || updateType === 'changeAdmin') {
          console.log(updateType);
        } else if (
          updateType === 'adminAnswer' ||
          updateType === 'adminReport'
        ) {
          createAdminAnswer();
        }
      },
      onError(err) {
        toast.error(`Error ${err.message}`);
      }
    });

  React.useEffect(() => {
    if (requestData && !loadingRequests && !requestError) {
      setRequests(requestData?.allRequestQuestions?.requestQuestions);
      setTotal(requestData?.allRequestQuestions?.total);
    }
  }, [requestData, loadingRequests, requestError]);

  React.useEffect(() => {
    getRequestQuestions({
      variables: {
        userId: user?.id,
        query: {
          page: page,
          limit: 10
        },
        userFind: {
          text: search,
          filter: filter
        }
      }
    });
  }, [page, search, filter, user]);

  React.useEffect(() => {
    getRequestQuestionsCount({
      variables: { userId: user?.id }
    });
  }, [page, search, filter, user]);

  const removeTypename = (objects) => {
    return objects.map((obj) => {
      const { __typename, ...rest } = obj;
      if (rest?.adminId) {
        const { __typename: type, ...restAdminId } = rest.adminId;
        return { ...rest, adminId: restAdminId?.id };
      }
      return rest;
    });
  };
  const onSubmitAdminAnswer = (type) => {
    if (type === 'moreData') {
      setUpdateType('adminAnswer');
    } else {
      setUpdateType('adminReport');
    }
    const newQuery = {
      title:
        type === 'moreData'
          ? 'Пожалуйста, дополните ваш запрос'
          : 'Ваш запрос закрыт',
      desc: answer,
      adminId: user?.id,
      created_at: dayjs().toISOString()
    };
    const updatedObjArr = removeTypename(selectedRequest.queries);
    const updatedQueries = [...updatedObjArr, newQuery];
    updateRequestQuestion({
      variables: {
        id: selectedRequest?.id,
        input: {
          user: selectedRequest?.user?.id,
          status: type === 'moreData' ? 'moreDataForAdmin' : 'finished',
          isAnswerViewed: false,
          lastAnswerDate: dayjs().toISOString(),
          queries: updatedQueries
        }
      }
    });
  };

  return (
    <SalesSupport
      user={user}
      requests={requests}
      total={total}
      setPage={setPage}
      page={page}
      setSearch={setSearch}
      setFilter={setFilter}
      setSelectedRequest={setSelectedRequest}
      selectedRequest={selectedRequest}
      setShowRequestModal={setShowRequestModal}
      showRequestModal={showRequestModal}
      setAnswer={setAnswer}
      onSubmitAdminAnswer={onSubmitAdminAnswer}
      loading={loadingUpdateRequest}
      loadingRequests={loadingRequests}
      requestsCountData={requestsCountData}
      requestsCountLoading={requestsCountLoading}
      getRequestQuestionsCount={getRequestQuestionsCount}
      refetch={refetch}
      uploadRequestPhoto={uploadRequestPhoto}
      uploadedMediaData={uploadedMediaData}
      uploadedMediaLoading={uploadedMediaLoading}
    />
  );
};

export default withMainLayout(SalesSupportContainer);
