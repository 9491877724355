import * as React from "react";
const EyeIcon = (props) => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g opacity={props.opacity}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.0785 8.3115C1.97383 8.1175 1.97383 7.88216 2.0785 7.68816C3.33983 5.3555 5.66983 3.3335 7.99983 3.3335C10.3298 3.3335 12.6598 5.3555 13.9212 7.68883C14.0258 7.88283 14.0258 8.11816 13.9212 8.31216C12.6598 10.6448 10.3298 12.6668 7.99983 12.6668C5.66983 12.6668 3.33983 10.6448 2.0785 8.3115Z"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.41421 6.58579C10.1953 7.36683 10.1953 8.63317 9.41421 9.41421C8.63317 10.1953 7.36683 10.1953 6.58579 9.41421C5.80474 8.63317 5.80474 7.36683 6.58579 6.58579C7.36683 5.80474 8.63317 5.80474 9.41421 6.58579"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    </g>
  </svg>
);
export default EyeIcon;
