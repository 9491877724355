import { Breadcrumb, Button, Form, Input, Layout, Select } from 'antd';
import { GatewayOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { Link, useNavigate } from 'react-router-dom';
import React, { useState } from 'react';

import PropTypes from 'prop-types';
import StyledTooltip from '../shared/StyledTooltip';
import styled from 'styled-components';

const FormItem = Form.Item;

const { Header, Content } = Layout;

const StyledBreadcrumb = styled(Breadcrumb)`
  margin: 16px 0;
`;

const StyledHeader = styled(Header)`
  background: #fff;
  border-bottom: 1px solid #d9d9d9;
  padding: 0px 24px;
`;

const StyledLayout = styled(Layout)`
  padding: 0 24px 24px;
  background: #fff;
`;

const StyledContent = styled(Content)`
  padding: 24px;
  margin: 0px;
  min-height: 280px;
`;

const HeaderTitle = styled.div`
  font-family: SF Pro Display;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;

  display: flex;
  align-items: center;

  color: #262626;
  margin-bottom: 24px;
`;

const CreateTicket = ({ allUsers, allLevels, createClick, refetchUsers }) => {
  const [type, setType] = useState('');
  const [title, setTitle] = useState('');
  const [text, setText] = useState('');
  const [level, setLevel] = useState(null);
  const [users, setUsers] = useState([]);
  const [severity, setSeverity] = useState(1);
  const [affectedUsers, setAffectedUsers] = useState([]);

  const navigate = useNavigate();

  const getTooltip = (text, fullText) => {
    return <StyledTooltip {...{ text, fullText }} />;
  };

  const onFinish = values => {
    createClick(values);
    navigate('/tickets');
  };

  const handleSearch = value => {
    refetchUsers({
      query: {
        phoneNumber: value
      },
      pagination: {
        page: 1,
        limit: 20
      }
    });
  };

  return (
    <Layout>
      <StyledBreadcrumb>
        <Breadcrumb.Item>
          <Link to="/tickets">Tickets</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Create Ticket</Breadcrumb.Item>
      </StyledBreadcrumb>
      <StyledHeader>
        <h2>Create Ticket</h2>
      </StyledHeader>
      <StyledLayout>
        <StyledContent className="site-layout-background">
          <HeaderTitle>Main</HeaderTitle>
          <Form layout="vertical" hideRequiredMark onFinish={onFinish}>
            <FormItem
              key="title"
              label={getTooltip('Title', 'Title of the ticket')}
              name="title"
              rules={[{ required: true, message: 'Please enter the title' }]}
            >
              <Input
                placeholder="Title"
                value={title}
                onChange={e => setTitle(e.target.value)}
              />
            </FormItem>
            <FormItem
              key="type"
              label={getTooltip('Type', 'Type of the ticket')}
              name="type"
              rules={[{ required: true, message: 'Please select the type' }]}
            >
              <Select
                placeholder="Type"
                onChange={value => setType(value)}
              >
                <Select.Option value="issue">Issue</Select.Option>
                <Select.Option value="task">Task</Select.Option>
                <Select.Option value="bug">Bug</Select.Option>
              </Select>
            </FormItem>
            <FormItem
              key="users"
              label={getTooltip('Users', 'Users assigned to this ticket')}
              name="users"
              rules={[{ required: true, message: 'Please select the users' }]}
            >
              <Select
                filterOption={false}
                mode="multiple"
                placeholder="Users"
                showSearch
                onChange={value => setUsers(value)}
                onSearch={handleSearch}
              >
                {allUsers &&
                  allUsers.map(user => (
                    <Select.Option key={user.id} value={user.id}>
                      {user.email} | {user.phoneNumber}
                    </Select.Option>
                  ))}
              </Select>
            </FormItem>
            <FormItem
              key="level"
              label={getTooltip('Level', 'Level of the ticket')}
              name="level"
              rules={[{ required: true, message: 'Please select the level' }]}
            >
              <Select
                placeholder="Level"
                onChange={value => setLevel(value)}
              >
                {allLevels &&
                  allLevels.map(level => (
                    <Select.Option key={level.id} value={level.id}>
                      {level.name} | degree: {level.degree}
                    </Select.Option>
                  ))}
              </Select>
            </FormItem>
            <FormItem
              key="severity"
              label={getTooltip('Severity', 'Severity of the ticket (1-5)')}
              name="severity"
              rules={[{ required: true, message: 'Please select the severity' }]}
            >
              <Select
                placeholder="Severity"
                onChange={value => setSeverity(value)}
              >
                <Select.Option key="1" value="1">
                  1 - Low
                </Select.Option>
                <Select.Option key="2" value="2">
                  2 - Medium
                </Select.Option>
                <Select.Option key="3" value="3">
                  3 - High
                </Select.Option>
                <Select.Option key="4" value="4">
                  4 - Critical
                </Select.Option>
                <Select.Option key="5" value="5">
                  5 - Blocker
                </Select.Option>
              </Select>
            </FormItem>
            <FormItem
              key="affectedUsers"
              label={getTooltip('Affected Users', 'Users affected by this ticket')}
              name="affectedUsers"
            >
              <Select
                filterOption={false}
                mode="multiple"
                placeholder="Affected Users"
                showSearch
                onChange={value => setAffectedUsers(value)}
              >
                {allUsers &&
                  allUsers.map(user => (
                    <Select.Option key={user.id} value={user.id}>
                      {user.email} | {user.phoneNumber}
                    </Select.Option>
                  ))}
              </Select>
            </FormItem>
            <FormItem
              key="text"
              label={getTooltip('Text', 'Text of the ticket')}
              name="text"
              rules={[{ required: true, message: 'Please enter the text' }]}
            >
              <Input.TextArea
                rows={10}
                placeholder="Ticket Text"
                onChange={e => setText(e.target.value)}
              />
            </FormItem>
            <Form.Item style={{ textAlign: 'right' }}>
              <Button type="primary" htmlType="submit">
                Create Ticket
              </Button>
            </Form.Item>
          </Form>
        </StyledContent>
      </StyledLayout>
    </Layout>
  );
};

CreateTicket.propTypes = {
  allUsers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      email: PropTypes.string
    })
  ).isRequired,
  allLevels: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string,
      degree: PropTypes.number
    })
  ).isRequired,
  createClick: PropTypes.func.isRequired,
  refetchUsers: PropTypes.func.isRequired
};

export default CreateTicket;