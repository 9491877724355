import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Table, Button, Divider } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useNavigate } from "react-router-dom";


import EditForm from '../shared/EditFormModal';
import CreateForm from '../shared/CreateForm';

const SupportCategories = ({
	categories,
	handleAddCategory,
	handleDeleteCategory,
	handleUpdateCategory
}) => {
  const navigate = useNavigate();

  const [display, setDisplay] = useState('default');
  const [editValue, setEditValue] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);

  const columns = [
    {
      title: 'Название категории',
      dataIndex: 'name'
    },
		{
      title: 'Действие',
      key: 'action',
      render: (text, item) => (
        <span>
          <Button
            type="link"
            onClick={() => {
              setDisplay('edit');
              setEditValue(item);
            }}
          >
            Редактировать
          </Button>
          <Divider type="vertical" />
        </span>
      )
    },
    {
      title: 'Действие',
      key: 'action',
      render: (text, item) => (
        <span>
          <Button
            type="link"
            onClick={() => {
              navigate(`/supportCategories/${item._id}`);
            }}
          >
            Перейти к подкатегории
          </Button>
          <Divider type="vertical" />
        </span>
      )
    }
  ];

  const fields = [
    {
      key: 'name',
      label: 'Название',
      tooltipText: 'Название категории'
    }
  ];

  const editFields = [
    {
      key: 'name',
      label: 'Название',
      value: editValue && editValue.name,
      tooltipText: 'Название категории'
    }
  ];

  const handleCreate = values => {
    handleAddCategory(values);
    setModalVisible(false);
  };

  const handleUpdate = values => {
    handleUpdateCategory(editValue._id, values);
    setDisplay('default');
    setEditValue(null);
  };

  const handleDelete = () => {
    handleDeleteCategory(editValue._id);
    setDisplay('default');
    setEditValue(null);
  };

  return (
    <>
      <Table
        dataSource={categories}
        columns={columns}
        title={() => (
          <div>
            <Button type="primary" onClick={() => setModalVisible(true)}>
              <PlusOutlined /> Новая категория
            </Button>
          </div>
        )}
      />
      <CreateForm
        title="Добавить новую категорию"
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        onCreate={handleCreate}
        fields={fields}
      />
      {display === 'edit' && editValue && (
        <EditForm
          title="Редактировать категорию"
          visible={display === 'edit'}
          onCancel={() => setDisplay('default')}
          onUpdate={handleUpdate}
          onDelete={handleDelete}
          fields={editFields}
        />
      )}
    </>
  );
};

SupportCategories.propTypes = {
  categories: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
  handleDeleteCategory: PropTypes.func.isRequired,
  handleAddCategory: PropTypes.func.isRequired,
  handleUpdateCategory: PropTypes.func.isRequired
};

export default SupportCategories;