import React from 'react';
import styled from 'styled-components';

import SupportHeader from './SupportHeader';
import SupportBody from './SupportBody';
import SupportRequestModal from './SupportRequestModal';
import Loading from '../shared/Loading';
const Container = styled.div`
    padding: 8px;
    background-color: white;
    border-radius: 8px;
`;
const Cont = styled.div`
    display: flex;
    height: 300px;
`;
const Support = ({
                   requests,
                   total,
                   setPage,
                   page,
                   setSearch,
                   setActiveMainTab,
                   activeMainTab,
                   setActiveSubTab,
                   activeSubTab,
                   setSelectedRequest,
                   selectedRequest,
                   setShowRequestModal,
                   showRequestModal,
                   setAnswer,
                   onSubmitAdminAnswer,
                   admins,
                   setChoosedAdmin,
                   choosedAdmin,
                   changeAdmin,
                   loading,
                   loadingRequests,
                   requestsCountData,
                   requestsCountLoading,
                   setAssigned,
                   dateRange,
                   setDateRange,
                 }) => {
  const renderStatus = (status) => {
    switch (status) {
      case 'inProgress':
        return `В обработке`;
      case 'notViewed':
        return `Не прочитано`;
      case 'moreDataForAdmin':
        return `Дополняется`;
      case 'finished':
        return 'Решено';
      default:
        return 'Не прочитано';
    }
  };

  return (
    <Container>
      <SupportHeader
        setPage={setPage}
        setSearch={setSearch}
        setActiveMainTab={setActiveMainTab}
        activeMainTab={activeMainTab}
        setActiveSubTab={setActiveSubTab}
        activeSubTab={activeSubTab}
        requestsCountData={requestsCountData}
        requestsCountLoading={requestsCountLoading}
        setAssigned={setAssigned}
        dateRange={dateRange}
        setDateRange={setDateRange}
      />
      {loadingRequests ? (
        <Cont>
          <Loading />
        </Cont>
      ) : (
        <SupportBody
          requests={requests}
          total={total}
          setPage={setPage}
          page={page}
          setSelectedRequest={setSelectedRequest}
          setShowRequestModal={setShowRequestModal}
          renderStatus={renderStatus}
        />
      )}
      <SupportRequestModal
        setSelectedRequest={setSelectedRequest}
        selectedRequest={selectedRequest}
        setShowRequestModal={setShowRequestModal}
        showRequestModal={showRequestModal}
        renderStatus={renderStatus}
        setAnswer={setAnswer}
        onSubmitAdminAnswer={onSubmitAdminAnswer}
        admins={admins}
        setChoosedAdmin={setChoosedAdmin}
        choosedAdmin={choosedAdmin}
        pressChangeAdmin={changeAdmin}
        loading={loading}
      />
    </Container>
  );
};

export default Support;
