import React, { useEffect, useState } from 'react';
import withMainLayout from '../../hocs/withMainLayout';
import { 
    Tooltip, 
    Table, 
    Spin, 
    Button, 
    Row, 
    Col,
} from 'antd';
import { gql, useQuery, useMutation, useLazyQuery } from '@apollo/client';
import { FilterOutlined, EditOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import 'dayjs/locale/ru';
import { GET_USERS } from '../salesSupport/SalesSupport.queries';
import InBodyFilter from './inBodyFilter';
import InBodyEdit from './InBodyEdit';

const GET_USER_IN_BODY_TESTS_PAGINATION = gql`
  query UserInBodyTestsByUserPagination($filters: UserInBodyTestFilters, $page: Int!, $limit: Int!) {
    userInBodyTestsByUserPagination(filters: $filters, page: $page, limit: $limit) {
        totalCount
        totalPages
        currentPage
        items {
            id
            testDate
            GENDER
            HT
            AGE
            WT
            TBW
            PROTEIN
            MINERAL
            FFM
            BFM         
            SMM
            BMI
            PBF         
            LRA
            LLA
            LT
            VFALevel
            EQUIP
            metabolicAge
            TOT_SCORE
            user {
                id
                username
                nickname
                firstName
                lastName
                phoneNumber
            }
        }
    }
    }
`;

const UPDATE_USER_INBODY_TEST = gql`
    mutation UpdateUserInBodyTest($id: ID!, $input: UserInBodyTestInput!) {
        updateUserInBodyTest(id: $id, input: $input) {
            id
            user {
                id
            }
        }
    }
`;

const InBodyContainer = () => {
    const [currentPage, setCurrentPage] = useState(1);
    const [openFilter, setOpenFilter] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);
    const [isUserNull, setIsUserNull] = useState(true);
    const [searchTerm, setSearchTerm] = useState('');
    const [users, setUsers] = React.useState([]);
    const [pageSize] = useState(10);
    const [item, setItem] = useState({});

    const { data, loading, refetch } = useQuery(GET_USER_IN_BODY_TESTS_PAGINATION, {
      variables: { page: currentPage, limit: pageSize },
      fetchPolicy: 'cache-and-network',
    });

    const [updateUserInBodyTest, { loading: updtLoading }] = useMutation(UPDATE_USER_INBODY_TEST, {
        onCompleted: (data) => {
            refetch({
                page: 1,
                limit: pageSize,
            });
            setOpenEdit(false);
        },
        onError: (error) => {
          console.error('Произошла ошибка при выполнении мутации:', error);
          setOpenEdit(false);
        },
    });

    const { items, totalCount } = data?.userInBodyTestsByUserPagination || {};

    const [
        getUsers,
        {
          loading: loadingUsers,
          error: errorUsers,
          data: dataUsers,
          refetch: refetchUsers
        }
    ] = useLazyQuery(GET_USERS);

    useEffect(() => {
        if (dataUsers && dataUsers.findUsers && !loadingUsers) {
          setUsers(dataUsers.findUsers.users);
        } else if (errorUsers) {
          console.log(`error `, errorUsers);
        }
    }, [dataUsers, loadingUsers, errorUsers]);

    useEffect(() => {
        refetchUsers({
            query: {
                phoneNumber: searchTerm
            },
            pagination: {
                page: 1,
                limit: 20
            }
        });
    }, [searchTerm]);

    const columns = [
        {
            title: 'InBody ID',
            dataIndex: 'id',
            key: 'id',
            width: '15%',
            render: (text) => (
              <Tooltip title={text}>
                <span style={{
                  display: 'inline-block',
                  maxWidth: '90px',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  verticalAlign: 'middle',
                  cursor: 'pointer'
                }}>
                  {text}
                </span>
              </Tooltip>
            ),
        },
        {
            title: 'Ник пользователя',
            dataIndex: ['user', 'nickname'],
            key: 'nickname',
            render: (text, item) => {
                return <span>{item?.user ? text : 'Пользователь не закреплен'}</span>;
            },
        },
        {
            title: 'Дата прохождения',
            dataIndex: 'testDate',
            key: 'testDate',
            render: (_text, item) => {
                const dateReceived = dayjs(item?.testDate).format(
                    'Do MMMM, YYYY, HH:mm'
                );
                return <span>{dateReceived}</span>;
            },
        },
        {
            title: 'Пол',
            dataIndex: 'GENDER',
            key: 'GENDER',
            align: 'center'
        },
        {
            title: 'Возраст',
            dataIndex: 'AGE',
            key: 'AGE',
            align: 'center'
        },
        {
            title: 'Вес',
            dataIndex: 'WT',
            key: 'WT',
            align: 'center'
        },
        {
            title: 'Рост',
            dataIndex: 'HT',
            key: 'HT',
            align: 'center'
        },
        {
            title: 'Метаболический возраст',
            dataIndex: 'metabolicAge',
            key: 'metabolicAge',
            align: 'center'
        },
        {
            title: 'Общий результат',
            dataIndex: 'TOT_SCORE',
            key: 'TOT_SCORE',
            align: 'center'
        },
        {
            title: '',
            key: 'action',
            render: (_, record) => {

                return (
                    <Button 
                        type={"primary"}
                        style={{width: '100px'}}
                        onClick={() => {
                            setItem(record);
                            setOpenEdit(true);
                        }}
                    >
                        Изменить
                    </Button>
                );
            },
          },
    ];

    const onFinish = (values) => {
        const formattedValues = {
            ...values,
            range: values.range
                ? values.range.map((date) => dayjs(date).toISOString())
                : undefined,
        };
    
        refetch({
            filters: {
                isUserNull: formattedValues?.isUserNull,
                phoneNumber: formattedValues?.phoneNumber,
                range: formattedValues?.range,
                HT: formattedValues?.HT,
                WT: formattedValues?.WT,
                AGE: formattedValues?.AGE,
                GENDER: formattedValues?.GENDER,
            },
            page: 1,
            limit: pageSize,
        });
        setOpenFilter(false);
    };
    
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const handleUserInputChange = (value) => {
        const cleanedValue = value.replace(/\D/g, '');
        setSearchTerm(cleanedValue);
    };

    const onFinishEdit = (phoneNumber, item) => {        
        const user = users?.find(item => item?.phoneNumber === phoneNumber);

        if(phoneNumber === item?.user?.phoneNumber) {
            return console.log('Пользователь не был изменен');
        }
        
        if(!phoneNumber) {
            return console.log('Пользователь не был выбран');
        }

        if(!user) {
            return console.log('Пользователь не найден');
        }

        updateUserInBodyTest({
            variables: { 
                id: item?.id, 
                input: {
                    user: user?.id
                }
            }
        });
    };    
    
    const onFinishEditFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const handleUserEditChange = (value) => {
        const cleanedValue = value.replace(/\D/g, '');
        setSearchTerm(cleanedValue);
    };

    return (
        <Spin spinning={loading || updtLoading}>
            <InBodyFilter
                users={users}
                openFilter={openFilter}
                setOpenFilter={setOpenFilter}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                isUserNull={isUserNull}
                setIsUserNull={setIsUserNull}
                handleUserInputChange={handleUserInputChange}
            />

            <InBodyEdit
                item={item}
                users={users}
                openEdit={openEdit}
                setOpenEdit={setOpenEdit}
                onFinishEdit={onFinishEdit}
                setSearchTerm={setSearchTerm}
                onFinishEditFailed={onFinishEditFailed}
                handleUserEditChange={handleUserEditChange}
            />

            <Col style={{backgroundColor: 'white', borderRadius: '8px', padding: '10px'}}>
                <Row style={{marginBottom: '10px'}} justify={'end'}>
                    <Button 
                        type={'primary'} 
                        icon={<FilterOutlined />}
                        onClick={() => setOpenFilter(true)}
                    >
                        Фильтр
                    </Button>
                </Row>
                <Row>
                    <Table
                        dataSource={items}
                        columns={columns}
                        rowKey={(item) => item.id}
                        style={{width: '100%'}}
                        pagination={{
                            defaultPageSize: pageSize,
                            total: totalCount,
                            current: currentPage,
                            onChange: (page) => setCurrentPage(page),
                            showSizeChanger: false,
                        }}
                    />
                </Row>
            </Col>
        </Spin>
    );
};

export default withMainLayout(InBodyContainer);
