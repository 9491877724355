import React from 'react';
import { Button, Form, DatePicker, TimePicker, Select, Input } from 'antd';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { useLoading } from '../../context/useLoading';
import showConfirm from '../shared/DeleteConfirm';

const { RangePicker } = TimePicker;

function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();
    return <Component {...props} router={{ location, navigate, params }} />;
  }

  return ComponentWithRouterProp;
}

const StyledDatePicker = styled(DatePicker)`
  width: 100%;
`;

const StyledRangePicker = styled(RangePicker)`
  width: 100%;
`;

const StyledForm = styled(Form)`
  width: 40%;
`;

const StyledTitle = styled.div`
  font-size: 16px;
  font-weight: 600;
  padding: 20px 0;
`;

const StyledButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const StyledDeletButton = styled(Button)`
  border: 2px solid red;
  color: red;
`;

const FormItem = Form.Item;

const CurrentEventForm = ({
  event,
  clubs,
  programSets,
  trainers,
  updateEvent,
  addEvent,
  deleteEvent,
  notificationChangeTrainer,
}) => {
  const [currentClubId, setCurrentClubId] = React.useState(
    event?.programSet?.club.id
  );
  const [editForm] = Form.useForm();

  React.useEffect(() => {
    setCurrentClubId(event?.programSet?.club.id);

    return editForm.resetFields();
  }, [event]);

  const { showLoading, hideLoading } = useLoading();

  const parseValues = (values) => {
    const startDate = values.startDate.startOf('day');
    // calculate formatted time as StartingDate + StartingHour
    const startTime = dayjs(startDate).add(
      (dayjs(values.duration[0]) - dayjs(values.duration[0]).startOf('day')) /
        1000,
      'seconds'
    );
    // calculate formatted time as StartingDate + EndingHour
    const endTime = dayjs(startDate).add(
      (dayjs(values.duration[1]) - dayjs(values.duration[1]).startOf('day')) /
        1000,
      'seconds'
    );
    const parseLimit = parseInt(values.limitStation);
    return {
      programSet: values.programSet,
      trainer: values.trainer,
      startTime,
      endTime,
      limitStation: parseLimit > 0 ? parseInt(values.limitStation) : null,
    };
  };

  const getNextWeekValues = (values) => {
    const startTime = dayjs(values.startTime).add(7, 'days');
    const endTime = dayjs(values.endTime).add(7, 'days');

    return {
      ...values,
      startTime,
      endTime,
    };
  };

  const handleUpdateEvent = () => {
    editForm.validateFields().then((val) => {
      showLoading();
      updateEvent({
        variables: {
          id: event?.id,
          input: parseValues(val),
        },
      }).then(() => {});
      if (event && event.trainer && event.trainer.id !== val.trainer) {
        notificationChangeTrainer({
          variables: {
            eventId: event.id,
          },
        });
      }
    });
  };

  const handleDeleteEvent = () => {
    showLoading();
    deleteEvent({
      variables: {
        id: event.id,
      },
    }).then(() => {});
  };

  const handleCopyEvent = () => {
    showLoading();
    editForm.validateFields().then((val) => {
      let values = parseValues(val);

      values = getNextWeekValues(values);
      addEvent({
        variables: {
          input: values,
        },
      });
    });
  };
  return (
    <>
      <StyledTitle>{event.programSet?.name}</StyledTitle>
      <StyledForm
        form={editForm}
        layout="vertical"
        hideRequiredMark
        key={event.id}
      >
        <FormItem
          key="StartTime"
          label="Дата события"
          name="startDate"
          rules={[
            {
              required: true,
              message: `Пожалуйста, выберите дату`,
            },
          ]}
          initialValue={dayjs(event?.startTime)}
        >
          <StyledDatePicker placeholder="Выберите дату" />
        </FormItem>
        <FormItem
          key="Duration"
          label="Продолжительность"
          name="duration"
          rules={[
            {
              required: true,
              message: `Пожалуйста, выберите время`,
            },
          ]}
          initialValue={[dayjs(event.startTime), dayjs(event.endTime)]}
        >
          <StyledRangePicker showTime />
        </FormItem>
        <FormItem
          key="club"
          label="Клуб"
          name="club"
          rules={[
            {
              required: true,
              message: `Пожалуйста, выберите клуб`,
            },
          ]}
          initialValue={event?.programSet?.club.id}
        >
          <Select
            placeholder="Выберите клуб"
            showSearch
            onChange={setCurrentClubId}
          >
            {clubs.map((club) => (
              <Select.Option key={club.id} value={club.id}>
                {club.name}
              </Select.Option>
            ))}
          </Select>
        </FormItem>
        <FormItem
          key="ProgramSet"
          label="Программа"
          name="programSet"
          rules={[
            {
              required: true,
              message: `Пожалуйста, выберите программу`,
            },
          ]}
          initialValue={event.programSet?.id}
        >
          <Select
            placeholder="Выберите программу"
            showSearch
            disabled={!currentClubId}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {programSets
              .filter((programSet) => programSet?.club?.id === currentClubId)
              .map((programSet) => (
                <Select.Option key={programSet.id} value={programSet.id}>
                  {programSet.name}
                </Select.Option>
              ))}
          </Select>
        </FormItem>
        <FormItem
          key="Trainer"
          label="Тренер"
          name="trainer"
          initialValue={event?.trainer?.id}
        >
          <Select
            placeholder="Выберите тренера"
            showSearch
            disabled={!currentClubId}
          >
            {trainers.map((trainer) => (
              <Select.Option key={trainer.id} value={trainer.id}>
                {trainer.nickname}
              </Select.Option>
            ))}
          </Select>
        </FormItem>
        <FormItem
          key="limitStation"
          label="Лимит станции"
          name="limitStation"
          initialValue={event?.limitStation}
        >
          <Input placeholder="Лимит станции" type="number" />
        </FormItem>
        <StyledButtonsContainer>
          <StyledDeletButton
            onClick={() => {
              showConfirm(() => {
                handleDeleteEvent();
              });
            }}
          >
            Удалить
          </StyledDeletButton>
          <Button onClick={handleUpdateEvent}>Сохранить изменения</Button>
        </StyledButtonsContainer>
        <Button onClick={handleCopyEvent}>
          Повторить событие через неделю
        </Button>
      </StyledForm>
    </>
  );
};

CurrentEventForm.propTypes = {
  event: PropTypes.shape({
    id: PropTypes.string,
    startTime: PropTypes.string,
    endTime: PropTypes.string,
    trainer: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      nickname: PropTypes.string,
    }),
    programSet: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      club: PropTypes.shape({
        id: PropTypes.string,
      }),
    }),
  }).isRequired,
  clubs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    })
  ).isRequired,
  programSets: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    })
  ).isRequired,
  trainers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      role: PropTypes.string,
      nicknmae: PropTypes.string,
    })
  ).isRequired,
};

export default withRouter(CurrentEventForm);
