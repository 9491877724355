import HALF_RACK_FULL from '../../images/HALF_RACK_FULL.svg';
import HIP_THRUST from '../../images/HIP_THRUST.svg';
import LAT_MACHINE_CONVERGENT from '../../images/LAT_MACHINE_CONVERGENT.svg';
import LEG_PRESS_45 from '../../images/LEG_PRESS_45.svg';
import POWER_RUNNER from '../../images/POWER_RUNNER.svg';
import SQUAT_LUNGE from '../../images/SQUAT_LUNGE.svg';
import SUPER_VERTICAL_CHEST_PRESS from '../../images/SUPER_VERTICAL_CHEST_PRESS.svg';
import SUPER_DELTOID_PRESS from '../../images/SUPER_DELTOID_PRESS.svg';
import SUPER_INCLINED_BENCH_PRESS from '../../images/SUPER_INCLINED_BENCH_PRESS.svg';
import SUPER_ROWING from '../../images/SUPER_ROWING.svg';
import ABDOMINAL_CRUNCH from '../../images/ABDOMINAL_CRUNCH.svg';
import ABDUCTOR_MACHINE from '../../images/ABDUCTOR_MACHINE.svg';
import ADJUSTABLE_CABLE_STATION_WITH_BAR from '../../images/ADJUSTABLE_CABLE_STATION_WITH_BAR.svg';
import CALF_MACHINE from '../../images/CALF_MACHINE.svg';
import CURLING_MACHINE from '../../images/CURLING_MACHINE.svg';
import HORIZONTAL_LEG_PRESS from '../../images/HORIZONTAL_LEG_PRESS.svg';
import JUNGLE_MACHINE_HLP from '../../images/JUNGLE_MACHINE_HLP.svg';
import LEG_CURLING from '../../images/LEG_CURLING.svg';
import LEG_EXTENSION from '../../images/LEG_EXTENSION.svg';
import MASTER_GLUTEUS_PLUS from '../../images/MASTER_GLUTEUS_PLUS.svg';
import PECK_BACK from '../../images/PECK_BACK.svg';
import TRICEPS_MACHINE from '../../images/TRICEPS_MACHINE.svg';
import FULLY_ADJUSTABLE_BENCH from '../../images/FULLY_ADJUSTABLE_BENCH.svg';
import BENCH_PRESS from '../../images/BENCH_PRESS.svg';
import ADJUSTABLE_BACK_EXTENSION from '../../images/ADJUSTABLE_BACK_EXTENSION.svg';
import TREAD_MILL from '../../images/TREAD_MILL.svg';
import PRESS_STOMACH from '../../images/PRESS_STOMACH.svg';

const icons = {
  HALF_RACK_FULL,
  HIP_THRUST,
  LAT_MACHINE_CONVERGENT,
  LEG_PRESS_45,
  POWER_RUNNER,
  SQUAT_LUNGE,
  SUPER_VERTICAL_CHEST_PRESS,
  SUPER_DELTOID_PRESS,
  SUPER_INCLINED_BENCH_PRESS,
  SUPER_ROWING,
  ABDOMINAL_CRUNCH,
  ABDUCTOR_MACHINE,
  ADJUSTABLE_CABLE_STATION_WITH_BAR,
  CALF_MACHINE,
  CURLING_MACHINE,
  HORIZONTAL_LEG_PRESS,
  JUNGLE_MACHINE_HLP,
  LEG_CURLING,
  LEG_EXTENSION,
  MASTER_GLUTEUS_PLUS,
  PECK_BACK,
  TRICEPS_MACHINE,
  FULLY_ADJUSTABLE_BENCH,
  BENCH_PRESS,
  ADJUSTABLE_BACK_EXTENSION,
  TREAD_MILL,
  PRESS_STOMACH
};
                  
export default icons;