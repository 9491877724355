import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { gql, useMutation, useQuery, useLazyQuery } from "@apollo/client";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";

import Message from "./Message";
import Loading from "../shared/Loading";
import { useLoading } from "../../context/useLoading";
import withMainLayout from "../../hocs/withMainLayout";

const GET_DATA = gql`
  query getData($id: ID!) {
    message(id: $id) {
      id
      title
      type
      html
      json
      users {
        id
        email
      }
      level {
        id
        name
        degree
      }
    }
    levels {
      id
      name
      degree
    }
  }
`;
const GET_USERS = gql`
  query find($query: UsersQuery, $pagination: PaginationQuery) {
    findUsers(queryUsers: $query, pagination: $pagination) {
      users {
        id
        nickname
        email
        phoneNumber
      }
      total
      limit
      page
      pages
    }
  }
`;

const UPDATE_MESSAGE = gql`
  mutation updateMessage($id: ID!, $input: MessageInput) {
    updateMessage(id: $id, input: $input) {
      id
      title
      type
      html
      json
      users {
        id
        email
      }
      level {
        id
        name
        degree
      }
    }
  }
`;
const DELETE_MESSAGE = gql`
  mutation deleteMessage($id: ID!) {
    deleteMessage(id: $id)
  }
`;
// todo: fix the bug when level name disappears

const MessageContainer = () => {
  const { id: messageId } = useParams();
  const navigate = useNavigate();
  const [messageData, setMessageData] = React.useState(null);
  const [users, setUsers] = React.useState([]);
  const [levels, setLevels] = React.useState(null);
  const { showLoading, hideLoading } = useLoading();

  const { data, loading, error } = useQuery(GET_DATA, {
    variables: { id: messageId }
  });
  const [updateMessage] = useMutation(UPDATE_MESSAGE, {
    onCompleted() {
      hideLoading();
      toast.success("Сообщение успешно отредактировано");
    }
  });

  const [deleteMessage] = useMutation(DELETE_MESSAGE, {
    onCompleted() {
      hideLoading();
      toast.success("Сообщение успешно удалено");
      navigate("/messages");
    }
  });

  const [
    getUsers,
    {
      loading: loadingUsers,
      error: errorUsers,
      data: dataUsers,
      refetch: refetchUsers
    }
  ] = useLazyQuery(GET_USERS);

  useEffect(() => {
    if (data && data.message && data.users && data.levels) {
      setMessageData(data.message);
      setLevels(data.levels);
    } else if (error) {
      console.log("error", error);
    }
  }, [data, loading, error]);

  useEffect(() => {
    getUsers({
      pagination: {
        page: 1,
        limit: 20
      }
    });
  }, []);

  if (loading && (!messageData || !users || !levels))
    return (
      <div>
        <Loading />
      </div>
    );

  const handleUpdateClick = (values) => {
    showLoading();
    updateMessage({ variables: { id: messageId, input: values } });
  };

  const handleDeleteClick = () => {
    showLoading();
    deleteMessage({ variables: { id: messageId } });
  };
  return (
    <>
      {messageData && levels && (
        <Message
          message={messageData}
          allUsers={users}
          allLevels={levels}
          updateClick={handleUpdateClick}
          deleteClick={handleDeleteClick}
          refetchUsers={refetchUsers}
        />
      )}
    </>
  );
};

MessageContainer.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired
    })
  }).isRequired
};

export default withMainLayout(MessageContainer);
