import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useNavigate, useParams } from "react-router-dom";

import { gql, useMutation, useQuery, useLazyQuery } from "@apollo/client";
import { toast } from "react-toastify";

import Marathon from "./Marathon";
import Loading from "../shared/Loading";
import { useLoading } from "../../context/useLoading";
import withMainLayout from "../../hocs/withMainLayout";
import { removeTypenameField } from "../../utils/apollo";

const GET_MARATHON = gql`
  query allPlanRecomendedForMarthon($marathonId: ID) {
    allPlanRecomendedForMarthon(marathonId: $marathonId) {
      id
      name
      type
      imageURL
      description
      duration
      price
      countClasses
      assessmentClasses
      educationClasses
      xPosition
      yPosition
      floorCount
      district
      rating
      planImageURL
      heroPathLevel {
        identifier
        degree
      }
      floors {
        floorNumber
        imageURL
      }
      awardsWithPercent {
        award {
          id
          name
          description
          imageURL
        }
        completionPercent
      }

      tasks {
        id
        name
        type
        typeOfClass
        typeOfClassMultiple
        visits
        countReads
        club {
                id
            }
      }
      planRecomended {
        week
        club {
          id
        }
        trainings {
          types {
            type
            shortDescription
            imageURL
          }
        }
      }
    }
    usersByRole(role: "trainer") {
      id
      role
      nickname
    }
    heroPathes {
      id
      name
      identifier
    }
    awards {
      id
      name
      type
      imageURL
    }
    lecturesByMarathonId(marathonId: $marathonId) {
      id
      title
      html
      json
      weekNumber
      questions {
        text
        options
        correctAnswers
        isMultipleAnswers
      }
    }
  }
`;

const UPDATE_MARATHON = gql`
  mutation updateMarathon($id: ID!, $input: MarathonInput) {
    updateMarathon(id: $id, input: $input) {
      id
    }
  }
`;

const DELETE_MARATHON = gql`
  mutation deleteMarathon($id: ID!) {
    deleteMarathon(id: $id)
  }
`;

const ADD_TASK_TO_MARATHON = gql`
  mutation addTaskToMarathon($id: ID!, $input: TaskInput) {
    addTaskToMarathon(id: $id, input: $input) {
      id
    }
  }
`;

const ADD_LECTURE = gql`
  mutation addLecture($input: LectureInput) {
    addLecture(input: $input) {
      id
    }
  }
`;

const UPDATE_LECTURE = gql`
  mutation updateLecture($id: ID!, $input: LectureInput) {
    updateLecture(id: $id, input: $input) {
      id
    }
  }
`;

const DELETE_TASK_FROM_MARATHON = gql`
  mutation deleteTaskFromMarathon($marathonId: ID, $taskId: ID) {
    deleteTaskFromMarathon(marathonId: $marathonId, taskId: $taskId) {
      id
    }
  }
`;

const UPDATE_TASK = gql`
  mutation updateTask($id: ID!, $input: TaskInput) {
    updateTask(id: $id, input: $input) {
      id
    }
  }
`;

const ADD_MARATHON_EVENT = gql`
  mutation addMarathonEvent($input: MarathonEventInput) {
    addMarathonEvent(input: $input) {
      id
    }
  }
`;

const UPDATE_MARATHON_EVENT = gql`
  mutation updateMarathonEvent($id: ID!, $input: MarathonEventInput) {
    updateMarathonEvent(id: $id, input: $input) {
      id
    }
  }
`;

const DELETE_MARATHON_EVENT = gql`
  mutation deleteMarathonEvent($id: ID!) {
    deleteMarathonEvent(id: $id)
  }
`;

const GET_MARATHON_EVENTS = gql`
  query marathonEventsByMarathonId($marathonId: ID,$query: PaginationQuery) {
    marathonEventsByMarathonId(marathonId: $marathonId, query: $query) {
      marathonEvents {
        id
        startTime
        endTime
        trainer {
          id
          nickname
        }
      }
      total
      limit
      page
      pages
    }
  }
`;

const GET_CLUBS = gql `
  query Clubs {
    clubs {
        id
        name
        city
    }
  }
`;

const MarathonContainer = ({ match }) => {
  const { id: marathonId } = useParams();
  const { showLoading, hideLoading } = useLoading();
  const [page, setPage] = useState(1);
  const navigate = useNavigate();
  const [marathon, setMarathon] = useState(null);
  const [trainers, setTrainers] = useState([]);
  const [awards, setAwards] = useState([]);
  const [lectures, setLectures] = useState([]);
  const [heroPathes, setHeroPathes] = useState([]);
  const [marathonEvents, setMarathonEvents] = useState([]);
  const [marathonEventsCnt, setMarathonEventsCnt] = useState(0);
  const [clubs, setClubs] = useState([]);
  const [choosedClub, setChoosedClub] = useState(null);

  useEffect(() => {
    if (clubs.length > 0) {
      setChoosedClub(clubs[0]);
    }
  }, [clubs]);

  const { data, loading, error, refetch } = useQuery(GET_MARATHON, {
    variables: {
      marathonId: marathonId
    }
  });

  const {
    loading: loadingMarathonEvents,
    error: errorMarathonEvents,
    data: dataMarathonEvents,
    refetch: refetchMarathonEvents
  } = useQuery(GET_MARATHON_EVENTS, {
    variables: {
      marathonId: marathonId,
      query: {
        page: 1,
        limit: 10
      }
    }
  });

  const {data: dataClubs, loadingClubs, error: errorClubs} = useQuery(GET_CLUBS);

  useEffect(() => {
    if (data && data.allPlanRecomendedForMarthon && data.usersByRole) {
      setMarathon(data.allPlanRecomendedForMarthon);
      setTrainers(data.usersByRole);
      setLectures(data.lecturesByMarathonId);
      setAwards(data.awards.filter((award) => award.type === "marathon"));
      setHeroPathes(data.heroPathes);
    }
  }, [data, loading, error]);

  React.useEffect(() => {
    if (
      dataMarathonEvents &&
      dataMarathonEvents.marathonEventsByMarathonId &&
      !loadingMarathonEvents
    ) {
      setMarathonEvents(
        dataMarathonEvents.marathonEventsByMarathonId.marathonEvents
      );
      setMarathonEventsCnt(dataMarathonEvents.marathonEventsByMarathonId.total);
    } else if (errorMarathonEvents) {
      console.log(`error `, errorMarathonEvents);
    }
  }, [dataMarathonEvents, loadingMarathonEvents, errorMarathonEvents]);

  React.useEffect(()=> {
    if (dataClubs && dataClubs.clubs && !loadingClubs) {
      setClubs(dataClubs.clubs);
    }
  }, [dataClubs, loadingClubs, errorClubs]);

  React.useEffect(() => {
    refetchMarathonEvents({
      marathonId: marathonId,
      query: {
        page: page,
        limit: 10
      }
    });
  }, [page]);

  const [updateMarathon] = useMutation(UPDATE_MARATHON, {
    onCompleted() {
      hideLoading();
      refetch();
      toast.success("Марафон обновлен");
    },
    onError(updateError) {
      toast.error(updateError.graphQLErrors[0]?.message);
    }
  });

  const handleUpdateMarathonAwards = (values) => {
    const newValues = removeTypenameField(values);

    updateMarathon({
      variables: {
        id: marathonId,
        input: newValues
      }
    });
  };

  const handleUpdateMarathonFloors = (values) => {
    const newValues = removeTypenameField(values);


    updateMarathon({
      variables: {
        id: marathonId,
        input: newValues
      }
    });
  };
  const handleUpdateMarathon = (values) => {
    showLoading();

    const newValues = removeTypenameField({
      ...values,
      xPosition: parseFloat(values.xPosition),
      yPosition: parseFloat(values.yPosition),
      floorCount: parseInt(values.floorCount, 10),
      rating: parseInt(values.rating, 10),
      price: parseInt(values.price, 10),
      countClasses: parseInt(values.countClasses, 10),
      assessmentClasses: parseInt(values.assessmentClasses, 10),
      educationClasses: parseInt(values.educationClasses, 10),
      duration: parseInt(values.duration, 10),
      heroPathLevel:
        values?.heroPathLevel?.identifier === ""
          ? null
          : {
              identifier: values?.heroPathLevel?.identifier,
              degree: parseInt(values?.heroPathLevel?.degree)
            }
    });

    updateMarathon({
      variables: {
        id: marathonId,
        input: newValues
      }
    });
  };

  const mergeTasks = (tasks, newTask) => {
    if(newTask === null){
      return tasks;
    }
    const { week, club, trainings } = newTask;
    const existingTaskIndex = tasks.findIndex(task => task.week === week && task.club === club);
  
    if (existingTaskIndex !== -1) {
      tasks[existingTaskIndex].trainings = [
        ...tasks[existingTaskIndex].trainings,
        ...trainings
      ];
    } else {
      tasks.push(newTask);
    }
  
    return tasks;
  };

  const transformOldTask = (oldTask) => {
    return {
      week: oldTask.week,
      trainings: oldTask.trainings.map(training => ({
        types: training.types.map(type => ({
          type: type.type
        }))
      })),
      club: oldTask.club.id
    };
  };

  const handleUpdatePlanMarathon = ({ tasks, newTask }) => {
    const transformedOldTasks = tasks.map(transformOldTask);
    const updatedTasks = mergeTasks(transformedOldTasks, newTask);
    const planRecomended = {
      planRecomended: updatedTasks
    };
  
    updateMarathon({
      variables: {
        id: marathonId,
        input: planRecomended
      }
    });
  };

  const [deleteMarathon] = useMutation(DELETE_MARATHON, {
    onCompleted() {
      hideLoading();
      toast.success("Марафон успешно удален");
      navigate("/marathons");
    }
  });

  const handleDeleteMarathon = () => {
    showLoading();
    deleteMarathon({
      variables: {
        id: marathonId
      }
    });
  };

  const [addTaskToMarathon] = useMutation(ADD_TASK_TO_MARATHON, {
    onCompleted() {
      hideLoading();
      toast.success("Задание добавлено в марафон");
      refetch();
    }
  });

  const [addLecture] = useMutation(ADD_LECTURE, {
    onCompleted() {
      hideLoading();
      toast.success("Лекция добавлено в марафон");
      refetch();
    }
  });

  const handleAddTask = (values) => {
    showLoading();
    const newValues = {
      ...values,
      visits: parseInt(values.visits, 10),
      countReads: parseInt(values.countReads, 10)
    };
    addTaskToMarathon({
      variables: {
        id: marathonId,
        input: newValues
      }
    });
  };

  const [deleteTaskFromMarathon] = useMutation(DELETE_TASK_FROM_MARATHON, {
    onCompleted() {
      hideLoading();
      refetch();
      toast.success("Задание удалено из марафона");
    }
  });

  const handleDeleteTask = (taskId) => {
    showLoading();
    deleteTaskFromMarathon({
      variables: {
        marathonId,
        taskId
      }
    });
  };

  const [updateTask] = useMutation(UPDATE_TASK, {
    onCompleted() {
      hideLoading();
      refetch();
      toast.success("Задание обновлено");
    }
  });

  const [updateLecture] = useMutation(UPDATE_LECTURE, {
    onCompleted() {
      hideLoading();
      refetch();
      toast.success("Задание обновлено");
    }
  });

  const handleUpdateTask = (taskId, values) => {
    showLoading();
    const newValues = removeTypenameField({
      ...values,
      visits: parseInt(values.visits, 10),
      countReads: parseInt(values.countReads, 10)
    });

    updateTask({
      variables: {
        id: taskId,
        input: newValues
      }
    });
  };

  const [addMarathonEvent] = useMutation(ADD_MARATHON_EVENT, {
    onCompleted() {
      hideLoading();
      refetchMarathonEvents({
        marathonId: marathonId,
        query: {
          page: page,
          limit: 10
        }
      });
      toast.success("Marathon event added");
    }
  });

  const handleAddMarathonEvent = ({ values }) => {
    showLoading();
    const newValues = {
      ...values,
      marathon: marathonId
    };

    addMarathonEvent({
      variables: {
        input: newValues
      }
    });
  };

  const handleAddLecture = (values) => {
    showLoading();
    addLecture({
      variables: {
        input: { ...values, type: "lecture", marathon: marathonId }
      }
    });
  };

  const handleUpdateLecture = (lectureId, values) => {
    showLoading();

    updateLecture({
      variables: {
        id: lectureId,
        input: values
      }
    });
  };

  const handleDeleteLecture = (lectureId) => {
    console.log("taskId", lectureId);
  };

  const [updateMarathonEvent] = useMutation(UPDATE_MARATHON_EVENT, {
    onCompleted() {
      hideLoading();
      refetchMarathonEvents({
        marathonId: marathonId,
        query: {
          page: page,
          limit: 10
        }
      });
      toast.success("Marathon event updated");
    }
  });

  const handleUpdateMarathonEvent = ({ id, values }) => {
    showLoading();

    updateMarathonEvent({
      variables: {
        id,
        input: values
      }
    });
  };

  const [deleteMarathonEvent] = useMutation(DELETE_MARATHON_EVENT, {
    onCompleted() {
      hideLoading();
      refetchMarathonEvents({
        marathonId: marathonId,
        query: {
          page: page,
          limit: 10
        }
      });
      toast.success("Marathon event deleted");
    }
  });

  const handleDeleteMarathonEvent = ({ id }) => {
    showLoading();
    deleteMarathonEvent({
      variables: {
        id
      }
    });
  };

  if (!marathon) {
    return <Loading />;
  }

  return (
    <Marathon
      {...{
        marathon,
        marathonEvents,
        marathonEventsCnt,
        page,
        setPage,
        handleUpdateMarathon,
        handleUpdateMarathonAwards,
        handleUpdateMarathonFloors,
        handleDeleteMarathon,
        handleAddTask,
        handleDeleteTask,
        handleUpdateTask,
        handleAddMarathonEvent,
        handleUpdateMarathonEvent,
        handleDeleteMarathonEvent,
        handleAddLecture,
        handleDeleteLecture,
        handleUpdateLecture,
        trainers,
        awards,
        lectures,
        heroPathes,
        setClubs,
        clubs,
        setChoosedClub,
        choosedClub,
        handleUpdatePlanMarathon
      }}
    />
  );
};

MarathonContainer.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired
    })
  }).isRequired
};

export default withMainLayout(MarathonContainer);
