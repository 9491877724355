import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { gql, useMutation, useQuery } from "@apollo/client";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";

import HeroPass from "./HeroPass";
import Loading from "../shared/Loading";
import { useLoading } from "../../context/useLoading";
import withMainLayout from "../../hocs/withMainLayout";

const GET_DATA = gql`
  query getData($id: ID!) {
    heroPass(id: $id) {
      id
      name
      description
      imageURL
      price
      type
      duration
      marathonEventCnt
      isActive
    }
  }
`;
const GET_HEROPASSES = gql`
  query getHeroPasses {
    heroPasses {
      id
      name
      description
      imageURL
      price
      type
      duration
      marathonEventCnt
      isActive
    }
  }
`;

const UPDATE_HEROPASS = gql`
  mutation updateHeroPass($id: ID!, $input: HeroPassInput) {
    updateHeroPass(id: $id, input: $input) {
      id
      name
      description
      imageURL
      price
      type
      duration
      marathonEventCnt
      isActive
    }
  }
`;
const DELETE_HEROPASS = gql`
  mutation deleteHeroPass($id: ID!) {
    deleteHeroPass(id: $id)
  }
`;

const HeroPassContainer = () => {
  const { id: heroPassId } = useParams();
  const navigate = useNavigate();
  const [heroPassData, setHeroPassData] = React.useState(null);
  const { showLoading, hideLoading } = useLoading();

  const { data, loading, error } = useQuery(GET_DATA, {
    variables: { id: heroPassId }
  });
  const [updateHeroPass] = useMutation(UPDATE_HEROPASS);

  const [deleteHeroPass] = useMutation(DELETE_HEROPASS, {
    onCompleted() {
      hideLoading();
      toast.success("HeroPass успешно удален");
      navigate("/heroPasses");
    }
  });

  useEffect(() => {
    if (data && data.heroPass) {
      setHeroPassData(data.heroPass);
    }
  }, [data, loading, error]);

  if (loading && !heroPassData)
    return (
      <div>
        <Loading />
      </div>
    );

  const handleUpdateClick = (values) => {
    showLoading();
    updateHeroPass({
      variables: {
        id: heroPassId,
        input: {
          ...values,
          price: parseInt(values.price, 10),
          duration: parseInt(values.duration, 10),
          marathonEventCnt: parseInt(values.marathonEventCnt, 10)
        }
      }
    });
    toast.success("HeroPass успешно обновлен");
    hideLoading();
  };

  const handleDeleteClick = () => {
    showLoading();
    deleteHeroPass({ variables: { id: heroPassId } });
  };
  return (
    <>
      {heroPassData && (
        <HeroPass
          heroPass={heroPassData}
          updateClick={handleUpdateClick}
          deleteClick={handleDeleteClick}
        />
      )}
    </>
  );
};

HeroPassContainer.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired
    })
  }).isRequired
};

export default withMainLayout(HeroPassContainer);
