import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";

import { gql, useMutation, useQuery } from "@apollo/client";
import { toast } from "react-toastify";

import TreasureChest from "./TreasureChest";
import Loading from "../shared/Loading";
import { useLoading } from "../../context/useLoading";
import withMainLayout from "../../hocs/withMainLayout";
import { removeTypenameField } from "../../utils/apollo";

const GET_TREASURE_CHEST = gql`
  query treasureChest($id: ID!) {
    treasureChest(id: $id) {
      id
      name
      imageURL
      type
      price
      rewards {
        id
        type
        changeValue
        chanceOfDrop
        imageURL
        ... on RewardEpicHero {
          epicHero {
            id
            name
            tickets
          }
        }
        ... on RewardTreasureChest {
          treasureChest {
            id
            name
            price
          }
        }
      }
    }

    treasureChests {
      id
      name
      type
    }

    epicHeros {
      id
      name
      tickets
    }
  }
`;

const UPDATE_TREASURE_CHEST = gql`
  mutation updateTreasureChest($id: ID!, $input: TreasureChestInput) {
    updateTreasureChest(id: $id, input: $input) {
      id
    }
  }
`;

const DELETE_TREASURE_CHEST = gql`
  mutation deleteTreasureChest($id: ID!) {
    deleteTreasureChest(id: $id)
  }
`;

const ADD_REWARD_TO_CHEST = gql`
  mutation addRewardToChest($id: ID!, $input: TreasureChestRewardInput) {
    addRewardToChest(id: $id, input: $input) {
      id
    }
  }
`;

const DELETE_REWARD_FROM_CHEST = gql`
  mutation deleteReward($treasureChestId: ID!, $rewardId: ID!) {
    deleteRewardFromChest(
      treasureChestId: $treasureChestId
      rewardId: $rewardId
    ) {
      id
      rewards {
        id
      }
    }
  }
`;

const UPDATE_REWARD = gql`
  mutation updateReward($id: ID!, $input: TreasureChestRewardInput) {
    updateReward(id: $id, input: $input) {
      id
    }
  }
`;

const TreasureChestContainer = ({ match }) => {
  const { id: chestId } = useParams();
  const { showLoading, hideLoading } = useLoading();
  const [treasureChest, setTreasureChest] = useState(null);

  const { data, loading, error, refetch } = useQuery(GET_TREASURE_CHEST, {
    variables: {
      id: chestId
    }
  });

  useEffect(() => {
    if (data && data.treasureChest) {
      setTreasureChest(data.treasureChest);
    }
  }, [data, loading, error]);

  const [updateTreasureChest] = useMutation(UPDATE_TREASURE_CHEST, {
    onCompleted() {
      hideLoading();
      refetch();
      toast.success("Сундук обновлен");
    },
    onError(updateError) {
      toast.error(updateError.graphQLErrors[0]?.message);
    }
  });

  const handleUpdateChest = (values) => {
    showLoading();

    const newValues = removeTypenameField({
      ...values,
      price: parseInt(values.price, 10)
    });
    console.log("newValues", newValues);
    updateTreasureChest({
      variables: {
        id: chestId,
        input: newValues
      }
    });
  };

  const [deleteTreasureChest] = useMutation(DELETE_TREASURE_CHEST, {
    onCompleted() {
      hideLoading();
      toast.success("Сундук успешно удален");
    }
  });

  const handleDeleteChest = () => {
    showLoading();
    deleteTreasureChest({
      variables: {
        id: chestId
      }
    });
  };

  const [addRewardToChest] = useMutation(ADD_REWARD_TO_CHEST, {
    onCompleted() {
      hideLoading();
      toast.success("Награда добавлена в сундук");
      refetch();
    }
  });

  const handleAddReward = (values) => {
    showLoading();
    const newValues = {
      ...values,
      changeValue: parseInt(values.changeValue, 10),
      chanceOfDrop: parseInt(values.chanceOfDrop, 10)
    };

    addRewardToChest({
      variables: {
        id: chestId,
        input: newValues
      }
    });
  };

  const [deleteRewardFromChest] = useMutation(DELETE_REWARD_FROM_CHEST, {
    onCompleted() {
      hideLoading();
      refetch();
      toast.success("Награда удалена из сундука");
    }
  });

  const handleDeleteReward = (rewardId) => {
    showLoading();
    deleteRewardFromChest({
      variables: {
        treasureChestId: chestId,
        rewardId
      }
    });
  };

  const [updateReward] = useMutation(UPDATE_REWARD, {
    onCompleted() {
      hideLoading();
      refetch();
      toast.success("Награда обновлена");
    }
  });

  const handleUpdateReward = (rewardId, values) => {
    showLoading();
    const newValues = removeTypenameField({
      ...values,
      changeValue: parseInt(values.changeValue, 10),
      chanceOfDrop: parseInt(values.chanceOfDrop, 10)
    });

    updateReward({
      variables: {
        id: rewardId,
        input: newValues
      }
    });
  };

  if (!treasureChest) {
    return <Loading />;
  }

  return (
    <TreasureChest
      {...{
        treasureChest,
        treasureChests: data?.treasureChests,
        epicHeroes: data?.epicHeros,
        handleUpdateChest,
        handleDeleteChest,
        handleAddReward,
        handleDeleteReward,
        handleUpdateReward
      }}
    />
  );
};

TreasureChestContainer.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired
    })
  }).isRequired
};

export default withMainLayout(TreasureChestContainer);
