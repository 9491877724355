import * as React from "react";
const Chat = (props) => (
  <svg
  width={16}
  height={16}
  viewBox="0 0 16 16"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
  {...props}
>
  <g opacity={props.opacity}>
  <path fillRule="evenodd" clipRule="evenodd" d="M8.00047 12.9861C7.63492 12.9861 7.27816 12.9547 6.93166 12.8964L4.94975 13.8789C4.88451 13.9151 4.80942 13.9358 4.72952 13.9358C4.4786 13.9358 4.27518 13.7324 4.27518 13.4814C4.27518 13.1734 4.27497 12.8701 4.27476 12.5845L4.27476 12.5833C4.27455 12.2894 4.27436 12.0135 4.27442 11.7677C2.95763 10.7856 2.09424 9.28503 2.09424 7.57523C2.09424 4.53358 4.79301 2.16504 8.00056 2.16504C11.2082 2.16504 13.9069 4.53425 13.9069 7.57583L13.9068 7.58519C13.901 10.622 11.2046 12.9861 8.00047 12.9861ZM5.04266 8.61683C5.61517 8.61683 6.07929 8.15272 6.07929 7.58021C6.07929 7.0077 5.61517 6.54359 5.04266 6.54359C4.47015 6.54359 4.00604 7.0077 4.00604 7.58021C4.00604 8.15272 4.47015 8.61683 5.04266 8.61683ZM9.03632 7.58021C9.03632 8.15272 8.57221 8.61683 7.9997 8.61683C7.42719 8.61683 6.96307 8.15272 6.96307 7.58021C6.96307 7.0077 7.42719 6.54359 7.9997 6.54359C8.57221 6.54359 9.03632 7.0077 9.03632 7.58021ZM10.9567 8.61683C11.5292 8.61683 11.9933 8.15272 11.9933 7.58021C11.9933 7.0077 11.5292 6.54359 10.9567 6.54359C10.3842 6.54359 9.92011 7.0077 9.92011 7.58021C9.92011 8.15272 10.3842 8.61683 10.9567 8.61683Z" fill="#545454"/>
  </g>
</svg>
);
export default Chat;
