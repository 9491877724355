import React, { useState, useEffect } from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import { toast } from 'react-toastify';

import PromptNews from './PromptNew';
import Loading from '../shared/Loading';
import { useLoading } from '../../context/useLoading';
import withMainLayout from '../../hocs/withMainLayout';

const GET_PROMPT_NEWS = gql`
  query getPromptNews {
    currentPromptNews {
      id
      news_text
      reaction_count
      news_date
    }
  }
`;

const DELETE_PROMPT_NEWS = gql`
  mutation deletePromptNews($id: ID!) {
    deletePromptNews(id: $id)
  }
`;

const ADD_PROMPT_NEWS = gql`
  mutation addPromptNews($input: PromptNewsInput) {
    addPromptNews(input: $input) {
      id
      news_text
      reaction_count
      news_date
    }
  }
`;

const UPDATE_PROMPT_NEWS = gql`
  mutation updatePromptNews($id: ID!, $input: PromptNewsInput) {
    updatePromptNews(id: $id, input: $input) {
      id
      news_text
      reaction_count
      news_date
    }
  }
`;

const PromptNewsContainer = () => {
  const [dataPromptNews, setDataPromptNews] = useState(null);
  const { showLoading, hideLoading } = useLoading();

  const [updatePromptNews] = useMutation(UPDATE_PROMPT_NEWS, {
    onCompleted: () => {
      hideLoading();
      toast.success('Новость успешно отредактирована');
      refetch();
    },
  });

  const { data, loading, error, refetch } = useQuery(GET_PROMPT_NEWS);

  const [deletePromptNews] = useMutation(DELETE_PROMPT_NEWS, {
    onCompleted: () => {
      refetch();
      hideLoading();
      toast.success('Новость успешно удадена');
    },
  });

  const [addPromptNews] = useMutation(ADD_PROMPT_NEWS, {
    onCompleted: () => {
      refetch();
      hideLoading();
      toast.success('Новость успешно создана');
    },
  });

  useEffect(() => {
    if (data && !loading && !error) {
      setDataPromptNews(data.currentPromptNews);
    }
  }, [data, loading, error]);

  const handleDeletePromptNews = (promptNewsId) => {
    showLoading();
    deletePromptNews({ variables: { id: promptNewsId } });
  };

  const handleAddPromptNews = (values) => {
    showLoading();
    const dataPromptNewsFormat = {
      news_text: values.news_text,
      reaction_count: parseInt(values.reaction_count),
      news_date: new Date(),
    };
    addPromptNews({
      variables: {
        input: dataPromptNewsFormat,
      },
    });
  };

  const handleUpdatePromptNews = (id, values) => {
    const dataPromptNewsFormat = {
      news_text: values.news_text,
      reaction_count: parseInt(values.reaction_count),
    };
    showLoading();
    updatePromptNews({ variables: { id, input: dataPromptNewsFormat } });
  };

  if (loading && !dataPromptNews) {
    return <Loading />;
  }

  return (
    <PromptNews
      promptNews={dataPromptNews}
      deletePromptNewsClick={handleDeletePromptNews}
      addPromptNewsClick={handleAddPromptNews}
      updatePromptNewsClick={handleUpdatePromptNews}
    />
  );
};

export default withMainLayout(PromptNewsContainer);
