import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Table, Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import CreateForm from '../shared/CreateForm';

const StyledImg = styled.img`
  width: 50px;
  height: 50px;
`;

const Marathons = ({ marathons, addMarathonsClick }) => {
  const [modalVisible, setModalVisible] = useState(false);

  const columns = [
    {
      title: 'Имя',
      dataIndex: 'name'
    },
    {
      title: 'Описание',
      dataIndex: 'description'
    },
    {
      title: 'Иконка',
      dataIndex: 'imageURL',
      render: item => {
        return <StyledImg src={item} alt="No Icon" />;
      }
    },
    {
      title: 'Действие',
      key: 'action',
      render: item => (
        <span>
          <Link to={`/marathons/${item.id}`}>Редактировать</Link>
        </span>
      )
    }
  ];

  const fields = [
    {
      key: 'name',
      label: 'Название марафона',
      tooltipText: 'Название марафона'
    },
    {
      key: 'type',
      label: 'Тип марафона',

      options: [
        { value: 'intro', label: 'intro' },
        { value: '21days', label: '21days' },
        { value: 'muscleGain', label: 'muscleGain' },
        { value: 'intensiveWeightLoss', label: 'intensiveWeightLoss' },
        { value: 'chestAndArms', label: 'chestAndArms' },
        { value: 'bootyLikeThat', label: 'bootyLikeThat' },
        { value: 'absAndCardio', label: 'absAndCardio' },
        { value: 'upgrade', label: 'upgrade' },
        { value: '42days', label: '42days' },
        { value: 'recreation', label: 'recreation' }
      ],
      tooltipText: 'Тип акции'
    },
    {
      key: 'description',
      label: 'Описание марафона',
      textArea: true,
      tooltipText: 'Описание марафона'
    },
    {
      key: 'duration',
      label: 'Срок марафона',
      tooltipText: 'Срок марафона в днях',
      number: true
    },
    {
      key: 'price',
      label: 'Цена за марафон',
      tooltipText: 'Цена за марафон в тенге',
      number: true
    },
    {
      key: 'countClasses',
      label: 'Кол-во билетов',
      tooltipText: 'Кол-во билетов',
      number: true
    },
    {
      key: 'assessmentClasses',
      label: 'Кол-во ассессмент билетов',
      tooltipText: 'Кол-во ассессмент билетов',
      number: true
    },
    {
      key: 'educationClasses',
      label: 'Кол-во обучающих билетов',
      tooltipText: 'Кол-во обучающих билетов',
      number: true
    },
    {
      key: 'xPosition',
      label: 'Позиция по x-осьи',
      tooltipText: 'Позиция по x-осьи',
      number: true
    },
    {
      key: 'yPosition',
      label: 'Позиция по y-осьи',
      tooltipText: 'Позиция по y-осьи',
      number: true
    },
    {
      key: 'district',
      label: 'Название района',
      tooltipText: 'Название района'
    },
    {
      key: 'rating',
      label: 'Премиальность района',
      tooltipText: 'Премиальность района'
    },
    {
      key: 'imageURL',
      label: 'Картинка Марафона',
      tooltipText: 'Картинка Марафона'
    }
  ];

  const handleCreate = values => {
    setModalVisible(false);
    addMarathonsClick(values);
  };

  return (
    <>
      <Table
        dataSource={marathons}
        columns={columns}
        title={() => (
          <div>
            <Button type="primary" onClick={() => setModalVisible(true)}>
              <PlusOutlined /> Новый Марафон
            </Button>
          </div>
        )}
      />
      <CreateForm
        title="Добавить новый марафон"
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        onCreate={handleCreate}
        fields={fields}
      />
    </>
  );
};

Marathons.propTypes = {
  marathons: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  addMarathonsClick: PropTypes.func.isRequired
};

export default Marathons;
