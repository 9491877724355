import React, { useState, useEffect } from 'react';
import { gql, useQuery } from '@apollo/client';

import TrainerEvents from './TrainerEvents';
import Loading from '../../shared/Loading';
import withMainLayout from '../../../hocs/withMainLayout';
import { useAuth } from '../../../context/useAuth';

const GET_DATA = gql`
  query getData {
    events {
      id
      programSet {
        id
        name
        club {
          id
          name
        }
      }
      trainer {
        id
        role
        nickname
      }
      status
      startTime
      endTime
    }
  }
`;

const TrainerEventsContainer = () => {
  const [dataEvents, setDataEvents] = useState(null);

  const { data, loading, error } = useQuery(GET_DATA);
  const { user } = useAuth();

  useEffect(() => {
    if (user && data && !loading && !error) {
      const { events } = data;
      // .events.filter(event => event.trainer?.id === user.id)

      setDataEvents(events);
    }
  }, [data, loading, error, user]);

  if (loading && !dataEvents) {
    return <Loading />;
  }

  return <>{dataEvents && <TrainerEvents events={dataEvents} />}</>;
};

export default withMainLayout(TrainerEventsContainer);
