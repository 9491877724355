import React, { useState, useEffect } from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import { toast } from 'react-toastify';

import TreasureChests from './TreasureChests';
import Loading from '../shared/Loading';
import { useLoading } from '../../context/useLoading';
import withMainLayout from '../../hocs/withMainLayout';

const GET_TREASURE_CHESTS = gql`
  query treasureChests {
    treasureChests {
      id
      name
      imageURL
      type
      price
      rewards {
        id
        type
        changeValue
        chanceOfDrop
        ... on RewardEpicHero {
          epicHero {
            id
            name
            tickets
          }
        }
        ... on RewardTreasureChest {
          treasureChest {
            id
            name
            price
          }
        }
      }
    }
  }
`;

const ADD_TREASURE_CHEST = gql`
  mutation addTreasureChest($input: TreasureChestInput) {
    addTreasureChest(input: $input) {
      id
    }
  }
`;

const TreasureChestsContainer = () => {
  const [treasureChests, setTreasureChests] = useState([]);
  const { showLoading, hideLoading } = useLoading();

  const { data, loading, error } = useQuery(GET_TREASURE_CHESTS);

  const [addTreasureChest] = useMutation(ADD_TREASURE_CHEST, {
    update(cache, { data: { addTreasureChest: treasureChest } }) {
      const { treasureChests: treasureChestsCache } = cache.readQuery({
        query: GET_TREASURE_CHESTS
      });
      cache.writeQuery({
        query: GET_TREASURE_CHESTS,
        data: { treasureChests: treasureChestsCache.concat([treasureChest]) }
      });
      hideLoading();
      toast.success('Сундук успешно создан');
    },
    onError(errorAddTreasureChest) {
      toast.error(errorAddTreasureChest.message);
    }
  });

  useEffect(() => {
    if (data && data.treasureChests) {
      setTreasureChests(data.treasureChests);
    }
  }, [data, loading, error]);

  const handleAddChest = values => {
    showLoading();

    const newValues = {
      ...values,
      price: parseInt(values.price, 10)
    };

    addTreasureChest({
      variables: {
        input: newValues
      }
    });
  };

  if (loading || !treasureChests) {
    return <Loading />;
  }

  return (
    <TreasureChests
      {...{
        treasureChests,
        handleAddChest
      }}
    />
  );
};

export default withMainLayout(TreasureChestsContainer);
