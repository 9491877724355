import React, { useState } from 'react';

import styled from 'styled-components';

import PropTypes from 'prop-types';
import { Table, Button, Divider, Modal, Select, Input, Avatar } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import showConfirm from '../shared/DeleteConfirm';
import ImageUpload from '../shared/ImageUpload';

const StyledAvatar = styled(Avatar)`
  margin-left: 5px;
`;

const StyledSelect = styled(Select)`
  width: 320px;
`;

const ImageUploadContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const emptyReward = {
  type: '',
  changeValue: 0,
  chanceOfDrop: 0,
  imageURL: '',
  epicHero: null,
  treasureChest: null,
};

const REWARD_TYPES = [
  {
    value: 'dumbbell',
    label: 'Гантельки',
  },
  {
    value: 'ticket',
    label: 'Билеты',
  },
  {
    value: 'guestTicket',
    label: 'Гостевые билеты',
  },
  {
    value: 'treasureChest',
    label: 'Сундук',
  },
  {
    value: 'epicHero',
    label: 'Эпик герой',
  },
];

const TreasureChestRewards = ({
  rewards,
  handleAddReward,
  handleDeleteReward,
  handleUpdateReward,
  epicHeroes,
  treasureChests,
}) => {
  const [display, setDisplay] = useState('create');
  const [editReward, setEditReward] = useState(emptyReward);
  const [modalVisible, setModalVisible] = useState(false);

  const columns = [
    {
      title: 'Тип',
      dataIndex: 'type',
    },
    {
      title: 'Количество',
      dataIndex: 'changeValue',
    },
    {
      title: 'Шанс выпадения(%)',
      dataIndex: 'chanceOfDrop',
    },
    {
      title: 'Герой',
      render: (text, item) => {
        return <span> {item?.epicHero?.name}</span>;
      },
    },
    {
      title: 'Сундук',
      render: (text, item) => {
        return <span> {item?.treasureChest?.name}</span>;
      },
    },
    {
      title: 'Действие',
      key: 'action',
      render: (text, item) => (
        <span>
          <Button
            type="link"
            onClick={() => {
              setDisplay('edit');
              setEditReward({
                ...item,
                epicHero: item?.epicHero?.id,
                treasureChest: item?.treasureChest?.id,
              });
              setModalVisible(true);
            }}
          >
            Редактировать
          </Button>
        </span>
      ),
    },
  ];

  const onCreate = () => {
    handleAddReward(editReward);
    setModalVisible(false);
  };

  const onUpdate = () => {
    if (editReward?.id) {
      handleUpdateReward(editReward?.id, editReward);
      setModalVisible(false);
    }
  };

  const onDelete = () => {
    showConfirm(() => {
      if (editReward?.id) {
        handleDeleteReward(editReward?.id);
        setModalVisible(false);
      }
    });
  };

  const modalText = display === 'create' ? 'Создать' : 'Редактировать';

  const modalFunction = display === 'create' ? onCreate : onUpdate;

  const onCancel = () => {
    setModalVisible(false);
  };

  const handleInputChange = (field, value) => {
    const newEditReward = {
      ...editReward,
      [field]: value,
    };

    setEditReward(newEditReward);
  };

  return (
    <>
      <Table
        dataSource={rewards}
        columns={columns}
        rowKey={(item) => item?.id}
        title={() => (
          <div>
            <Button
              type="primary"
              onClick={() => {
                setEditReward(emptyReward);
                setModalVisible(true);
                setDisplay('create');
              }}
            >
              <PlusOutlined /> Новая награда
            </Button>
          </div>
        )}
      />
      <Modal
        open={modalVisible}
        title={modalText}
        onCancel={onCancel}
        footer={[
          <Button
            danger
            style={{
              float: 'left',
              visibility: display === 'create' && 'hidden',
            }}
            onClick={onDelete}
            key="deleteButton"
          >
            Удалить
          </Button>,
          <Button onClick={onCancel} key="cancelButton">
            Отмена
          </Button>,
          <Button key="submitButton" type="primary" onClick={modalFunction}>
            {modalText}
          </Button>,
        ]}
      >
        <h3> Тип награды </h3>
        <StyledSelect
          onChange={(value) => handleInputChange('type', value)}
          value={editReward?.type}
        >
          {REWARD_TYPES.map((rewardType) => (
            <Select.Option value={rewardType.value} key={rewardType.value}>
              {rewardType.label}
            </Select.Option>
          ))}
        </StyledSelect>
        <Divider />
        <h3> Количество </h3>
        <Input
          type="number"
          onChange={(e) => handleInputChange('changeValue', e.target.value)}
          value={editReward?.changeValue}
        />
        <h3> Шанс выпадения </h3>
        <Input
          type="number"
          onChange={(e) => handleInputChange('chanceOfDrop', e.target.value)}
          value={editReward?.chanceOfDrop}
        />
        {(editReward?.type === 'dumbbell' ||
          editReward?.type === 'ticket' ||
          editReward?.type === 'guestTicket') && (
          <>
            <h3> Иконка награды </h3>
            <ImageUploadContainer>
              <ImageUpload
                onUpdate={(value) => {
                  handleInputChange('imageURL', value);
                }}
              />
              <StyledAvatar
                size="96"
                shape="square"
                src={editReward.imageURL}
              />
            </ImageUploadContainer>
          </>
        )}
        {editReward?.type === 'epicHero' && (
          <>
            <h3> Эпик герой </h3>
            <StyledSelect
              value={editReward?.epicHero}
              onChange={(value) => handleInputChange('epicHero', value)}
            >
              {epicHeroes.map((epicHero) => (
                <Select.Option value={epicHero?.id}>
                  {epicHero?.name}
                </Select.Option>
              ))}
            </StyledSelect>
          </>
        )}
        {editReward?.type === 'treasureChest' && (
          <>
            <h3> Сундук </h3>
            <StyledSelect
              value={editReward?.treasureChest}
              onChange={(value) => handleInputChange('treasureChest', value)}
            >
              {treasureChests.map((treasureChest) => (
                <Select.Option value={treasureChest?.id}>
                  {treasureChest?.name}
                </Select.Option>
              ))}
            </StyledSelect>
          </>
        )}
      </Modal>
    </>
  );
};

TreasureChestRewards.propTypes = {
  rewards: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
    })
  ).isRequired,
  handleAddReward: PropTypes.func.isRequired,
  handleDeleteReward: PropTypes.func.isRequired,
  handleUpdateReward: PropTypes.func.isRequired,
  treasureChests: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  epicHeroes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default TreasureChestRewards;
