import * as React from "react";
const ChatIconWithBC = (props) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width={24} height={24} rx={8} fill="black" fillOpacity={0.1} />
    <path
      d="M13.3339 5.99756H7.99839C6.89336 5.99756 5.99756 6.89336 5.99756 7.99839V14.0009C5.99756 15.1059 6.89336 16.0017 7.99839 16.0017H9.33695V17.631C9.33695 17.7736 9.41867 17.9035 9.54717 17.9653C9.67567 18.027 9.8282 18.0097 9.93953 17.9206L12.3382 16.0017H16.0017C17.1068 16.0017 18.0026 15.1059 18.0026 14.0009V10.6662"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.66504 12.667H14.6675"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.66504 9.999H11.3328"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.8073 6.81221L17.188 6.19291C17.0629 6.06783 16.8933 5.99756 16.7164 5.99756C16.5395 5.99756 16.3699 6.06783 16.2448 6.19291L14.1963 8.24137C14.0712 8.36645 14.001 8.53609 14.001 8.71298V9.66575C14.001 9.84993 14.1503 9.99923 14.3344 9.99923H15.2872C15.4641 9.99923 15.6337 9.92896 15.7588 9.80388L17.8073 7.75539C18.0677 7.49494 18.0677 7.07267 17.8073 6.81221Z"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default ChatIconWithBC;
