import React, { useState, useEffect } from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import { toast } from 'react-toastify';

import PromptDescriptions from './PromptDescriptions';
import Loading from '../shared/Loading';
import { useLoading } from '../../context/useLoading';
import withMainLayout from '../../hocs/withMainLayout';

const GET_PROMPTDESCRIPTION = gql`
  query getPromptDescription {
    promptDescriptions {
      id
      type
      maxTokens
      temperature
      text
      agent_name
      prompt_type
    }
  }
`;

const DELETE_PROMPTDESCRIPTION = gql`
  mutation deletePromptDescription($id: ID!) {
    deletePromptDescription(id: $id)
  }
`;

const ADD_PROMPTDESCRIPTION = gql`
  mutation addPromptDescription($input: PromptDescriptionInput) {
    addPromptDescription(input: $input) {
      id
      type
      maxTokens
      temperature
      text
      agent_name
      prompt_type
    }
  }
`;

const UPDATE_PROMPTDESCRIPTION = gql`
  mutation updatePromptDescription($id: ID!, $input: PromptDescriptionInput) {
    updatePromptDescription(id: $id, input: $input) {
      id
      type
      maxTokens
      temperature
      text
      agent_name
      prompt_type
    }
  }
`;

const PromptDescriptionsContainer = () => {
  const [dataPromptDescriptions, setDataPromptDescriptions] = useState(null);
  const { showLoading, hideLoading } = useLoading();

  const [updatePromptDescription] = useMutation(UPDATE_PROMPTDESCRIPTION, {
    onCompleted: () => {
      hideLoading();
      toast.success('Параметры бота успешно отредактированы');
      refetch();
    },
  });

  const { data, loading, error, refetch } = useQuery(GET_PROMPTDESCRIPTION);

  const [deletePromptDescription] = useMutation(DELETE_PROMPTDESCRIPTION, {
    onCompleted: () => {
      refetch();
      hideLoading();
      toast.success('Цена успешно удалена');
    },
  });

  const [addPromptDescription] = useMutation(ADD_PROMPTDESCRIPTION, {
    onCompleted: () => {
      refetch();
      hideLoading();
      toast.success('Параметры бота успешно созданы');
    },
  });

  useEffect(() => {
    if (data && !loading && !error) {
      setDataPromptDescriptions(data.promptDescriptions);
    }
  }, [data, loading, error]);

  const handleDeletePromptDescription = (promptDescriptionId) => {
    toast.error('Функция недоступна');
    // showLoading()
    // deletePromptDescription({ variables: { id: promptDescriptionId } })
  };

  const handleAddPromptDescription = (values) => {
    showLoading();
    const dataPromptDescription = {
      text: values.text,
      agent_name: values.agent_name,
      prompt_type: values.prompt_type,
      maxTokens: parseInt(values.maxTokens),
      temperature: parseFloat(values.temperature),
    };
    addPromptDescription({
      variables: {
        input: dataPromptDescription,
      },
    });
  };

  const handleUpdatePromptDescription = (id, values) => {
    const dataPromptDescription = {
      text: values.text,
      agent_name: values.agent_name,
      prompt_type: values.prompt_type,
      maxTokens: parseInt(values.maxTokens),
      temperature: parseFloat(values.temperature),
    };
    showLoading();
    updatePromptDescription({ variables: { id, input: dataPromptDescription } });
  };

  if (loading && !dataPromptDescriptions) {
    return <Loading />;
  }

  return (
    <PromptDescriptions
      promptDescriptions={dataPromptDescriptions}
      deletePromptDescriptionClick={handleDeletePromptDescription}
      addPromptDescriptionClick={handleAddPromptDescription}
      updatePromptDescriptionClick={handleUpdatePromptDescription}
    />
  );
};

export default withMainLayout(PromptDescriptionsContainer);
