import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Form, Layout, Menu, Breadcrumb } from 'antd';

import {
  InfoCircleOutlined,
  OrderedListOutlined,
  ClockCircleOutlined
} from '@ant-design/icons';

import ProgramSetGeneral from './ProgramSetGeneral';
import ProgramSetStations from './ProgramSetStations';
import ProgramSetTime from './ProgramSetTime';
import ProgramSetСharacteristics from './ProgramSetСharacteristics';
import ProgramSetBootcamp from './ProgramSetBootcamp';

const { Header, Sider, Content } = Layout;

const StyledBreadcrumb = styled(Breadcrumb)`
  margin: 16px 0;
`;

const StyledHeader = styled(Header)`
  background: #fff;
  border-bottom: 1px solid #d9d9d9;
  padding: 0px 24px;
`;

const StyledMenu = styled(Menu)`
  height: 100%;
  padding-top: 16px;
`;
const StyledLayout = styled(Layout)`
  padding: 0 24px 24px;
  background: #fff;
`;

const StyledContent = styled(Content)`
  padding: 24px;
  margin: 0px;
  min-height: 280px;
`;

const ProgramSet = ({
  programSet,
  clubs,
  levels,
  exercises,
  updateClickForTime,
  updateClickForGeneral,
  deleteClick,
  handleUpdateStationExercise,
  handleAddStationExercise,
  handleDeleteStationExercise,
  updateStationExercisesOrder
}) => {
  const [form] = Form.useForm();
  const [currentTab, setCurrentTab] = React.useState(1);
  const zonesData = () => {
    const club = clubs.find(clubData => clubData.id === programSet?.club?.id);
    return club?.zones;
  };
  const [zones, setZones] = React.useState(zonesData);

  const handleTabChange = activeKey => {
    setCurrentTab(activeKey);
  };

  const onFinishForTime = values => {
    updateClickForTime(values);
  };

  const onFinishForGeneral = values => {
    updateClickForGeneral(values);
  };

  return (
    <Layout>
      <StyledBreadcrumb>
        <Breadcrumb.Item>
          <Link to="/programSets">Программы</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{programSet?.name}</Breadcrumb.Item>
      </StyledBreadcrumb>
      <StyledHeader>
        <h2>Редактирование программы</h2>
      </StyledHeader>
      <Layout>
        <Sider>
          <StyledMenu mode="inline" defaultSelectedKeys={['1']}>
            <Menu.Item
              icon={<InfoCircleOutlined />}
              key="1"
              onClick={() => handleTabChange(1)}
            >
              Основные
            </Menu.Item>
            <Menu.Item
              icon={<InfoCircleOutlined />}
              key="4"
              onClick={() => handleTabChange(4)}
            >
              Характеристики
            </Menu.Item>
            <Menu.Item
              icon={<OrderedListOutlined />}
              key="2"
              onClick={() => handleTabChange(2)}
            >
              Список станций
            </Menu.Item>
            <Menu.Item
              icon={<ClockCircleOutlined />}
              key="3"
              onClick={() => handleTabChange(3)}
            >
              Время
            </Menu.Item>
          </StyledMenu>
        </Sider>
        <StyledLayout>
          <StyledContent className="site-layout-background">
            {currentTab === 1 && (
              <ProgramSetGeneral
                {...{
                  levels,
                  programSet,
                  clubs,
                  zones,
                  setZones,
                  onFinish: onFinishForGeneral,
                  form,
                  deleteClick
                }}
              />
            )}
            {currentTab === 2 && programSet?.type !== 'bootcamp' && (
              <ProgramSetStations
                {...{
                  programSet,
                  exercises,
                  handleUpdateStationExercise,
                  handleAddStationExercise,
                  handleDeleteStationExercise,
                  updateStationExercisesOrder
                }}
              />
            )}
            {currentTab === 2 && programSet?.type === 'bootcamp' && (
              <ProgramSetBootcamp
                {...{
                  programSet,
                  exercises,
                  onUpdate: onFinishForGeneral
                }}
              />
            )}
            {currentTab === 3 && (
              <ProgramSetTime
                {...{ programSet, form, onFinish: onFinishForTime }}
              />
            )}
            {currentTab === 4 && (
              <ProgramSetСharacteristics
                {...{
                  programSet,
                  onFinish: onFinishForGeneral,
                  form
                }}
              />
            )}
          </StyledContent>
        </StyledLayout>
      </Layout>
    </Layout>
  );
};

ProgramSet.propTypes = {
  programSet: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    club: PropTypes.shape({
      id: PropTypes.string
    })
  }).isRequired,
  exercises: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  clubs: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  updateClickForGeneral: PropTypes.func.isRequired,
  updateClickForTime: PropTypes.func.isRequired,
  deleteClick: PropTypes.func.isRequired,
  handleUpdateStationExercise: PropTypes.func.isRequired,
  handleAddStationExercise: PropTypes.func.isRequired,
  handleDeleteStationExercise: PropTypes.func.isRequired,
  updateStationExercisesOrder: PropTypes.func.isRequired
};

export default ProgramSet;
