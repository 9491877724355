import React, { useEffect, useState } from "react";

import { Button } from 'antd';
import Loading from "../shared/Loading";
import PropTypes from "prop-types";
import Tickets from "./Tickets";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import withMainLayout from "../../hocs/withMainLayout";

const TicketsContainer = () => {
  const navigate = useNavigate();
  const [tickets, setTickets] = useState(null);

  useEffect(() => {
    // Simulate fetching all tickets
    const fetchedTickets = [
      {
        id: "1",
        title: "Sample Ticket 1",
        type: "issue",
        text: "This is a sample ticket 1.",
        users: [{ id: "1", email: "user1@example.com" }],
        level: { id: "1", name: "Level 1", degree: 1 },
      },
      {
        id: "2",
        title: "Sample Ticket 2",
        type: "issue",
        text: "This is a sample ticket 2.",
        users: [{ id: "2", email: "user2@example.com" }],
        level: { id: "2", name: "Level 2", degree: 2 },
      },
    ];
    setTickets(fetchedTickets);
  }, []);

  if (!tickets) return <Loading />;

  return (
    <>
      <Button type="primary" onClick={() => navigate('/tickets/new')}>
        Create New Ticket
      </Button>
      <Tickets tickets={tickets} />
    </>
  );
};

TicketsContainer.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
};

export default withMainLayout(TicketsContainer);