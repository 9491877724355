import React from 'react';
import styled from 'styled-components';
import { Table, Input, Button } from 'antd';
import dayjs from 'dayjs';

import CreateRequestModal from './NewRequestModal';

import SearchLogo from '../../images/search.svg';
import PlusIcon from '../../images/plusIcon.svg';

const Wrapper = styled.div`
  border-radius: 8px;

  border: 1px solid rgba(0, 0, 0, 0.05);
`;

const FilterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
`;
const StyledInput = styled(Input)`
  border-radius: 8px;
  background-color: rgba(0, 0, 0, 0.04);
  height: 40px;
  width: 240px;
  background: rgba(0, 0, 0, 0.04);
  background-image: url(${SearchLogo});
  background-repeat: no-repeat;
  background-position: 5%;
  padding: 0 44px;
  border: none;
  color: rgba(0, 0, 0, 0.2);
  &:focus {
    border: none;
    outline: none;
    background: rgba(0, 0, 0, 0.08);
    background-image: url(${SearchLogo});
    background-repeat: no-repeat;
    background-position: left center;
    background-position: 5%;
    color: #000;
  }
`;

const Btn = styled(Button)`
  border-radius: 8px;
  margin-left: 8px;
  height: 40px;
  font-family: 'GraphikLCGMedium';
  background-color: #6f2cff;
  color: #ffffff;
  font-size: 14px;
  display: flex;
  align-items: center;
  border: none;
  &:focus {
    background-color: #6f2cff;
    border: none;
  }
  &:hover {
    background-color: #6f2cff;
    opacity: 0.5;
    border: none;
  }
`;

const SalesSupportBody = ({
  user,
  requests,
  total,
  setPage,
  page,
  setSelectedRequest,
  setShowRequestModal,
  renderStatus,
  setSearch,
  refetch,
  uploadRequestPhoto,
  uploadedMediaData,
  uploadedMediaLoading
}) => {
  const [showCreateModal, setShowCreateModal] = React.useState(false);

  const columns = [
    {
      title: () => {
        return (
          <div
            style={{
              fontSize: 14,
              fontFamily: 'GraphikLCGMedium',
              color: 'gray'
            }}
          >
            Имя
          </div>
        );
      },
      dataIndex: ['user', 'nickname'],
      width: '10%',
      render: (text, item) => {
        const nickname = item.user.nickname;

        return <div style={{ fontFamily: 'GraphikLCGMedium' }}>{nickname}</div>;
      }
    },
    {
      title: () => {
        return (
          <div
            style={{
              fontSize: 14,
              fontFamily: 'GraphikLCGMedium',
              color: 'gray'
            }}
          >
            Категория
          </div>
        );
      },
      dataIndex: ['requestCategory', 'name'],
      width: '15%',
      render: (text, item) => (
        <div style={{ fontFamily: 'GraphikLCGMedium' }}>{text}</div>
      )
    },
    {
      title: () => {
        return (
          <div
            style={{
              fontSize: 14,
              fontFamily: 'GraphikLCGMedium',
              color: 'gray'
            }}
          >
            Ответственный
          </div>
        );
      },
      dataIndex: ['assigned', 'nickname'],
      width: '10%',
      render: (text, item) => (
        <div
          style={{
            fontFamily: 'GraphikLCGMedium',
            color: text ? 'black' : 'gray'
          }}
        >
          {item?.assigned?.nickname || 'Отсутствует'}
        </div>
      )
    },
    {
      title: () => {
        return (
          <div
            style={{
              fontSize: 14,
              fontFamily: 'GraphikLCGMedium',
              color: 'gray'
            }}
          >
            Статус
          </div>
        );
      },
      dataIndex: 'status',
      width: '10%',
      render: (text, item) => (
        <div
          style={{
            fontFamily: 'GraphikLCGMedium',
            color: text === 'moreDataForAdmin' ? '#FF0000' : 'inherit'
          }}
        >
          {renderStatus(text)}
        </div>
      )
    },
    {
      title: () => {
        return (
          <div
            style={{
              fontSize: 14,
              fontFamily: 'GraphikLCGMedium',
              color: 'gray'
            }}
          >
            Дата обновления
          </div>
        );
      },
      dataIndex: 'updated_at',
      width: '10%',
      render: (text, item) => (
        <div style={{ fontFamily: 'GraphikLCGMedium' }}>
          {dayjs(text).format('DD.MM.YY - HH:mm')}
        </div>
      )
    },
    {
      title: () => {
        return (
          <div
            style={{
              fontSize: 14,
              fontFamily: 'GraphikLCGMedium',
              color: 'gray'
            }}
          >
            Дата получения
          </div>
        );
      },
      dataIndex: 'created_at',
      width: '10%',
      render: (text, item) => (
        <div style={{ fontFamily: 'GraphikLCGMedium' }}>
          {dayjs(text).format('DD.MM.YY - HH:mm')}
        </div>
      )
    }
  ];

  return (
    <Wrapper>
      <Table
        dataSource={requests}
        columns={columns}
        rowKey={(item) => item.id}
        size="middle"
        pagination={{
          defaultPageSize: 10,
          total: total,
          current: page,
          onChange: (page, pageSize) => {
            setPage(page);
          },
          showSizeChanger: false
        }}
        title={() => (
          <FilterContainer>
            <StyledInput
              placeholder="Поиск"
              onChange={(e) => {
                const inputValue = e.target.value.toLowerCase();
                setSearch(inputValue);
              }}
            />
            <Btn
              type="primary"
              icon={<img src={PlusIcon} />}
              onClick={() => setShowCreateModal(true)}
            >
              Новая заявка
            </Btn>
          </FilterContainer>
        )}
        onRow={(item) => {
          return {
            onClick: (itm) => {
              setSelectedRequest(item);
              setShowRequestModal(true);
            }
          };
        }}
      />
      <CreateRequestModal
        visible={showCreateModal}
        onClose={() => setShowCreateModal(false)}
        userId={user?.id}
        refetch={refetch}
        uploadRequestPhoto={uploadRequestPhoto}
        uploadedMediaData={uploadedMediaData}
        uploadedMediaLoading={uploadedMediaLoading}
      />
    </Wrapper>
  );
};

export default SalesSupportBody;
