import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Modal, Form, DatePicker, Space, Card, Input, Divider, Row, Typography, Image } from 'antd';
import datePickerLocale from 'antd/es/date-picker/locale/ru_RU';
import dayjs from 'dayjs';

import StyledTooltip from '../shared/StyledTooltip';

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const UpdateMarathonLevelModal = ({
  modalVisible,
  userMarathonEvent,
  handleUpdateUser,
  onCancel,
}) => {
  const [form] = Form.useForm();
  const [values, setValues] = React.useState({});

  const getTooltip = (text, fullText) => {
    return <StyledTooltip {...{ text, fullText }} />;
  };

  const handleOkClick = () => {
    handleUpdateUser({ values });
    onCancel();
    onCancel(false);
  };

  const getTitle = ({ task }) => {
    switch (task?.type) {
      case 'visits':
        return `Посетить: ${task?.visits}`
      case 'visitsToClass':
        return `${task?.typeOfClass?.toUpperCase()}: ${task.visits}`;
      case 'visitsToClassMultiple':
        return `${task?.typeOfClassMultiple?.map((typeOfClass, index) => {
          return typeOfClass?.toUpperCase();
        })}: ${task.visits}`;
      case 'lecture':
        return `Прочитать лекции: ${task.countReads}`;
      default:
        return '';
    }
  };

  const totalVisits = userMarathonEvent?.tasksProgress
    ?.map(item => item?.task?.visits || 0)
    ?.reduce((acc, num) => acc + num, 0);

  const currentVisits = userMarathonEvent?.tasksProgress
    ?.map(item => Math.min(item?.completedCnt || 0, item?.task?.visits || 0))
    ?.reduce((acc, num) => acc + num, 0);

  const completionPercentage = Math.floor(totalVisits > 0 ? (currentVisits / totalVisits) * 100 : 0);

  const getAwards = (percent) => {
    const awards = [
      { type: 'bronze', url: 'https://awards.object.pscloud.io/Marathon21-bronze.png', required: 70 },
      { type: 'silver', url: 'https://awards.object.pscloud.io/Marathon21-silver.png', required: 85 },
      { type: 'gold', url: 'https://awards.object.pscloud.io/Marathon21-gold.png', required: 100 }
    ];
  
    const calculateVisitsNeeded = (targetPercent) => {
      const targetVisits = Math.ceil((targetPercent / 100) * totalVisits);
      return Math.max(0, targetVisits - currentVisits);
    };
  
    if (percent < 70) {
      return { 
        current: null,
        nextSteps: awards.map(award => ({
          ...award,
          visitsNeeded: calculateVisitsNeeded(award.required)
        }))
      };
    }

    if (percent >= 70 && percent < 85) {
      return { 
        current: awards[0],
        nextSteps: awards.slice(1).map(award => ({
          ...award,
          visitsNeeded: calculateVisitsNeeded(award.required)
        }))
      };
    }

    if (percent >= 85 && percent < 100) {
      return { 
        current: awards[1],
        nextSteps: awards.slice(2).map(award => ({
          ...award,
          visitsNeeded: calculateVisitsNeeded(award.required)
        }))
      };
    }
    
    if (percent >= 100) {
      return { 
        current: awards[2],
        nextSteps: awards.slice(3).map(award => ({
          ...award,
          visitsNeeded: calculateVisitsNeeded(award.required)
        }))
      };
    }
  
    return {
      current: awards[3],
      nextSteps: []
    };
  };  

  const { current, nextSteps } = getAwards(completionPercentage);

  return (
    <Modal
      open={modalVisible}
      title="Редактировать прогресс"
      onCancel={() => {
        form.resetFields();
        onCancel();
      }}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            form.resetFields();
            const { basicTime, tasksProgress } = values;
            handleUpdateUser({
              id: userMarathonEvent?.id,
              basicTime: basicTime,
              tasksProgress: tasksProgress,
            });
            onCancel();
          })
          .catch((info) => {
            console.log('Validate Failed:', info);
          });
      }}
      cancelText="Отмена"
    >
      <MainContainer>
        <Form form={form} layout="vertical">
          <Form.Item
            key="basicTime"
            name="basicTime"
            label={getTooltip(
              'Стартовое время',
              'C какого времени считать прогресс'
            )}
            initialValue={
              userMarathonEvent?.basicTime
                ? dayjs(userMarathonEvent?.basicTime)
                : dayjs(userMarathonEvent?.marathonEvent?.startTime)
            }
          >
            <DatePicker
              locale={datePickerLocale}
              placeholder="Выберите дату начала"
              format="DD.MM.YYYY"
              style={{
                width: '100%',
                color: 'black',
              }}
            />
          </Form.Item>
          <Form.List
            key="tasksProgress"
            name="tasksProgress"
            label="Задания"
            initialValue={userMarathonEvent?.tasksProgress.map(
              (taskProgress) => {
                return {
                  task: taskProgress?.task,
                  completedCnt: taskProgress?.completedCnt,
                  taskId: taskProgress?.task?.id,
                };
              }
            )}
          >
            {(fields) => {
              return (
                <>
                  {fields.map((field, index) => {
                    const title = getTitle({
                      task: userMarathonEvent?.tasksProgress[field.key].task,
                    });
                    return (
                      <Space
                        direction="horizontal"
                        size={16}
                        style={{ marginRight: index % 2 === 0 ? 8 : 0 }}
                      >
                        <Card
                          title={`${title}`}
                          key={field.key}
                          style={{ width: '232px' }}
                        >
                          <Form.Item
                            style={{ display: 'none' }}
                            name={[field.name, 'taskId']}
                          >
                            <Input placeholder="Занаие" />
                          </Form.Item>
                          <Form.Item
                            name={[field.name, 'completedCnt']}
                            rules={[
                              {
                                required: true,
                                message: 'Укажите количество',
                              },
                            ]}
                          >
                            <Input placeholder="Количество" />
                          </Form.Item>
                        </Card>
                      </Space>
                    );
                  })}
                </>
              );
            }}
          </Form.List>
          <Divider/>
          <Row align={'middle'} justify={'space-around'}>
            <Typography style={{fontSize: '16px', fontWeight: 'bold'}}>
              Общее: {totalVisits}
            </Typography>
            <Divider type="vertical"/>
            <Typography style={{fontSize: '16px', fontWeight: 'bold'}}>
              Текущий результат: {currentVisits}
            </Typography>
            <Divider type="vertical"/>
            <Row align={'middle'}>
              <Typography style={{fontSize: '16px', fontWeight: 'bold', marginRight: '5px'}}>
                Процент: {completionPercentage}% 
              </Typography>
              {
                current?.url &&
                <Image 
                  src={current?.url} 
                  preview={false}
                  style={{height: '25px', width: '25px'}}
                />
              }
            </Row>
          </Row>
          <Divider/>
          <Row align="middle" justify="space-around">
            {nextSteps?.map((award, index) => (
              <Row key={index} align="middle" style={{flexDirection: 'column'}}>
                <Image 
                  src={award?.url} 
                  preview={false}
                  style={{ height: '40px', width: '40px' }}
                />
                <Typography style={{fontSize: '15px', fontWeight: '600'}}>
                  Не хватает: {award?.visitsNeeded}
                </Typography>
              </Row>
            ))}
          </Row>
        </Form>
      </MainContainer>
    </Modal>
  );
};

export default UpdateMarathonLevelModal;
