import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  Layout,
  Menu,
  Breadcrumb,
  Form,
  Input,
  Button,
  Select,
  Switch
} from 'antd';
import { Link } from 'react-router-dom';
import {
  InfoCircleOutlined,
  CheckOutlined,
  CloseOutlined
} from '@ant-design/icons';

import showConfirm from '../shared/DeleteConfirm';
import StyledTooltip from '../shared/StyledTooltip';
import EditForm from '../shared/EditForm';
import Recurrents from './Recurrents.container';
const FormItem = Form.Item;

const { Header, Sider, Content } = Layout;

const StyledBreadcrumb = styled(Breadcrumb)`
  margin: 16px 0;
`;

const StyledHeader = styled(Header)`
  background: #fff;
  border-bottom: 1px solid #d9d9d9;
  padding: 0px 24px;
`;

const StyledMenu = styled(Menu)`
  height: 100%;
  padding-top: 16px;
`;
const StyledLayout = styled(Layout)`
  padding: 0 24px 24px;
  background: #fff;
`;

const StyledContent = styled(Content)`
  padding: 24px;
  margin: 0px;
  min-height: 280px;
`;
const HeaderTitle = styled.div`
  font-family: SF Pro Display;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;

  display: flex;
  align-items: center;

  color: #262626;
  margin-bottom: 24px;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const HeroPass = ({ heroPass, updateClick, deleteClick }) => {
  const [form] = Form.useForm();
  const [currentTab, setCurrentTab] = useState(1);
  const [type, setType] = React.useState(heroPass?.type);
  const [isActive, setIsActive] = React.useState(heroPass?.isActive);

  const handleTabChange = activeKey => {
    setCurrentTab(activeKey);
  };

  const getTooltip = (text, fullText) => {
    return <StyledTooltip {...{ text, fullText }} />;
  };

  const onFinish = values => {
    updateClick({
      ...values
    });
  };

  const onDelete = () => {
    showConfirm(() => {
      deleteClick();
    });
  };

  return (
    <Layout>
      <StyledBreadcrumb>
        <Breadcrumb.Item>
          <Link to="/heroPasses">HeroPasses</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{heroPass?.name}</Breadcrumb.Item>
      </StyledBreadcrumb>
      <StyledHeader>
        <h2>Редактирование HeroPass</h2>
      </StyledHeader>
      <Layout>
        <Sider>
          <StyledMenu mode="inline" defaultSelectedKeys={['1']}>
            <Menu.Item
              icon={<InfoCircleOutlined />}
              key="1"
              onClick={() => handleTabChange(1)}
            >
              Основные
            </Menu.Item>
            <Menu.Item
              icon={<InfoCircleOutlined />}
              key="2"
              onClick={() => handleTabChange(2)}
            >
              Рекуррентные платежи
            </Menu.Item>
          </StyledMenu>
        </Sider>
        <StyledLayout>
          <StyledContent className="site-layout-background">
            {currentTab === 1 && (
              <>
                <HeaderTitle>Основные</HeaderTitle>
                <Form form={form} layout="vertical" hideRequiredMark>
                  <FormItem
                    key="name"
                    label={getTooltip('Название', 'Название HeroPass')}
                    name="name"
                    rules={[
                      {
                        required: true,
                        message: 'Укажите название HeroPass'
                      }
                    ]}
                    initialValue={heroPass?.name}
                  >
                    <Input placeholder="Название" />
                  </FormItem>
                  <FormItem
                    key="description"
                    label={getTooltip('Описание', 'Описание HeroPass')}
                    rules={[
                      {
                        required: true,
                        message: 'Укажите описание HeroPass'
                      }
                    ]}
                    initialValue={heroPass?.description}
                    name="description"
                  >
                    <Input placeholder="Описание" />
                  </FormItem>
                  <FormItem
                    key="price"
                    label={getTooltip('Цена', 'Цена HeroPass')}
                    rules={[
                      {
                        required: true,
                        message: 'Укажите цену HeroPass'
                      }
                    ]}
                    initialValue={heroPass?.price}
                    name="price"
                  >
                    <Input placeholder="Цена" />
                  </FormItem>
                  <FormItem
                    key="duration"
                    initialValue={heroPass?.duration}
                    rules={[
                      {
                        required: true,
                        message: 'Укажите продолжительность HeroPass'
                      }
                    ]}
                    label={getTooltip(
                      'Продолжительность',
                      'Продолжительность HeroPass'
                    )}
                    name="duration"
                  >
                    <Input type="number" placeholder="Туннель" />
                  </FormItem>
                  <FormItem
                    key="imageURL"
                    initialValue={heroPass?.imageURL}
                    rules={[
                      {
                        required: true,
                        message: 'Укажите ссылку на изображения'
                      }
                    ]}
                    label={getTooltip(
                      'Ссылка на изображения',
                      'Ссылка на изображения'
                    )}
                    name="imageURL"
                  >
                    <Input placeholder="Ссылка на изображения" />
                  </FormItem>
                  <FormItem
                    key="type"
                    label={getTooltip('Тип HeroPass', 'Тип HeroPass')}
                    name="type"
                    initialValue={heroPass?.type}
                  >
                    <Select
                      mode="single"
                      placeholder="Choose type"
                      showSearch
                      onChange={value => setType(value)}
                    >
                      <Select.Option key="limited" value="limited">
                        Лимитом
                      </Select.Option>
                      <Select.Option key="unlimited" value="unlimited">
                        Безлимит
                      </Select.Option>
                    </Select>
                  </FormItem>
                  <FormItem
                    key="isActive"
                    initialValue={heroPass?.isActive}
                    label={getTooltip('Активировать', 'Активировать HeroPass')}
                    name="isActive"
                  >
                    <Switch
                      onChange={checked => {
                        setIsActive(checked);
                      }}
                      defaultChecked={isActive}
                      checkedChildren={<CheckOutlined />}
                      unCheckedChildren={<CloseOutlined />}
                      placeholder="Активировать"
                    />
                  </FormItem>
                  {type === 'limited' && (
                    <FormItem
                      key="marathonEventCnt"
                      label={getTooltip(
                        'Количество Марафонов',
                        'Количество Марафонов'
                      )}
                      initialValue={heroPass?.marathonEventCnt}
                      name="marathonEventCnt"
                    >
                      <Input type="number" placeholder="Количество Марафонов" />
                    </FormItem>
                  )}
                  <Form.Item>
                    <ButtonContainer>
                      <Button danger onClick={onDelete}>
                        Удалить
                      </Button>
                      <Button
                        type="primary"
                        htmlType="submit"
                        onClick={() => {
                          form
                            .validateFields()
                            .then(values => {
                              form.resetFields();
                              onFinish(values);
                            })
                            .catch(info => {
                              console.log('Validate Failed:', info);
                            });
                        }}
                      >
                        Сохранить
                      </Button>
                    </ButtonContainer>
                  </Form.Item>
                </Form>
              </>
            )}
            {currentTab === 2 && <Recurrents heroPassId={heroPass.id} />}
          </StyledContent>
        </StyledLayout>
      </Layout>
    </Layout>
  );
};
HeroPass.propTypes = {
  heroPass: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    city: PropTypes.string,
    country: PropTypes.string
  }).isRequired,
  updateClick: PropTypes.func.isRequired,
  deleteClick: PropTypes.func.isRequired
};
export default HeroPass;
