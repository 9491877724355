import React, { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import PropTypes from "prop-types";

import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import ruLocale from "@fullcalendar/core/locales/ru";

import { Modal } from "antd";
import ReceptionEventContainer from "../receptionEvent/ReceptionEvent.container";

function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();
    return <Component {...props} router={{ location, navigate, params }} />;
  }

  return ComponentWithRouterProp;
}

const StyledModal = styled(Modal)`
  width: max-content;
`;

const CalendarContainer = styled.div`
  background: #fff;
`;

const ReceptionEvents = ({ events }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [eventId, setEventId] = useState(null);

  const descriptiveEvents = events.map((event) => {
    return {
      ...event,
      programName: event?.programSet.name,
      clubName: event?.programSet?.club?.name
    };
  });

  const myEventsList = descriptiveEvents.map((event) => ({
    title: event.programName,
    start: Date.parse(event.startTime),
    end: Date.parse(event.endTime),
    id: event.id,
    bookings: event.bookings
  }));

  const handleCalendarClick = (event) => {
    setModalVisible(true);
    const selectedEventId = event?.event?._def?.publicId;
    setEventId(selectedEventId);
  };

  return (
    <>
      <CalendarContainer>
        <FullCalendar
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
          initialView="dayGridMonth"
          views={["dayGridMonth", "timeGridWeek", "timeGridDay"]}
          viewClassNames="dayGridMonth"
          headerToolbar={{
            start: "prev next title",
            center: "today",
            end: "dayGridMonth timeGridWeek timeGridDay"
          }}
          slotLabelFormat={{ hour: "numeric", minute: "2-digit" }}
          eventTimeFormat={{
            hour: "2-digit",
            minute: "2-digit",
            hour12: false
          }}
          locale={ruLocale}
          eventClick={handleCalendarClick}
          events={myEventsList}
        />
      </CalendarContainer>
      <StyledModal
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        onOk={() => setModalVisible(false)}
        width={900}
      >
        <h1>Зарегистрированные не ЧК</h1>
        <ReceptionEventContainer eventId={eventId} />
      </StyledModal>
    </>
  );
};

ReceptionEvents.propTypes = {
  events: PropTypes.arrayOf(PropTypes.shape({})).isRequired
};

export default withRouter(ReceptionEvents);
