import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Loading from "../shared/Loading";
import PropTypes from "prop-types";
import Ticket from "./Ticket";
import { toast } from "react-toastify";
import { useLoading } from "../../context/useLoading";
import withMainLayout from "../../hocs/withMainLayout";

const TicketContainer = () => {
  const { id: ticketId } = useParams();
  const navigate = useNavigate();
  const { showLoading, hideLoading } = useLoading();

  const [ticketData, setTicketData] = useState(null);
  const [users, setUsers] = useState([]);
  const [levels, setLevels] = useState(null);

  useEffect(() => {
    // Simulate fetching ticket data
    const fetchedTicket = {
      id: ticketId,
      title: "Sample Ticket",
      type: "issue",
      text: "This is a sample ticket.",
      users: [{ id: "1", email: "user1@example.com" }],
      level: { id: "1", name: "Level 1", degree: 1 },
    };
    setTicketData(fetchedTicket);

    // Simulate fetching users and levels
    const fetchedUsers = [
      { id: "1", email: "user1@example.com" },
      { id: "2", email: "user2@example.com" },
    ];
    const fetchedLevels = [
      { id: "1", name: "Level 1", degree: 1 },
      { id: "2", name: "Level 2", degree: 2 },
    ];
    setUsers(fetchedUsers);
    setLevels(fetchedLevels);
  }, [ticketId]);

  if (!ticketData || !users || !levels) return <Loading />;

  const handleUpdateClick = (values) => {
    showLoading();
    // Simulate updating the ticket
    setTimeout(() => {
      hideLoading();
      toast.success("Ticket successfully updated");
    }, 1000);
  };

  const handleDeleteClick = () => {
    showLoading();
    // Simulate deleting the ticket
    setTimeout(() => {
      hideLoading();
      toast.success("Ticket successfully deleted");
      navigate("/tickets");
    }, 1000);
  };

  return (
    <>
      {ticketData && levels && (
        <Ticket
          ticket={ticketData}
          allUsers={users}
          allLevels={levels}
          updateClick={handleUpdateClick}
          deleteClick={handleDeleteClick}
          refetchUsers={() => {}}
        />
      )}
    </>
  );
};

TicketContainer.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

export default withMainLayout(TicketContainer);