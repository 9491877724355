import React, { useState, useEffect } from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import { toast } from 'react-toastify';

import ProgramSets from './ProgramSets';
import Loading from '../shared/Loading';
import { useLoading } from '../../context/useLoading';
import withMainLayout from '../../hocs/withMainLayout';

const GET_PROGRAMSETS = gql`
  query getProgramSets {
    programSets {
      id
      name
      shortDescription
      transitionTimeRounds
      transitionTimeSets
      timeRep
      timeGreen
      timeRed
      introDuration
      strength
      ability
      endurance
      sexuality
      willPower
      biceps
      pectoralMuscles
      backMuscles
      lumbarMuscles
      quadriceps
      buttocks
      legBiceps
      caviar
      delta
      triceps
      press
      cardio
      club {
        id
        name
      }
      zone {
        id
        name
      }
      levels {
        id
        name
      }
    }
    clubs {
      id
      name
      zones {
        id
        name
      }
    }

    levels {
      id
      name
    }
  }
`;

const DELETE_PROGRAMSET = gql`
  mutation deleteProgramSet($id: ID!) {
    deleteProgramSet(id: $id)
  }
`;

const ADD_PROGRAMSET = gql`
  mutation addProgramSet($input: ProgramSetInput) {
    addProgramSet(input: $input) {
      id
      name
      shortDescription
      transitionTimeRounds
      transitionTimeSets
      timeRep
      timeGreen
      timeRed
      introDuration
      strength
      ability
      endurance
      sexuality
      willPower
      biceps
      pectoralMuscles
      backMuscles
      lumbarMuscles
      quadriceps
      buttocks
      legBiceps
      caviar
      delta
      triceps
      press
      cardio
      club {
        id
      }
      zone {
        id
      }
      levels {
        id
        name
      }
    }
  }
`;

const UPDATE_PROGRAMSET = gql`
  mutation updateProgram($id: ID!, $input: ProgramInput) {
    updateProgram(id: $id, input: $input) {
      id
      name
      transitionTimeRounds
      transitionTimeSets
      timeRep
      timeGreen
      timeRed
      introDuration
      club {
        id
      }
      zone {
        id
      }
      levels {
        id
        name
      }
    }
  }
`;

const ProgramSetsContainer = () => {
  const [dataProgramSets, setDataProgramSets] = useState(null);
  const [dataClubs, setDataClubs] = useState(null);
  const [dataLevels, setDataLevels] = useState(null);

  const { showLoading, hideLoading } = useLoading();

  const [updateProgramSet] = useMutation(UPDATE_PROGRAMSET);

  const { data, loading, error } = useQuery(GET_PROGRAMSETS, {
    fetchPolicy: 'no-cache'
  });

  const [deleteProgramSet] = useMutation(DELETE_PROGRAMSET, {
    update(cache, { data: { deleteProgramSet: id } }) {
      const { programSets } = cache.readQuery({ query: GET_PROGRAMSETS });
      cache.writeQuery({
        query: GET_PROGRAMSETS,
        data: {
          programSets: programSets.filter(programSet => programSet.id !== id)
        }
      });
      hideLoading();
      toast.success('Programset успешно удален');
    }
  });

  const [addProgramSet] = useMutation(ADD_PROGRAMSET, {
    update(cache, { data: { addProgramSet: programSet } }) {
      const { programSets } = cache.readQuery({ query: GET_PROGRAMSETS });
      cache.writeQuery({
        query: GET_PROGRAMSETS,
        data: { programSets: programSets.concat([programSet]) }
      });
      hideLoading();
      toast.success('Упражнение успешно создано');
    }
  });

  useEffect(() => {
    if (data && !loading && !error) {
      setDataProgramSets(data.programSets);
      setDataClubs(data.clubs);
      setDataLevels(data.levels);
    }
  }, [data, loading, error]);

  const handleDeleteProgramSet = programSetId => {
    showLoading();
    deleteProgramSet({ variables: { id: programSetId } });
  };

  const handleAddProgramSet = values => {
    showLoading();
    const dataProgramSet = {
      transitionTimeRounds: parseInt(values.transitionTimeRounds, 10),
      transitionTimeSets: parseInt(values.transitionTimeSets, 10),
      timeRep: parseInt(values.timeRep, 10),
      timeGreen: parseInt(values.timeGreen, 10),
      timeRed: parseInt(values.timeRed, 10),
      introDuration: parseInt(values.introDuration, 10),
      strength: parseInt(values.strength, 10),
      ability: parseInt(values.ability, 10),
      endurance: parseInt(values.endurance, 10),
      sexuality: parseInt(values.sexuality, 10),
      willPower: parseInt(values.willPower, 10),
      biceps: parseInt(values.biceps, 10),
      pectoralMuscles: parseInt(values.pectoralMuscles, 10),
      backMuscles: parseInt(values.backMuscles, 10),
      lumbarMuscles: parseInt(values.lumbarMuscles, 10),
      quadriceps: parseInt(values.quadriceps, 10),
      buttocks: parseInt(values.buttocks, 10),
      legBiceps: parseInt(values.legBiceps, 10),
      caviar: parseInt(values.caviar, 10),
      delta: parseInt(values.delta, 10),
      triceps: parseInt(values.triceps, 10),
      press: parseInt(values.press, 10),
      cardio: parseInt(values.cardio, 10)
    };
    delete values.transitionTimeRounds;
    delete values.transitionTimeSets;
    delete values.timeRep;
    delete values.timeGreen;
    delete values.timeRed;
    delete values.introDuration;
    delete values.strength;
    delete values.ability;
    delete values.endurance;
    delete values.sexuality;
    delete values.biceps;
    delete values.pectoralMuscles;
    delete values.backMuscles;
    delete values.lumbarMuscles;
    delete values.buttocks;
    delete values.legBiceps;
    delete values.caviar;
    delete values.delta;
    delete values.triceps;
    delete values.willPower;
    delete values.press;
    delete values.cardio;

    addProgramSet({
      variables: {
        input: { ...values, ...dataProgramSet }
      }
    });
  };

  const handleUpdateProgramSet = (id, values) => {
    updateProgramSet({ variables: { id, input: values } });
  };

  if (loading && (!dataProgramSets || !dataClubs || !dataLevels)) {
    return <Loading />;
  }

  return (
    <>
      {dataProgramSets && dataClubs && dataLevels && (
        <ProgramSets
          programSets={dataProgramSets}
          clubs={dataClubs}
          levels={dataLevels}
          deleteProgramSetClick={handleDeleteProgramSet}
          addProgramSetClick={handleAddProgramSet}
          updateProgramSetClick={handleUpdateProgramSet}
        />
      )}
    </>
  );
};

export default withMainLayout(ProgramSetsContainer);
