import styled from 'styled-components';
import React from 'react';
import { message } from 'antd';
import dayjs from 'dayjs';

import CancelLogo from '../../images/Clear.png';

const WrapperBusket = styled.div`
  background-color: #fff;
  padding: 20px 0 0 0;
  position: fixed;
  transition: all 0.3s;
  top: 0;
  right: ${(props) => (props.show ? '0' : '-30%')};
  width: 25%;
  height: 100%;
  z-index: 1;
`;
const Header = styled.div`
  display: flex;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  padding: 0 5% 4% 5%;
  justify-content: space-between;
`;

const Title = styled.div`
  font-family: 'GraphikLCGMedium';
  font-size: 24px;
  line-height: 24px;
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
`;
const HeaderBody = styled.div`
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  padding-left: 5%;
  padding-right: 5%;
`;
const HeaderText = styled.div`
  font-size: 18px;
  font-family: 'GraphikLCGMedium';
`;
const TextRow = styled.div`
  flex-direction: row;
  display: flex;
  margin: 3% 0 0 0;
`;
const HeaderBodyText = styled.div`
  font-size: 16px;
`;
const Main = styled.div`
  padding-left: 5%;
  padding-right: 5%;
`;

const Cancel = styled.button`
  border: none;
  background-color: #fff;
  cursor: pointer;
  padding-right: 1%;
  width: 100px;
  height: 30px;
`;

const Text = styled.span`
  color: #000000;
  font-family: 'GraphikLCGRegular';
  font-size: 14px;
  line-height: 16px;
  text-align: left;
`;

const SalesPaymentsInfo = ({ setShowInfo, showInfo, sale }) => {
  const [messageApi, contextHolder] = message.useMessage();

  const paymentTitle = () => {
    let text = '';

    if (sale?.payment) {
      text = `${sale?.payment?.countClasses} билетов ${
        sale?.payment?.ticketType === 'regular'
          ? 'для клиентов Invictus'
          : 'для всех'
      }`;
    }
    if (sale?.heroPass) {
      text = `HeroPass "${sale?.heroPass?.name}"`;
    }
    if (sale?.marathonEvent && sale?.type !== 'addedFromHeroPass') {
      text = `"${sale?.marathonEvent?.marathon?.name}" c ${dayjs(
        sale?.marathonEvent?.startTime
      ).format('Do MMMM')} до ${dayjs(sale?.marathonEvent?.endTime).format(
        'Do MMMM, YYYY'
      )}`;
    }
    if (sale?.type === 'addedFromHeroPass') {
      text = `Приобретение Марафона через HeroPass "${
        sale?.marathonEvent?.marathon?.name
      }" c ${dayjs(sale?.marathonEvent?.startTime).format(
        'Do MMMM'
      )} до ${dayjs(sale?.marathonEvent?.endTime).format('Do MMMM, YYYY')}`;
    }
    return <Text>{text}</Text>;
  };

  const price = () => {
    let cost = 0;
    if (sale?.payment) {
      cost = sale?.payment?.cost;
    }
    if (sale?.heroPass) {
      cost = sale?.heroPass?.price;
    }
    if (sale?.marathonEvent && sale?.type !== 'addedFromHeroPass') {
      cost = sale?.marathonEvent?.marathon?.price;
    }
    return (
      <Text>
        {sale?.type === 'addedFromHeroPass' ? 'Через HeroPass' : cost}₸
      </Text>
    );
  };

  const paymentMethod = () => {
    if (sale?.paymentMethod === 'cash') {
      return 'Наличные';
    } else if (sale?.paymentMethod === 'card') {
      return 'Карта';
    } else if (sale?.paymentMethod === 'kaspiCredit') {
      return 'Каспий кредит';
    }
  };

  return (
    <>
      {contextHolder}
      <WrapperBusket show={showInfo}>
        <Header>
          <Title> Детали платежа </Title>
          <img
            src={CancelLogo}
            onClick={() => setShowInfo(false)}
            style={{ cursor: 'pointer' }}
          />
        </Header>
        <Body>
          <HeaderBody>
            <HeaderText style={{ paddingTop: 20 }}>
              Информация о покупке
            </HeaderText>
            <TextRow>
              <HeaderBodyText
                style={{
                  fontSize: 14,
                  color: 'rgba(0, 0, 0, 0.5)',
                  fontFamily: 'GraphikLCGMedium',
                }}
              >
                Номер заказа:{' '}
              </HeaderBodyText>
              <HeaderBodyText
                style={{
                  marginLeft: 'auto',
                  fontFamily: 'GraphikLCGMedium',
                  fontSize: 14,
                }}
              >
                {sale?.checkId}
              </HeaderBodyText>
            </TextRow>
            <TextRow>
              <HeaderBodyText
                style={{
                  fontSize: 14,
                  color: 'rgba(0, 0, 0, 0.5)',
                  fontFamily: 'GraphikLCGMedium',
                }}
              >
                URL чека:{' '}
              </HeaderBodyText>
              <HeaderBodyText
                onClick={async () => {
                  if (sale?.checkLink) {
                    await navigator.clipboard.writeText(sale?.checkLink);
                    messageApi.open({
                      type: 'success',
                      content: 'URL скопирован',
                    });
                  } else {
                    messageApi.open({
                      type: 'error',
                      content: 'URL не найден',
                    });
                  }
                }}
                style={{
                  cursor: 'pointer',
                  marginLeft: 'auto',
                  fontFamily: 'GraphikLCGMedium',
                  fontSize: 14,
                }}
              >
                Копировать
              </HeaderBodyText>
            </TextRow>
            <TextRow>
              <HeaderBodyText
                style={{
                  fontSize: 14,
                  color: 'rgba(0, 0, 0, 0.5)',
                  fontFamily: 'GraphikLCGMedium',
                }}
              >
                Имя клиента:{' '}
              </HeaderBodyText>
              <HeaderBodyText
                style={{
                  marginLeft: 'auto',
                  fontFamily: 'GraphikLCGMedium',
                  fontSize: 14,
                }}
              >
                {sale?.user?.nickname}
              </HeaderBodyText>
            </TextRow>
            <TextRow>
              <HeaderBodyText
                style={{
                  fontSize: 14,
                  color: 'rgba(0, 0, 0, 0.5)',
                  fontFamily: 'GraphikLCGMedium',
                }}
              >
                Номер телефона:{' '}
              </HeaderBodyText>
              <HeaderBodyText
                style={{
                  marginLeft: 'auto',
                  fontFamily: 'GraphikLCGMedium',
                  fontSize: 14,
                }}
              >
                {sale?.user?.phoneNumber}
              </HeaderBodyText>
            </TextRow>

            <TextRow style={{ marginBottom: 24 }}>
              <HeaderBodyText
                style={{
                  fontSize: 14,
                  color: 'rgba(0, 0, 0, 0.5)',
                  fontFamily: 'GraphikLCGMedium',
                }}
              >
                Дата и время::{' '}
              </HeaderBodyText>
              <HeaderBodyText
                style={{
                  marginLeft: 'auto',
                  fontFamily: 'GraphikLCGMedium',
                  paddingBottom: 24,
                  fontSize: 14,
                  paddingRight: 5,
                }}
              >
                {dayjs(sale?.created_at).format('DD.MM.YY')}
                {' | '}
                {dayjs(sale?.created_at).format('HH:mm')}
              </HeaderBodyText>
            </TextRow>
          </HeaderBody>
          <Main>
            <TextRow>
              <HeaderText>Оплата</HeaderText>
            </TextRow>
            <TextRow>
              <HeaderBodyText
                style={{
                  fontSize: 14,
                  fontFamily: 'GraphikLCGMedium',
                  color: 'rgba(0, 0, 0, 0.5)',
                }}
              >
                Товары
              </HeaderBodyText>
              <HeaderBodyText
                style={{
                  marginLeft: 'auto',
                  fontFamily: 'GraphikLCGMedium',
                  fontSize: 14,
                }}
              >
                {paymentTitle()}
              </HeaderBodyText>
            </TextRow>
            <TextRow>
              <HeaderBodyText
                style={{
                  fontSize: 14,
                  fontFamily: 'GraphikLCGMedium',
                  color: 'rgba(0, 0, 0, 0.5)',
                }}
              >
                Стоимость
              </HeaderBodyText>
              <HeaderBodyText
                style={{
                  marginLeft: 'auto',
                  fontFamily: 'GraphikLCGMedium',
                  fontSize: 14,
                }}
              >
                {price()}
              </HeaderBodyText>
            </TextRow>
            <TextRow>
              <HeaderBodyText
                style={{
                  fontSize: 14,
                  fontFamily: 'GraphikLCGMedium',
                  color: 'rgba(0, 0, 0, 0.5)',
                }}
              >
                Скидка
              </HeaderBodyText>
              <HeaderBodyText
                style={{
                  marginLeft: 'auto',
                  fontFamily: 'GraphikLCGMedium',
                  fontSize: 14,
                }}
              >
                {sale?.discount}
              </HeaderBodyText>
            </TextRow>
            <TextRow>
              <HeaderBodyText
                style={{
                  fontSize: 14,
                  fontFamily: 'GraphikLCGMedium',
                  color: 'rgba(0, 0, 0, 0.5)',
                }}
              >
                Сумма к оплате
              </HeaderBodyText>
              <HeaderBodyText
                style={{
                  marginLeft: 'auto',
                  fontFamily: 'GraphikLCGMedium',
                  fontSize: 14,
                }}
              >
                {sale?.paidAmount}₸
              </HeaderBodyText>
            </TextRow>
            <TextRow>
              <HeaderBodyText
                style={{
                  fontSize: 14,
                  fontFamily: 'GraphikLCGMedium',
                  color: 'rgba(0, 0, 0, 0.5)',
                }}
              >
                Способ оплаты
              </HeaderBodyText>
              <HeaderBodyText
                style={{
                  marginLeft: 'auto',
                  fontFamily: 'GraphikLCGMedium',
                  fontSize: 14,
                  paddingBottom: 40,
                }}
              >
                {paymentMethod()}
              </HeaderBodyText>
            </TextRow>
          </Main>
          {/* <Button
          style={{
            borderRadius: 8,
            marginRight: 10,
            marginLeft: 10,
            backgroundColor: '#FFFFFF',
            fontSize: 14,
            fontFamily: 'DINProMedium'
          }}
        >
          Скачать чек
        </Button> */}
        </Body>
      </WrapperBusket>
    </>
  );
};

export default SalesPaymentsInfo;
