import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import React from 'react';
import { Table } from 'antd';

const Tickets = ({ tickets }) => {
  const columns = [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      render: (text, record) => <Link to={`/tickets/${record.id}`}>{text}</Link>,
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: 'Level',
      dataIndex: 'level',
      key: 'level',
      render: (level) => level ? `${level.name} | degree: ${level.degree}` : 'N/A',
    },
    {
      title: 'Users',
      dataIndex: 'users',
      key: 'users',
      render: (users) => users.map(user => user.email).join(', '),
    },
  ];

  return <Table dataSource={tickets} columns={columns} rowKey="id" />;
};

Tickets.propTypes = {
  tickets: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      level: PropTypes.shape({
        name: PropTypes.string,
        degree: PropTypes.number,
      }),
      users: PropTypes.arrayOf(
        PropTypes.shape({
          email: PropTypes.string,
        })
      ),
    })
  ).isRequired,
};

export default Tickets;