import React from 'react';
import { Table, Tag } from 'antd';
import dayjs from 'dayjs';

const SupportBody = ({
  requests,
  total,
  setPage,
  page,
  setSelectedRequest,
  setShowRequestModal,
  renderStatus,
}) => {
  
  const columns = [
    {
      title: () => {
        return (
          <div
            style={{
              fontSize: 14,
              fontFamily: 'GraphikLCGMedium',
              color: 'gray',
            }}
          >
            Имя
          </div>
        );
      },
      dataIndex: ['user', 'nickname'],
      width: '10%',
      render: (text, item) => {
        const userRole = item.user.role;
        const nickname = item.user.nickname;

        const isSales = userRole === 'salesperson' || userRole === 'salesAdmin' || userRole === 'reception';

        return (
          <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
            {isSales && (
              <Tag
                style={{
                  background: '#7526FF',
                  color: '#fff',
                  borderRadius: '4px',
                  padding: '2px 4px',
                  fontWeight: 'bold',
                  fontSize: '12px',
                  lineHeight: '1',
                  fontFamily: 'GraphikLCGMedium'
                }}
              >
                SALES
              </Tag>
            )}
            <div style={{ fontFamily: 'GraphikLCGMedium' }}>{nickname}</div>
          </div>
        );
      },
    },
    {
      title: () => {
        return (
          <div
            style={{
              fontSize: 14,
              fontFamily: 'GraphikLCGMedium',
              color: 'gray',
            }}
          >
            Категория
          </div>
        );
      },
      dataIndex: ['requestCategory', 'name'],
      width: '15%',
      render: (text, item) => (
        <div style={{ fontFamily: 'GraphikLCGMedium' }}>{text}</div>
      ),
    },
    {
      title: () => {
        return (
          <div
            style={{
              fontSize: 14,
              fontFamily: 'GraphikLCGMedium',
              color: 'gray',
            }}
          >
            Ответственный
          </div>
        );
      },
      dataIndex: ['assigned', 'nickname'],
      width: '10%',
      render: (text, item) => (
        <div
          style={{
            fontFamily: 'GraphikLCGMedium',
            color: text ? 'black' : 'gray',
          }}
        >
          {item?.assigned?.nickname || 'Отсутствует'}
        </div>
      ),
    },
    {
      title: () => {
        return (
          <div
            style={{
              fontSize: 14,
              fontFamily: 'GraphikLCGMedium',
              color: 'gray',
            }}
          >
            Статус
          </div>
        );
      },
      dataIndex: 'status',
      width: '10%',
      render: (text, item) => (
        <div style={{ fontFamily: 'GraphikLCGMedium' }}>
          {renderStatus(text)}
        </div>
      ),
    },
    {
      title: () => {
        return (
          <div
            style={{
              fontSize: 14,
              fontFamily: 'GraphikLCGMedium',
              color: 'gray',
            }}
          >
            Дата обновления
          </div>
        );
      },
      dataIndex: 'updated_at',
      width: '10%',
      render: (text, item) => (
        <div style={{ fontFamily: 'GraphikLCGMedium' }}>
          {dayjs(text).format('DD.MM.YY - HH:mm')}
        </div>
      ),
    },
    {
      title: () => {
        return (
          <div
            style={{
              fontSize: 14,
              fontFamily: 'GraphikLCGMedium',
              color: 'gray',
            }}
          >
            Дата получения
          </div>
        );
      },
      dataIndex: 'created_at',
      width: '10%',
      render: (text, item) => (
        <div style={{ fontFamily: 'GraphikLCGMedium' }}>
          {dayjs(text).format('DD.MM.YY - HH:mm')}
        </div>
      ),
    },
  ];

  const handleTableChange = (pagination) => {
    setPage(pagination.current);
  };

  return (
    <>
      <Table
        dataSource={requests}
        columns={columns}
        rowKey={(item) => item.id}
        pagination={{
          pageSize: 10,
          total: total,
          current: page,
          onChange: (page) => {
            setPage(page);
          },
          showSizeChanger: false,
        }}
        onChange={handleTableChange}
        onRow={(item) => {
          return {
            onClick: () => {
              setSelectedRequest(item);
              setShowRequestModal(true);
            },
          };
        }}
      />
    </>
  );
};

export default SupportBody;
