import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Table, Button, Divider } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import CreateWeeklyTask from './CreateWeeklyTask';
import EditWeeklyTask from './EditWeeklyTask';

const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;
const HeaderTitle = styled.div`
  font-family: SF Pro Display;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;

  display: flex;
  align-items: center;

  color: #262626;
`;

const WeeklyTasks = ({
  weeklyTasks,
  addWeeklyTaskClick,
  updateWeeklyTaskClick,
  deleteWeeklyTaskClick
}) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [display, setDisplay] = useState('defualt');
  const [editWeeklyTask, setEditWeeklyTask] = useState(null);
  const columns = [
    {
      title: 'Название',
      dataIndex: 'name'
    },
    {
      title: 'Действие',
      key: 'action',
      render: (text, item) => (
        <span>
          <Button
            onClick={() => {
              setDisplay('edit');
              setEditWeeklyTask(item);
            }}
          >
            Редактировать
          </Button>
          <Divider type="vertical" />
        </span>
      )
    }
  ];

  const handleCreate = values => {
    addWeeklyTaskClick(values);
    setModalVisible(false);
  };

  const handleUpdate = values => {
    updateWeeklyTaskClick({ id: editWeeklyTask.id, values });
    setDisplay('default');
    setEditWeeklyTask(null);
  };

  const handleDelete = () => {
    deleteWeeklyTaskClick({ id: editWeeklyTask.id });
    setDisplay('default');
    setEditWeeklyTask(null);
  };

  return (
    <>
      <Table
        dataSource={weeklyTasks}
        columns={columns}
        title={() => (
          <Header>
            <HeaderTitle>Станции</HeaderTitle>
            <Button type="primary" onClick={() => setModalVisible(true)}>
              <PlusOutlined /> Новое задание на неделю
            </Button>
          </Header>
        )}
      />
      <CreateWeeklyTask
        title="Добавить новое задание на неделю"
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        onCreate={handleCreate}
      />
      {display === 'edit' && editWeeklyTask && (
        <EditWeeklyTask
          visible={display === 'edit'}
          title="Редактировать задание на неделю"
          onCancel={() => {
            setDisplay('defualt');
            setEditWeeklyTask(null);
          }}
          weeklyTask={editWeeklyTask}
          onUpdate={handleUpdate}
          onDelete={handleDelete}
        />
      )}
    </>
  );
};

WeeklyTasks.propTypes = {
  weeklyTasks: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string
  }).isRequired,
  addWeeklyTaskClick: PropTypes.func.isRequired,
  updateWeeklyTaskClick: PropTypes.func.isRequired,
  deleteWeeklyTaskClick: PropTypes.func.isRequired
};

export default WeeklyTasks;
