import React from 'react';
import { gql, useQuery, useLazyQuery } from '@apollo/client';

import SalesUsers from './SalesUsers';
import withMainLayout from '../../hocs/withMainLayout';
import Loading from '../shared/Loading';

const GET_USERS = gql`
  query find($query: UsersQuery, $pagination: PaginationQuery) {
    findUsers(queryUsers: $query, pagination: $pagination) {
      users {
        id
        username
        nickname
        phoneNumber
        email
        utmSource
        utmCampaign
        utmMedium
        contactId
        leadId
        created_at
        hero {
          id
        }
      }
      total
      limit
      page
      pages
    }
  }
`;

const SalesUsersContainer = () => {
  const [users, setUsers] = React.useState(null);
  const [usersCnt, setUsersCnt] = React.useState(0);
  const [page, setPage] = React.useState(1);
  const [searchPhoneNumber, setSearchPhoneNumber] = React.useState('');
  const [
    getUsers,
    {
      loading: loadingUsers,
      error: errorUsers,
      data: dataUsers,
      refetch: refetchUsers
    }
  ] = useLazyQuery(GET_USERS);

  React.useEffect(() => {
    getUsers({
      pagination: {
        page: page,
        limit: 10
      }
    });
  }, []);

  React.useEffect(() => {
    if (dataUsers && dataUsers.findUsers && !loadingUsers) {
      setUsers(dataUsers.findUsers.users);
      setUsersCnt(dataUsers.findUsers.total);
    } else if (errorUsers) {
      console.log(`error `, errorUsers);
    }
  }, [dataUsers, loadingUsers, errorUsers]);

  React.useEffect(() => {
    refetchUsers({
      query: {
        phoneNumber: searchPhoneNumber
      },
      pagination: {
        page: page,
        limit: 10
      }
    });
  }, [page, searchPhoneNumber]);

  if (loadingUsers && !users) {
    return <Loading />;
  }

  if (errorUsers) {
    console.log(errorUsers);
    return <h1> error occured</h1>;
  }

  return (
    <SalesUsers
      dataUsers={users}
      usersCnt={usersCnt}
      setPage={setPage}
      page={page}
      searchPhoneNumber={searchPhoneNumber}
      setSearchPhoneNumber={setSearchPhoneNumber}
    />
  );
};

export default withMainLayout(SalesUsersContainer);
