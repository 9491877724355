import React from 'react';
import styled from 'styled-components';
import { Modal } from 'antd';
import { useAuth } from '../../context/useAuth';

const Container = styled.div`
  background-color: white;
  border-radius: 8px;
`;

const Title = styled.p`
  color: rgba(0, 0, 0, 1);
  font-family: 'GraphikLCGMedium';
  font-size: 24px;
  line-height: 32px;
  text-align: center;
`;

const Section = styled.div`
  display: flex;
  justify-content: space-between;
`;

const PaySection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const SubText = styled.p`
  color: rgba(0, 0, 0, 0.5);
  font-family: 'GraphikLCGMedium';
  font-size: 14px;
  line-height: 16px;
`;

const Name = styled.p`
  color: rgba(0, 0, 0, 1);
  font-family: 'GraphikLCGRegular';
  font-size: 14px;
  line-height: 16px;
`;

const Price = styled.p`
  color: rgba(0, 0, 0, 1);
  font-family: 'GraphikLCGRegular';
  font-size: 24px;
  line-height: 32px;
`;

const ButtonOk = styled.button`
  width: 100%;
  background-color: rgba(111, 44, 255, 1);
  border-radius: 8px;
  border: none;
  height: 56px;
  color: rgba(255, 255, 255, 1);
  font-family: 'GraphikLCGMedium';
  font-size: 18px;
  line-height: 24px;
  cursor: pointer;
  &:hover {
    transition: all 0.3s;
    border: 1px solid rgba(111, 44, 255, 1);
    background-color: white;
    color: rgba(111, 44, 255, 1);
  }
`;

const ButtonCancel = styled.button`
  margin-top: 8px;
  width: 100%;
  background-color: rgba(255, 255, 255, 1);
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  height: 56px;
  color: rgba(0, 0, 0, 1);
  font-family: 'GraphikLCGMedium';
  font-size: 18px;
  line-height: 24px;
  cursor: pointer;
`;

const ConfirmationRefund = ({
  refundConfirmationModel,
  setRefundConfirmationModel,
  showLoading,
  selectUser,
  changeRefundAmount,
  handleRefund,
  refundAmount
}) => {
  const { user } = useAuth();
  return (
    <Modal
      className="ConfirmationRefund"
      open={refundConfirmationModel}
      centered
      footer={null}
      width={368}
      bodyStyle={{ overflowX: 'scroll' }}
      onCancel={() => setRefundConfirmationModel(false)}
    >
      <Container>
        <Title>Вы подтверждаете оформление возврата?</Title>
        <Section
          style={{
            marginTop: 32,
            marginBottom: 8,
          }}
        >
          <SubText>Имя клиента:</SubText>
          <Name>{selectUser?.nickname}</Name>
        </Section>
        <Section style={{ marginBottom: 16 }}>
          <SubText>Администратор:</SubText>
          <Name>{user?.nickname}</Name>
        </Section>
        <PaySection>
          <SubText>Сумма к возврату</SubText>
          <Price>
            {changeRefundAmount ? changeRefundAmount : refundAmount}
            {'₸'}
          </Price>
        </PaySection>
        <ButtonOk
          onClick={() => {
            handleRefund({});
            showLoading();
            setRefundConfirmationModel(false);
          }}
        >
          Подтверждаю
        </ButtonOk>
        <ButtonCancel
          onClick={() => {
            setRefundConfirmationModel(false);
          }}
        >
          Отмена
        </ButtonCancel>
      </Container>
    </Modal>
  );
};

export default ConfirmationRefund;
