import React from 'react';
import { useQuery, useLazyQuery, useMutation } from '@apollo/client';
import styled from 'styled-components';
import { toast } from 'react-toastify';

import withMainLayout from '../../hocs/withMainLayout';
import Loading from '../shared/Loading';
import ClientsHeader from './ClientsHeader';
import ClientsBody from './ClientsBody';
import ClientInfo from './ClientInfo';
import NewClient from './NewClient';
import NewSubscription from './NewSubscription';
import Confirmation from './Confirmation';
import HistoryProgram from './HistoryProgram';
import RefundSub from './RefundSub';
import ClientPayments from './ClientPayments';
import ClientsUsersWithHeroPass from './ClientsUsersWithHeroPass';
import ClientsRecurrents from './ClientsRecurrents';
import GuestsClients from './GuestsClients';
import ConfirmationRefund from './ConfirmationRefund';

import { useAuth } from '../../context/useAuth';
import { useLoading } from '../../context/useLoading';

import {
  GET_USERS,
  GET_ACTIVE_USER_MARATHON,
  GET_USER_MARATHONS,
  GET_MARATHON,
  HERO_PASSES,
  PAY_BY_ORDER,
  REFUND_PAYMENT,
  PAYMENTS,
  CURRENT_USER_HP,
  USER_PAYMENTS,
  USERS_BY_ROLE,
  GET_USERS_WITH_HEROPASS,
  ADD_NOTE,
  ADD_RECURRENT_NOTE,
  GET_RECURRENT_USERS,
  TRANSFER_NOT_USED_MARATHON,
  GET_MARATHON_EVENTS,
  GET_USERS_WITH_GUEST_TICKETS,
  CHANGE_CLUB,
  REREG,
  REFUND,
  GET_CLUBS,
} from './Clients.queries';

const Wrapper = styled.div`
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  background-color: #fff;
`;

const ClientsContainer = () => {
  const { user } = useAuth();
  const { showLoading } = useLoading();
  const [users, setUsers] = React.useState(null);
  const [usersCnt, setUsersCnt] = React.useState(0);
  const [page, setPage] = React.useState(1);
  const [searchPhoneNumber, setSearchPhoneNumber] = React.useState('');
  const [selectUser, setSelectUser] = React.useState();
  const [activeMarathon, setActiveMarathon] = React.useState();
  const [newUserModal, setNewUserModal] = React.useState(false);
  const [heroPasses, setHeroPasses] = React.useState();
  const [marathon, setMarathon] = React.useState();
  const [newAbonement, setNewAbonement] = React.useState();
  const [abonement, setAbonement] = React.useState('Все абонементы');
  const [paymentModal, setPaymentModal] = React.useState(false);
  const [payment, setPayment] = React.useState('');
  const [name, setName] = React.useState('');
  const [totalPrice, setTotalPrice] = React.useState('');
  const [payments, setPayments] = React.useState();
  const [typeAbonement, setTypeAbonement] = React.useState();
  const [newUserPhoneNumber, setNewUserPhoneNumber] = React.useState('');
  const [newUserEmail, setNewUserEmail] = React.useState('');
  const [userCurrentHP, setUserCurrentHP] = React.useState();
  const [date, setDate] = React.useState();
  const [marathonEvent, setMarathonEvent] = React.useState();
  const [newSubscriptionModal, setNewSubcriptionModal] = React.useState(false);
  const [clientInfoModal, setClientInfoModal] = React.useState(false);
  const [oldUser, setOldUser] = React.useState(false);
  const [userPayments, setUserPayments] = React.useState([]);
  const [salesUsers, setSalesUsers] = React.useState();
  const [salesPerson, setSalesPerson] = React.useState('');
  const [comment, setComment] = React.useState('');
  const [mixedPayment, setMixedPayment] = React.useState(false);
  const [cashAmount, setCashAmount] = React.useState('0');
  const [cardAmount, setCardAmount] = React.useState('0');
  const [creditAmount, setCreditAmount] = React.useState('0');
  const [companyBin, setCompanyBin] = React.useState('');
  const [reRegSearch, setReRegSearch] = React.useState(false);
  const [refundSubModal, setRefundSubModal] = React.useState(false);
  const [selectedUserReissue, setSelectedUserReissue] = React.useState();
  const [searchReissueClient, setSearchReissueClient] = React.useState();
  const [searchClientsWithHeroPass, setSearchClientsWithHeroPass] =
    React.useState('');
  const [secondPage, SetSecondPage] = React.useState(1);
  const [reRegUsers, setReRegUsers] = React.useState();
  const [isReRegisterHeroPass, setIsReRegisterHeroPass] = React.useState(false);
  const [marathons, setMarathons] = React.useState(null);
  const [activeMarathonTickets, setActiveMarathonTickets] = React.useState(null);
  const [historyPrograms, setHistoryPrograms] = React.useState(false);
  const [historyPayments, setHistoryPayments] = React.useState(false);
  const [usersWithHeroPass, setUsersWithHeroPass] = React.useState();
  const [totalUsersWithHeroPass, setTotalUsersWithHeroPass] = React.useState();
  const [thirdPage, setThirdPage] = React.useState(1);
  const [clients, setClients] = React.useState(0);
  const [note, setNote] = React.useState('');
  const [idUserMarathonEvent, setIdUserMarathonEvent] = React.useState();
  const [recurrentPage, setRecurrentPage] = React.useState(1);
  const [recurrentUsers, setRecurrentUsers] = React.useState();
  const [recurrentUsersTotal, setRecurrentUsersTotal] = React.useState();
  const [searchRecurrentUsers, setSearchRecurrentUsers] = React.useState(null);
  const [idUserRecurrent, setIdUserRecurrent] = React.useState();
  const [selectedRecurrentUser, setSelectedRecurrentUser] = React.useState();
  const [filter, setFilter] = React.useState();
  const [month, setMonth] = React.useState();
  const [marathonEventsToTransfer, setMarathonEventsToTransfer] =
    React.useState();
  const [guestUsersPage, setGuestUsersPage] = React.useState(1);
  const [searchGuestUsers, setSearchGuestUsers] = React.useState(null);
  const [guestUsers, setGuestUsers] = React.useState();
  const [marathonSummerCamp, setMarathonSummerCamp] = React.useState();
  const [selectedGym, setSelectedGym] = React.useState();
  // refund
  const [file, setFile] = React.useState(null);
  const [refundLetterTime, setRefundLetterTime] = React.useState(null);
  const [refundAmount, setRefundAmount] = React.useState(null);
  const [changeRefundAmount, setChangeRefundAmount] = React.useState(null);
  const [refundConfirmationModel, setRefundConfirmationModel] =
    React.useState(false);

  const [reRegType, setReRegType] = React.useState('');
  const [reRegAmount, setReRegAmount] = React.useState();
  const [refundAmountExplanation, setRefundAmountExplanation] = React.useState();
  const [clubs, setClubs] = React.useState();
  const [
    getUsers,
    {
      loading: loadingUsers,
      error: errorUsers,
      data: dataUsers,
      refetch: refetchUsers,
    },
  ] = useLazyQuery(GET_USERS);

  const [
    getReRegUsers,
    {
      loading: loadingReRegUsers,
      error: errorReRegUsers,
      data: dataReRegUsers,
      refetch: refetchReRegUsers,
    },
  ] = useLazyQuery(GET_USERS);

  const [
    getUsersWithHeroPass,
    {
      loading: loadingUsersWithHeroPass,
      error: errorUsersWithHeroPass,
      data: dataUsersWithHeroPass,
      refetch: refetchUsersWithHeroPass,
    },
  ] = useLazyQuery(GET_USERS_WITH_HEROPASS);

  const [
    getRecurrentUsers,
    {
      loading: loadingRecurrentUsers,
      error: errorRecurrentUsers,
      data: dataRecurrentUsers,
      refetch: refetchRecurrentUsers,
    },
  ] = useLazyQuery(GET_RECURRENT_USERS);

  const [getUserActiveMarathon, { data, loading, error, refetch }] =
    useLazyQuery(GET_ACTIVE_USER_MARATHON);

  const {
    data: dataHP,
    loading: loadingHP,
    error: errorHP,
  } = useQuery(HERO_PASSES);

  const [
    getReRegAmount,
    {
      loading: loadingReRegAmount,
      error: errorReRegAmount,
      data: dataReRegAmount,
      refetch: refetchReRegAmount,
    },
  ] = useLazyQuery(REREG, {
    fetchPolicy: 'no-cache',
  });

  React.useEffect(() => {
    if (selectedGym?.id && selectUser?.id) {
      getReRegAmount({
        variables: {
          userId: selectUser.id,
          clubId: selectedGym.id,
        },
      });
    }
  }, [selectedGym, selectUser?.id, getReRegAmount]);

  React.useEffect(() => {
    if (dataReRegAmount && !loadingReRegAmount && !errorReRegAmount) {
      setReRegAmount(dataReRegAmount?.calculateTransfer);
    }
  }, [dataReRegAmount, loadingReRegAmount, errorReRegAmount]);

  React.useEffect(() => {
    if (selectedGym?.id && selectUser?.id && refetchReRegAmount) {
      refetchReRegAmount({
        userId: selectUser.id,
        clubId: selectedGym.id,
      });
    }
  }, [selectedGym, selectUser?.id, refetchReRegAmount]);

  const {
    data: dataRefund,
    loading: loadingRefund,
    error: errorRefund,
  } = useQuery(REFUND, {
    fetchPolicy: 'no-cache',
    variables: {
      userId: selectUser?.id,
    },
  });

  const { data: clubsData, loading: clubsLoading } = useQuery(GET_CLUBS);

  const [
    getUsersWithGuestTickets,
    {
      loading: loadingUsersWithGuestTickets,
      error: errorUsersWithGuestTickets,
      data: dataUsersWithGuestTickets,
      refetch: refetchUsersWithGuestTickets,
    },
  ] = useLazyQuery(GET_USERS_WITH_GUEST_TICKETS);

  const {
    data: dataMarathon,
    loading: loadingMarathon,
    error: errorMarathon,
  } = useQuery(GET_MARATHON, {
    fetchPolicy: 'no-cache',
    variables: {
      id: '614974f10163567b03eb6926',
    },
  });

  const {
    data: dataMarathonSummerCamp,
    loading: loadingMarathonSummerCamp,
    error: errorMarathonSummerCamp,
  } = useQuery(GET_MARATHON, {
    fetchPolicy: 'no-cache',
    variables: {
      id: '645cc562fe6b24000b736068',
    },
  });

  const {
    data: dataUserCurrentHP,
    loading: loadingUserCurrentHP,
    error: errorUserCurrentHP,
    refetch: refetchUserCurrentHP,
  } = useQuery(CURRENT_USER_HP, {
    fetchPolicy: 'no-cache',
    variables: {
      userId: selectUser?.id,
    },
  });

  const [
    getUserMarathons,
    {
      data: dataUserMarathons,
      loading: loadingUserMarathons,
      error: errorUserMarathons,
    },
  ] = useLazyQuery(GET_USER_MARATHONS);

  const handleReload = () => {
    window.location.reload();
  };

  const [addNewUser] = useMutation(PAY_BY_ORDER, {
    fetchPolicy: 'no-cache',
    onCompleted() {
      toast.success('Абонемент успешно добавлен');
      setTimeout(() => {
        handleReload();
      }, 1500);
    },
    onError() {
      toast.error('Произошла ошибка попробуйте позже');
      setTimeout(() => {
        handleReload();
      }, 1500);
    },
  });

  const [reRegister] = useMutation(CHANGE_CLUB, {
    fetchPolicy: 'no-cache',
    onCompleted() {
      toast.success('Клуб успешно изменен');
      setTimeout(() => {
        handleReload();
      }, 1500);
    },
  });

  const [refundPayment] = useMutation(REFUND_PAYMENT, {
    onError(err) {
      toast.error(`Error ${err.message}`);
    },
    onCompleted() {
      toast.success('возврат успешно оформлен');
      setTimeout(() => {
        handleReload();
      }, 1500);
    },
  });

  const {
    data: dataPayments,
    loading: loadingPayments,
    error: errorPayments,
  } = useQuery(PAYMENTS);

  const [
    getUserPayments,
    {
      data: dataUserPayments,
      loading: loadingUserPayments,
      error: errorUserPayments,
    },
  ] = useLazyQuery(USER_PAYMENTS);

  const {
    data: dataUsersByRole,
    loading: loadingUsersByRole,
    error: errorUsersByRole,
  } = useQuery(USERS_BY_ROLE, {
    fetchPolicy: 'no-cache',
  });

  const [addNote] = useMutation(ADD_NOTE, {
    onCompleted() {
      toast.success('Заметка успешно добавлена');
      setTimeout(() => {
        handleReload();
      }, 1500);
    },
  });

  const [addRecurrentNote] = useMutation(ADD_RECURRENT_NOTE, {
    fetchPolicy: 'no-cache',
    onCompleted() {
      toast.success('Заметка успешно добавлена');
      setTimeout(() => {
        handleReload();
      }, 1500);
    },
  });

  const [transferNotUsedMarathon, { error: errorTransferNotUsedMarathon }] =
    useMutation(TRANSFER_NOT_USED_MARATHON, {
      onError(err) {
        toast.error(`Error ${errorTransferNotUsedMarathon.message}`);
      },
      onCompleted() {
        toast.success('Марафон успешно перенесен');
        setTimeout(() => {
          handleReload();
        }, 1500);
      },
    });
  const [
    getMarathonEvents,
    {
      data: marathonEventsData,
      loading: marathonEventsLoading,
      error: marathonEventsError,
    },
  ] = useLazyQuery(GET_MARATHON_EVENTS, {
    fetchPolicy: 'no-cache',
  });

  React.useEffect(() => {
    getUsers({
      pagination: {
        page: page,
        limit: 10,
      },
    });
  }, [getUsers, page]);

  React.useEffect(() => {
    getReRegUsers({
      pagination: {
        page: secondPage,
        limit: 10,
      },
    });
  }, [getReRegUsers, secondPage]);

  React.useEffect(() => {
    getUsersWithHeroPass({
      variables: {
        pagination: {
          page: thirdPage,
          limit: 10,
        },
        queryUsers: {
          phoneNumber: searchClientsWithHeroPass,
        },
        heroPassId: null,
        month: null,
        year: null,
      },
    });
  }, [getUsersWithHeroPass, searchClientsWithHeroPass, thirdPage]);

  React.useEffect(() => {
    getRecurrentUsers({
      variables: {
        pagination: {
          page: recurrentPage,
          limit: 10,
        },
        queryUsers: {
          phoneNumber: searchRecurrentUsers,
        },
      },
    });
  }, [getRecurrentUsers, searchRecurrentUsers, recurrentPage]);

  React.useEffect(() => {
    getUsersWithGuestTickets({
      variables: {
        query: {
          limit: 10,
          page: guestUsersPage,
        },
        queryUsers: {
          phoneNumber: searchGuestUsers,
        },
      },
    });
  }, [guestUsersPage, searchGuestUsers, getUsersWithGuestTickets]);

  React.useEffect(() => {
    if (dataUsers && dataUsers.findUsers && !loadingUsers) {
      setUsers(dataUsers.findUsers.users);
      setUsersCnt(dataUsers.findUsers.total);
    }
  }, [dataUsers, loadingUsers, errorUsers]);

  React.useEffect(() => {
    if (dataReRegUsers && dataReRegUsers.findUsers && !loadingReRegUsers) {
      setReRegUsers(dataReRegUsers.findUsers.users);
    }
  }, [dataReRegUsers, loadingReRegUsers, errorReRegUsers]);

  React.useEffect(() => {
    if (
      dataUsersWithHeroPass &&
      dataUsersWithHeroPass.activeUserHeroPasses &&
      !loadingUsersWithHeroPass
    ) {
      setUsersWithHeroPass(
        dataUsersWithHeroPass.activeUserHeroPasses?.userHeroPasses
      );
      setTotalUsersWithHeroPass(
        dataUsersWithHeroPass.activeUserHeroPasses.total
      );
    }
  }, [dataUsersWithHeroPass, loadingUsersWithHeroPass, errorUsersWithHeroPass]);

  React.useEffect(() => {
    if (
      dataRecurrentUsers &&
      dataRecurrentUsers.usersRecurrentPayments &&
      !loadingRecurrentUsers
    ) {
      setRecurrentUsers(dataRecurrentUsers.usersRecurrentPayments);
      setRecurrentUsersTotal(dataRecurrentUsers.total);
    }
  }, [dataRecurrentUsers, loadingRecurrentUsers, errorRecurrentUsers]);

  React.useEffect(() => {
    if (
      dataUsersWithGuestTickets &&
      dataUsersWithGuestTickets.userGuestTicketWithPaginate &&
      !loadingUsersWithGuestTickets
    ) {
      setGuestUsers(dataUsersWithGuestTickets.userGuestTicketWithPaginate);
    }
  }, [
    dataUsersWithGuestTickets,
    loadingUsersWithGuestTickets,
    errorUsersWithGuestTickets,
  ]);

  React.useEffect(() => {
    refetchUsers({
      query: {
        phoneNumber: searchPhoneNumber,
      },
      pagination: {
        page: page,
        limit: 10,
      },
    });
  }, [page, searchPhoneNumber, refetchUsers]);

  React.useEffect(() => {
    refetchReRegUsers({
      query: {
        phoneNumber: searchReissueClient,
      },
      pagination: {
        page: secondPage,
        limit: 10,
      },
    });
  }, [secondPage, searchReissueClient, refetchReRegUsers]);

  React.useEffect(() => {
    refetchUsersWithHeroPass({
      queryUsers: {
        phoneNumber: searchClientsWithHeroPass,
      },
      pagination: {
        page: thirdPage,
        limit: 10,
      },
      heroPassId: filter ? filter?.id : null,
      month: month ? parseInt(month.month) : null,
      year: month ? parseInt(month.year) : null,
    });
    // .then(() => {
    //   hideLoading()
    // })
  }, [
    thirdPage,
    searchClientsWithHeroPass,
    month,
    filter,
    refetchUsersWithHeroPass,
  ]);

  React.useEffect(() => {
    refetchRecurrentUsers({
      queryUsers: {
        phoneNumber: searchRecurrentUsers,
      },
      pagination: {
        page: recurrentPage,
        limit: 10,
      },
    });
  }, [recurrentPage, searchRecurrentUsers, refetchRecurrentUsers]);

  React.useEffect(() => {
    refetchUsersWithGuestTickets({
      variables: {
        query: {
          limit: 10,
          page: guestUsersPage,
        },
        queryUsers: {
          phoneNumber: searchGuestUsers,
        },
      },
    });
  }, [guestUsersPage, searchGuestUsers, refetchUsersWithGuestTickets]);

  React.useEffect(() => {
    if (data && !loading && !error) {
      setActiveMarathon(data.getActiveMarathon);
      setActiveMarathonTickets(data.userMarathonEventByUser);
    }
  }, [data, loading, error]);

  React.useEffect(() => {
    if (dataHP && !loadingHP && !errorHP) {
      setHeroPasses(dataHP.heroPasses);
    }
  }, [dataHP, loadingHP, errorHP]);

  React.useEffect(() => {
    if (dataRefund && !loadingRefund && !errorRefund) {
      setRefundAmountExplanation(dataRefund?.calculateRefund);
    }
  }, [dataRefund, loadingRefund, errorRefund]);

  React.useEffect(() => {
    if (clubsData && !clubsLoading && users) {
      setClubs(clubsData.clubs);
    }
  }, [clubsData, clubsLoading, users]);

  React.useEffect(() => {
    if (dataMarathon && !loadingMarathon && !errorMarathon) {
      setMarathon(dataMarathon.marathon);
    }
  }, [dataMarathon, loadingMarathon, errorMarathon]);

  React.useEffect(() => {
    if (
      dataMarathonSummerCamp &&
      !loadingMarathonSummerCamp &&
      !errorMarathonSummerCamp
    ) {
      setMarathonSummerCamp(dataMarathonSummerCamp.marathon);
    }
  }, [
    dataMarathonSummerCamp,
    loadingMarathonSummerCamp,
    errorMarathonSummerCamp,
  ]);

  React.useEffect(() => {
    if (dataPayments && !loadingPayments && !errorPayments) {
      setPayments(dataPayments.payments);
    }
  }, [dataPayments, loadingPayments, errorPayments]);

  React.useEffect(() => {
    if (
      !loadingUserCurrentHP &&
      dataUserCurrentHP &&
      dataUserCurrentHP.currentUserHeroPass
    ) {
      setUserCurrentHP(dataUserCurrentHP.currentUserHeroPass);
    }
  }, [dataUserCurrentHP, loadingUserCurrentHP, errorUserCurrentHP]);

  React.useEffect(() => {
    if (
      dataUserPayments &&
      dataUserPayments.userPayments &&
      !loadingUserPayments
    ) {
      setUserPayments(dataUserPayments.userPayments);
    }
  }, [dataUserPayments, loadingUserPayments, errorUserPayments]);

  React.useEffect(() => {
    if (dataUsersByRole && !loadingUsersByRole && dataUsersByRole.salesByRole) {
      setSalesUsers(dataUsersByRole.salesByRole);
    }
  }, [dataUsersByRole, loadingUsersByRole, errorUsersByRole]);

  React.useEffect(() => {
    if (dataUserMarathons && !loadingUserMarathons && !errorUserMarathons) {
      setMarathons(dataUserMarathons.userMarathonEventsByUser);
    }
  }, [dataUserMarathons, loadingUserMarathons, errorUserMarathons]);

  React.useEffect(() => {
    if (marathonEventsData && !marathonEventsLoading && !marathonEventsError) {
      const currentDay = new Date(
        new Date().getTime() - 8 * 24 * 60 * 60 * 1000
      ); // subtract 8 days from the current date
      const filteredMarathonEvents =
        marathonEventsData.marathon.marathonEvents.filter(
          (marathon) => new Date(marathon.startTime) >= currentDay
        );
      const slicedMarathonEvents = filteredMarathonEvents.slice(
        Math.max(filteredMarathonEvents.length - 5, 0)
      );

      setMarathonEventsToTransfer(slicedMarathonEvents);
    }
  }, [marathonEventsData, marathonEventsLoading, marathonEventsError]);

  const HandleAddNote = ({ id, values }) => {
    addNote({
      variables: {
        id: id,
        input: {
          note: {
            noteText: values,
          },
        },
      },
    });
  };
  const HandleAddRecurrentNote = ({ id, values }) => {
    addRecurrentNote({
      variables: {
        id: id,
        input: {
          note: {
            noteText: values,
          },
        },
      },
    });
  };
  const handleTransferNotUsedMarathon = ({
    id,
    marathonId,
    marathonEventId,
  }) => {
    transferNotUsedMarathon({
      variables: {
        userId: id,
        marathon: marathonId,
        marathonEventId: marathonEventId,
      },
    });
  };
  const handleGetMarathonEvents = ({ value }) => {
    getMarathonEvents({
      variables: {
        id: value,
      },
    });
  };

  const handleAddNewUser = ({
    user,
    newAbonement,
    payment,
    totalPrice,
    newUserPhoneNumber,
    newUserEmail,
    name,
    date,
    marathonEvent,
    selectUser,
    salesPerson,
    comment,
    cardAmount,
    cashAmount,
    creditAmount,
    companyBin,
    isReRegisterHeroPass,
    selectedUserReissue,
  }) => {
    try {
      const findUser = salesUsers.find(
        (salesUser) => salesUser?.id === salesPerson
      );
      addNewUser({
        variables: {
          orderInput: {
            customer: user?.id,
            salesman: salesPerson ? salesPerson : null,
            club: findUser?.club?.id || user?.club?.id,
            marathonEvent: marathonEvent,
            payment: typeAbonement === 'payments' ? newAbonement?.id : null,
            heroPass: typeAbonement === 'heropass' ? newAbonement?.id : null,
            paymentMethod: payment,
            type: 'salesman',
            name: isReRegisterHeroPass ? null : newAbonement?.name,
            paidAmount: parseFloat(totalPrice),
            activateTime: isReRegisterHeroPass ? null : date,
            comment: isReRegisterHeroPass ? null : comment,
            cardAmount: parseFloat(cardAmount),
            cashAmount: parseFloat(cashAmount),
            creditAmount: parseFloat(creditAmount),
            companyBin: payment === 'company' ? companyBin : null,
            isReRegisterHeroPass: isReRegisterHeroPass,
            toUser: isReRegisterHeroPass ? selectedUserReissue?.id : null,
            fromUser: isReRegisterHeroPass ? selectUser?.id : null,
          },
          userInput:
            oldUser || isReRegisterHeroPass
              ? null
              : {
                  phoneNumber: newUserPhoneNumber,
                  email: newUserEmail,
                  username: name,
                },
          userId: oldUser ? selectUser?.id : null,
          // club: findUser?.club?.id || user?.club?.id,
        },
      });
    } catch (errorNewUser) {
      toast.error('Что-то пошло не так');
    }
  };

  const handleReRegister = ({
    user,
    payment,
    totalPrice,
    selectUser,
    salesPerson,
    cardAmount,
    cashAmount,
    creditAmount,
  }) => {
    try {
      reRegister({
        variables: {
          changeInput: {
            userHeroPass: userCurrentHP.id,
            club: selectedGym.id,
            salesman: salesPerson,
            paymentMethod: payment,
            paidAmount: parseFloat(totalPrice),
            cashAmount: parseFloat(cashAmount),
            cardAmount: parseFloat(cardAmount),
            creditAmount: parseFloat(creditAmount),
            receptionist: salesPerson ? salesPerson : user?.id,
          },
          userId: selectUser?.id,
        },
      });
    } catch (errorNewUser) {
      toast.error('Что-то пошло не так');
    }
  };

  const handleRefundPayment = () => {
    refundPayment({
      variables: {
        userId: selectUser?.id,
        userHeroPassId: userCurrentHP?.id,
        imageFile: file,
        clubId: user?.club?.id,
        salesmanId: salesPerson || null,
        refundAmount: parseFloat(refundAmount),
        changeRefundAmount: changeRefundAmount
          ? parseFloat(changeRefundAmount)
          : null,
        comment: comment,
        refundLetterTime: refundLetterTime,
        paymentMethod: payment,
        paidAmount: changeRefundAmount
          ? parseFloat(changeRefundAmount)
          : parseFloat(refundAmount),
        cashAmount: parseFloat(cashAmount),
        cardAmount: parseFloat(cardAmount),
      },
    });
  };
  if (loadingUsers && !user) {
    return <Loading />;
  }

  if (errorUsers) {
    return <h1> error occured</h1>;
  }

  const activePayments = payments?.filter((el) => el?.isActive === true);

  const handleConfirmation = () => {
    if (reRegType === 'location') {
      handleReRegister({
        user,
        payment,
        totalPrice,
        selectUser,
        salesPerson,
        cardAmount,
        cashAmount,
        creditAmount,
      });
    } else {
      handleAddNewUser({
        user,
        newAbonement,
        payment,
        totalPrice,
        newUserPhoneNumber,
        newUserEmail,
        name,
        date,
        marathonEvent,
        selectUser,
        salesPerson,
        comment,
        cardAmount,
        cashAmount,
        creditAmount,
        companyBin,
        isReRegisterHeroPass,
        selectedUserReissue,
      });
    }
    // showLoading();
    setNewUserModal(false);
    setPaymentModal(false);
  };

  return (
    <>
      <Wrapper>
        <ClientsHeader
          setSearchPhoneNumber={setSearchPhoneNumber}
          setAbonement={setAbonement}
          abonement={abonement}
          setNewUserModal={setNewUserModal}
          clients={clients}
          setClients={setClients}
          setSearchClientsWithHeroPass={setSearchClientsWithHeroPass}
          setSearchRecurrentUsers={setSearchRecurrentUsers}
          setFilter={setFilter}
          heroPasses={heroPasses}
          setMonth={setMonth}
          setSearchGuestUsers={setSearchGuestUsers}
          setPayment={setPayment}
          setDate={setDate}
          setSalesPerson={setSalesPerson}
        />
        {clients === 0 && (
          <ClientsBody
            users={users}
            usersCnt={usersCnt}
            page={page}
            setPage={setPage}
            abonement={abonement}
            setSelectUser={setSelectUser}
            setClientInfoModal={setClientInfoModal}
            setNewUserModal={setNewUserModal}
            getUserActiveMarathon={getUserActiveMarathon}
            getUserMarathons={getUserMarathons}
            getUserPayments={getUserPayments}
            setSelectedRecurrentUser={setSelectedRecurrentUser}
          />
        )}
        {clients === 1 && (
          <ClientsUsersWithHeroPass
            usersWithHeroPass={usersWithHeroPass}
            totalUsersWithHeroPass={totalUsersWithHeroPass}
            thirdPage={thirdPage}
            setThirdPage={setThirdPage}
            setComment={setNote}
            comment={note}
            setIdUserMarathonEvent={setIdUserMarathonEvent}
            HandleAddNote={HandleAddNote}
            idUserMarathonEvent={idUserMarathonEvent}
            filter={filter}
          />
        )}
        {clients === 2 && (
          <ClientsRecurrents
            recurrentUsers={recurrentUsers}
            recurrentUsersTotal={recurrentUsersTotal}
            recurrentPage={recurrentPage}
            setRecurrentPage={setRecurrentPage}
            comment={comment}
            setComment={setComment}
            setIdUserRecurrent={setIdUserRecurrent}
            idUserRecurrent={idUserRecurrent}
            HandleAddRecurrentNote={HandleAddRecurrentNote}
            setSelectUser={setSelectUser}
            setClientInfoModal={setClientInfoModal}
            getUserActiveMarathon={getUserActiveMarathon}
            getUserMarathons={getUserMarathons}
            getUserPayments={getUserPayments}
            setSelectedRecurrentUser={setSelectedRecurrentUser}
          />
        )}
        {clients === 3 && (
          <GuestsClients
            guestUsers={guestUsers}
            guestUsersPage={guestUsersPage}
            setGuestUsersPage={setGuestUsersPage}
          />
        )}
        <ClientInfo
          selectUser={selectUser}
          setSelectUser={setSelectUser}
          activeMarathon={activeMarathon}
          userCurrentHP={userCurrentHP}
          dataUserCurrentHP={dataUserCurrentHP}
          setNewSubcriptionModal={setNewSubcriptionModal}
          clientInfoModal={clientInfoModal}
          setClientInfoModal={setClientInfoModal}
          setReRegSearch={setReRegSearch}
          reRegSearch={reRegSearch}
          reRegUsers={reRegUsers}
          selectedUserReissue={selectedUserReissue}
          setSelectedUserReissue={setSelectedUserReissue}
          setSearchReissueClient={setSearchReissueClient}
          SetSecondPage={SetSecondPage}
          secondPage={secondPage}
          salesUsers={salesUsers}
          setSalesPerson={setSalesPerson}
          salesPerson={salesPerson}
          setIsReRegisterHeroPass={setIsReRegisterHeroPass}
          setPaymentModal={setPaymentModal}
          payment={payment}
          setMixedPayment={setMixedPayment}
          mixedPayment={mixedPayment}
          cardAmount={cardAmount}
          cashAmount={cashAmount}
          creditAmount={creditAmount}
          setPayment={setPayment}
          setTotalPrice={setTotalPrice}
          totalPrice={totalPrice}
          setCashAmount={setCashAmount}
          setCardAmount={setCardAmount}
          setCreditAmount={setCreditAmount}
          setCompanyBin={setCompanyBin}
          companyBin={companyBin}
          activeMarathonTickets={activeMarathonTickets}
          setHistoryPrograms={setHistoryPrograms}
          marathons={marathons}
          setHistoryPayments={setHistoryPayments}
          userPayments={userPayments}
          loadingUserCurrentHP={loadingUserCurrentHP}
          refetchUserCurrentHP={refetchUserCurrentHP}
          recurrentUsers={recurrentUsers}
          selectedRecurrentUser={selectedRecurrentUser}
          setSelectedRecurrentUser={setSelectedRecurrentUser}
          handleTransferNotUsedMarathon={handleTransferNotUsedMarathon}
          setMarathonEvent={setMarathonEvent}
          handleGetMarathonEvents={handleGetMarathonEvents}
          marathonEventsToTransfer={marathonEventsToTransfer}
          setRefundSubModal={setRefundSubModal}
          refundSubModal={refundSubModal}
          dataUserPayments={dataUserPayments}
          setSelectedGym={setSelectedGym}
          selectedGym={selectedGym}
          reRegAmount={reRegAmount}
          setReRegAmount={setReRegAmount}
          clubs={clubs}
          reRegType={reRegType}
          setReRegType={setReRegType}
          refetch={refetch}
          // getReg={getReg}
        />
        <NewClient
          newUserModal={newUserModal}
          setNewUserModal={setNewUserModal}
          heroPasses={heroPasses}
          marathon={marathon}
          setNewAbonement={setNewAbonement}
          newAbonement={newAbonement}
          setPaymentModal={setPaymentModal}
          setPayment={setPayment}
          payment={payment}
          setName={setName}
          name={name}
          setTotalPrice={setTotalPrice}
          payments={activePayments}
          setTypeAbonement={setTypeAbonement}
          newUserPhoneNumber={newUserPhoneNumber}
          setNewUserPhoneNumber={setNewUserPhoneNumber}
          newUserEmail={newUserEmail}
          setNewUserEmail={setNewUserEmail}
          date={date}
          setDate={setDate}
          setMarathonEvent={setMarathonEvent}
          totalPrice={totalPrice}
          salesUsers={salesUsers}
          setSalesPerson={setSalesPerson}
          salesPerson={salesPerson}
          comment={comment}
          setComment={setComment}
          setMixedPayment={setMixedPayment}
          mixedPayment={mixedPayment}
          cashAmount={cashAmount}
          setCashAmount={setCashAmount}
          cardAmount={cardAmount}
          creditAmount={creditAmount}
          setCardAmount={setCardAmount}
          setCompanyBin={setCompanyBin}
          setCreditAmount={setCreditAmount}
          companyBin={companyBin}
          marathonSummerCamp={marathonSummerCamp}
          typeAbonement={typeAbonement}
          marathonEvent={marathonEvent}
        />
        {paymentModal && (
          <Confirmation
            paymentModal={paymentModal}
            setPaymentModal={setPaymentModal}
            payment={payment}
            setTotalPrice={setTotalPrice}
            totalPrice={totalPrice}
            name={name}
            handleAddNewUser={handleAddNewUser}
            handleReRegister={handleReRegister}
            newAbonement={newAbonement}
            newUserPhoneNumber={newUserPhoneNumber}
            newUserEmail={newUserEmail}
            date={date}
            marathonEvent={marathonEvent}
            setNewUserModal={setNewUserModal}
            showLoading={showLoading}
            setSelectUser={setSelectUser}
            selectUser={selectUser}
            salesPerson={salesPerson}
            comment={comment}
            cardAmount={cardAmount}
            cashAmount={cashAmount}
            creditAmount={creditAmount}
            companyBin={companyBin}
            isReRegisterHeroPass={isReRegisterHeroPass}
            selectedUserReissue={selectedUserReissue}
            reRegType={reRegType}
            handleConfirmation={handleConfirmation}
          />
        )}
        {newSubscriptionModal && (
          <NewSubscription
            newSubscriptionModal={newSubscriptionModal}
            setNewSubcriptionModal={setNewSubcriptionModal}
            selectUser={selectUser}
            setSelectUser={setSelectUser}
            activeMarathon={activeMarathon}
            userCurrentHP={userCurrentHP}
            dataUserCurrentHP={dataUserCurrentHP}
            setClientInfoModal={setClientInfoModal}
            typeAbonement={typeAbonement}
            setTypeAbonement={setTypeAbonement}
            setNewAbonement={setNewAbonement}
            newAbonement={newAbonement}
            marathon={marathon}
            heroPasses={heroPasses}
            payments={activePayments}
            setDate={setDate}
            setTotalPrice={setTotalPrice}
            payment={payment}
            setPayment={setPayment}
            setMarathonEvent={setMarathonEvent}
            setPaymentModal={setPaymentModal}
            setOldUser={setOldUser}
            setName={setName}
            userPayments={userPayments}
            salesUsers={salesUsers}
            setSalesPerson={setSalesPerson}
            salesPerson={salesPerson}
            comment={comment}
            setComment={setComment}
            setMixedPayment={setMixedPayment}
            mixedPayment={mixedPayment}
            cashAmount={cashAmount}
            setCashAmount={setCashAmount}
            cardAmount={cardAmount}
            setCardAmount={setCardAmount}
            creditAmount={creditAmount}
            setCreditAmount={setCreditAmount}
            totalPrice={totalPrice}
            setCompanyBin={setCompanyBin}
            companyBin={companyBin}
            marathonSummerCamp={marathonSummerCamp}
            marathonEvent={marathonEvent}
            date={date}
          />
        )}
        {historyPrograms && (
          <HistoryProgram
            selectUser={selectUser}
            historyPrograms={historyPrograms}
            setHistoryPrograms={setHistoryPrograms}
            marathons={marathons}
            setSelectUser={setSelectUser}
            setClientInfoModal={setClientInfoModal}
            setReRegSearch={setReRegSearch}
            refundLetterTime={refundLetterTime}
            setRefundLetterTime={setRefundLetterTime}
            refundAmount={refundAmount}
            setRefundAmount={setRefundAmount}
            changeRefundAmount={changeRefundAmount}
            setChangeRefundAmount={setChangeRefundAmount}
            file={file}
            setFile={setFile}
          />
        )}
        {historyPayments && (
          <ClientPayments
            selectUser={selectUser}
            userPayments={userPayments}
            historyPayments={historyPayments}
            setClientInfoModal={setClientInfoModal}
            setReRegSearch={setReRegSearch}
            setHistoryPayments={setHistoryPayments}
            setSelectUser={setSelectUser}
          />
        )}
        {refundSubModal && (
          <RefundSub
            selectUser={selectUser}
            salesUsers={salesUsers}
            setSalesPerson={setSalesPerson}
            userCurrentHP={userCurrentHP}
            refundSubModal={refundSubModal}
            setRefundSubModal={setRefundSubModal}
            setSelectUser={setSelectUser}
            setClientInfoModal={setClientInfoModal}
            refundLetterTime={refundLetterTime}
            setRefundLetterTime={setRefundLetterTime}
            refundAmount={refundAmount}
            setRefundAmount={setRefundAmount}
            changeRefundAmount={changeRefundAmount}
            setChangeRefundAmount={setChangeRefundAmount}
            file={file}
            setFile={setFile}
            setComment={setComment}
            comment={comment}
            setRefundConfirmationModel={setRefundConfirmationModel}
            refundAmountExplanation={refundAmountExplanation}
            payment={payment}
            setMixedPayment={setMixedPayment}
            mixedPayment={mixedPayment}
            cardAmount={cardAmount}
            cashAmount={cashAmount}
            setPayment={setPayment}
            setCashAmount={setCashAmount}
            setCardAmount={setCardAmount}
          />
        )}
        {refundConfirmationModel && (
          <ConfirmationRefund
            refundConfirmationModel={refundConfirmationModel}
            setRefundConfirmationModel={setRefundConfirmationModel}
            showLoading={showLoading}
            selectUser={selectUser}
            changeRefundAmount={changeRefundAmount}
            handleRefund={handleRefundPayment}
            refundAmount={refundAmount}
          />
        )}
      </Wrapper>
    </>
  );
};

export default withMainLayout(ClientsContainer);
