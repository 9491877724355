import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { gql, useMutation, useQuery } from "@apollo/client";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import ProgramSet from "./ProgramSet";
import Loading from "../shared/Loading";
import { useLoading } from "../../context/useLoading";
import withMainLayout from "../../hocs/withMainLayout";

const GET_DATA = gql`
  query getData($id: ID!) {
    programSet(id: $id) {
      id
      name
      shortDescription
      type
      transitionTimeRounds
      transitionTimeRoundsForStrength
      transitionTimeSets
      transitionTimeSetsForStrength
      timeRep
      timeGreen
      timeRed
      introDuration
      firstCycleStationCount
      secondTransitionTimeRounds
      secondTransitionTimeSets
      secondTimeRep
      secondTimeGreen
      secondTimeRed
      transitionTimeCycle
      strength
      ability
      endurance
      sexuality
      willPower
      biceps
      pectoralMuscles
      backMuscles
      lumbarMuscles
      quadriceps
      buttocks
      legBiceps
      caviar
      delta
      triceps
      press
      cardio
      imageURL
      isStrength
      club {
        id
        name
      }
      zone {
        id
        name
        stations {
          id
          icon
          name
          number
        }
      }
      levels {
        id
        degree
        name
      }
      stationExercises {
        id
        station {
          id
          number
          name
          icon
        }
        exercise {
          id
          name
          description
          videoIdentifier
        }
        exerciseDetails {
          id
          level {
            id
            degree
            name
          }
          reps
          sets
          maxWeight
          minWeight
        }
        exerciseTime
        relaxTime
        transitionTime
      }
      setDescriptions
      secondSetDescriptions
      bootcampRounds {
        id
        intervalCount
        intervalDuration
        isRest
        stationDetails {
          id
          station {
            id
            name
            isCardio
          }
          intervalExercises {
            id
            exercise {
              id
              name
            }
            isUnlimited
            beginnerSpeed
            intermediateSpeed
            athleteSpeed
            proSpeed
            reps
            isRest
          }
        }
      }
    }

    exercises {
      id
      name
      description
      videoURL
      videoIdentifier
    }

    clubs {
      id
      name
      zones {
        id
        name
      }
    }
    levels {
      id
      degree
      name
    }
  }
`;

const GET_PROGRAMSETS = gql`
  query getProgramSets {
    programSets {
      id
      name
      shortDescription
      type
      transitionTimeRounds
      transitionTimeRoundsForStrength
      transitionTimeSets
      transitionTimeSetsForStrength
      timeRep
      timeGreen
      timeRed
      introDuration
      firstCycleStationCount
      secondTransitionTimeRounds
      secondTransitionTimeSets
      secondTimeRep
      secondTimeGreen
      secondTimeRed
      transitionTimeCycle
      strength
      ability
      endurance
      sexuality
      willPower
      biceps
      pectoralMuscles
      backMuscles
      lumbarMuscles
      quadriceps
      buttocks
      legBiceps
      caviar
      delta
      triceps
      press
      cardio
      imageURL
      club {
        id
        name
      }
      zone {
        id
        name
      }
      levels {
        id
        degree
        name
      }
      bootcampRounds {
        id
        intervalCount
        intervalDuration
        isRest
        stationDetails {
          id
          station {
            id
            name
            isCardio
          }
          intervalExercises {
            id
            exercise {
              id
              name
            }
            isUnlimited
            beginnerSpeed
            intermediateSpeed
            athleteSpeed
            proSpeed
            reps
            isRest
          }
        }
      }
    }
    clubs {
      id
      name
      zones {
        id
        name
      }
    }
    levels {
      id
      degree
      name
    }
  }
`;

const UPDATE_PROGRAMSET = gql`
  mutation updateProgramSet($id: ID!, $input: ProgramSetInput) {
    updateProgramSet(id: $id, input: $input) {
      id
      name
      shortDescription
      type
      transitionTimeRounds
      transitionTimeRoundsForStrength
      transitionTimeSets
      transitionTimeSetsForStrength
      timeRep
      timeGreen
      timeRed
      introDuration
      firstCycleStationCount
      secondTransitionTimeRounds
      secondTransitionTimeSets
      secondTimeRep
      secondTimeGreen
      secondTimeRed
      transitionTimeCycle
      strength
      ability
      endurance
      sexuality
      willPower
      biceps
      pectoralMuscles
      backMuscles
      lumbarMuscles
      quadriceps
      buttocks
      legBiceps
      caviar
      delta
      triceps
      press
      cardio
      imageURL
      club {
        id
      }
      zone {
        id
      }
      levels {
        id
        degree
        name
      }
      bootcampRounds {
        id
        intervalCount
        intervalDuration
        isRest
        stationDetails {
          id
          station {
            id
            name
            isCardio
          }
          intervalExercises {
            id
            exercise {
              id
              name
            }
            isUnlimited
            beginnerSpeed
            intermediateSpeed
            athleteSpeed
            proSpeed
            reps
            isRest
          }
        }
      }
    }
  }
`;

const UPDATE_STATION_EXERCISE = gql`
  mutation updateStationExercise($id: ID!, $input: StationExerciseInput) {
    updateStationExercise(id: $id, input: $input) {
      id
      station {
        id
      }
      exercise {
        id
      }
      exerciseDetails {
        id
        reps
        sets
        maxWeight
        minWeight
      }
      exerciseTime
      relaxTime
      transitionTime
    }
  }
`;

const ADD_STATION_EXERCISE = gql`
  mutation addStationExercise($input: StationExerciseInput) {
    addStationExercise(input: $input) {
      id
      station {
        id
      }
      exercise {
        id
      }
      exerciseDetails {
        id
        reps
        sets
        maxWeight
        minWeight
      }
      exerciseTime
      relaxTime
      transitionTime
    }
  }
`;

const GET_STATION_EXERCISE = gql`
  query getData($id: ID!) {
    programSet(id: $id) {
      id
      levels {
        id
        degree
        name
      }
      stationExercises {
        id
        station {
          id
        }
        exercise {
          id
          name
        }
        exerciseDetails {
          id
          reps
          sets
          maxWeight
          minWeight
        }
        exerciseTime
        relaxTime
        transitionTime
      }
    }
  }
`;

const DELETE_STATION_EXERCISE = gql`
  mutation deleteStationExercise($id: ID!) {
    deleteStationExercise(id: $id)
  }
`;

const DELETE_PROGRAMSET = gql`
  mutation deleteProgramSet($id: ID!) {
    deleteProgramSet(id: $id)
  }
`;

const ProgramSetContainer = (props) => {
  const { id: programSetId } = useParams();
  const navigate = useNavigate();
  const [programSetData, setProgramSetData] = useState(null);
  const [levelsData, setLevelsData] = useState(null);
  const [exercisesData, setExercisesData] = useState(null);
  const [clubsData, setClubsData] = useState(null);
  const { data, loading, error, refetch } = useQuery(GET_DATA, {
    variables: { id: programSetId }
  });

  const { showLoading, hideLoading } = useLoading();

  const [deleteProgramSet] = useMutation(DELETE_PROGRAMSET, {
    onCompleted: () => {
      hideLoading();
      toast.success("Программа успешно удалена");
      navigate("/programSets");
    }
  });

  const [updateProgramSet] = useMutation(UPDATE_PROGRAMSET);

  const [updateStationExercise] = useMutation(UPDATE_STATION_EXERCISE, {
    update(cache, { data: { updateStationExercise: stationExercise } }) {
      let { programSet } = cache.readQuery({
        query: GET_STATION_EXERCISE,
        variables: { id: programSetId }
      });
      const index = programSet?.stationExercises?.find(
        (oneStationExercise) => oneStationExercise.id === stationExercise.id
      );
      const newStationExercises = programSet.stationExercises.slice();
      if (index !== null && index !== -1) {
        newStationExercises.splice(index, 1, stationExercise);
      }

      programSet = {
        id: programSet.id,
        stationExercises: newStationExercises
      };

      cache.writeQuery({
        query: GET_STATION_EXERCISE,
        variables: { id: programSetId },
        data: { programSet }
      });
      toast.success("Программа успешно обновлена");
      hideLoading();
    }
  });

  const [addStationExercise] = useMutation(ADD_STATION_EXERCISE, {
    update(cache, { data: { addStationExercise: stationExercise } }) {
      let { programSet } = cache.readQuery({
        query: GET_STATION_EXERCISE,
        variables: { id: programSetId }
      });
      programSet = {
        id: programSet.id,
        stationExercises: programSet.stationExercises
          ? programSet.stationExercises.concat([stationExercise])
          : [stationExercise]
      };

      const stationExercises =
        programSet && programSet.stationExercises
          ? programSet.stationExercises
              .filter((elem) => elem !== null)
              .map((dataStationExercise) => dataStationExercise.id)
          : [];

      updateProgramSet({
        variables: { id: programSetId, input: { stationExercises } }
      });
      cache.writeQuery({
        query: GET_STATION_EXERCISE,
        variables: { id: programSetId },
        data: { programSet }
      });
      toast.success("Упражнение успешно добавлено на станцию");
      hideLoading();
    }
  });

  // const [deleteStationExercise] = useMutation(DELETE_STATION_EXERCISE, {
  //   update(cache, { data: { deleteStationExercise: id } }) {
  //     let { programSet } = cache.readQuery({
  //       query: GET_STATION_EXERCISE,
  //       variables: { id: programSetId }
  //     });
  //     programSet = {
  //       id: programSet.id,
  //       stationExercises: programSet.stationExercises.filter(
  //         (stationExerciseData) => stationExerciseData.id !== id
  //       )
  //     };

  //     const stationExercises =
  //       programSet && programSet.stationExercises
  //         ? programSet.stationExercises.map(
  //             (stationExerciseData) => stationExerciseData.id
  //           )
  //         : [];

  //     updateProgramSet({
  //       variables: { id: programSetId, input: { stationExercises } }
  //     });
  //     cache.writeQuery({
  //       query: GET_STATION_EXERCISE,
  //       variables: { id: programSetId },
  //       data: { programSet }
  //     });
  //     hideLoading();
  //     toast.success("Упражнение успешно удалено со станции");
  //   }
  // });

  const handleUpdateStationExercise = (values) => {
    console.log("values", values);
    const stationExerciseId = values?.id;
    const exercise = values?.currentExercise;
    const exerciseDetails = values?.exerciseDetails.map((exerciseDetail) => {
      const { maxWeight, minWeight, levelId, reps, sets } = exerciseDetail;
      return {
        level: levelId,
        maxWeight: parseInt(maxWeight, 10),
        minWeight: parseInt(minWeight, 10),
        reps: parseInt(reps, 10),
        sets: parseInt(sets, 10)
      };
    });

    showLoading();
    updateStationExercise({
      variables: {
        id: stationExerciseId,
        input: {
          exercise,
          exerciseDetails
        }
      }
    });
  };

  const handleAddStationExercise = (values) => {
    const exercise = values?.currentExercise;
    const station = values?.station?.id;
    const exerciseDetails = values?.exerciseDetails.map((exerciseDetail) => {
      const { maxWeight, minWeight, levelId, reps, sets } = exerciseDetail;
      return {
        level: levelId,
        maxWeight: parseInt(maxWeight, 10),
        minWeight: parseInt(minWeight, 10),
        reps: parseInt(reps, 10),
        sets: parseInt(sets, 10)
      };
    });
    const exerciseTime = parseInt(values.exerciseTime, 10);
    const relaxTime = parseInt(values.relaxTime, 10);
    const transitionTime = parseInt(values.transitionTime, 10);

    showLoading();
    addStationExercise({
      variables: {
        input: {
          exercise,
          station,
          exerciseTime,
          relaxTime,
          transitionTime,
          exerciseDetails
        }
      }
    });
  };

  const handleDeleteStationExercise = (stationExerciseId) => {
    showLoading();

    const stationExercises =
      programSetData && programSetData.stationExercises
        ? programSetData.stationExercises.filter(
            (stationExerciseData) =>
              stationExerciseData.id !== stationExerciseId
          )
        : [];

    updateProgramSet({
      variables: {
        id: programSetId,
        input: {
          stationExercises: stationExercises.map(
            (stationExercise) => stationExercise.id
          )
        }
      }
    });
    hideLoading();
    refetch();
    toast.success("Упражнение успешно удалено со станции");
  };

  const updateStationExercisesOrder = (descriptiveStations) => {
    showLoading();
    const stationIds = descriptiveStations
      .filter((station) => station.isPresent)
      .map((station) => station.stationExercise.id);
    updateProgramSet({
      variables: { id: programSetId, input: { stationExercises: stationIds } }
    });
    toast.success("Упражнение успешно  обновлена");
    hideLoading();
  };

  useEffect(() => {
    if (data && data.programSet && data.clubs) {
      setProgramSetData(data.programSet);
      setLevelsData(data.levels);
      setExercisesData(data.exercises);
      setClubsData(data.clubs);
    }
  }, [data, loading, error]);

  if (loading)
    return (
      <div>
        <Loading />
      </div>
    );

  const handleDeleteProgramSet = () => {
    deleteProgramSet({ variables: { id: programSetId } });
  };

  const handleUpdateClickForTime = (values) => {
    let dataProgramSet = null;
    console.log("values in handleUpdateClick", values);
    dataProgramSet = {
      transitionTimeSets: parseInt(values.transitionTimeSets, 10),
      timeRep: parseInt(values.timeGreen, 10) + parseInt(values.timeRed, 10),
      timeGreen: parseInt(values.timeGreen, 10),
      timeRed: parseInt(values.timeRed, 10),
      introDuration: parseInt(values.introDuration, 10),
      secondTransitionTimeRounds: parseInt(
        values.secondTransitionTimeRounds,
        10
      ),
      secondTransitionTimeSets: parseInt(values.secondTransitionTimeSets, 10),
      secondTimeRep:
        parseInt(values.secondTimeGreen, 10) +
        parseInt(values.secondTimeRed, 10),
      secondTimeGreen: parseInt(values.secondTimeGreen, 10),
      secondTimeRed: parseInt(values.secondTimeRed, 10),
      firstCycleStationCount: parseInt(values.firstCycleStationCount, 10),
      transitionTimeCycle: parseInt(values.transitionTimeCycle, 10)
    };
    if (values.transitionTimeRounds) {
      dataProgramSet = {
        transitionTimeRounds: parseInt(values.transitionTimeRounds, 10),
        ...dataProgramSet
      };
    }
    if (values.transitionTimeRoundsForStrength) {
      const transitionTimeRoundsForStrength =
        values.transitionTimeRoundsForStrength
          .split(" ")
          .filter((str) => str)
          .map((str) => parseInt(str, 10));
      dataProgramSet = {
        ...dataProgramSet,
        transitionTimeRoundsForStrength: transitionTimeRoundsForStrength
      };
    }
    if (values.transitionTimeSetsForStrength) {
      const transitionTimeSetsForStrength = values.transitionTimeSetsForStrength
        .split(" ")
        .filter((str) => str)
        .map((str) => parseInt(str, 10));
      dataProgramSet = {
        ...dataProgramSet,
        transitionTimeSetsForStrength: transitionTimeSetsForStrength
      };
    }
    showLoading();
    updateProgramSet({
      variables: {
        id: programSetId,
        input: dataProgramSet
      }
    });
    toast.success("программсет успешно  обновлена");
    hideLoading();
  };

  const handleUpdateClickForGeneral = (values) => {
    let dataProgramSet = null;
    if (values.setDescriptions) {
      const setDescriptions = values.setDescriptions
        .split(" ")
        .filter((str) => str)
        .map((str) => parseInt(str, 10));
      console.log("set descriptions 1st", setDescriptions);
      dataProgramSet = {
        ...values,
        setDescriptions
      };
    }
    if (values.secondSetDescriptions) {
      const secondSetDescriptions = values.secondSetDescriptions
        .split(" ")
        .filter((str) => str)
        .map((str) => parseInt(str, 10));
      console.log("set descriptions 2nd", secondSetDescriptions);
      dataProgramSet = { ...dataProgramSet, secondSetDescriptions };
    }

    dataProgramSet = {
      ...dataProgramSet,
      strength: parseInt(values.strength, 10),
      ability: parseInt(values.ability, 10),
      endurance: parseInt(values.endurance, 10),
      sexuality: parseInt(values.sexuality, 10),
      willPower: parseInt(values.willPower, 10),
      biceps: parseInt(values.biceps, 10),
      pectoralMuscles: parseInt(values.pectoralMuscles, 10),
      backMuscles: parseInt(values.backMuscles, 10),
      lumbarMuscles: parseInt(values.lumbarMuscles, 10),
      quadriceps: parseInt(values.quadriceps, 10),
      buttocks: parseInt(values.buttocks, 10),
      legBiceps: parseInt(values.legBiceps, 10),
      caviar: parseInt(values.caviar, 10),
      delta: parseInt(values.delta, 10),
      triceps: parseInt(values.triceps, 10),
      press: parseInt(values.press, 10),
      cardio: parseInt(values.cardio, 10),
      bootcampRounds: values.bootcampRounds
    };

    showLoading();
    updateProgramSet({
      variables: {
        id: programSetId,
        input: dataProgramSet
      }
    });
    toast.success("Программсет успешно  обновлена");
    hideLoading();
  };

  return (
    <>
      {programSetData && clubsData && levelsData && (
        <ProgramSet
          programSet={programSetData}
          clubs={clubsData}
          levels={levelsData}
          exercises={exercisesData}
          updateClickForTime={handleUpdateClickForTime}
          updateClickForGeneral={handleUpdateClickForGeneral}
          deleteClick={handleDeleteProgramSet}
          handleUpdateStationExercise={handleUpdateStationExercise}
          handleAddStationExercise={handleAddStationExercise}
          handleDeleteStationExercise={handleDeleteStationExercise}
          updateStationExercisesOrder={updateStationExercisesOrder}
        />
      )}
    </>
  );
};

ProgramSetContainer.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired
    })
  }).isRequired
};

export default withMainLayout(ProgramSetContainer);
