import React from 'react';
import PropTypes from 'prop-types';
import { gql, useLazyQuery, useMutation } from '@apollo/client';

import { Table, Button, Popconfirm, Tag } from 'antd';
import styled from 'styled-components';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import 'dayjs/locale/ru';
import { toast } from 'react-toastify';

import { useLoading } from '../../context/useLoading';

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ColorfulText = styled.div`
  color: ${(p) => p.color};
`;

const GET_DATA = gql`
  query getData($userId: ID!, $query: PaginationQuery) {
    bookingsByUser(userId: $userId, query: $query) {
      bookings {
        id
        status
        userReview {
          value
          comment
        }
        userPoint {
          id
          changeValue
          type
        }
        event {
          id
          startTime
          endTime
          programSet {
            id
            name
          }
        }
        dumbbells
      }
      total
      limit
      page
      pages
    }
  }
`;

const CHECK_IN_USER = gql`
  mutation checkIn($input: CheckInInput) {
    checkIn(input: $input) {
      status
      message
    }
  }
`;

const REMOVE_CHECK_IN = gql`
  mutation removeCheckIn($input: CheckInInput) {
    removeCheckIn(input: $input) {
      status
      message
    }
  }
`;

const UserBookings = ({ userId }) => {
  const { showLoading, hideLoading } = useLoading();
  const [userBookings, setUserBookings] = React.useState([]);
  const [total, setTotal] = React.useState(0);
  const [page, setPage] = React.useState(1);
  dayjs.locale('ru');

  const [getData, { data, error, loading, refetch }] = useLazyQuery(GET_DATA, {
    fetchPolicy: 'no-cache',
  });

  const [checkInUser] = useMutation(CHECK_IN_USER, {
    onError(err) {
      hideLoading();
      toast.error(`Error ${err.message}`);
    },
    onCompleted() {
      toast.success(`Посещение успешно засчитано`);
      hideLoading();
      refetch();
    },
  });

  const [removeCheckIn] = useMutation(REMOVE_CHECK_IN, {
    onError(err) {
      hideLoading();
      toast.error(`Error ${err.message}`);
    },
    onCompleted() {
      toast.success(`Посещение успешно убрано`);
      hideLoading();
      refetch();
    },
  });

  React.useEffect(() => {
    getData({
      variables: {
        userId: userId,
        query: {
          page: page,
          limit: 10,
        },
      },
    });
  }, []);

  React.useEffect(() => {
    refetch({
      userId: userId,
      query: {
        page: page,
        limit: 10,
      },
    });
  }, [page]);

  React.useEffect(() => {
    if (data && data.bookingsByUser.bookings && !loading) {
      setUserBookings(data.bookingsByUser.bookings);
      setTotal(data.bookingsByUser.total);
    } else if (error) {
      console.log(`error `, error);
    }
  }, [data, loading, error]);

  const renderStatus = (item) => {
    const { status } = item;
    switch (status) {
      case 'missed':
        return <Tag color="red">ПРОПУСК</Tag>;
      case 'canceled':
        return <Tag color="gold">ОТМЕНЕН({item.dumbbells})</Tag>;
      case 'upcoming':
        return <Tag color="purple">ПРЕДСТОЯЩЕЕ</Tag>;
      default:
        return <Tag color="green">ПОСЕТИЛ</Tag>;
    }
  };

  const renderChangeValue = (changeValue) => {
    if (changeValue > 0) {
      return <ColorfulText color="#00c000"> +{changeValue}</ColorfulText>;
    } else if (changeValue < 0) {
      return <ColorfulText color="#FF4D4F"> {changeValue}</ColorfulText>;
    }
    return <ColorfulText color="#000000"> {changeValue}</ColorfulText>;
  };

  const bookingColumns = [
    {
      title: 'Дата и время события',
      width: '40%',
      render: (text, item) => {
        const { event } = item;
        const eventDate = dayjs(event?.startTime).format('Do MMMM, YYYY');
        const eventStartTime = dayjs(event?.startTime).format('HH:mm');
        const eventEndTime = dayjs(event?.endTime).format('HH:mm');
        const eventDateString = `${eventDate} | ${eventStartTime}-${eventEndTime}`;
        return (
          <span>
            <Link to={`/events/${event?.id}`}>{eventDateString}</Link>{' '}
          </span>
        );
      },
    },
    {
      title: 'Программа',
      render: (text, item) => {
        return item?.event?.programSet?.name;
      },

      width: '20%',
    },
    {
      title: 'Оценка',
      width: '10%',
      dataIndex: 'value',
      render: (text, item) => {
        return (
          <span>
            {item.userReview?.value && (
              <>
                {item.userReview?.value}{' '}
                <span role="img" aria-label="star">
                  ⭐
                </span>
              </>
            )}
          </span>
        );
      },
    },
    {
      title: 'Комментарий',
      dataIndex: 'comment',
      width: '20%',
      render: (text, item) => {
        return <span>{item.userReview?.comment}</span>;
      },
    },
    {
      title: 'Очки',
      width: '15%',
      dataIndex: 'changeValue',
      render: (text, item) => renderChangeValue(item?.userPoint?.changeValue),
    },
    {
      title: 'Статус',
      dataIndex: 'status',
      render: (text, item) => renderStatus(item),
    },
    {
      title: 'Действие',
      kay: 'action',
      render: (item) => {
        console.log('item', item);
        if (item?.status === 'attended') {
          return (
            <span>
              <Popconfirm
                title="Убрать чекин ?"
                cancelText="Отмена"
                onConfirm={() => {
                  showLoading();
                  removeCheckIn({
                    variables: {
                      input: {
                        userId,
                        eventId: item?.event?.id,
                      },
                    },
                  });
                }}
              >
                <Button type="link" danger>
                  Убрать чекин
                </Button>
              </Popconfirm>
            </span>
          );
        }
        return (
          <span>
            <Popconfirm
              title="Сделать чекин ?"
              cancelText="Отмена"
              onConfirm={() => {
                showLoading();
                setTimeout(() => {
                  hideLoading();
                }, 3000);
                checkInUser({
                  variables: {
                    input: {
                      userId,
                      eventId: item?.event?.id,
                    },
                  },
                });
              }}
            >
              <Button
                type="link"
                onClick={() => {
                  console.log('asd');
                }}
              >
                Сделать чекин
              </Button>
            </Popconfirm>
          </span>
        );
      },
    },
  ];

  return (
    <MainContainer>
      <h2> Посещения </h2>
      <Table
        dataSource={userBookings}
        columns={bookingColumns}
        rowKey={(item) => item.id}
        loading={loading}
        pagination={{
          defaultPageSize: 10,
          total: total,
          current: page,
          onChange: (page, pageSize) => {
            setPage(page);
          },
          showSizeChanger: false,
        }}
      />
    </MainContainer>
  );
};

UserBookings.propTypes = {
  userBookings: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      programName: PropTypes.string,
      userReview: PropTypes.shape({
        value: PropTypes.number,
        comment: PropTypes.string,
      }),
      event: PropTypes.shape({
        startTime: PropTypes.string,
        endTime: PropTypes.string,
      }),
    })
  ).isRequired,
};

export default UserBookings;
