import React from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';
import { Table } from 'antd';

import Item from 'antd/lib/list/Item';

const ConversionClientsBody = ({
  clients,
  filteredUsers,
  setModalComment,
  setIdUserMarathonEvent,
}) => {
  const columns = [
    {
      title: () => {
        return (
          <div
            style={{
              fontSize: 14,
              fontFamily: 'GraphikLCGMedium',
              color: 'gray',
            }}
          >
            Имя клиента
          </div>
        );
      },
      dataIndex: ['user', 'nickname'],
      width: '15%',
    },
    {
      title: () => {
        return (
          <div
            style={{
              fontSize: 14,
              fontFamily: 'GraphikLCGMedium',
              color: 'gray',
            }}
          >
            Номер
          </div>
        );
      },
      dataIndex: ['user', 'phoneNumber'],
      width: '15%',
    },
    {
      title: () => {
        return (
          <div
            style={{
              fontSize: 14,
              fontFamily: 'GraphikLCGMedium',
              color: 'gray',
            }}
          >
            Визитов
          </div>
        );
      },
      dataIndex: ['marathonVisits'],
      width: '15%',
    },
    {
      title: () => {
        return (
          <div
            style={{
              fontSize: 14,
              fontFamily: 'GraphikLCGMedium',
              color: 'gray',
            }}
          >
            Пропусков
          </div>
        );
      },
      dataIndex: ['marathonMissed'],
      width: '15%',
    },
    {
      title: () => {
        return (
          <div
            style={{
              fontSize: 14,
              fontFamily: 'GraphikLCGMedium',
              color: 'gray',
            }}
          >
            Абонемент
          </div>
        );
      },
      dataIndex: ['userHeroPass', 'heroPass', 'name'],
      width: '15%',
      render: (text) => {
        if (text === null) {
          return <>Нет</>;
        }
        return <>{text}</>;
      },
    },
    {
      title: () => {
        return (
          <div
            style={{
              fontSize: 14,
              fontFamily: 'GraphikLCGMedium',
              color: 'gray',
            }}
          >
            Заметка
          </div>
        );
      },
      dataIndex: ['note', 'noteText', 'createdAt', 'id'],
      width: '25%',
      render: (text, item) => {
        if (!item?.note?.noteText) {
          return (
            <button
              style={{
                color: 'gray',
                border: 0,
                backgroundColor: 'transparent',
                cursor: 'pointer',
              }}
              onClick={() => {
                {
                  setModalComment(true);
                  setIdUserMarathonEvent(item?.id);
                }
              }}
            >
              Добавить
            </button>
          );
        }
        return (
          <button
            style={{
              color: 'black',
              border: 0,
              backgroundColor: 'transparent',
              cursor: 'pointer',
              display: 'flex',
            }}
            onClick={() => {
              {
                setModalComment(true);
                setIdUserMarathonEvent(item?.id);
              }
            }}
          >
            <>{item?.note?.noteText}</>
            <div style={{ color: 'gray', paddingLeft: 5 }}>
              {dayjs(item?.note?.createdAt).format('DD.MM.YY')}
            </div>
          </button>
        );
      },
    },
  ];

  return (
    <Table
      dataSource={filteredUsers()}
      rowKey={(obj) => obj?.id}
      columns={columns}
      pagination={false}
      // onRow={(obj) => {
      //   return {
      //     onClick: (itm) => {
      //       onCardClick(obj?.el?.number, obj?.el.user, obj?.el.endTime, obj.sex)
      //     }
      //   }
      // }}
    />
  );
};

export default ConversionClientsBody;
