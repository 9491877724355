import React, { useState, useEffect } from 'react';
import { gql, useMutation, useQuery, useLazyQuery } from '@apollo/client';
import { toast } from 'react-toastify';

import PromptTrainings from './PromptTrainings';
import Loading from '../shared/Loading';
import { useLoading } from '../../context/useLoading';
import withMainLayout from '../../hocs/withMainLayout';

const GET_TRAINING_DESCRIPTIONS = gql`
  query trainingDescriptions {
    trainingDescriptions {
      id
      programSetType
      description
    }
  }
`;

const MODIFY_TRAINING_DESCRIPTIONS = gql`
  mutation modifyTrainingDescription(
    $programSetType: String
    $description: String
  ) {
    modifyTrainingDescription(
      programSetType: $programSetType
      description: $description
    ) {
      status
      message
    }
  }
`;

const PromptTrainingsContainer = () => {
  const [promptTrainings, setPromptTrainings] = useState([]);
  const { showLoading, hideLoading } = useLoading();

  const { data, loading, error, refetch } = useQuery(GET_TRAINING_DESCRIPTIONS);

  const [modifyTrainingDescription] = useMutation(
    MODIFY_TRAINING_DESCRIPTIONS,
    {
      onCompleted() {
        refetch();
        hideLoading();
        toast.success('Данные о тренера успешно отредактирован');
      },
    }
  );

  useEffect(() => {
    if (data && !loading && !error) {
      setPromptTrainings(data?.trainingDescriptions);
    }
  }, [data, loading, error]);

  const handleModifyTrainingDescription = (programSetType, description) => {
    showLoading();
    modifyTrainingDescription({
      variables: { programSetType, description },
    });
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <PromptTrainings
      promptTrainings={promptTrainings}
      handleModifyTrainingDescription={handleModifyTrainingDescription}
    />
  );
};

export default withMainLayout(PromptTrainingsContainer);
