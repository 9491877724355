import React, { useState, useEffect } from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import { toast } from 'react-toastify';

import Payments from './Payments';
import Loading from '../shared/Loading';
import { useLoading } from '../../context/useLoading';
import withMainLayout from '../../hocs/withMainLayout';

const GET_PAYMENT = gql`
  query getPayment {
    payments {
      id
      name
      cost
      discount
      countClasses
      ticketType
      isActive
      isDemo
    }
  }
`;

const DELETE_PAYMENT = gql`
  mutation deletePayment($id: ID!) {
    deletePayment(id: $id)
  }
`;

const ADD_PAYMENT = gql`
  mutation addPayment($input: PaymentInput) {
    addPayment(input: $input) {
      id
      cost
      name
      discount
      countClasses
      ticketType
      isActive
      isDemo
    }
  }
`;

const UPDATE_PAYMENT = gql`
  mutation updatePayment($id: ID!, $input: PaymentInput) {
    updatePayment(id: $id, input: $input) {
      id
      cost
      name
      discount
      countClasses
      ticketType
      isActive
      isDemo
    }
  }
`;

const PaymentsContainer = () => {
  const [dataPayments, setDataPayments] = useState(null);
  const { showLoading, hideLoading } = useLoading();

  const [updatePayment] = useMutation(UPDATE_PAYMENT);

  const { data, loading, error, refetch } = useQuery(GET_PAYMENT);

  const [deletePayment] = useMutation(DELETE_PAYMENT, {
    update(cache, { data: { deletePayment: id } }) {
      const { payments } = cache.readQuery({ query: GET_PAYMENT });
      cache.writeQuery({
        query: GET_PAYMENT,
        data: {
          payments: payments.filter(payment => payment.id !== id)
        }
      });
      hideLoading();
      toast.success('Цена успешно удалена');
    },
    onCompleted: () => {
      refetch();
    }
  });

  const [addPayment] = useMutation(ADD_PAYMENT, {
    update(cache, { data: { addPayment: payment } }) {
      const { payments } = cache.readQuery({ query: GET_PAYMENT });
      cache.writeQuery({
        query: GET_PAYMENT,
        data: { payments: payments.concat([payment]) }
      });
      hideLoading();
      toast.success('Цена успешно создана');
    }
  });

  useEffect(() => {
    if (data && !loading && !error) {
      setDataPayments(data.payments);
    }
  }, [data, loading, error]);

  const handleDeletePayment = paymentId => {
    showLoading();
    deletePayment({ variables: { id: paymentId } });
  };

  const handleAddPayment = values => {
    console.log('values in add payment', values);
    showLoading();
    const dataPayment = {
      name: values.name,
      cost: parseInt(values.cost, 10),
      discount: parseInt(values.discount, 10),
      countClasses: parseInt(values.countClasses, 10),
      ticketType: values.ticketType,
      isActive: values.isActive,
      isDemo: values.isDemo
    };
    addPayment({
      variables: {
        input: dataPayment
      }
    });
  };

  const handleUpdatePayment = (id, values) => {
    const dataPayment = {
      name: values.name,
      cost: parseInt(values.cost, 10),
      discount: parseInt(values.discount, 10),
      countClasses: parseInt(values.countClasses, 10),
      ticketType: values.ticketType,
      isActive: values.isActive,
      isDemo: values.isDemo
    };

    updatePayment({ variables: { id, input: dataPayment } });
  };

  if (loading && !dataPayments) {
    return <Loading />;
  }

  return (
    <Payments
      payments={dataPayments}
      deletePaymentClick={handleDeletePayment}
      addPaymentClick={handleAddPayment}
      updatePaymentClick={handleUpdatePayment}
    />
  );
};

export default withMainLayout(PaymentsContainer);
