import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  Table,
  Button,
  Divider,
  Modal,
  Form,
  Input,
  Select,
  Avatar,
  DatePicker,
} from 'antd';
import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import StyledTooltip from '../shared/StyledTooltip';
import EditProduct from './EditProduct';

const FormItem = Form.Item;
const { TextArea } = Input;

const ImageUploadContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const StyledAvatar = styled(Avatar)`
  margin-left: 5px;
`;

const StyledHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledInput = styled(Input)`
  width: 256px;
`;

const StyledDatePicker = styled(DatePicker)`
  width: 100%;
`;

const Products = ({
  dataProducts,
  deleteProductClick,
  addProductClick,
  updateProductClick,
}) => {
  const [form] = Form.useForm();
  const [display, setDisplay] = useState('default');
  const [editProduct, setEditProduct] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [products, setProducts] = useState([]);
  const [searchInput, setSearchInput] = useState('');

  React.useEffect(() => {
    let descriptiveProducts = dataProducts.map((product) => {
      return {
        ...product,
      };
    });
    if (searchInput) {
      descriptiveProducts = descriptiveProducts.filter((product) => {
        return product.name?.toLowerCase().includes(searchInput);
      });
    }
    setProducts(descriptiveProducts);
  }, [dataProducts, searchInput]);

  const columns = [
    {
      title: 'Название',
      dataIndex: 'name',
      width: '30%',
    },
    {
      title: 'Действие',
      key: 'action',
      width: '30%',
      render: (text, item) => (
        <span>
          <Button
            onClick={() => {
              setDisplay('edit');
              setEditProduct(item);
            }}
          >
            Редактировать
          </Button>
        </span>
      ),
    },
  ];

  const handleCreate = (values) => {
    addProductClick(values);
    setModalVisible(false);
  };

  const getTooltip = (text, fullText) => {
    return <StyledTooltip {...{ text, fullText }} />;
  };

  return (
    <>
      <Table
        dataSource={products}
        columns={columns}
        rowKey={(item) => item.id}
        title={() => (
          <StyledHeaderContainer>
            <StyledInput
              prefix={<SearchOutlined />}
              placeholder="Поиск"
              onChange={(e) => {
                setSearchInput(e.target.value.toLowerCase());
              }}
            />
            <Button type="primary" onClick={() => setModalVisible(true)}>
              <PlusOutlined /> Новый товар
            </Button>
          </StyledHeaderContainer>
        )}
      />
      <Modal
        open={modalVisible}
        title="Новый товар"
        okText="Создать"
        cancelText="Отмена"
        onCancel={() => {
          setModalVisible(false);
        }}
        onOk={() => {
          form
            .validateFields()
            .then((values) => {
              form.resetFields();
              handleCreate({ ...values });
            })
            .catch((info) => {
              // eslint-disable-next-line no-console
              console.log('Validate Failed:', info);
            });
        }}
      >
        <Form form={form} layout="vertical">
          <FormItem
            key="name"
            label={getTooltip('Название товара', 'Название товара')}
            name="name"
            rules={[
              {
                required: true,
                message: `Пожалуйста, напишите название товара`,
              },
            ]}
          >
            <Input placeholder="Название" />
          </FormItem>
        </Form>
      </Modal>
      {display === 'edit' && editProduct && (
        <EditProduct
          visible={editProduct}
          onCancel={() => {
            setEditProduct(null);
            setModalVisible(false);
          }}
          title="Редактировать"
          editProduct={editProduct}
          onUpdate={updateProductClick}
          onDelete={deleteProductClick}
        />
      )}
    </>
  );
};
Products.propTypes = {
  dataProducts: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  deleteProductClick: PropTypes.func.isRequired,
  addProductClick: PropTypes.func.isRequired,
  updateProductClick: PropTypes.func.isRequired,
};

export default Products;
