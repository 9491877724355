import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Table, Button, Divider, Input } from 'antd';
import { SearchOutlined, PlusOutlined } from '@ant-design/icons';

import CreateForm from '../shared/CreateForm';
import showConfirm from '../shared/DeleteConfirm';
import EditForm from '../shared/EditFormModal';

const StyledHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledInput = styled(Input)`
  width: 256px;
`;

const Exercises = ({
  exercises,
  deleteExerciseClick,
  addExerciseClick,
  updateExerciseClick
}) => {
  const [display, setDisplay] = useState('default');
  const [editExercise, setEditExercise] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);

  const [data, setData] = useState([]);
  const [searchInput, setSearchInput] = useState('');

  useEffect(() => {
    if (exercises) {
      let filteredExercises = exercises;
      if (searchInput) {
        filteredExercises = filteredExercises.filter(exercise => {
          return exercise.name?.toLowerCase().includes(searchInput);
        });
      }
      setData(filteredExercises);
    }
  }, [exercises, searchInput]);

  const columns = [
    {
      title: 'Упражнение',
      dataIndex: 'name',
      width: '70%'
    },
    {
      title: 'Действие',
      key: 'action',
      render: (text, item) => (
        <span>
          <Button
            onClick={() => {
              setDisplay('edit');
              setEditExercise(item);
            }}
          >
            Редактировать
          </Button>
          <Divider type="vertical" />
          <Button
            type="danger"
            onClick={() => {
              showConfirm(() => {
                deleteExerciseClick(item.id);
              });
            }}
          >
            Удалить
          </Button>
        </span>
      )
    }
  ];

  const fields = [
    {
      key: 'name',
      label: 'Название упражнения',
      tooltipText: 'Пример: “Жим лежа”'
    },
    // {
    //   key: 'description',
    //   label: 'Описание',
    //   textArea: true
    // },
    {
      key: 'videoURL',
      label: 'Ссылка на видео',
      isNotRequired: true,
      tooltipText: 'Видео-инструкция выполнения упражнения'
    },
    {
      key: 'videoIdentifier',
      label: 'Кодовое название упражнения',
      isNotRequired: true,
      tooltipText: 'Кодовое название упражнения для нахождения видео'
    },
    {
      key: 'type',
      label: 'Тип упражнения',
      isNotRequired: true,
      mode: 'multiple',
      options: [
        { value: 'assessment', label: 'assessment' },
        { value: 'endGame', label: 'endGame' }
      ],
      tooltipText: 'Тип упражнения'
    }
  ];

  const editFields = [
    {
      key: 'name',
      label: 'Название упражнения',
      value: editExercise && editExercise.name,
      isRequired: true,
      tooltipText: 'Пример: “Жим лежа”'
    },
    // {
    //   key: 'description',
    //   label: 'Описание',
    //   value: editExercise && editExercise.description,
    //   textArea: true,
    //   isRequired: true
    // },
    {
      key: 'videoURL',
      label: 'Ссылка на видео',
      value: editExercise && editExercise.videoURL,
      isNotRequired: true,
      tooltipText: 'Видео-инструкция выполнения упражнения'
    },
    {
      key: 'videoIdentifier',
      label: 'Кодовое название упражнения',
      value: editExercise && editExercise.videoIdentifier,
      isNotRequired: true,
      tooltipText: 'Кодовое название упражнения для нахождения видео'
    },
    {
      key: 'type',
      label: 'Тип упражнения',
      isNotRequired: true,
      value: editExercise && editExercise.type,
      mode: 'multiple',
      options: [
        { value: 'assessment', label: 'assessment' },
        { value: 'endGame', label: 'endGame' }
      ],
      tooltipText: 'Тип упражнения'
    }
  ];

  const handleCreate = values => {
    addExerciseClick(values);
    setModalVisible(false);
  };

  const handleUpdate = values => {
    updateExerciseClick(editExercise.id, values);
    setDisplay('default');
    setEditExercise(null);
  };

  return (
    <>
      <Table
        dataSource={data}
        columns={columns}
        rowKey={item => item.id}
        title={() => (
          <StyledHeaderContainer>
            <StyledInput
              prefix={<SearchOutlined />}
              placeholder="Поиск"
              onChange={e => {
                setSearchInput(e.target.value.toLowerCase());
              }}
            />

            <Button type="primary" onClick={() => setModalVisible(true)}>
              <PlusOutlined />
              Новое упражнение
            </Button>
          </StyledHeaderContainer>
        )}
      />
      <CreateForm
        title="Добавить новое Упражнение"
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        onCreate={handleCreate}
        fields={fields}
      />
      {display === 'edit' && editFields && (
        <EditForm
          title="Редактировать Упражнение"
          visible={display === 'edit'}
          onCancel={() => setDisplay('default')}
          onUpdate={handleUpdate}
          fields={editFields}
          onDelete={() => {
            showConfirm(() => {
              deleteExerciseClick(editExercise?.id);
            });
          }}
        />
      )}
    </>
  );
};

Exercises.propTypes = {
  exercises: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      description: PropTypes.string,
      videoUrl: PropTypes.string,
      videoIdentifier: PropTypes.string
    }).isRequired
  ).isRequired,
  deleteExerciseClick: PropTypes.func.isRequired,
  addExerciseClick: PropTypes.func.isRequired,
  updateExerciseClick: PropTypes.func.isRequired
};

export default Exercises;
