import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import { gql, useMutation, useQuery } from "@apollo/client";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

import Club from "./Club";
import Loading from "../shared/Loading";
import { useLoading } from "../../context/useLoading";
import withMainLayout from "../../hocs/withMainLayout";

const GET_DATA = gql`
  query getData($id: ID!) {
    club(id: $id) {
      id
      name
      city
      country
      zones {
        id
      }
      tunnel
      locksCount {
        male
        female
      }
    }
  }
`;
const GET_CLUBS = gql`
  query getClubs {
    clubs {
      id
      name
      city
      country
      zones {
        id
      }
      tunnel
      locksCount {
        male
        female
      }
    }
  }
`;

const UPDATE_CLUB = gql`
  mutation updateClub($id: ID!, $input: ClubInput) {
    updateClub(id: $id, input: $input) {
      id
      name
      city
      country
      tunnel
      locksCount {
        male
        female
      }
    }
  }
`;
const DELETE_CLUB = gql`
  mutation deleteClub($id: ID!) {
    deleteClub(id: $id)
  }
`;

const ClubContainer = () => {
  const { id: clubId } = useParams();
  const navigate = useNavigate();
  const [clubData, setClubData] = React.useState(null);
  const { showLoading, hideLoading } = useLoading();

  const { data, loading, error } = useQuery(GET_DATA, {
    variables: { id: clubId }
  });
  const [updateClub] = useMutation(UPDATE_CLUB, {
    onCompleted: () => {
      toast.success("Клуб успешно обновлен");
      hideLoading();
    }
  });

  const [deleteClub] = useMutation(DELETE_CLUB, {
    update(cache, { data: { deleteClub: id } }) {
      const { clubs } = cache.readQuery({ query: GET_CLUBS });
      cache.writeQuery({
        query: GET_CLUBS,
        data: {
          clubs: clubs.filter((club) => club.id !== id)
        }
      });
      hideLoading();
      toast.success("Клуб успешно удален");
      navigate("/clubs");
    }
  });

  useEffect(() => {
    if (data && data.club) {
      setClubData(data.club);
    }
  }, [data, loading, error]);

  if (loading && !clubData)
    return (
      <div>
        <Loading />
      </div>
    );

  const handleUpdateClick = (values) => {
    showLoading();
    updateClub({ variables: { id: clubId, input: values } });
  };

  const handleDeleteClick = () => {
    showLoading();
    deleteClub({ variables: { id: clubId } });
  };
  return (
    <>
      {clubData && (
        <Club
          club={clubData}
          updateClick={handleUpdateClick}
          deleteClick={handleDeleteClick}
        />
      )}
    </>
  );
};

ClubContainer.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired
    })
  }).isRequired
};

export default withMainLayout(ClubContainer);
