
import { gql } from '@apollo/client';

export const GET_REQUEST_QUESTIONS = gql`
query allRequestQuestions($adminId: ID, $query: PaginationQuery, $userFind: UserFind, $startDate: String, $endDate: String) {
	allRequestQuestions(
		adminId: $adminId, 
		query: $query, 
		userFind: $userFind,
		startDate: $startDate,
		endDate: $endDate,
	) {
			total
			limit
			page
			pages
			requestQuestions {
					id
					status
					isAnswerViewed
					created_at
					updated_at
					lastAnswerDate
					event
					marathonEvent
					requestCategory {
							_id
							name
					}
					requestSubcategory {
							_id
							name
					}
					queries {
							media
							desc
							title
							created_at
							adminId {
								id
								nickname
							}
					}
					affectedUser {
						id
					}
					assigned {
							id
							nickname
							lastName
							firstName
					}
					user {
							id
							username
							role
							nickname
							firstName
							phoneNumber
							club {
								id
								name
								city
							}
					}
			}
	}
}
`;

export const UPDATE_USER_REQUEST = gql`
  mutation updatedUserRequestQuestion($id: ID!, $input: RequestQuestionInput) {
    updatedUserRequestQuestion(id: $id, input: $input) {
      id
    }
  }
`;

export const GET_ADMINS = gql`
	query usersByRole {
		usersByRole(role: "admin") {
				id
				nickname
		}
	}
`;

export const GET_REQUEST_QUESTIONS_BY_TYPE = gql`
  query AllRequestQuestionsByType {
    allRequestQuestionsByType {
			finished
			userProblemNotSolved
			moreDataForAdmin
			inProgress
			notViewed
    }
  }
`;
